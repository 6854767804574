import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  outline: {
    outline: "none"
  },
  searchBar: {
    backgroundColor: "#0D76B9",
    padding: "15px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 8,
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  inputSearch: {
    backgroundColor: "#fff",
    borderRadius: 20,
    "& .MuiInputBase-root": {
      borderRadius: 20,
      [theme.breakpoints.down("xs")]: {
        borderRadius: 10,
      },
      "& .MuiOutlinedInput-input": {
        fontSize: 12,
      },
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: 10,
    },
  },
  searchIcon: {
    fontSize: 30,
    color: "#797979",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  icon_filter: {
    backgroundColor: "#fff",

    width: 40,
    height: 30,
    maxHeight: 35,
    padding: 5,
    borderRadius: 8,
    cursor: "pointer",

    [theme.breakpoints.down("xs")]: {
      width: 30,
      height: 25,
      maxHeight: 30,
      borderRadius: 5,
    },
    "&> img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  form: {
    padding: "10px 25px",
    borderRadius: 8,
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  form__wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F3F3F3",
    borderRadius: 30,
    marginLeft: 20,
    cursor: "pointer",
    zIndex: 99,
    width: "100%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
    },
    "& >h6": {
      width: "100%",
      color: "#AAAAAA",
      padding: "15px",
      fontSize: 14,
      [theme.breakpoints.down("xs")]: {
        padding: "8px",
        fontSize: 12,
      },
    },
    "&> button": {
      position: "absolute",
      right: 10,
    },
  },
  form__action: {
    display: "flex",
    justifyContent: "space-between",
    width: "40%",
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  form__field: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "& >img": {
      marginRight: 8,
      width: 40,
      cursor: "pointer",
    },
    "&> p": {
      fontSize: 18,
    },
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: "50%",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: 35,
      height: 35,
    },
  },
  icon_emoji: {
    height: 30,
    width: 30,
    [theme.breakpoints.down("xs")]: {
      width: 25,
      height: 25,
    },
  },
  root_modal: {
    width: "100%",
  },
  modal__container: {
    maxWidth: 560,
    borderRadius: 12,
    backgroundColor: "#fff",
    zIndex: 9,
    margin: "0 auto",
    position: "absolute",
    top: "-30%",
    left: "50%",
    transform: "translate(-50%,10%)",
    width: "100%",
  },
  modal__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px",
    minHeight: 65,
    borderBottom: "1px solid rgb(235, 235, 235)",
    "& >h6": {
      fontWeight: 700,
      fontSize: 18,
    },
  },
  modal__content: {
    maxHeight: 400,
    overflowY: "auto",
    padding: "15px 20px",
  },
  modal__content__info: {
    display: "flex",
    alignItems: "center",
  },
  modal__username: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "27px",
  },
  modal__category: {
    fontSize: 12,
    color: "#828282",
  },
  modal__avatar: {
    width: 50,
    height: 50,
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: 10,
  },
  modal__input__post: {
    "& .MuiInputBase-root": {
      "&::after": {
        display: "none",
      },
      "&::before": {
        display: "none",
      },
    },
  },
  modal__content__actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #B9B9B9",
    padding: 10,
    "& >h6": {
      cursor: "pointer",
    },
  },
  modal__content__actions__icon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "25%",
    [theme.breakpoints.down("xs")]: {
      width: "30%",
    },
    "& >img": {
      marginRight: 8,
      width: 40,
      height: "100%",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: 30,
      },
    },
  },
  modal__content__btn: {
    background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
    borderRadius: 10,
    fontSize: 13,
    color: "#fff",
    padding: "5px 12px",
    minWidth: 120,
  },
  modal__dropzone: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    width: "100%",
    position: "relative",
  },
  modal__dropzone__video__iconClose: {
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 99,
    backgroundColor: "#FFF",
    borderRadius: "50%",
    width: 25,
    height: 25,
    cursor: "pointer",
  },
  modal__dropzone__video__wrapper: {
    backgroundColor: "#f7f8fa",
    width: "100%",
    height: 200,
    boxShadow: "0px 0px 4px rgba(168, 168, 168, 0.25)",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#ECECEC",
      transition: "linear 0.3s all",
    },
    "& >input ": {
      display: "none",
    },
  },
  modal__dropzone__video__content: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& > span": {
      color: "#AAAAAA",
      fontSize: 13,
    },
  },
  imageReader: {
    width: "240px",
    height: "240px",
    objectFit: "cover",
  },
  card: {
    padding: 25,
    margin: "20px 0",
    borderRadius: 8,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  card__wrapper: {
    borderTop: "1px solid #CDCDCD",
    borderBottom: "1px solid #CDCDCD",
    width: "100%",
    padding: "15px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px",
    },
  },
  card__actions: {
    width: "45%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "65%",
    },
    "&>div": {
      display: "flex",
      alignItems: "center",
    },
  },
  card__actions__icon: {
    width: 30,
    height: 30,
    marginRight: 5,
    cursor: "pointer",
  },

  form__wrapper__comment: {
    marginLeft: 0,
    padding: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  input__comment: {
    "& .MuiInputBase-root": {
      marginLeft: 10,
      "&::after": {
        display: "none",
      },
      "&::before": {
        display: "none",
      },
    },
  },
  btn_menu_category: {
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: 8,
    textTransform: "initial",
    width: "100%",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
  },
  popover_category: {
    "& .MuiPaper-root": {
      marginTop: 12,
      overflow: "initial",
      "&::before": {
        content: "''",
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderBottom: "8px solid #fff",
        position: "absolute",
        left: "75%",
        top: -8,
        zIndex: 999,
      },
    },
  },

  menu__category__list: {
    minWidth: 150,
    maxHeight: 250,
    overflowY: "auto",
  },
  menu__category__icon: {
    width: 25,
    maxHeight: 15,
    marginRight: 8,
  },

  popover_filter_list: {
    "& .MuiPaper-root": {
      marginTop: 18,
      overflow: "initial",
      borderRadius: 10,
      "&::before": {
        content: "''",
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderBottom: "8px solid #fff",
        position: "absolute",
        left: "70%",
        top: -8,
        zIndex: 999,
      },
    },
  },
  menu__filter__list: {
    minWidth: 300,
    // maxHeight: 250,
    // overflowY: "auto",
    padding: "10px 15px",
  },
  menu__filter__title: {
    fontWeight: 700,
    fontSize: 15,
  },
  menu__filter__viewer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& >div": {
      paddingBottom: 10,
    },
    "&>div> h6": {
      paddingLeft: 15,
      fontSize: 13,
      color: "#333333",
      cursor: "pointer",
    },
    "&> div>p": {
      fontSize: 11,
      color: "#BDBDBD",
      paddingLeft: 15,
    },
    "& > img": {
      width: 25,
      height: 25,
      cursor: "pointer",
    },
  },
}));

export default useStyles;
