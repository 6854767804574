import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button, IconButton, makeStyles, Theme, Tooltip, Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import managerGroupApi from "api/managerGroupApi";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_create_test.png";
import CheckboxField from "components/FormFields/CheckboxField";
import InputField from "components/FormFields/InputField";
import {RadioGroupField} from "components/FormFields/RadioGroupFiled";
import {SelectField} from "components/FormFields/SelectFiled";
import {ListOptions, ListResponseOptions} from "models/managerGroup";
import {
  fetchPrizeList,
  selectFilterPrizeList,
  selectPrizeList,
} from "features/Admin/pages/ManagerPrize/managerPrizeSlice";
import {
  fetchDataOptions,
  selectListArea,
  selectListDepartment,
} from "features/Auth/options/optionsSlice";
import {Question} from "models/managerTest";
import moment from "moment";
import {Fragment, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import * as yup from "yup";
import CreateQuestion from "./components/CreateQuestion";
import authApi from "api/authApi";

export default function AddExam() {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      flexGrow: 1,
      "& .MuiFormGroup-root": {
        flexDirection: "row",
      },
    },
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 130,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        top: 50,
        left: 0,
        color: "#004673",
        fontSize: 25,
        fontWeight: 700,
      },
    },
    title: {
      fontSize: 15,
      fontWeight: 600,
      width: "9%",
      [theme.breakpoints.up("xl")]: {
        width: "10%",
      },
    },

    btn_create: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 20,
      padding: "10px 15px",
    },
  }));

  const {t} = useTranslation();

  const schema = yup.object().shape({
    startDate: yup.string().required(`${t("managerTest.vuilongchonngaykiemtra")}`),
    time: yup.string().required(`${t("managerTest.vuilongchongiokiemtra")}`),
    examTime: yup.string().required(`${t("managerTest.vuilongnhapthoigianthi")}`),
    workTypeId: yup.string().when("allWorkType", {
      is: false,
      then: yup.string().required(`${t("managerTest.vuilongchonbophan")}`),
    }),
    category: yup.string().required("*Vui lòng chọn hình thức kiểm tra"),
  });
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const listArea = useAppSelector(selectListArea);
  const [listWorkTypes, setListWorkTypes] = useState<any>([]);
  const listDepartment = useAppSelector(selectListDepartment);
  const [listProvince, setListProVince] = useState<any>([]);
  const [listFarm, setListFarm] = useState<any>([]);
  const prizeList = useAppSelector(selectPrizeList);
  const filter = useAppSelector(selectFilterPrizeList);
  const [valueForm, setValueForm] = useState<Question>();
  const [createQuestion, setCreateQuestion] = useState(false);
  const [disableDepartment, setDisableDepartment] = useState(false);
  const [disableArea, setDisableArea] = useState(false);
  const [errorHierachy, setErrorHierachy] = useState("");
  const [isAll, setIsAll] = useState(false);
  const [hierachies, setHierachies] = useState<any>([
    {
      id: Math.random() * 9999,
    },
  ]);
  const [listPosition, setListPosition] = useState<any>([]);

  const initialValue: Question = {
    isAll: false,
    departmentId: "",
    type: "",
    startDate: "",
    examTime: "",
    time: "",
    prizeId: "",
    totalPoint: "",
    allDepartment: false,
    category: "",
    farmId: "",
    positionId: "",
    newProvinceId: "",
  };
  const {control, handleSubmit, setValue} = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    (async () => {
      try {
        const data: any = await authApi.getPosition();
        setListPosition(data.positionList.content);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(fetchDataOptions());
    dispatch(fetchPrizeList(filter));
  }, [dispatch]);
  useEffect(() => {
    (async () => {
      try {
        const res: any = await managerGroupApi.getWorkTypes();
        setListWorkTypes(res.workType);
      } catch (error) {}
    })();
  }, []);
  useEffect(() => {
    if (isAll) {
      setDisableArea(true);
      setDisableDepartment(true);
      setHierachies([
        {
          id: Math.random() * 9999,
        },
      ]);
      setValue("areaId", "");
      setValue("departmentId", "");
      setValue("workTypeId", "");
      setValue("newProvinceId", "");
      setValue("farmId", "");
      setValue("positionId", "");
    } else {
      setDisableArea(false);
      setDisableDepartment(false);
    }
  }, [isAll]);

  const handleSubmitForm = (value: Question) => {
    if (
      hierachies.length === 1 &&
      !isAll &&
      hierachies.every((x: any) => !x.areaId && !x.departmentId && !x.workTypeId && !x.positionId)
    ) {
      setErrorHierachy(`${t("managerLesson.vuilongchonphancap")}`);
      toast.error(`${t("managerLesson.vuilongchonphancap")}`);
      return;
    }
    const currentHours = new Date().getHours();
    const currentMinutes = new Date().getMinutes();
    if (value.time) {
      const currentDay = moment(new Date()).format("YYYY-MM-DD");
      const currentTimeStamp = currentHours * 3600 + currentMinutes * 60;
      const timeClicked = Number(value.time.slice(0, 2)) * 3600 + Number(value.time.slice(-2)) * 60;
      if (currentTimeStamp > timeClicked && currentDay === value.startDate) {
        toast.error(`${t("managerTest.vuilongchongiokiemtralonhonhoacbanggiohientai")}`);
        return;
      }
    }
    const convertExamTime =
      (Math.floor(Number(value?.examTime) / 60) < 10
        ? "0" + Math.floor(Number(value?.examTime) / 60)
        : Math.floor(Number(value?.examTime) / 60)) +
      ":" +
      (Number(value?.examTime) % 60 > 0
        ? Number(value?.examTime) % 60
        : "0" + (Number(value?.examTime) % 60));

    const endDate =
      Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) > 59
        ? Number(value?.time?.split(":")[0]) +
          Number(convertExamTime?.split(":")[0]) +
          1 +
          ":" +
          (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60 < 10
            ? "0" +
              (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60)
            : Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60)
        : Number(value?.time?.split(":")[0]) +
          Number(convertExamTime?.split(":")[0]) +
          ":" +
          (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]));
    setValueForm({
      ...value,
      startDate: value.startDate + "T" + value.time,
      prizeId: Number(value.prizeId) || "",
      totalPoint: Number(value.totalPoint) || "",
      type: value.category === "multipleChoice" ? "CHOICES" : "TYPING",
      hiarachies: hierachies,
      endDate: value.startDate + "T" + endDate,
    });
    setCreateQuestion(true);
  };
  const handleAddMoreHierachy = () => {
    setHierachies((prevHierachies: any) => [
      ...prevHierachies,
      {
        id: Math.random() * 9999,
      },
    ]);
  };

  const handleDeleteHierachy = (id: number) => {
    const hrchy = [...hierachies];
    if (hierachies.length > 1) {
      hrchy.splice(
        hrchy.findIndex((x) => x.id === id),
        1
      );
    }
    setHierachies(hrchy);
  };

  const handleGetListFarm = async (option: ListOptions, name: string, index: number) => {
    try {
      const res: ListResponseOptions<ListOptions> = await managerGroupApi.getFarms(
        name === "newProvinceId" ? option.id : hierachies[index].newProvinceId,
        name === "workTypeId" ? option.id : hierachies[index].workTypeId
      );
      if (res.responseCode === "00") {
        // setDisableFarm(false);
        const farms = [...listFarm];
        const foundIdx = farms.findIndex((x) => x.id === index);
        if (foundIdx > -1) {
          const newArr = farms.map((x: any) => {
            if (x.id === index) {
              return {
                id: x.id,
                farms: res.listFarm,
              };
            } else {
              return {
                ...x,
              };
            }
          });
          setListFarm(newArr);
        } else {
          setListFarm((x: any) => [
            ...x,
            {
              id: index,
              farms: res.listFarm,
            },
          ]);
        }
      } else {
        // setDisableFarm(true);
      }
    } catch (error) {}
  };

  const handleChangeWorkType = async (option: any, index: number) => {
    const hrchy = [...hierachies];
    hrchy[index].workTypeId = option.id;
    hrchy[index].nameWorkType = option.name;
    setHierachies(hrchy);
    await handleGetListFarm(option, "workTypeId", index);
  };
  const handleChangeDepartment = (option: any, index: number) => {
    const hrchy = [...hierachies];
    hrchy[index].departmentId = option.id;
    hrchy[index].nameDepartment = option.name;
    setHierachies(hrchy);
  };
  const handleChangeArea = async (option: any, index: number) => {
    const hrchy = [...hierachies];
    const farms = [...listFarm];
    hrchy[index].nameArea = option.name;
    hrchy[index].areaId = option.id;
    if (hrchy[index].newProvinceId && listFarm[index].farms) {
      hrchy[index].newProvinceId = "";
      farms[index].farms = [];
      setListFarm(farms);
    }
    setHierachies(hrchy);
    await handleGetListProvinceByAreaId(option, index);
  };

  const handleChangeFarm = (option: any, index: number) => {
    const hrchy = [...hierachies];
    hrchy[index].farmId = option.id;
    setHierachies(hrchy);
  };
  const handleChangePosition = (option: any, index: number) => {
    const hrchy = [...hierachies];
    hrchy[index].positionId = option.id;
    hrchy[index].namePosition = option.name;
    setHierachies(hrchy);
  };

  const handleChangeCity = async (option: any, index: number) => {
    const hrchy = [...hierachies];
    hrchy[index].newProvinceId = option.id;
    hrchy[index].nameProvince = option.name;
    setHierachies(hrchy);
    await handleGetListFarm(option, "newProvinceId", index);
  };

  const handleGetListProvinceByAreaId = async (option: ListOptions, index: number) => {
    try {
      const res: ListResponseOptions<ListOptions> = await managerGroupApi.getProvincesByAreaId(
        option?.id
      );
      if (res.responseCode === "00") {
        const provinces = [...listProvince];
        const foundIdx = provinces.findIndex((x) => x.id === index);
        if (foundIdx > -1) {
          const newArr = provinces.map((x: any) => {
            if (x.id === index) {
              return {
                id: x.id,
                provinces: res.provinces,
              };
            } else {
              return {
                ...x,
              };
            }
          });
          setListProVince(newArr);
        } else {
          setListProVince((x: any) => [
            ...x,
            {
              id: index,
              provinces: res.provinces,
            },
          ]);
        }
      }
    } catch (error) {}
  };

  return (
    <Box>
      {createQuestion ? (
        <CreateQuestion
          valueForm={valueForm}
          setValueForm={setValueForm}
          setCreateQuestion={setCreateQuestion}
        />
      ) : (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box className={classes.background}>
            <Typography variant="subtitle2">
              {t("managerTest.thongtinchung").toUpperCase()}
            </Typography>
          </Box>
          {/* <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.bophan")}
            </Typography>
            <Box width="50%" mr={5}>
              <SelectField
                options={department}
                name="departmentId"
                control={control}
                label={t("managerTest.chonbophan")}
                disabled={disableDepartment}
                department="department"
              />
            </Box>
            <CheckboxField
              name="allDepartment"
              label={t("tatca")}
              control={control}
              handleChange={setDisableDepartment}
            />
          </Box> */}
          {hierachies.map((hierachy: any, index: number) => (
            <Box className={classes.root} key={hierachy.id}>
              {index === 0 ? (
                <Typography variant="subtitle2" className={classes.title}>
                  {t("managerLesson.phancap")}
                </Typography>
              ) : (
                <Typography variant="subtitle2" className={classes.title}></Typography>
              )}

              <Box className={classes.root}>
                <Box width="12%">
                  <SelectField
                    name={`${hierachies.length === 1 ? "areaId" : `areaId-${index}`}`}
                    label={t("auth.area")}
                    options={listArea.map(({id, name}) => ({
                      id,
                      name: name,
                    }))}
                    control={control}
                    handleClick={handleChangeArea}
                    disabled={disableArea}
                    idxItemClick={index}
                    value={hierachy.areaId || ""}
                  />
                </Box>
                <Box width="12%" ml={2}>
                  <SelectField
                    name={`${hierachies.length === 1 ? "newProvinceId" : `newProvinceId-${index}`}`}
                    label={t("auth.city")}
                    options={listProvince.find((x: any) => x.id === index)?.provinces || []}
                    control={control}
                    disabled={
                      listProvince?.filter((province: any) => province.id === index)[0]?.id ===
                      index
                        ? false
                        : true
                    }
                    idxItemClick={index}
                    value={hierachy.newProvinceId || ""}
                    handleClick={handleChangeCity}
                  />
                </Box>
                <Box ml={2} width="12%">
                  <SelectField
                    name={`${hierachies.length === 1 ? "departmentId" : `departmentId-${index}}`}`}
                    label={t("auth.department")}
                    options={listDepartment.map(({id, title}) => ({
                      id,
                      name: title,
                    }))}
                    disabled={disableDepartment}
                    control={control}
                    handleClick={handleChangeDepartment}
                    idxItemClick={index}
                    value={hierachy.departmentId || ""}
                  />
                </Box>
                <Box ml={2} width="12%">
                  <SelectField
                    name={`${hierachies.length === 1 ? "workTypeId" : `workTypeId-${index}`}`}
                    label={t("auth.workType")}
                    options={listWorkTypes.map((workType: any) => ({
                      id: workType?.id,
                      name: workType?.name,
                    }))}
                    disabled={
                      listDepartment?.filter((department) => department.id === 15)[0]?.id ===
                      Number(hierachy.departmentId)
                        ? false
                        : true
                    }
                    control={control}
                    handleClick={handleChangeWorkType}
                    idxItemClick={index}
                    value={hierachy.workTypeId || ""}
                  />
                </Box>
                <Box width="12%" ml={2}>
                  <SelectField
                    name={`${hierachies.length === 1 ? "farmId" : `farmId-${index}`}`}
                    label={t("auth.farm")}
                    options={listFarm.find((x: any) => x.id === index)?.farms || []}
                    disabled={
                      listFarm?.filter((farm: any) => farm.id === index)[0]?.id === index
                        ? false
                        : true
                    }
                    control={control}
                    idxItemClick={index}
                    value={hierachy.farmId || ""}
                    handleClick={handleChangeFarm}
                  />
                </Box>
                <Box width="12%" mx={2}>
                  <SelectField
                    name={`${hierachies.length === 1 ? "positionId" : `positionId-${index}`}`}
                    label={t("auth.position")}
                    options={listPosition}
                    control={control}
                    idxItemClick={index}
                    value={hierachy.positionId || ""}
                    handleClick={handleChangePosition}
                  />
                </Box>
                {index === 0 && (
                  <Fragment>
                    <CheckboxField
                      name="isAll"
                      label="Tất cả"
                      control={control}
                      handleChange={setIsAll}
                    />
                    {!isAll && (
                      <Tooltip title="Add more">
                        <IconButton onClick={handleAddMoreHierachy}>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Fragment>
                )}
                {index > 0 && (
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleDeleteHierachy(hierachy.id)}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          ))}
          {errorHierachy !== "" && (
            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}></Typography>
              <Typography variant="subtitle2" color="secondary" style={{flexGrow: 1}}>
                {errorHierachy}
              </Typography>
            </Box>
          )}

          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.ngaygiokiemtra")}
            </Typography>
            <Box display="flex" flexGrow={1}>
              <InputField
                control={control}
                type="date"
                label=""
                name="startDate"
                min={moment(new Date()).format("YYYY-MM-DD")}
              />
              <Box ml={2} width="100%">
                <InputField control={control} type="time" label="" name="time" />
              </Box>
            </Box>
          </Box>

          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.thoigianlambai")}
            </Typography>
            <Box width="30%">
              <InputField
                control={control}
                name="examTime"
                label={t("managerTest.nhapthoigianthi")}
              />
              <Box ml={3}>
                <Typography variant="subtitle2" component="i" style={{color: "#999999"}}>
                  ({t("managerTest.vidu")}: 100 = 01:40:00)
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.hinhthuckiemtra")}
            </Typography>

            <RadioGroupField
              name="category"
              label=""
              control={control}
              options={[
                {label: `${t("managerTest.tracnghiem")}`, value: "multipleChoice"},
                {label: `${t("managerTest.tuluan")}`, value: "essay"},
              ]}
            />
          </Box>

          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.tongdiem")}
            </Typography>
            <Box width="30%">
              <InputField
                control={control}
                name="totalPoint"
                label={t("managerTest.nhaptongdiem")}
              />
            </Box>
          </Box>

          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.phanthuong")}
            </Typography>

            <Box width="80%">
              <SelectField
                options={prizeList?.content}
                name="prizeId"
                control={control}
                label={t("managerTest.chonphanthuong")}
              />
            </Box>
          </Box>
          <Box textAlign="right">
            <Button type="submit" className={classes.btn_create}>
              {t("managerTest.batdautaodethi")}
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
}
