import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Menu,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import managerGroupApi from "api/managerGroupApi";
import managerLessonApi from "api/managerLesson";
import {useAppDispatch, useAppSelector} from "app/hooks";
import EMOJI from "assets/img/emoji.png";
import ICON_VIDEO from "assets/img/icon_video.png";
import CheckboxField from "components/FormFields/CheckboxField";
import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import Picker, {SKIN_TONE_MEDIUM_DARK} from "emoji-picker-react";
import {
  fetchCategoryList,
  selectCategoryList,
} from "features/Admin/pages/ManagerCategory/managerCategorySlice";
import {
  fetchDataOptions,
  fetchListWorkTypes,
  selectListArea,
  selectListDepartment,
  selectListWorkTypes,
} from "features/Auth/options/optionsSlice";
import {ResponseStatus} from "models/common";
import {ListOptions, ListResponseOptions} from "models/managerGroup";
import {CreateLesson, Lesson} from "models/managerLesson";
import moment from "moment";
import React, {useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";

export default function EditLesson() {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      marginBottom: 5,
      width: "100%",
    },
    header: {
      color: "#004673",
      fontSize: 20,
      fontWeight: 700,
      marginBottom: 10,
      marginTop: 30,
    },
    title: {
      fontSize: 15,
      fontWeight: 600,
      width: "17%",
      [theme.breakpoints.up("xl")]: {
        fontSize: 18,
        width: "14%",
      },
    },
    selectArea: {
      padding: 6,
      backgroundColor: disableArea ? "#f8f4f4" : "#fff",
    },
    label: {
      padding: 5,
      color: "#999999",
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 9,
      },
    },

    btn_create: {
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      color: "#fff",
      borderRadius: 10,
      padding: "10px 15px",
      marginLeft: 10,
    },
    btn_draft: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 10,
      padding: "10px 15px",
    },
    icon_emoji: {
      position: "absolute",
      bottom: 10,
      right: 10,
      "& >span >img": {
        width: 25,
        height: 25,
      },
    },
    form__video__wrapper: {
      backgroundColor: "#fff",
      width: "100%",
      height: 400,
      boxShadow: "0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 8,
      "&:hover": {
        backgroundColor: "#ECECEC",
        transition: "linear 0.3s all",
      },
      "& >input ": {
        display: "none",
      },
    },
    form__video__iconClose: {
      position: "absolute",
      right: 10,
      top: 10,
      zIndex: 99,
      backgroundColor: "#FFF",
      borderRadius: "50%",
      width: 25,
      height: 25,
      cursor: "pointer",
    },
    form__video__content: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
      "& > span": {
        color: "#AAAAAA",
        fontSize: 13,
      },
    },
    btn_goBack: {
      position: "absolute",
      top: -45,
      left: -20,
    },
  }));
  const {t} = useTranslation();
  const schema = yup.object().shape({
    publishDate: yup.string().when("postNow", {
      is: false,
      then: yup.string().required(`${t("managerLesson.vuilongchonngaydang")}`),
    }),
    publishTime: yup.string().when("postNow", {
      is: false,
      then: yup.string().required(`${t("managerLesson.vuilongchongiodang")}`),
    }),
    lessionCategoryId: yup.string().required(`${t("managerLesson.vuilongchonchude")}`),
    lessionDescription: yup.string().required(`${t("managerLesson.vuilongchonmota")}`),
    lessionTitle: yup.string().required(`${t("managerLesson.vuilongnhaptenbaihoc")}`),
    // areaId: yup
    //   .string()
    //   .when("isAll", {is: false, then: yup.string().required("Vui lòng chọn khu vực")}),
    // workTypeId: yup
    //   .string()
    //   .when("isAll", {is: false, then: yup.string().required("Vui lòng chọn bộ phận")}),
  });
  const location: any = useLocation();
  const isPostedPathname = location?.state?.type === "POSTED";
  const detailLesson: Lesson = location.state.detailLesson;
  const listArea = useAppSelector(selectListArea);
  const listCategory = useAppSelector(selectCategoryList);
  const listWorkTypes = useAppSelector(selectListWorkTypes);
  const [isPostNow, setIsPostNow] = useState(false);
  const [isAll, setIsAll] = useState(detailLesson.isAll);
  const [disableWorkType, setDisableWorkType] = useState(true);
  const [disableDepartment, setDisableDepartment] = useState(true);
  const [disableArea, setDisableArea] = useState(false);
  const [disableDate, setDisableDate] = useState(false);
  const [disableTime, setDisableTime] = useState(false);
  const listDepartment = useAppSelector(selectListDepartment);
  const [idDepartment, setIdDepartment] = useState({
    id: 0,
    name: "",
  });

  const [errorVideo, setErrorVideo] = useState("");
  const [video, setVideo] = useState(detailLesson.attachment.url);
  const [fileVideo, setFileVideo] = useState("");
  const navigate = useNavigate();
  const [openEmoji, setOpenEmoji] = useState<null | HTMLElement>(null);
  const handleOpenEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmoji(event.currentTarget);
  };

  useEffect(() => {
    setIdDepartment({id: detailLesson.departmentId, name: ""});
  }, []);

  const initialValues: CreateLesson = {
    postNow: false,
    isAll: detailLesson.isAll,
    publishDate: `${String(detailLesson?.publishDate).slice(0, 4)}-${String(
      detailLesson?.publishDate
    ).slice(4, 6)}-${String(detailLesson?.publishDate).slice(-2)}`,
    publishTime:
      detailLesson.publishTime >= 1000
        ? `${String(detailLesson.publishTime).slice(0, 2)}:${String(detailLesson.publishTime).slice(
            2,
            4
          )}`
        : `0${String(detailLesson.publishTime).slice(0, 1)}:${String(
            detailLesson.publishTime
          ).slice(1, 3)}`,
    lessionCategoryId: String(detailLesson.lessionCategoryId),
    hiddenDate: moment(detailLesson?.expiryTimestamp).format("YYYY-MM-DD"),
    hiddenTime: moment(detailLesson?.expiryTimestamp).format("HH:mm"),
    lessionDescription: detailLesson.lessionDescription,
    lessionTitle: detailLesson.lessionName,
    areaId: String(detailLesson.areaId) || 0,
    workTypeId: String(detailLesson.workTypeId) || 0,
    departmentId: String(detailLesson.departmentId) || 0,
  };
  const {control, handleSubmit, setValue, getValues} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const onEmojiClick = (event: any, emojiObject: any) => {
    setValue("lessionDescription", getValues().lessionDescription + emojiObject.emoji);
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchDataOptions());
    dispatch(fetchCategoryList());
  }, []);
  useEffect(() => {
    if (isAll) {
      setValue("areaId", "");
      setValue("workTypeId", "");
      setValue("departmentId", "");
      setDisableArea(true);
      setDisableWorkType(true);
      setDisableDepartment(true);
    } else {
      setDisableArea(false);
      setDisableWorkType(false);
      setDisableDepartment(false);
    }
    if (isPostNow) {
      setValue("publishDate", "");
      setValue("publishTime", "");
      setDisableTime(true);
      setDisableDate(true);
    } else {
      setDisableTime(false);
      setDisableDate(false);
    }
  }, [isAll, isPostNow]);
  const classes = useStyles();

  const handleGetListWorkType = async (option: ListOptions) => {
    try {
      const res: ListResponseOptions<ListOptions> = await managerGroupApi.getWorkTypeByArea(
        option?.id
      );
      if (res.responseCode === "00") {
        dispatch(fetchListWorkTypes(res));
      }
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      try {
        const res: ListResponseOptions<ListOptions> = await managerGroupApi.getWorkTypeByArea(
          detailLesson?.areaId
        );
        if (res.responseCode === "00") {
          dispatch(fetchListWorkTypes(res));
        }
      } catch (error) {}
    })();
  }, []);
  useEffect(() => {
    if (video !== "") {
      setErrorVideo("");
    }
  }, [video]);
  const handleSubmitForm = async (value: CreateLesson) => {
    if (video === "") {
      setErrorVideo(`${t("managerLesson.vuilongchonvideobaihoc")}`);
      return;
    }
    const newValue = {
      ...value,
      isAll: value.isAll ? "Y" : "N",
      publishDate: value.postNow
        ? moment(new Date()).format("YYYY/MM/DD").split("/").join("")
        : value.publishDate.split("-").join(""),
      publishTime: value.postNow
        ? moment(new Date()).format("HH/mm").split("/").join("")
        : value.publishTime.split(":").join(""),
      lessionId: String(detailLesson.id),
      hiddenDate: value.hiddenDate?.split("-").join(""),
      hiddenTime: value.hiddenTime?.split(":").join(""),
    };
    const formData = new FormData();
    formData.append("publishDate", newValue.publishDate);
    formData.append("publishTime", newValue.publishTime);
    value.hiddenDate !== "Invalid date" && formData.append("expiryDate", newValue.hiddenDate);
    value.hiddenTime !== "Invalid date" && formData.append("expiryTime", newValue.hiddenTime);
    formData.append("isAll", newValue.isAll);
    formData.append("lessionCategoryId", newValue.lessionCategoryId);
    formData.append("lessionDescription", newValue.lessionDescription);
    value.areaId !== "null" &&
      !isPostedPathname &&
      formData.append("areaId", String(newValue.areaId));
    value.workTypeId !== "null" &&
      !isPostedPathname &&
      formData.append("workTypeId", String(newValue.workTypeId));
    value.departmentId !== "null" &&
      !isPostedPathname &&
      formData.append("departmentId", String(newValue.departmentId));
    fileVideo && !isPostedPathname && formData.append("video", fileVideo);
    formData.append("lessionTitle", newValue.lessionTitle);
    formData.append("lessionId", newValue.lessionId);

    try {
      const res: ResponseStatus = await managerLessonApi.updateLesson(formData);
      if (res.responseCode === "00") {
        toast.success(`${t("managerLesson.capnhatbaihocthanhcong")}`);
        location?.state?.type === "FUTURE"
          ? navigate("/admin/manager-lesson/listFuture")
          : location?.state?.type === "POSTED"
          ? navigate("/admin/manager-lesson/listPosted")
          : navigate("/admin/manager-lesson/listDraft");
      } else {
        toast.error(`${t("managerLesson.capnhatbaihocthatbai")}`);
        throw new Error("Update lesson is failed");
      }
    } catch (error) {}
  };
  const handleChangeFile = (acceptedFiles: any) => {
    setFileVideo(acceptedFiles[0]);
    let reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = (e: any) => {
      setVideo(URL.createObjectURL(acceptedFiles[0]));
    };
  };
  return (
    <Box style={{position: "relative"}}>
      <Box className={classes.btn_goBack}>
        <Tooltip title={t("button.goback") || ""}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{marginLeft: 7}} />
          </IconButton>
        </Tooltip>
      </Box>

      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Typography variant="subtitle2" className={classes.header}>
          {t("admin.chinhsua")}
        </Typography>
        <Box className={classes.root} mt={2}>
          <Box className={classes.root} width="100%">
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.lichdang")}
            </Typography>
            <Box className={classes.root}>
              <Box width="30%">
                <InputField
                  control={control}
                  type="date"
                  label=""
                  name="publishDate"
                  disabled={disableDate}
                />
              </Box>
              <Box ml={2} mr={4} width="30%">
                <InputField
                  control={control}
                  type="time"
                  label=""
                  name="publishTime"
                  disabled={disableTime}
                />
              </Box>

              <CheckboxField
                name="postNow"
                label={t("managerLesson.dangngay")}
                control={control}
                handleChange={setIsPostNow}
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.root}>
          <Box className={classes.root} width="100%">
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.lichan")}
            </Typography>
            <Box className={classes.root}>
              <Box width="30%">
                <InputField control={control} type="date" label="" name="hiddenDate" />
              </Box>
              <Box ml={2} mr={4} width="30%">
                <InputField control={control} type="time" label="" name="hiddenTime" />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.phancap")}
            </Typography>
            <Box className={classes.root}>
              <Box width="30%">
                <SelectField
                  name="areaId"
                  label={t("auth.area")}
                  control={control}
                  borderRadius="4px"
                  options={listArea}
                  handleClick={handleGetListWorkType}
                  disabled={isPostedPathname ? true : disableArea}
                />
              </Box>
              <Box ml={2} width="25%">
                <SelectField
                  name="departmentId"
                  label={t("auth.department")}
                  options={listDepartment.map(({id, title}) => ({
                    id,
                    name: title,
                  }))}
                  disabled={isPostedPathname ? true : disableDepartment}
                  control={control}
                  handleClick={(option: any) => {
                    if (option.name !== "Sản Xuất") {
                      setValue("workTypeId", "");
                    }
                    setIdDepartment({
                      id: option.id,
                      name: option.title,
                    });
                  }}
                />
              </Box>
              <Box ml={2} mr={4} width="25%">
                <SelectField
                  name="workTypeId"
                  label={t("auth.workType")}
                  options={listWorkTypes.map(({workType}) => ({
                    id: workType.id,
                    name: workType.name,
                  }))}
                  disabled={
                    (isPostedPathname
                      ? true
                      : listDepartment?.filter((department) => department.title === "Sản Xuất")[0]
                          ?.id === idDepartment.id
                      ? false
                      : true) || disableWorkType
                  }
                  control={control}
                />
              </Box>
              <CheckboxField
                name="isAll"
                label={t("tatca")}
                control={control}
                handleChange={setIsAll}
                disabled={isPostedPathname ? true : false}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.chude")}
            </Typography>
            <Box className={classes.root}>
              <SelectField
                name="lessionCategoryId"
                label={t("managerLesson.chude")}
                options={listCategory}
                control={control}
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.tenbaihoc")}
            </Typography>
            <Box className={classes.root}>
              <InputField
                name="lessionTitle"
                label={t("managerLesson.tenbaihoc") + "..."}
                control={control}
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.mota")}
            </Typography>
            <Box className={classes.root}>
              <Box position="relative" width="100%">
                <InputField
                  name="lessionDescription"
                  label={t("managerLesson.mota")}
                  control={control}
                  rows={3}
                  multiline
                />
                <IconButton onClick={handleOpenEmoji} className={classes.icon_emoji}>
                  <img src={EMOJI} alt="EMOJI" />
                </IconButton>
                {openEmoji && (
                  <Menu
                    id="simple-menu"
                    anchorEl={openEmoji}
                    keepMounted
                    open={Boolean(openEmoji)}
                    onClose={() => setOpenEmoji(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    //  className={classes.menu}
                  >
                    <Picker
                      onEmojiClick={onEmojiClick}
                      disableAutoFocus={true}
                      skinTone={SKIN_TONE_MEDIUM_DARK}
                      groupNames={{smileys_people: "PEOPLE"}}
                      native
                    />
                  </Menu>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.themvideo")}
            </Typography>
            <Dropzone onDrop={handleChangeFile}>
              {({getRootProps, getInputProps}) => (
                <>
                  <Box className={classes.root} position="relative">
                    {video !== "" && !isPostedPathname && (
                      <Box className={classes.form__video__iconClose} onClick={() => setVideo("")}>
                        <CloseIcon />
                      </Box>
                    )}
                    {video === "" && (
                      <Box
                        {...getRootProps({
                          className: classes.form__video__wrapper,
                        })}
                      >
                        <input {...getInputProps()} accept="video/*" />

                        <Box className={classes.form__video__content}>
                          <img src={ICON_VIDEO} alt="ICON_VIDEO" height="90px" />
                          <Typography variant="subtitle1">
                            {t("managerLesson.themvideo")}
                          </Typography>
                          <Typography variant="subtitle1" component="span">
                            {t("managerLesson.hoackeotha")}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {video !== "" && (
                      <ReactPlayer
                        url={video}
                        controls={true}
                        width="100%"
                        height="100%"
                        config={{file: {attributes: {controlsList: "nodownload"}}}}
                        // Disable right click
                        onContextMenu={(e: any) => e.preventDefault()}
                      />
                    )}
                  </Box>
                </>
              )}
            </Dropzone>
          </Box>
        </Box>
        {errorVideo !== "" && (
          <Typography variant="subtitle2" color="secondary" style={{marginLeft: 150}}>
            {errorVideo}
          </Typography>
        )}
        <Box textAlign="right" mt={3}>
          <Button className={classes.btn_draft} onClick={() => navigate(-1)}>
            {t("button.goback")}
          </Button>
          <Button type="submit" className={classes.btn_create}>
            {t("button.accept")}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
