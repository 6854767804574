import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Department, ListOptions, ListResponseOptions } from "models/managerGroup";

export interface OptionsState {
  listArea: ListOptions[];
  loading: boolean;
  listProvice: ListOptions[];
  listWorkTypes: ListOptions[];
  listFarm: ListOptions[];
  listDepartment: Department[];
}

const initialState: OptionsState = {
  listArea: [],
  loading: false,
  listProvice: [],
  listWorkTypes: [],
  listFarm: [],
  listDepartment: [],
};

const optionsSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    fetchDataOptions(state) {
      state.loading = true;
    },

    fetchDataOptionsSuccess(state) {
      state.loading = false;
    },
    fetchDataOptionsFailed(state) {
      state.loading = false;
    },

    fetchListAreas(state, action: PayloadAction<ListResponseOptions<ListOptions>>) {
      state.listArea = action.payload.listArea;
    },
    // List WorkTypes
    fetchListWorkTypes(state, action: PayloadAction<ListResponseOptions<ListOptions>>) {
      state.listWorkTypes = action.payload.workType;
    },

    //  List Province
    fetchListProvince(state, action: PayloadAction<ListResponseOptions<ListOptions>>) {
      state.listProvice = action.payload.provinces;
    },

    // List Farm
    fetchListFarm(state, action: PayloadAction<ListResponseOptions<ListOptions>>) {
      state.listFarm = action.payload.listFarm;
    },

    // List department
    fetchListDepartment(state, action: PayloadAction<ListResponseOptions<Department>>) {
      state.listDepartment = action.payload.listDepartment;
    },
  },
});

// action
export const {
  fetchDataOptions,
  fetchDataOptionsSuccess,
  fetchDataOptionsFailed,
  fetchListAreas,
  fetchListWorkTypes,
  fetchListProvince,
  fetchListFarm,
  fetchListDepartment,
} = optionsSlice.actions;

//Selector
export const selectListArea = (state: RootState) => state.optionsReducer.listArea;
export const selectListWorkTypes = (state: RootState) => state.optionsReducer.listWorkTypes;
export const selectListProvince = (state: RootState) => state.optionsReducer.listProvice;
export const selectListFarm = (state: RootState) => state.optionsReducer.listFarm;
export const selectListDepartment = (state: RootState) => state.optionsReducer.listDepartment;

const optionsReducer = optionsSlice.reducer;

export default optionsReducer;
