import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  btn_detail: {
    background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
    borderRadius: 10,
    color: "#fff",
    padding: "5px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      fontSize: 8,
      borderRadius: 5,
      minWidth: 45,
    },
  },
  loading: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "& .MuiCircularProgress-root": {
      display: "flex",
      margin: "0 auto",
      height: "100%",
      marginTop: 100,
    },
  },
}));

export default useStyles;
