import {Box, Button, Typography} from "@material-ui/core";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import InputField from "components/FormFields/InputField";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {CreatePrize} from "models/managerPrize";
import {fetchPrizeList, selectFilterPrizeList} from "../../managerPrizeSlice";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {toast} from "react-toastify";
import managerPrizeApi from "api/managerPrize";
import {ResponseStatus} from "models/common";
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";

export interface AddPrizeProps {}

export default function AddPrize(props: AddPrizeProps) {
  const useStyles = makeStyles(() => ({
    title: {
      color: "#004673",
      fontSize: 20,
      fontWeight: 700,
      paddingBottom: 5,
      paddingTop: 5,
    },
    btn_add: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 10,
      padding: "10px 15px",
      alignSelf: "center",
      fontSize: 13,
    },
  }));
  const {t} = useTranslation();

  const schema = yup.object().shape({
    name: yup.string().required(`${t("managerPrize.tenphanthuongkhongduocdetrong")}`),
    condition: yup.string().required(`${t("managerPrize.dieukiennhanthuongkhongduocdetrong")}`),
  });
  const classes = useStyles();
  const filter = useAppSelector(selectFilterPrizeList);
  const dispatch = useAppDispatch();
  const initialValues: CreatePrize = {
    name: "",
    condition: "",
  };
  const {handleSubmit, control, reset} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  const handleSubmitForm = async (value: CreatePrize) => {
    try {
      const res: ResponseStatus = await managerPrizeApi.addPrize(value);
      if (res.responseCode === "00") {
        toast.success(`${t("managerPrize.themphanthuongthanhcong")}`);
        dispatch(fetchPrizeList(filter));
        reset(initialValues);
      } else {
        if (res.responseCode === "30") {
          toast.error(`${t("managerPrize.phanthuongdatontai")}`);
          throw new Error("Create prize is failed");
        }
      }
    } catch (error) {}
  };
  return (
    <Box>
      <Typography variant="subtitle2" className={classes.title}>
        {t("button.addnew").toUpperCase()}
      </Typography>

      <Box>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box display="flex" alignItems="center">
            <Box mr={4} width="80%" mb={1}>
              <InputField
                control={control}
                label={t("managerPrize.tenphanthuong")}
                name="name"
                borderRadius="10px"
              />
            </Box>
            <Button startIcon={<AddIcon />} className={classes.btn_add} type="submit">
              {t("button.addnew")}
            </Button>
          </Box>
          <InputField
            control={control}
            label={t("managerPrize.dieukiennhanthuong")}
            name="condition"
            borderRadius="10px"
            multiline={true}
            rows={4}
          />
        </form>
      </Box>
    </Box>
  );
}
