import {
  Box,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import {makeStyles, withStyles} from "@material-ui/styles";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_exam_upcoming.png";
import LOADING from "assets/img/loading.gif";
import {setFilterListExam} from "features/Admin/pages/ManagerTest/managerExamListSlice";
import moment from "moment";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {dateTimeToFormatHHMM, dateTimeToFormatYYYY} from "utils/dateTimeFormat";
import Timeout from "./components/Timeout";
import {
  fetchListExamStudent,
  SelectFilterListExamStudent,
  SelectListExamStudent,
  selectLoadingListExamStudent,
} from "./listExamStudentSlice";

export default function ListExamStudent() {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        height: 100,
      },
      [theme.breakpoints.down("xs")]: {
        height: 50,
      },
      "& >h6": {
        position: "absolute",
        bottom: 30,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
          bottom: 10,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 12,
          bottom: 10,
        },
      },
    },
    loading: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      "& >img": {
        width: 100,
        height: 100,
      },
    },
  }));

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "10px 2px",
        background: "linear-gradient(360deg, #137BBD 0%, rgba(68, 177, 238, 0.8) 120.47%)",
        color: "#FFFFFF",
        fontSize: 8,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        [theme.breakpoints.up("sm")]: {
          fontSize: 13,
          padding: "16px 5px",
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: 15,
        },
      },
      body: {
        padding: "10px 3px",
        fontSize: 8,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        fontWeight: 500,

        [theme.breakpoints.up("sm")]: {
          fontSize: 13,
          padding: "16px 5px",
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: 15,
        },
      },
    })
  )(TableCell);
  const {t} = useTranslation();

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const listTest = useAppSelector(SelectListExamStudent);
  const loading = useAppSelector(selectLoadingListExamStudent);
  const filter = useAppSelector(SelectFilterListExamStudent);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  useEffect(() => {
    dispatch(
      fetchListExamStudent({...filter, fromDate: moment(new Date()).format("yyyy-MM-DD HH:mm:ss")})
    );
  }, [dispatch, filter]);

  const handleChangePage = (page: number) => {
    dispatch(
      setFilterListExam({
        ...filter,
        page: page - 1,
      })
    );
  };
  return loading ? (
    <Box className={classes.loading}>
      <img src={LOADING} alt="LOADING" />
    </Box>
  ) : (
    <Box px={isMobile ? 1 : 4} mt={2}>
      <Box className={classes.background}>
        <Typography variant="subtitle2">{t("listexam.lichkiemtrasaptoi")}</Typography>
      </Box>
      <Box>
        {listTest.basicExamResponses.content.length > 0 ? (
          <TableContainer component={Paper} style={{overflowX: "initial"}}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" width={isMobile ? "40px" : "70px"}>
                    {t("listexam.stt")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width={isMobile ? "80px" : "150px"}>
                    {t("listexam.ngaythi")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width={isMobile ? "80px" : "150px"}>
                    {t("listexam.giothi")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="300px">
                    {t("listexam.tieudebaithi")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="250px">
                    {t("listexam.phanthuong")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width={isMobile ? "100px" : "200px"}>
                    {t("listexam.thoigian")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listTest?.basicExamResponses?.content?.map((exam, index) => (
                  <TableRow key={exam.id}>
                    <StyledTableCell align="center" component="th" scope="exam">
                      {listTest?.basicExamResponses?.size * listTest?.basicExamResponses?.number +
                        index +
                        1}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {" "}
                      {dateTimeToFormatYYYY(exam.startDate)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {dateTimeToFormatHHMM(exam.startDate)}
                    </StyledTableCell>
                    <StyledTableCell align="center">{exam?.title}</StyledTableCell>

                    <StyledTableCell align="center">{exam?.prize?.name}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Timeout exam={exam} isMobile={isMobile} />
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="subtitle2">{t("listexam.chuacobaikiemtra")}</Typography>
        )}
        {listTest?.basicExamResponses?.totalPages > 1 && (
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Pagination
              count={Math.ceil(
                listTest?.basicExamResponses?.totalElements / listTest?.basicExamResponses?.size
              )}
              page={listTest?.basicExamResponses?.number + 1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={(e, page) => handleChangePage(page)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
