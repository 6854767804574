import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import {Pagination} from "@material-ui/lab";
import {withStyles} from "@material-ui/styles";
import managerUserApi from "api/managerUser";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_manager_admin.png";
import InputField from "components/FormFields/InputField";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {ResponseCreateAdmin} from "models/managerUser";
import {useEffect, useState} from "react";

import {yupResolver} from "@hookform/resolvers/yup";
import {selectInfoLogin} from "features/Auth/authSlice";
import {InfoUser} from "models/auth";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";
import {fetchUserList, selectUserList} from "./managerAdminSlice";
import useStyles from "./style";

export default function ManagerAdmin() {
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const classes = useStyles({
    background: BACKGROUND,
  });
  const {t} = useTranslation();

  const schema = yup.object().shape({
    name: yup.string().required(`${t("managerAdmin.vuilongnhaphovaten")}`),
    email: yup.string().required(`${t("managerAdmin.vuilongnhapemail")}`),
    phone: yup.string().required(`${t("managerAdmin.vuilongnhapsodienthoai")}`),
    staffId: yup.string().required(`${t("managerAdmin.vuilongnhapmanhanvien")}`),
  });
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    staffId: "",
  };
  const {handleSubmit, control} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  const userList = useAppSelector(selectUserList);
  const infoLogin = useAppSelector(selectInfoLogin);
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [filter, setFilter] = useState<any>({
    role: "ADMIN",
    page: 0,
    size: 10,
  });

  useEffect(() => {
    dispatch(fetchUserList(filter));
  }, [dispatch, filter]);
  const handleSubmitForm = async (value: any) => {
    setLoading(true);

    try {
      const res: ResponseCreateAdmin = await managerUserApi.createAdmin(value);
      if (res.responseCode === "00") {
        toast.success(`${t("managerAdmin.taotaikhoanthanhcong")}`);
        setLoading(false);
      } else {
        setLoading(false);

        toast.error(`${t("managerAdmin.manhanvienhoacemaildaduocsudung")}`);
      }
    } catch (error) {}
  };
  const handleChangePage = (page: number) => {
    setFilter({
      ...filter,
      page: page - 1,
    });
  };

  const handleDeleteUser = async (user: InfoUser) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {user.name}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}>{t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_edit} style={{margin: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    })
      .then(async () => {
        const res: ResponseStatus = await managerUserApi.deleteUsers(user?.id);
        if (res.responseCode === "00") {
          toast.success(`${t("managerAdmin.xoaadminthanhcong")}`);
          dispatch(fetchUserList(filter));
        } else {
          toast.error(`${t("managerAdmin.xoaadminthatbai")}`);
          throw new Error("Delete user is failed");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box position="relative">
      {loading && (
        <Box className={classes.loading}>
          <Box>
            {/* <img src={LOADING} alt="LOADING" /> */}
            <CircularProgress style={{width: 50, height: 50}} />
          </Box>
        </Box>
      )}
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" className={classes.title}>
            {t("managerAdmin.taotaikhoan")}
          </Typography>
          <Box mb={1}>
            <Button
              startIcon={<AddIcon style={{fontSize: 15}} />}
              className={classes.btn_edit}
              type="submit"
            >
              {t("button.addnew")}
            </Button>
          </Box>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputField
              control={control}
              label={t("auth.fullName")}
              name="name"
              borderRadius="15px"
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              control={control}
              label={t("auth.email")}
              name="email"
              borderRadius="15px"
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              control={control}
              label={t("auth.phone")}
              name="phone"
              borderRadius="15px"
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              control={control}
              label={t("auth.code")}
              name="staffId"
              borderRadius="15px"
            />
          </Grid>
        </Grid>
      </form>

      <Box className={classes.background}>
        <Box mt={1}>
          <Typography variant="subtitle1" component="i" style={{color: "#FD5468"}}>
            {t("managerAdmin.matkhauguivemail")}
          </Typography>
        </Box>
        <Typography variant="subtitle2"> {t("managerAdmin.danhsachtaikhoan")}</Typography>
      </Box>

      <Box>
        <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" width="70px">
                  {t("stt")}
                </StyledTableCell>
                <StyledTableCell align="center">{t("auth.fullName")}</StyledTableCell>
                <StyledTableCell align="center" width="130px">
                  {t("auth.phone")}
                </StyledTableCell>
                <StyledTableCell align="center" width="250px">
                  {t("auth.email")}
                </StyledTableCell>
                <StyledTableCell align="center" width="150px">
                  {t("auth.code")}
                </StyledTableCell>
                <StyledTableCell align="center" width="300px">
                  {t("thaotac")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.content.map((user, index) => (
                <TableRow key={user.id}>
                  <StyledTableCell align="center" component="th" scope="user">
                    {userList.size * userList.number + index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{user.name}</StyledTableCell>
                  <StyledTableCell align="center">{user.phone}</StyledTableCell>
                  <StyledTableCell align="center">{user.email}</StyledTableCell>
                  <StyledTableCell align="center">{user.staffId}</StyledTableCell>
                  <StyledTableCell align="center">
                    {infoLogin.role === "SUPER_ADMIN" && (
                      <Button className={classes.btn_delete} onClick={() => handleDeleteUser(user)}>
                        {t("button.delete")}
                      </Button>
                    )}
                    {/* <Button
                      className={classes.btn_edit}
                      onClick={() => navigate(`/admin/manager-admin/edit/${user?.id}`)}
                    >
                      {t("button.edit")}
                    </Button> */}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {userList.totalPages > 1 && (
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Pagination
              count={Math.ceil(userList.totalElements / userList.size)}
              page={userList.number + 1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={(e, page) => handleChangePage(page)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
