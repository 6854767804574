import {makeStyles, Theme} from "@material-ui/core";

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 1280,
    display: "flex",
    height: "100vh",
  },
  toolbar: {
    paddingRight: 24,
  },
  menu: {
    "& .MuiPaper-root": {
      borderRadius: "12px",
      backgroundColor: "#fff",
      boxShadow: "0px 6px 20px rgb(0 0 0 / 20%)",
      overflowX: "hidden",
      overflowY: "auto",
      padding: 10,
      width: 200,
      marginRight: 50,
    },
    "& .MuiList-root ": {
      width: "100%",
    },
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ...theme.mixins.toolbar,
  },
  username: {
    fontSize: 15,
    marginRight: 10,
    color: "#5B5B5B",
  },
  icon__profile: {
    cursor: "pointer",
    color: "#ea8c8c",
    padding: 10,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  appBar: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 4px rgba(168, 168, 168, 0.3)",
    borderBottom: "1px solid #99999",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "#000",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    cursor: "pointer",
  },
  logo: {
    height: "auto",
    width: 110,
    borderRadius: 0,
    cursor: "pointer",
    marginLeft: 10,
  },
  icon__prev: {
    backgroundColor: "#E9E9E9",
    width: 30,
    height: 30,
    marginRight: 5,
    color: "black",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    padding: "15px 20px",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default useStyles;
