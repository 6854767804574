import managerPrizeApi from "api/managerPrize";
import InputField from "components/FormFields/InputField";
import {CreatePrize, Prize} from "models/managerPrize";
import React, {useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {Box, Button} from "@material-ui/core";
import useStyles from "./style";
import {ResponseStatus} from "models/common";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {fetchPrizeList, selectFilterPrizeList} from "../../managerPrizeSlice";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

interface EditPrizeProps {
  id: string;
  setOpenModal: (openModal: any) => void;
}

export default function EditPrize({id, setOpenModal}: EditPrizeProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilterPrizeList);
  const {t} = useTranslation();
  const [prize, setPrize] = useState({
    name: "",
    condition: "",
  });

  const initialValues: CreatePrize = {
    name: prize.name,
    condition: prize.condition,
  };

  const {control, handleSubmit, reset} = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
  });

  useEffect(() => {
    reset(prize);
  }, [prize, reset]);
  useEffect(() => {
    (async () => {
      try {
        const res: Prize = await managerPrizeApi.getDetailPrize(id);
        setPrize(res.prize);
      } catch (error) {}
    })();
  }, [id]);
  const handleSubmitForm = async (value: CreatePrize) => {
    try {
      const res: ResponseStatus = await managerPrizeApi.updatePrize(value, id);
      if (res.responseCode === "00") {
        toast.success(`${t("managerPrize.capnhatphanthuongthanhcong")}`);
        dispatch(fetchPrizeList(filter));
        setOpenModal(false);
      } else {
        toast.error(`${t("managerPrize.capnhatthatbai")}`);
        setOpenModal(false);
        throw new Error(`${t("managerPrize.capnhatthatbai")}`);
      }
    } catch (error) {}
  };
  return (
    <Box className={classes.content}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <InputField name="name" control={control} label={t("managerPrize.tenphanthuong")} />
        <InputField
          name="condition"
          control={control}
          label={t("managerPrize.dieukiennhanthuong")}
          multiline={true}
          rows={4}
        />

        <Box textAlign="center">
          <Button className={classes.btn_submit} type="submit">
            {t("button.edit")}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
