import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_create_test.png";
import CheckboxField from "components/FormFields/CheckboxField";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import {
  fetchDataOptions,
  selectListDepartment,
  selectListWorkTypes,
} from "features/Auth/options/optionsSlice";
import React, {useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";

import CreateQuestion from "./components/CreateQuestion";

import {ListExam, Question} from "models/managerTest";
import * as yup from "yup";
import {RadioGroupField} from "components/FormFields/RadioGroupFiled";
import {
  fetchPrizeList,
  selectFilterPrizeList,
  selectPrizeList,
} from "features/Admin/pages/ManagerPrize/managerPrizeSlice";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import managerTestApi from "api/managerTest";

export default function EditExam() {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      marginBottom: 15,
      width: "100%",
      "& .MuiFormGroup-root": {
        flexDirection: "row",
      },
    },
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 130,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        top: 50,
        left: 0,
        color: "#004673",
        fontSize: 25,
        fontWeight: 700,
      },
    },
    title: {
      fontSize: 15,
      fontWeight: 600,
      width: "20%",
      [theme.breakpoints.up("xl")]: {
        fontSize: 18,
        width: "17%",
      },
    },

    btn_create: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 20,
      padding: "10px 15px",
    },
    btn_goBack: {
      position: "absolute",
      top: -15,
      left: -20,
      zIndex: 99,
    },
  }));
  const params = useParams();

  const [detailExam, setDetailExam] = useState<ListExam>();

  useEffect(() => {
    (async () => {
      const res: ListExam = await managerTestApi.getDetailExam(Number(params.id));
      setDetailExam(res.exam);
    })();
  }, []);

  const {t} = useTranslation();

  const schema = yup.object().shape({
    startDate: yup.string().required(`${t("managerTest.vuilongchonngaykiemtra")}`),
    time: yup.string().required(`${t("managerTest.vuilongchongiokiemtra")}`),
    examTime: yup.string().required(`${t("managerTest.vuilongnhapthoigianthi")}`),
    workTypeId: yup.string().when("allWorkType", {
      is: false,
      then: yup.string().required(`${t("managerTest.vuilongchonbophan")}`),
    }),
    category: yup.string().required("*Vui lòng chọn hình thức kiểm tra"),
  });
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const listDepartment = useAppSelector(selectListDepartment);
  const prizeList = useAppSelector(selectPrizeList);
  const filter = useAppSelector(selectFilterPrizeList);
  const [valueForm, setValueForm] = useState<Question>();
  const [createQuestion, setCreateQuestion] = useState(false);
  const [disableDepartment, setDisableDepartment] = useState(false);
  useEffect(() => {
    dispatch(fetchDataOptions());
    dispatch(fetchPrizeList(filter));
  }, [dispatch]);
  const initialValues: Question = {
    departmentId: Number(detailExam?.departmentIds) || "",
    type: "",
    startDate: String(moment(detailExam?.startDate).format("yyyy-MM-DD")),
    examTime: detailExam?.examTime || 0,
    time: String(moment(detailExam?.startDate).format("HH:mm")),
    totalPoint: detailExam?.totalPoint || 0,
    allDepartment: detailExam?.departments?.length > 1 ? true : false,
    category: detailExam?.type === "TYPING" ? "essay" : "multipleChoice",
    prizeId: detailExam?.prizeId || "",
  };
  const {control, handleSubmit, reset} = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    reset(initialValues);
  }, [reset, detailExam]);

  const handleSubmitForm = (value: Question) => {
    const currentHours = new Date().getHours();
    const currentMinutes = new Date().getMinutes();
    if (value.time) {
      const currentDay = moment(new Date()).format("YYYY-MM-DD");
      const currentTimeStamp = currentHours * 3600 + currentMinutes * 60;
      const timeClicked = Number(value.time.slice(0, 2)) * 3600 + Number(value.time.slice(-2)) * 60;
      if (currentTimeStamp > timeClicked && currentDay === value.startDate) {
        toast.error(`${t("managerTest.vuilongchongiokiemtralonhonhoacbanggiohientai")}`);
        return;
      }
    }
    const convertExamTime =
      (Math.floor(Number(value?.examTime) / 60) < 10
        ? "0" + Math.floor(Number(value?.examTime) / 60)
        : Math.floor(Number(value?.examTime) / 60)) +
      ":" +
      (Number(value?.examTime) % 60 > 0
        ? Number(value?.examTime) % 60
        : "0" + (Number(value?.examTime) % 60));

    const endDate =
      Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) > 59
        ? Number(value?.time?.split(":")[0]) +
          Number(convertExamTime?.split(":")[0]) +
          1 +
          ":" +
          (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60 < 10
            ? "0" +
              (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60)
            : Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]) - 60)
        : Number(value?.time?.split(":")[0]) +
          Number(convertExamTime?.split(":")[0]) +
          ":" +
          (Number(value?.time?.split(":")[1]) + Number(convertExamTime?.split(":")[1]));
    setValueForm({
      ...value,
      title: detailExam?.examTitle,
      startDate: value.startDate + "T" + value.time,
      prizeId: Number(value.prizeId) || "",
      totalPoint: Number(value.totalPoint) || "",
      type: value.category === "multipleChoice" ? "CHOICES" : "TYPING",
      departmentId: value.allDepartment
        ? listDepartment.map((x) => x.id)
        : [Number(value.departmentId)],
      endDate: value.startDate + "T" + endDate,
    });
    setCreateQuestion(true);
  };

  return (
    <Box position="relative">
      {createQuestion ? (
        <CreateQuestion
          valueForm={valueForm}
          setValueForm={setValueForm}
          setCreateQuestion={setCreateQuestion}
          detailExam={detailExam}
        />
      ) : (
        <>
          <Box className={classes.btn_goBack}>
            <Tooltip title={t("button.goback") || ""}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIosIcon style={{marginLeft: 7}} />
              </IconButton>
            </Tooltip>
          </Box>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Box className={classes.background}>
              <Typography variant="subtitle2">
                {t("managerTest.thongtinchung").toUpperCase()}
              </Typography>
            </Box>
            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("managerTest.bophan")}
              </Typography>
              <Box width="50%" mr={5}>
                <SelectField
                  name="departmentId"
                  label={t("managerTest.chonbophan")}
                  control={control}
                  borderRadius="4px"
                  disabled={true}
                  options={listDepartment.map(({id, title}) => ({
                    id,
                    name: title,
                  }))}
                />
              </Box>
              <CheckboxField
                name="allDepartment"
                label={t("tatca")}
                control={control}
                disabled
                handleChange={setDisableDepartment}
              />
            </Box>

            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("managerTest.ngaygiokiemtra")}
              </Typography>
              <Box display="flex" width="80%">
                <InputField
                  control={control}
                  type="date"
                  label=""
                  name="startDate"
                  min={moment(new Date()).format("YYYY-MM-DD")}
                />
                <Box ml={2} width="100%">
                  <InputField control={control} type="time" label="" name="time" />
                </Box>
              </Box>
            </Box>

            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("managerTest.thoigianlambai")}
              </Typography>
              <Box width="30%">
                <InputField
                  control={control}
                  name="examTime"
                  label={t("managerTest.nhapthoigianthi")}
                />
                <Box ml={3}>
                  <Typography variant="subtitle2" component="i" style={{color: "#999999"}}>
                    ({t("managerTest.vidu")}: 100 = 01:40:00)
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("managerTest.hinhthuckiemtra")}
              </Typography>

              <RadioGroupField
                name="category"
                label=""
                disabled
                control={control}
                options={[
                  {label: `${t("managerTest.tracnghiem")}`, value: "multipleChoice"},
                  {label: `${t("managerTest.tuluan")}`, value: "essay"},
                ]}
              />
            </Box>

            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("managerTest.tongdiem")}
              </Typography>
              <Box width="30%">
                <InputField
                  control={control}
                  name="totalPoint"
                  label={t("managerTest.nhaptongdiem")}
                />
              </Box>
            </Box>

            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("managerTest.phanthuong")}
              </Typography>

              <Box width="80%">
                <SelectField
                  options={prizeList.content.map(({id, name}) => ({
                    id,
                    name: name,
                  }))}
                  name="prizeId"
                  control={control}
                  label={t("managerTest.chonphanthuong")}
                />
              </Box>
            </Box>
            <Box textAlign="right">
              <Button type="submit" className={classes.btn_create}>
                {t("managerTest.tieptheo")}
              </Button>
            </Box>
          </form>
        </>
      )}
    </Box>
  );
}
