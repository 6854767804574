import {Box, Button, Card, makeStyles, Theme} from "@material-ui/core";
import CardPosts from "components/cardPosts";
import React, {Fragment, useEffect, useState} from "react";
import {useAppSelector, useAppDispatch} from "app/hooks";
import {selectCommunityPostList} from "../../communityPostSlice";
import {ListResponseCommunityPost, CommnunityPostList} from "models/managerCommunityPost";
import managerCommnunityApi from "api/managerCommunity";
import {
  fetchCommunityPostList,
  fetchCommunityPostListFailed,
  fetchCommunityPostListSuccess,
} from "../../communityPostSlice";
import {ReviewPost, ListReviewPost} from "models/managerCommunityPost";
export interface ListPendingProps {}

export default function ListPending(props: ListPendingProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    btn_approve: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      borderRadius: 8,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
      marginRight: 10,
    },
    btn_reject: {
      borderRadius: 8,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    },
    card: {
      padding: 25,
      margin: "20px 0",
      borderRadius: 8,
    },
  }));
  const dispatch = useAppDispatch();
  const postList = useAppSelector(selectCommunityPostList);

  const classes = useStyles();
  const [params, setParams] = useState({
    status_type: "InReview",
    sort_type: 0,
    page_index: 0,
    page_size: 10,
  });
  const handleApprove = async (params: any, id: string) => {
    const value = {
      post_id_list: id,
      action: "Approved",
    };
    try {
      const res: ListReviewPost<ReviewPost> = await managerCommnunityApi.reviewPost(value);
      if (res.responseCode === "00") {
        dispatch(fetchCommunityPostList(params));
      }
    } catch (error) {}
  };
  const handleReject = async (params: any, id: string) => {
    const value = {
      post_id_list: id,
      action: "Rejected",
      reviewed_comment: "Rejected",
    };
    try {
      const res: ListReviewPost<ReviewPost> = await managerCommnunityApi.reviewPost(value);
      if (res.responseCode === "00") {
        dispatch(fetchCommunityPostList(params));
      }
    } catch (error) {}
  };
  const handleGetListPost = async (params: any) => {
    try {
      const res: ListResponseCommunityPost<CommnunityPostList> =
        await managerCommnunityApi.getListPost(params);
      if (res.responseCode === "00") {
        dispatch(fetchCommunityPostList(params));
      }
    } catch (error) {}
  };
  const handleApproveAll = async (params: any) => {
    let listPostNeedApprove = "";
    if (postList) {
      postList.forEach((item, index) => {
        if (index + 1 === postList.length) {
          listPostNeedApprove = listPostNeedApprove + item.id;
        } else {
          listPostNeedApprove = listPostNeedApprove + item.id + ",";
        }
      });
      const value = {
        post_id_list: listPostNeedApprove,
        action: "Approved",
      };
      try {
        const res: ListReviewPost<ReviewPost> = await managerCommnunityApi.reviewPost(value);
        if (res.responseCode === "00") {
          dispatch(fetchCommunityPostList(params));
        }
      } catch (error) {}
    }
  };
  const handleRejectAll = async (params: any) => {
    let listPostNeedReject = "";
    if (postList) {
      postList.forEach((item, index) => {
        if (index + 1 === postList.length) {
          listPostNeedReject = listPostNeedReject + item.id;
        } else {
          listPostNeedReject = listPostNeedReject + item.id + ",";
        }
      });
      const value = {
        post_id_list: listPostNeedReject,
        action: "Rejected",
        reviewed_comment: "Rejected All",
      };
      try {
        const res: ListReviewPost<ReviewPost> = await managerCommnunityApi.reviewPost(value);
        if (res.responseCode === "00") {
          dispatch(fetchCommunityPostList(params));
        }
      } catch (error) {}
    }
  };
  useEffect(() => {
    handleGetListPost(params);
  }, []);
  return (
    <Box px={5}>
      <Box textAlign="right" mb={2}>
        <Button className={classes.btn_approve} onClick={() => handleApproveAll(params)}>
          Duyệt tất cả
        </Button>
        <Button className={classes.btn_reject} onClick={() => handleRejectAll(params)}>
          Từ chối tất cả
        </Button>
      </Box>
      {postList?.length > 0
        ? postList.map((item, index) => (
            <Card className={classes.card} key={index}>
              <CardPosts
                content={item}
                showBtnDeleted={false}
                handleApprove={() => handleApprove(params, item.id)}
                handleReject={() => handleReject(params, item.id)}
              />
            </Card>
          ))
        : null}
    </Box>
  );
}
