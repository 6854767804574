import {Box, Button, Collapse, IconButton, Modal, Slide, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import managerGroupApi from "api/managerGroupApi";
import {useAppDispatch} from "app/hooks";
import clsx from "clsx";
import InputField from "components/FormFields/InputField";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {GroupList, ListFarm, ListProvince, ListWorkType} from "models/managerGroup";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {fetchGroupList} from "../managerGroupSlice";
import FarmGroup from "./Farm";
import useStyles from "./style";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";

export interface ProvinceGroupProps {
  province: ListProvince;
  area: GroupList;
  department: ListWorkType;
}

export default function ProvinceGroup({province, area, department}: ProvinceGroupProps) {
  const {t} = useTranslation();
  const schema = yup.object().shape({
    farmName: yup.string().required(`${t("managerGroup.vuilongnhaptentrai")}`),
  });
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const handleOpenDropdown = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const initialValue = {
    farmName: "",
  };
  const {control, handleSubmit, reset} = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(schema),
  });

  const handleSubmitForm = async (value: any) => {
    try {
      const res: ResponseStatus = await managerGroupApi.addFarm({
        ...value,
        newProvinceId: province.provinceId,
        areaId: area.areaId,
        workTypeId: department.workTypeId,
      });
      if (res.responseCode === "00") {
        dispatch(fetchGroupList());
        toast.success(`${t("managerGroup.themtraithanhcong")}`);
        setOpenModal(false);
        reset();
      } else {
        toast.error(`${t("managerGroup.tentraidatontai")}`);
      }
    } catch (error) {}
  };

  const handleDeleteProvince = async () => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {province.provinceName}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}>{t("delete.dongy")}</Button>,
      cancellationText: <Button className={classes.btn_add}> {t("delete.huybo")}</Button>,
    })
      .then(async () => {
        const value: any = {
          areaId: area.areaId,
          workTypeId: department.workTypeId,
          provinceId: province.provinceId,
        };
        const res: ResponseStatus = await managerGroupApi.deleteProvince(value);
        if (res.responseCode === "00") {
          toast.success(`${t("managerGroup.xoatinhthanhthanhcong")}`);
          dispatch(fetchGroupList());
          setOpenModal(false);
        } else {
          toast.error(`${t("managerGroup.xoathatbai")}`);
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h5"
          onClick={handleOpenDropdown}
          className={clsx(classes.title_province, classes.title)}
        >
          {open ? <ExpandMore /> : <NavigateNextIcon className={classes.btn_next} />}
          <Typography variant="h5" component="span">
            {" "}
            {province?.provinceName}
          </Typography>
        </Typography>
        <Box className={classes.content__right}>
          <Button className={classes.btn_delete} onClick={handleDeleteProvince}>
            {t("button.delete")}
          </Button>
          <Button className={classes.btn_add} onClick={() => setOpenModal(true)}>
            {t("managerGroup.buttonthem")}{" "}
          </Button>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {province?.farms?.map((item: ListFarm) => (
          <FarmGroup farm={item} key={item.farmId} />
        ))}
      </Collapse>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container}>
            <Box className={classes.modal__header}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="subtitle2">{province.provinceName}</Typography>
              <div></div>
            </Box>

            <Box className={classes.form}>
              <Typography variant="h5">{t("button.addnew")} :</Typography>
              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <InputField
                  name="farmName"
                  label={t("managerGroup.nhaptentrai")}
                  control={control}
                  borderRadius="10px"
                />

                <Box textAlign="center">
                  <Button type="submit" className={classes.form__button}>
                    {t("managerGroup.buttonxacnhan")}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
}
