import {
  Box,
  createStyles,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {Pagination} from "@material-ui/lab";
import {makeStyles, withStyles} from "@material-ui/styles";
import managerUserApi from "api/managerUser";
import BACKGROUND from "assets/img/bg_manager_student.png";
import NO_AVATAR from "assets/img/no_avatar.png";
import AcademicRecord from "features/Home/pages/AcademicRecord";
import {InfoUser} from "models/auth";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {dateTimeToFormatDDMMYYYY} from "utils/dateTimeFormat";

export interface DetailUserProps {}

export default function DetailUser(props: DetailUserProps) {
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        backgroundColor: "#E9A051",
        color: "#FFF",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const useStyles = makeStyles(() => ({
    btn_goBack: {
      position: "absolute",
      top: -15,
      left: -20,
    },
    background: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > img": {
        width: "60%",
        objectFit: "cover",
        height: "100%",
      },
    },
    title: {
      color: "#004673",
      fontSize: 20,
      fontWeight: 700,
    },
    wrapper: {
      display: "flex",
      height: "100%",
      backgroundColor: "#efefef",
      padding: "20px",
      boxShadow: " 0px 2px 4px rgba(168, 168, 168, 0.25), 0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 8,
      alignItems: "center",
    },
    wrapper__job: {
      backgroundColor: "#efefef",
      padding: 20,
      marginTop: 10,
      boxShadow: " 0px 2px 4px rgba(168, 168, 168, 0.25), 0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 8,
      "& .MuiGrid-item": {
        padding: "5px 20px",
        paddingLeft: 10,
      },
    },
    job__title: {
      // paddingLeft: 5,
      paddingBottom: 5,
      fontSize: 15,
      color: "#5A5A5A",
    },
    avatar: {
      objectFit: "cover",
      borderRadius: 10,
      width: 200,
      height: 200,
    },
    content_info: {
      backgroundColor: "#fff",
      color: "#1B1B1B",
      padding: "8px 20px",
      marginBottom: "15px",
      borderRadius: 8,
      fontSize: 14,
      minHeight: 40,
    },
    container: {
      maxHeight: 500,
    },
    formControl: {
      width: 150,
      backgroundColor: "#FFF",
      "& .MuiSelect-root": {
        padding: 10,
      },
    },
  }));
  const {id} = useParams();
  const classes = useStyles();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [detailUser, setDetailUser] = useState<InfoUser>();
  const handleChangeYear = (event: React.ChangeEvent<{value: unknown}>) => {
    setYear(Number(event.target.value));
  };
  useEffect(() => {
    (async () => {
      try {
        const res: InfoUser = await managerUserApi.detailUser(id);
        setDetailUser(res);
      } catch (error) {}
    })();
  }, []);
  return (
    <Box style={{position: "relative", padding: "0 40px"}}>
      <Box className={classes.btn_goBack}>
        <Tooltip title="Quay lại">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{marginLeft: 7}} />
          </IconButton>
        </Tooltip>
      </Box>
      {/* Thông tin cá nhân */}

      <Box className={classes.background}>
        <Typography variant="subtitle2" className={classes.title}>
          {t("profile.thongtincanhan")}
        </Typography>
        <img src={BACKGROUND} alt="BACKGROUND" />
      </Box>
      <Box className={classes.wrapper}>
        <Box>
          <img
            src={detailUser?.profileImageUrl || NO_AVATAR}
            alt="avatar"
            className={classes.avatar}
          />
        </Box>
        <Box width="100%" borderLeft="1px solid #B4B4B4" paddingLeft="15px" ml={2}>
          <Typography variant="subtitle2" className={classes.content_info}>
            {detailUser?.name}
          </Typography>
          <Typography variant="subtitle2" className={classes.content_info}>
            {detailUser?.staffId}
          </Typography>
          <Typography variant="subtitle2" className={classes.content_info}>
            {detailUser?.email}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.content_info}
            style={{marginBottom: 0}}
          >
            {detailUser?.phone}
          </Typography>
        </Box>
      </Box>

      {/* Thông tin công việc */}

      <Box mt={3}>
        <Typography variant="subtitle2" className={classes.title}>
          {t("profile.thongtincongviec")}
        </Typography>

        <Box className={classes.wrapper__job}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={6}>
              <Typography variant="body1" className={classes.job__title}>
                {t("auth.area")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.content_info}
                style={{marginBottom: 0}}
              >
                {detailUser?.area?.name || "---"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" className={classes.job__title}>
                {t("auth.department")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.content_info}
                style={{marginBottom: 0}}
              >
                {detailUser?.department?.title || "---"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" className={classes.job__title}>
                {t("auth.workType")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.content_info}
                style={{marginBottom: 0}}
              >
                {detailUser?.workType?.name || "---"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" className={classes.job__title}>
                {t("admin.tinh/thanhpho")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.content_info}
                style={{marginBottom: 0}}
              >
                {detailUser?.newProvince?.name || "---"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" className={classes.job__title}>
                {t("auth.position")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.content_info}
                style={{marginBottom: 0}}
              >
                {detailUser?.position || "---"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" className={classes.job__title}>
                {t("auth.farm")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.content_info}
                style={{marginBottom: 0}}
              >
                {detailUser?.farm?.name || "---"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" className={classes.job__title}>
                {" "}
                {t("auth.date.join")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.content_info}
                style={{marginBottom: 0}}
              >
                {dateTimeToFormatDDMMYYYY(detailUser?.dateOfJoining) || "---"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Học bạ */}
      <AcademicRecord id={id} />
      {/* <Box
        my={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
      >
        <Typography variant="subtitle2" className={classes.title}>
          {t("academic.hocbacanhan")}
        </Typography>
        <hr
          style={{
            margin: "0 20px",
            flexGrow: 1,
          }}
        />
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            value={year}
            onChange={handleChangeYear}
            defaultValue={new Date().getFullYear()}
          >
            {Array.from(new Array(10)).map((id, index) => (
              <option value={index + 2020} key={id}>
                {index + 2020}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t("academic.ngaythi")}</StyledTableCell>
                <StyledTableCell align="center">{t("academic.tenbaithi")}</StyledTableCell>
                <StyledTableCell align="center">{t("academic.trangthai")}</StyledTableCell>
                <StyledTableCell align="center">{t("academic.diemthi")}</StyledTableCell>
                <StyledTableCell align="center">{t("academic.xeploai")}</StyledTableCell>
                <StyledTableCell align="center">{t("academic.ghichu")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableCell align="center">12/10/2021</StyledTableCell>
              <StyledTableCell align="center">Bài test 1</StyledTableCell>
              <StyledTableCell align="center">Đậu</StyledTableCell>
              <StyledTableCell align="center" style={{color: "#DB2139"}}>
                95
              </StyledTableCell>
              <StyledTableCell align="center">Thủ khoa</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableBody>

            <TableBody>
              <StyledTableCell
                colSpan={3}
                style={{color: "#004673", textAlign: "center", fontSize: 17, fontWeight: 700}}
                align="center"
              >
                {t("academic.diemtrungbinhnam")}
              </StyledTableCell>
              <StyledTableCell align="center" style={{color: "#DB2139", fontWeight: 700}}>
                58
              </StyledTableCell>
              <StyledTableCell align="center" style={{color: "#004673", fontWeight: 700}}>
                Trung bình
              </StyledTableCell>
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Pagination
            // count={Math.ceil(prizeList.totalElements / prizeList.size)}
            count={2}
            variant="outlined"
            // page={prizeList.number +1}
            color="primary"
            shape="rounded"
            // onChange={(e, page) => handleChangePage(page)}
          />
        </Box>
      </Box> */}
    </Box>
  );
}
