import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  btn_approve: {
    background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
    borderRadius: 8,
    fontSize: 13,
    color: "#fff",
    padding: "5px 12px",
    marginRight: 10,
  },
  icon__visibility: {
    color: "#898989",
    fontSize: 15,
    marginRight: 5,
  },
  root_modal: {
    width: "80%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
  },
  modal__container: {
    width: "100%",
    borderRadius: 12,
    backgroundColor: "#fff",
    zIndex: 9,
    display: "flex",
    flexDirection: "column",
    margin: "30px 0",
  },
  modal__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px",
    minHeight: 65,
    borderBottom: "1px solid rgb(235, 235, 235)",
    "& >h6": {
      fontWeight: 700,
      fontSize: 18,
      marginRight: 50,
    },
  },
  category__name: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: 15,
    backgroundColor: "rgba(44, 44, 44, 0.5)",
    color: "#fff",
    padding: "10px 0px",
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& >img": {
      width: 25,
      height: 25,
      marginRight: 15,
    },
  },
  magazine__title: {
    fontWeight: 600,
    fontSize: 15,
  },
  thumbnail: {
    width: "100%",
    height: 200,
    objectFit: "cover",
    marginTop: 15,
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > h6 ": {
      fontSize: 13,
      color: "#898989",
      [theme.breakpoints.down("sm")]: {
        fontSize: 11,
      },
    },
  },
  icon_play: {
    padding: 5,
    backgroundColor: "#fff",
    width: 50,
    height: 50,
    borderRadius: "50%",
    color: "#f9ab00",
    transition: "ease-in 0.5s",
    border: "8px solid #999",
    "&:hover": {
      backgroundColor: "#f9ab00",
      color: "#fff",
    },
  },
  player_wrapper: {
    position: "relative",
    paddingTop: "60.25% ",
  },
  bg_pdf: {
    width: "100%",
    height: 400,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
  },
  icon_pdf: {
    width: "20px",
    marginRight: "10px",
    height: "30px",
    objectFit: "cover",
  },

  react_player: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  btn_reject: {
    borderRadius: 8,
    fontSize: 13,
    color: "#fff",
    padding: "5px 12px",
    background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
  },
  btn_delete: {
    color: "#FD5468",
    fontSize: 30,
  },

  card__content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  card__content__info: {
    display: "flex",
    alignItems: "center",
  },
  card__username: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "27px",
  },
  card__category: {
    fontSize: 12,
    color: "#828282",
    display: "flex",
    "& >li": {
      fontSize: 12,
      marginLeft: 12,
    },
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      width: 45,
      height: 45,
    },
  },
  card__posted: {
    fontSize: 16,
    lineHeight: "26px",
    padding: "5px",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "22px",
      paddingTop: 10,
    },
  },

  image: {
    height: "100%",
    flexGrow: 1,
    width: "100%",
    objectFit: "cover",
    maxHeight: 700,
    cursor: "pointer",
  },
  content_image: {
    width: "100%",
    padding: "15px 0",
  },
  overlay: {
    position: "absolute",
    inset: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  content_overlay: {
    display: "flex",
    height: "100%",
    margin: "0 auto",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  lightBox: {
    "& >div": {
      zIndex: 9999,
    },
  },
}));

export default useStyles;
