import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slide,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import managerGroupApi from "api/managerGroupApi";
import {useAppDispatch, useAppSelector} from "app/hooks";
import clsx from "clsx";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {
  GroupList,
  ListOptions,
  ListProvince,
  ListResponseOptions,
  ListWorkType,
} from "models/managerGroup";
import React, {useEffect, useState} from "react";
import {useController, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {arrCenter, arrNorth, arrSouth} from "utils/provinces";
import {fetchGroupList} from "../managerGroupSlice";
import ProvinceGroup from "./Province";
import useStyles from "./style";
import * as yup from "yup";
import {fetchListProvince, selectListProvince} from "features/Auth/options/optionsSlice";
import {useTranslation} from "react-i18next";

export interface DepartmentGroupProps {
  department: ListWorkType;
  area: GroupList;
}

export default function DepartmentGroup({department, area}: DepartmentGroupProps) {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const listProvince = useAppSelector(selectListProvince);
  const schema = yup.object().shape({
    newProvinceId: yup.string().required(`${t("managerGroup.vuilongchontinhthanh")}`),
  });
  const [openModal, setOpenModal] = useState(false);
  const confirm = useConfirm();
  const initialValue = {
    newProvinceId: "",
  };

  const {control, handleSubmit, reset} = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(schema),
  });

  const {
    field: {value, onChange, onBlur},
    fieldState: {invalid, error},
  } = useController({
    name: "newProvinceId",
    control,
  });
  const handleOpenDropdown = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    (async () => {
      try {
        const res: ListResponseOptions<ListOptions> = await managerGroupApi.getProvincesByAreaId(
          area?.areaId
        );
        if (res.responseCode === "00") {
          dispatch(fetchListProvince(res));
        }
      } catch (error) {}
    })();
  }, []);
  const listCityNotAdd = listProvince.filter((city) =>
    department.provinces.every((x) => !x?.provinceName?.includes(city.name))
  );

  const handleSubmitForm = async (value: any) => {
    try {
      const res: ResponseStatus = await managerGroupApi.addProvince({
        ...value,
        workTypeId: department.workTypeId,
        areaId: area.areaId,
      });
      if (res.responseCode === "00") {
        setOpenModal(false);
        dispatch(fetchGroupList());
        toast.success(`${t("managerGroup.themtinhthanhthanhcong")}`);
        reset();
      }
    } catch (error) {}
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 18;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        borderRadius: 10,
      },
    },
  };

  const handleDeleteWorkType = async () => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {department.workTypeName}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}>{t("delete.dongy")}</Button>,
      cancellationText: <Button className={classes.btn_add}>{t("delete.huybo")}</Button>,
    })
      .then(async () => {
        const value: any = {
          areaId: area?.areaId,
          workTypeId: department?.workTypeId,
        };
        const res: ResponseStatus = await managerGroupApi.deleteWorkTypeFromArea(value);
        if (res.responseCode === "00") {
          toast.success(`${t("managerGroup.xoabophanthanhcong")}`);
          dispatch(fetchGroupList());
          setOpenModal(false);
        } else {
          toast.error(`${t("managerGroup.xoathatbai")}`);
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h5"
          className={clsx(classes.title_department, classes.title)}
          onClick={handleOpenDropdown}
        >
          {open ? <ExpandMore /> : <NavigateNextIcon className={classes.btn_next} />}
          <Typography variant="h5" component="span">
            {" "}
            {department.workTypeName}
          </Typography>
        </Typography>
        <Box className={classes.content__right}>
          <Button className={classes.btn_delete} onClick={handleDeleteWorkType}>
            {t("button.delete")}
          </Button>
          <Button className={classes.btn_add} onClick={() => setOpenModal(true)}>
            {t("managerGroup.buttonthem")}{" "}
          </Button>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {department.provinces.map((item: ListProvince) => (
          <ProvinceGroup
            province={item}
            key={item.provinceId}
            area={area}
            department={department}
          />
        ))}
      </Collapse>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container}>
            <Box className={classes.modal__header}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="subtitle2">{department.workTypeName}</Typography>
              <div></div>
            </Box>

            <Box className={classes.form}>
              <Typography variant="h5">{t("button.addnew")} :</Typography>
              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <FormControl fullWidth variant="outlined" size="small" error={invalid}>
                  <InputLabel id="province">{t("managerGroup.danhsachtinhthanh")}</InputLabel>
                  <Select
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    labelId="province"
                    variant="outlined"
                    label={t("managerGroup.danhsachtinhthanh")}
                    inputProps={{
                      name: "newProvinceId",
                      id: "province",
                    }}
                    MenuProps={MenuProps}
                  >
                    {listCityNotAdd?.map((option: ListOptions) => (
                      <MenuItem key={option?.id} value={option?.id}>
                        {option?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>

                <Box textAlign="center">
                  <Button type="submit" className={classes.form__button}>
                    {t("managerGroup.buttonxacnhan")}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
}
