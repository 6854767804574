import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import {useAppDispatch, useAppSelector} from "app/hooks";
import LOGO from "assets/img/logo.png";
import NO_AVATAR from "assets/img/no_avatar.png";
import clsx from "clsx";
import EditAccount from "components/Admin/EditAccount";
import Profile from "components/Profile";
import {TOKEN, TOKEN_ADMIN} from "constants/config";
import Admin from "features/Admin/pages/Charts";
import ChartLessonByYear from "features/Admin/pages/Charts/pages/ChartLessonByYear";
import ChartAttendByArea from "features/Admin/pages/Charts/pages/ChartAttendByArea";
import ManagerAdmin from "features/Admin/pages/ManagerAdmin";
import History from "features/Admin/pages/ManagerAdmin/components/History";
import ManagerCategory from "features/Admin/pages/ManagerCategory";
import ManagerGroup from "features/Admin/pages/ManagerGroup";
import ManagerListLesson from "features/Admin/pages/ManagerLesson";
import AddLesson from "features/Admin/pages/ManagerLesson/components/AddLesson";
import EditLesson from "features/Admin/pages/ManagerLesson/components/EditLesson";
import DetailLesson from "features/Admin/pages/ManagerLesson/components/ListPosted/pages/Detail";
import ManagerPrize from "features/Admin/pages/ManagerPrize";
import ListStudent from "features/Admin/pages/ManagerStudent";
import DetailUser from "features/Admin/pages/ManagerStudent/components/DetailUser";
import ManagerTest from "features/Admin/pages/ManagerTest";
import DetailExam from "features/Admin/pages/ManagerTest/components/DetailExam";
import AddExam from "features/Admin/pages/ManagerTest/pages/AddExam";
import CreateExamAgain from "features/Admin/pages/ManagerTest/pages/CreateExamAgain";
import ListTestByCodeExam from "features/Admin/pages/ManagerTest/pages/ListTestByCodeExam";
import DetailTest from "features/Admin/pages/ManagerTest/pages/ListTestByCodeExam/components/DetailTest";
import {fetchInfoLogin, selectInfoLogin} from "features/Auth/authSlice";
import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import ListItems from "./components/ListItem";
import useStyles from "./style";
import ListPending from "features/Admin/pages/Community/pages/ListPending";
import NewsAdmin from "features/Admin/pages/Community/pages/News";
import ChartAttendProvinceByArea from "features/Admin/pages/Charts/pages/ChartAttendProvinceByArea";
import ChartAttendAreaByYear from "features/Admin/pages/Charts/pages/ChartAttendAreaByYear";
import ChartAttendProvinceByYear from "features/Admin/pages/Charts/pages/ChartAttendProvinceByYear";
import ChartAttendFarmByProvince from "features/Admin/pages/Charts/pages/ChartAttendFarmByProvince";
import ChartExamResultAreaByYear from "features/Admin/pages/Charts/pages/ChartExamResultAreaByYear";
import ChartExamResult3AreaByMonth from "features/Admin/pages/Charts/pages/ChartExamResult3AreaByMonth";
import ChartExamResultProvinceByYear from "features/Admin/pages/Charts/pages/ChartExamResultProvinceByYear";
import ChartExamResultFramByProvince from "features/Admin/pages/Charts/pages/ChartExamResultFarmByProvince";
import ManagerMagazine from "features/Admin/pages/ManagerMagazine";
import AddMagazine from "features/Admin/pages/ManagerMagazine/components/AddMagazine";
import EditMagazine from "features/Admin/pages/ManagerMagazine/components/EditMagazine";
import MagazineNews from "features/Admin/pages/ManagerMagazine/components/MagazineNews";
import ListSkipExam from "features/Admin/pages/ManagerTest/pages/ListSkipExam";
import ListSkip from "features/Admin/pages/ManagerLesson/components/ListSkip";
import AccessHistory from "features/Admin/pages/ManagerStudent/pages/AccessHistory";
import RegistrationList from "features/Admin/pages/ManagerStudent/pages/RegistrationList";
import ReupLesson from "features/Admin/pages/ManagerLesson/components/ReupLesson";
import EditExam from "features/Admin/pages/ManagerTest/pages/EditExam";

export default function AdminTemPlate() {
  const token_admin = localStorage.getItem(TOKEN_ADMIN);
  const infoLogin = useAppSelector(selectInfoLogin);
  const role = localStorage.getItem("ROLE");
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  useEffect(() => {
    if (token_admin) {
      dispatch(fetchInfoLogin());
    }
  }, [token_admin, dispatch]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem(TOKEN_ADMIN);
    localStorage.removeItem(TOKEN);
    localStorage.removeItem("ROLE");
    navigate("/");
  };
  if (!token_admin && role !== "ADMIN") {
    return <Navigate to="/" />;
  }
  return (
    <div className={classes.root}>
      <CssBaseline />

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.menu}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            navigate("/admin/profile");
          }}
        >
          Hồ sơ cá nhân
        </MenuItem>

        <MenuItem onClick={handleLogout}>Đăng xuất</MenuItem>
      </Menu>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <Avatar
            variant="rounded"
            src={LOGO}
            alt="logo"
            className={classes.logo}
            onClick={() => navigate("/admin")}
          />
          <IconButton onClick={handleDrawerClose} className={classes.icon__prev}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {/* Menu list */}
        <List style={{height: "100vh", overflow: "auto"}}>
          <ListItems openDrawer={open} />
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer}>
          <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
                onClick={() => navigate("/admin")}
              >
                {/* Dashboard */}
              </Typography>
              <Typography variant="subtitle2" className={classes.username}>
                Hi{"  "}
                <Typography
                  variant="subtitle2"
                  component="span"
                  style={{color: "rgb(19, 123, 189)", paddingLeft: 5, cursor: "pointer"}}
                >
                  {infoLogin.name}
                </Typography>
              </Typography>
              <IconButton
                aria-controls={anchorEl ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleOpenMenu}
                className={classes.icon__profile}
              >
                <Avatar src={infoLogin.profileImageUrl || NO_AVATAR} className={classes.avatar} />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
        <Box className={classes.container}>
          <Routes>
            <Route path="/profile" element={<Profile />} />

            {/* Charts */}
            <Route path="/chart-lesson-by-year" element={<ChartLessonByYear />} />
            <Route path="/chart-attend-by-area" element={<ChartAttendByArea />} />
            <Route path="/chart-attend-provinces-by-area" element={<ChartAttendProvinceByArea />} />
            <Route path="/chart-attend-area-by-year" element={<ChartAttendAreaByYear />} />
            <Route path="/chart-attend-province-by-year" element={<ChartAttendProvinceByYear />} />
            <Route path="/chart-attend-farm-by-province" element={<ChartAttendFarmByProvince />} />
            <Route path="/chart-exam-result-area-by-year" element={<ChartExamResultAreaByYear />} />
            <Route
              path="/chart-exam-result-3-area-by-month"
              element={<ChartExamResult3AreaByMonth />}
            />
            <Route
              path="/chart-exam-result-province-by-year"
              element={<ChartExamResultProvinceByYear />}
            />
            <Route
              path="/chart-exam-result-farm-by-province"
              element={<ChartExamResultFramByProvince />}
            />

            {/* Manager group */}
            <Route path="/manager-group" element={<ManagerGroup />} />

            {/* Manager Admin */}
            <Route path="/manager-admin" element={<ManagerAdmin />} />
            <Route path="/manager-admin-history" element={<History />} />
            <Route path="/manager-admin/edit/:id" element={<EditAccount />} />
            <Route path="/manager-admin/detail/:id" element={<DetailUser />} />
            {/* Manager prize */}
            <Route path="/manager-prize" element={<ManagerPrize />} />

            {/* Manager Test */}
            <Route path="/manager-test/list" element={<ManagerTest />} />
            <Route path="/manager-test/create-new" element={<AddExam />} />
            <Route path="/manager-test/exam/:id" element={<DetailExam />} />
            <Route path="/manager-test/create-test-again" element={<CreateExamAgain />} />
            <Route path="/manager-test/exam/list-test/:id" element={<ListTestByCodeExam />} />
            <Route path="/manager-test/exam/list-test/detail/:id" element={<DetailTest />} />
            <Route path="/manager-test/exam/list-skip/:id" element={<ListSkipExam />} />
            <Route path="/manager-test/exam/edit/:id" element={<EditExam />} />

            {/* Manager Student */}
            <Route path="/manager-student" element={<ListStudent />} />
            <Route path="/manager-student/detail/:id" element={<DetailUser />} />
            <Route path="/manager-student/edit/:id" element={<EditAccount />} />
            <Route
              path="/manager-student-access-history"
              element={<AccessHistory></AccessHistory>}
            />
            <Route
              path="/manager-student-register"
              element={<RegistrationList></RegistrationList>}
            />
            {/* Manager Category */}
            <Route path="/manager-category" element={<ManagerCategory />} />

            {/* Manager Lesson */}
            <Route path="/manager-lesson" element={<ManagerListLesson />} />
            <Route path="/manager-lesson/create-new" element={<AddLesson />} />
            <Route path="/manager-lesson/:page" element={<ManagerListLesson />} />
            <Route path="/manager-lesson/listPosted/detail/:id" element={<DetailLesson />} />
            <Route path="/manager-lesson/listPosted/list-skip/:id" element={<ListSkip />} />

            <Route path="/manager-lesson/edit/future/:id" element={<EditLesson />} />
            <Route path="/manager-lesson/edit/draft/:id" element={<EditLesson />} />
            <Route path="/manager-lesson/edit/posted/:id" element={<EditLesson />} />
            <Route path="/manager-lesson/reup/:id" element={<ReupLesson />} />
            {/* Community */}
            <Route path="/community-pending" element={<ListPending />} />
            <Route path="/community-news" element={<NewsAdmin />} />

            {/* Magazine */}
            <Route path="/magazine-list" element={<ManagerMagazine />} />
            <Route path="/magazine-create" element={<AddMagazine />} />
            <Route path="/magazine-edit/:id" element={<EditMagazine />} />
            <Route path="/magazine-news" element={<MagazineNews />} />

            <Route path="/" element={<Admin />} />
          </Routes>
        </Box>
      </main>
    </div>
  );
}
