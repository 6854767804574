import {
  Box,
  Button,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import {withStyles} from "@material-ui/styles";
import managerUserApi from "api/managerUser";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_manager_student.png";
import {
  fetchUserList,
  selectFilterInreviewUserList,
  selectUserList,
  setFilterInreviewUserList,
} from "features/Admin/pages/ManagerAdmin/managerAdminSlice";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import useStyles from "./style";
export interface RegistrationListProps {}
export default function RegistrationList(props: RegistrationListProps) {
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const {t} = useTranslation();
  const classes = useStyles({
    background: BACKGROUND,
  });
  const confirm = useConfirm();
  const filter = useAppSelector(selectFilterInreviewUserList);
  const dispatch = useAppDispatch();
  const userList = useAppSelector(selectUserList);
  useEffect(() => {
    dispatch(fetchUserList(filter));
  }, [dispatch, filter]);
  const navigate = useNavigate();
  const handleApproved = async (id: any) => {
    let params = {
      user_id_list: [id],
      action: "Approved",
    };
    try {
      const res: ResponseStatus = await managerUserApi.approvedUser(params);
      if (res.responseCode === "00") {
        toast.success(`${t("managerStudent.duyethocvienthanhcong")}`);
        dispatch(fetchUserList(filter));
      }
    } catch (error) {}
  };
  const handleChangePage = (page: number) => {
    dispatch(
      setFilterInreviewUserList({
        ...filter,
        page: page - 1,
      })
    );
  };
  const handleDelete = async (user: any) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {user.name}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}>{t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_edit} style={{margin: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    }).then(async () => {
      try {
        const res: ResponseStatus = await managerUserApi.deleteUsers(user.id);

        if (res.responseCode === "00") {
          toast.success(`${t("managerStudent.xoahocvienthanhcong")}`);
          dispatch(fetchUserList(filter));
        }
      } catch (error) {}
    });
  };
  const handleEdit = async (user: any) => {
    navigate(`/admin/manager-student/edit/${user.id}`, {
      state: {
        source: "Register",
      },
    });
  };

  return (
    <>
      <Box className={classes.background}>
        <Typography variant="subtitle2"> {t("admin.listitem.danhsachdangky")}</Typography>
      </Box>
      <TableContainer style={{margin: "20px 0"}} className={classes.container} component={Paper}>
        <Table stickyHeader style={{minWidth: "1600px"}}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" width="20px">
                {t("managerStudent.register.stt")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.register.ngaydangky")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.register.tennhanvien")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.register.manhanvien")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.register.email")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.register.khuvuc")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.register.bophan")}
              </StyledTableCell>
              <StyledTableCell align="center" width="120px">
                {t("managerStudent.register.tinh")}
              </StyledTableCell>
              <StyledTableCell align="center" width="200px">
                {t("managerStudent.register.thaotac")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.content.map((user, index) => (
              <TableRow key={index}>
                <StyledTableCell align="center" component="th" scope="user">
                  {userList.size * userList.number + index + 1}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {String(user?.createdAt).slice(8, 10)}-{String(user?.createdAt).slice(5, 7)}-
                  {String(user?.createdAt).slice(0, 4)}
                </StyledTableCell>
                <StyledTableCell align="center">{user?.name}</StyledTableCell>
                <StyledTableCell align="center">{user?.staffId}</StyledTableCell>
                <StyledTableCell align="center">{user?.email}</StyledTableCell>
                <StyledTableCell align="center">{user?.area?.name}</StyledTableCell>
                <StyledTableCell align="center">{user?.department?.title}</StyledTableCell>
                <StyledTableCell align="center">{user?.newProvince?.name}</StyledTableCell>
                <StyledTableCell align="center">
                  <Button className={classes.btn_detail} onClick={() => handleApproved(user.id)}>
                    {t("button.duyet")}
                  </Button>
                  <Button className={classes.btn_edit} onClick={() => handleEdit(user)}>
                    {t("button.detail")}
                  </Button>
                  <Button className={classes.btn_delete} onClick={() => handleDelete(user)}>
                    {t("button.delete")}
                  </Button>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {userList.totalPages > 1 && (
        <Box mt={3} display="flex" justifyContent="space-between">
          <Typography variant="subtitle2" style={{fontWeight: 600}}>
            {t("managerStudent.tongcong")}: {userList.totalElements}
          </Typography>
          <Pagination
            count={Math.ceil(userList.totalElements / userList.size)}
            page={userList.number + 1}
            variant="outlined"
            color="primary"
            shape="rounded"
            onChange={(e, page) => handleChangePage(page)}
          />
        </Box>
      )}
    </>
  );
}
