import {makeStyles, Theme} from "@material-ui/core";

type Props = {
  background: string;
  pathname: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  btn_menu_category: {
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: 8,
    textTransform: "initial",
    width: "100%",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
  },

  menu__category__list: {
    paddingBottom: 20,
    "& .MuiMenu-paper": {
      width: (props: Props) => (props.pathname === "admin" ? "30%" : "34%"),
      marginTop: "150px",
    },
  },
  menu__category__item: {
    padding: "5px 30px",

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&>img": {
      width: 30,
      height: 30,
      cursor: "pointer",
    },
  },
  datePicker: {
    width: "100%",
    "& .MuiPickersStaticWrapper-staticWrapperRoot": {
      marginTop: 20,
      borderRadius: 10,
      backgroundColor: "transparent",
      boxShadow:
        "5px 5px 0px 0px #289FED, 10px 10px 0px 0px #5FB8FF, 15px 15px 0px 0px #A1D8FF, 4px 1px 18px 6px rgba(5,26,7,0)",

      [theme.breakpoints.down("lg")]: {
        width: "100%",
        minWidth: "auto",
      },
      "& .MuiPickersBasePicker-container": {
        display: "block",

        "& .MuiPickersBasePicker-pickerView": {
          margin: "0 auto",
          backgroundColor: "transparent",
          color: "#fff",
          width: "100% !important",
          maxWidth: "100%",
          minWidth: "100%",
          "&>div": {
            background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",

            "& .MuiPickersCalendarHeader-daysHeader": {
              paddingBottom: 20,
              "&  .MuiPickersCalendarHeader-dayLabel": {
                color: "#fff",
              },
            },
          },
          "& .MuiPickersCalendar-transitionContainer": {
            paddingTop: 20,
            minHeight: 260,
            backgroundImage: (props: Props) => `url(${props.background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            marginTop: 0,
            [theme.breakpoints.down("lg")]: {
              minHeight: 220,
            },
          },
          "& .MuiPickersCalendarHeader-switchHeader": {
            width: "60%",
            margin: "10px auto",

            [theme.breakpoints.down("lg")]: {
              width: "90%",
            },
            "& .MuiPickersCalendarHeader-iconButton": {
              backgroundColor: "transparent",
            },
            "& .MuiPickersCalendarHeader-dayLabel": {
              color: "#fff",
              [theme.breakpoints.down("lg")]: {
                width: 30,
              },
            },
          },
        },
      },
    },
  },
  icon_pig_calendar: {
    width: "100%",
    height: 25,
    objectFit: "cover",
    [theme.breakpoints.down("lg")]: {
      height: 15,
    },
  },
  notInThisMonthDayPaper: {
    width: "35px",
    height: "35px",
    margin: "3px",
    boxShadow: "none",
    borderRadius: 0,
    padding: "1px",
    backgroundColor: "transparent !important",
    color: "#CFCFCF",
    [theme.breakpoints.down("lg")]: {
      width: 25,
      height: 25,
    },
  },
  normalDayPaper: {
    width: "35px",
    height: "35px",
    margin: "3px",
    boxShadow: "none",
    borderRadius: 0,
    padding: "1px",
    cursor: "pointer",
    backgroundColor: "transparent !important",
    [theme.breakpoints.down("lg")]: {
      width: 25,
      height: 25,
    },
  },
  selectedDayPaper: {
    color: "blue",
    backgroundColor: "blue",
    borderRadius: "50%",
    width: 40,
    height: 40,
    margin: "3px",
    boxShadow: "none",
    padding: "1px",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      width: 25,
      height: 25,
    },
  },
  todayPaper: {
    width: "35px",
    height: "35px",
    margin: "3px",
    boxShadow: "none",
    borderRadius: 0,
    padding: "1px",
    cursor: "pointer",
    backgroundColor: "transparent !important",
    color: " white",
    [theme.breakpoints.down("lg")]: {
      width: 25,
      height: 25,
    },
  },
  currentDate__content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",

    "&> img": {
      position: "absolute",
      top: -17,
      width: 15,
      height: 15,
    },
    "&>h6": {
      color: "#fff",
    },
  },
}));

export default useStyles;
