import {Column} from "@ant-design/plots";
import {each, groupBy} from "@antv/util";
import {Box, Card, FormControl, Select, Typography} from "@material-ui/core";
import managerChartApi from "api/managerChart";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {
  AttendByYear,
  ProvinceForStatistic,
  ResponseAttendProvincesByYear,
} from "models/managerChart";
import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {fetchProvincesForStatistic, selectProvincesForStatistic} from "../../managerChartSlice";
import useStyles from "./style";

export default function ChartAttendProvinceByYear() {
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const listProvincesForStatistic = useAppSelector(selectProvincesForStatistic);
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  // const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth() + 1);
  const [provinceId, setProvinceId] = useState<number>(0);
  const [currentProvinceName, setCurrentProvinceName] = useState<string>("");

  const [attendBynewprovinceId, setAttendByNewprovinceId] = useState<AttendByYear[]>([]);

  const handleChangeProvince = (event: React.ChangeEvent<{value: unknown}>) => {
    setProvinceId(Number(event.target.value));
  };
  // const handleChangeMonth = (event: React.ChangeEvent<{value: unknown}>) => {
  //   setCurrentMonth(Number(event.target.value));
  // };
  const handleChangeYear = (event: React.ChangeEvent<{value: unknown}>) => {
    setCurrentYear(Number(event.target.value));
  };

  useEffect(() => {
    dispatch(fetchProvincesForStatistic());
  }, []);
  useEffect(() => {
    if (listProvincesForStatistic.length > 0) {
      setProvinceId(listProvincesForStatistic[0].id);
    }
  }, [listProvincesForStatistic]);

  useEffect(() => {
    if (provinceId === 0) return;
    (async () => {
      try {
        const res: ResponseAttendProvincesByYear<AttendByYear> =
          await managerChartApi.getStatisticAttendProvincesByYear({
            // month: currentMonth,
            year: currentYear,
            newProvinceId: provinceId,
          });
        if (res.responseCode === "00") {
          setAttendByNewprovinceId(res.attendanceByMonthNews);
          setCurrentProvinceName(res.provinceName);
        }
      } catch (error) {}
    })();
  }, [currentYear, provinceId]);

  const annotations: any = [];
  each(groupBy(attendBynewprovinceId, "month"), (values: any, month: any) => {
    const total = values.reduce((a: any, b: any) => a + b.attendanceCount.totalStudents, 0);
    annotations.push({
      type: "text",
      content: `${total}`,
      position: [`${t("chart.thang")} ${month}`, total],
      style: {
        textAlign: "center",
        fontSize: 14,
        fill: "rgba(0,0,0,0.85)",
      },
      offsetY: -10,
    });
  });

  const config: any = (dataStatistic: AttendByYear[], annotation: any) => ({
    data:
      dataStatistic
        .map((item) => [
          {
            month: `${t("chart.thang")} ${item.month}`,
            value: item.attendanceCount.fullyWatched,
            status: `${t("chart.hocdu")}`,
          },
          {
            month: `${t("chart.thang")} ${item.month}`,
            value: item.attendanceCount.notFullyWatched,
            status: `${t("chart.hocthieu")}`,
          },
          {
            month: `${t("chart.thang")} ${item.month}`,
            value: item.attendanceCount.noWatched,
            status: `${t("chart.bohoc")}`,
          },
        ])
        .reduce((previosValue, currentValue) => {
          return previosValue.concat(currentValue);
        }, []) || [],
    isStack: true,
    xField: "month",
    yField: "value",
    seriesField: "status",

    color: ({status}: any) => {
      if (status === `${t("chart.hocdu")}`) {
        return "#123664";
      } else if (status === `${t("chart.hocthieu")}`) {
        return "#EBA337";
      }
      return "#B80F0F";
    },

    state: {
      active: {
        animate: {duration: 100, easing: "easeLinear"},
        style: {
          lineWidth: 2,
          stroke: "#000",
        },
      },
    },
    padding: 50,
    maxColumnWidth:130,
    label: {
      position: "middle",
      style: {
        fill: "#fff",
      },
      formatter: (data: any) => {
        const itemFilter = dataStatistic.filter(
          (item: AttendByYear) => `${t("chart.thang")} ${item.month}` === data.month
        )[0];
        const total = itemFilter.attendanceCount.totalStudents;
        if (data.value === 0) {
          return;
        }

        return Number.isInteger((data.value / total) * 100)
          ? `${(data.value / total) * 100}%`
          : `${((data.value / total) * 100).toFixed(1)}%`;
      },

      layout: [
        {
          type: "interval-adjust-position",
        },
      ],
    },
    interactions: [
      {
        type: "element-highlight-by-color",
      },
      {
        type: "element-link",
      },
    ],
    annotations: annotation,
    legend: {
      position: "bottom",
      offsetY: 5,
    },
  });

  return (
    <Box>
      <Box mr={2} mb={2} textAlign="right">
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{minWidth: currentProvinceName.length > 20 ? 230 : 150}}
        >
          <Select
            native
            value={provinceId}
            onChange={handleChangeProvince}
            defaultValue={provinceId}
          >
            {listProvincesForStatistic?.map((provinces: ProvinceForStatistic) => (
              <option value={provinces.id}>{provinces.name}</option>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            value={currentMonth}
            onChange={handleChangeMonth}
            defaultValue={currentMonth}
          >
            {Array.from(new Array(12)).map((id, index) => (
              <Fragment key={index.toString()}>
                {index === 0 && <option value="">Tất cả</option>}
                <option value={index + 1}>Tháng {index + 1}</option>
              </Fragment>
            ))}
          </Select>
        </FormControl> */}
        <FormControl variant="outlined" className={classes.formControl}>
          <Select native value={currentYear} onChange={handleChangeYear} defaultValue={currentYear}>
            {Array.from(new Array(10)).map((id, index) => (
              <option value={index + 2020} key={index.toString()}>
                {index + 2020}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <Box className={classes.title__province}>
          <Typography variant="subtitle2" style={{color: "#DC2138"}}>
            {currentProvinceName}
          </Typography>
          <hr
            style={{
              margin: "0 20px",
              flexGrow: 1,
            }}
          />
        </Box>
        {attendBynewprovinceId.filter(
          (item: AttendByYear) => item.attendanceCount.totalStudents !== 0
        ).length > 0 ? (
          <Card style={{padding: 20}}>
            <Column {...config(attendBynewprovinceId, annotations)} />
          </Card>
        ) : (
          <Typography variant="subtitle2">{t("chart.khongcodulieu")}</Typography>
        )}
      </Box>
    </Box>
  );
}
