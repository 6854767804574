import {
  Box,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";

export interface ListViewrProps {
  listViewer: any;
}

export default function ListViewr({listViewer}: ListViewrProps) {
  const useStyles = makeStyles(() => ({
    container: {
      maxHeight: 550,
    },
  }));
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        zIndex: 99,
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",

        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <Box>
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader style={{minWidth: "1300px"}}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" width="50px">
                {t("stt")}
              </StyledTableCell>
              <StyledTableCell align="center" width="200px">
                {t("managerMagazine.thoigianxem")}
              </StyledTableCell>
              <StyledTableCell align="center" width="150px">
                {t("auth.fullName")}
              </StyledTableCell>
              <StyledTableCell align="center" width="100px">
                {t("auth.code")}
              </StyledTableCell>
              <StyledTableCell align="center" width="120px">
                {t("auth.area")}
              </StyledTableCell>
              <StyledTableCell align="center" width="200px">
                {t("auth.province")}
              </StyledTableCell>
              <StyledTableCell align="center" width="200px">
                {t("auth.department")}
              </StyledTableCell>
              <StyledTableCell align="center" width="160px">
                {t("auth.workType")}
              </StyledTableCell>
              <StyledTableCell align="center" width="160px">
                {t("auth.position")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listViewer?.content.map((magazineViewer: any, index: number) => (
              <TableRow key={magazineViewer.id}>
                <StyledTableCell align="center" component="th" scope="magazine">
                  {listViewer?.size * listViewer?.number + index + 1}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {magazineViewer.localDate} - {magazineViewer.localTime}
                </StyledTableCell>
                <StyledTableCell align="center">{magazineViewer.user.name}</StyledTableCell>
                <StyledTableCell align="center">{magazineViewer.user.staffId}</StyledTableCell>
                <StyledTableCell align="center">{magazineViewer.user?.area?.name}</StyledTableCell>
                <StyledTableCell align="center">
                  {magazineViewer.user?.newProvince?.name}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {magazineViewer.user?.department?.title}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {magazineViewer.user?.workType?.name}
                </StyledTableCell>
                <StyledTableCell align="center">{magazineViewer.user?.position}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
