import {useTranslation} from "react-i18next";

export default function useActivityHistory() {
  const {t} = useTranslation();
  const BASE_ACTIVITY = [
    {
      id: 1,
      description: t("activityHistory.taotaikhoanadmin"),
      info: {
        user: {
          userID: "activityHistory.",
          userName: "",
        },
      },
    },
    {
      id: 2,
      description: t("activityHistory.duyettaikhoan"),
      info: {
        userList: {
          userID: "",
          userName: "",
        },
      },
    },
    {
      id: 3,
      description: t("activityHistory.chinhsuataikhoan"),
      info: {
        user: {
          userID: "",
          userName: "",
        },
      },
    },
    {
      id: 4,
      description: t("activityHistory.xoataikhoan"),
      info: {
        user: {
          userID: "",
          userName: "",
        },
      },
    },
    {
      id: 5,
      description: t("activityHistory.taobaihocmoi"),
      info: {
        lession: {
          id: "",
          name: "",
        },
      },
    },
    {
      id: 6,
      description: t("activityHistory.taobaithimoi"),
      info: {
        exam: {
          id: "",
          name: "",
          examDate: "",
        },
      },
    },
    {
      id: 7,
      description: t("activityHistory.xoataikhoan"),
      info: {
        user: {
          userID: "",
          userName: "",
        },
      },
    },
    {
      id: 8,
      description: t("activityHistory.capnhatdiemtuluan"),
      info: {
        user: {
          userID: "",
          userName: "",
        },
      },
    },
    {
      id: 9,
      description: t("activityHistory.duyetbaiviet"),
      info: {
        postList: [],
      },
    },
    {
      id: 7,
      description: t("activityHistory.tuchoibaiviet"),
      info: {
        postList: [],
      },
    },
  ];
  return {
    BASE_ACTIVITY,
  };
}
