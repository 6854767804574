import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 150,
    backgroundColor: "#FFFFFF",

    "& .MuiSelect-root": {
      paddingRight:30,
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:10
    },
  },
  
  title__area: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    margin: "15px 0",
    "& >h6": {
      fontSize: 28,
      fontWeight: 500,
    },
  },
}));

export default useStyles;
