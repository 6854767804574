import {NewListParams, ResponseStatus} from "models/common";
import {MagazineList, ParamsMagazineViewr, ResponseMagazine} from "models/managerMagazine";
import axiosClient from "./axiosClient";

const managerMagazineApi = {
  createMagazine(data: any): Promise<ResponseMagazine<MagazineList>> {
    const url = "/magazine/createMagazine";
    return axiosClient.post(url, data);
  },
  getMagazineList(params: NewListParams): Promise<ResponseMagazine<MagazineList>> {
    const url = "/magazine/getMagazineList";
    return axiosClient.get(url, {params});
  },
  updateMagazine(data: any): Promise<ResponseMagazine<MagazineList>> {
    const url = "/magazine/updateMagazine";
    return axiosClient.post(url, data);
  },
  deleteMagazine(data: {magazine_id: string}): Promise<ResponseMagazine<MagazineList>> {
    const url = "/magazine/deleteMagazine";
    return axiosClient.post(url, data);
  },
  getMagazineViewer(params: ParamsMagazineViewr): any {
    const url = `/magazine/viewers`;
    return axiosClient.get(url, {params});
  },
};

export default managerMagazineApi;
