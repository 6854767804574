import {yupResolver} from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import managerCommnunityApi from "api/managerCommunity";
import {useAppDispatch, useAppSelector} from "app/hooks";
import EMOJI from "assets/img/emoji.png";
import ICON_LIKE from "assets/img/icon_like_fb.svg";
import NO_AVATAR from "assets/img/no_avatar.png";
import InputField from "components/FormFields/InputField";
import {TOKEN} from "constants/config";
import Picker, {SKIN_TONE_MEDIUM_DARK} from "emoji-picker-react";
import {
  fetchCommunityPostList,
  selectParamsCommunityPostList,
} from "features/Admin/pages/Community/communityPostSlice";
import {selectInfoLogin} from "features/Auth/authSlice";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {
  CommentInterface,
  CommunityCommentList,
  ListResponseCommunityPost,
  ListResponseReact,
  ResponseHandleComment,
} from "models/managerCommunityPost";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import * as yup from "yup";
import Reply from "../Reply";
export interface CommentProps {
  comment: CommentInterface;
}

export default function Comment({comment}: CommentProps) {
  const {t} = useTranslation();
  const infoLogin = useAppSelector(selectInfoLogin);
  const [openInputReply, setOpenInputReply] = useState(false);
  const [replyComment, setRepplyComment] = useState<any>();
  const [listChildComment, setListChildComment] = useState<any>([]);
  const [showMenuEdit, setShowMenuEdit] = useState<null | HTMLElement>(null);
  const useStyles = makeStyles((theme: Theme) => ({
    comments: {
      paddingTop: 16,
      display: "flex",
    },
    reply: {
      paddingLeft: 72,
      fontSize: 10,
      cursor: "pointer",
    },
    comments__avatar: {
      width: 50,
      height: 50,
      marginRight: 10,
      objectFit: "none",
    },

    comments__content: {
      backgroundColor: comment?.ownerComment?.id === infoLogin.id ? "#eaf2ff" : "#F2F2F2",
      borderRadius: 10,
      padding: 10,
      position: "relative",
      width: "100%",
    },
    comments__action__more: {
      display:
        comment?.ownerComment?.id === infoLogin.id ||
        infoLogin.role === "ADMIN" ||
        infoLogin.role === "SUPER_ADMIN"
          ? "block"
          : "none",
      position: "absolute",
      right: -60,
      bottom: 10,
    },
    comments__content__username: {
      fontWeight: 600,
      fontSize: 15,
    },
    comments__content__totalLike: {
      position: "absolute",
      right: 10,
      bottom: -10,
      "& > img": {
        width: 20,
        height: 20,
      },
    },
    comment__content__bgtooltip: {
      padding: 5,
      display: "flex",
      "& > img": {
        width: 15,
        height: 15,
        marginRight: 10,
      },
    },
    comments__actions: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: 300,
      padding: "5px 10px",
      paddingBottom: 0,
      "& >h6": {
        fontSize: 13,
        color: "#828282",
        cursor: "pointer",
      },
    },
    icon_emoji: {
      height: 20,
      width: 20,
    },
    form__wrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#F3F3F3",
      borderRadius: 50,
      cursor: "pointer",
      zIndex: 99,
      width: "100%",
      position: "relative",
      padding: "0px 15px",
    },
    input__comment: {
      "& .MuiInputBase-root": {
        "&::after": {
          display: "none",
        },
        "&::before": {
          display: "none",
        },
      },
    },
    btn_delete: {
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
    },
    btn_edit: {
      background: "linear-gradient(360deg, #019ABB 0%, rgba(167, 255, 255, 0.9) 168.18%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
      marginRight: 10,
    },
    icon_emoji_edit: {
      position: "absolute",
      bottom: 7,
      right: 10,
      "& >span >img": {
        width: 25,
        height: 25,
      },
    },
  }));
  const classes = useStyles();
  const confirm = useConfirm();
  const params = useAppSelector(selectParamsCommunityPostList);
  const [openEdit, setOpenEdit] = useState(false);
  const [openShowComments, setOpenShowComments] = useState<boolean>(false);
  const [openEmoji, setOpenEmoji] = useState<null | HTMLElement>(null);
  const [openEmojiEdit, setOpenEmojiEdit] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const [initialValues, setInitialValues] = useState({
    comment: comment?.content,
  });

  const schema = yup.object().shape({
    comment: yup.string().required(`${t("home.detaillesson.vuilongnhapbinhluan")}`),
  });
  const handleOpenMenuEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowMenuEdit(event.currentTarget);
  };

  const handleOpenEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmoji(event.currentTarget);
  };

  const handleGetChildComment = async (idCommentParent: string) => {
    let params: any = {
      parent_comment_id: idCommentParent,
      page_size: 200,
      page_index: 0,
    };
    try {
      const res: ListResponseCommunityPost<CommunityCommentList> =
        await managerCommnunityApi.getListComment(params);
      if (res.responseCode === "00") {
        setListChildComment(res.communityCommentList);
      }
    } catch (error) {}
  };

  const handleComment = async (e: any, postId: string, commentId: string) => {
    if (e.target.value.trim()) {
      if (e.key === "Enter") {
        const formData = new FormData();
        formData.append("content", e.target.value);
        formData.append("parent_post_id", postId);
        formData.append("parent_comment_id", commentId);
        try {
          const res: ResponseHandleComment = await managerCommnunityApi.createComment(formData);
          if (res.responseCode === "00") {
            dispatch(fetchCommunityPostList({...params, loading: true}));
            setRepplyComment("");
            handleGetChildComment(commentId);
          } else {
            toast.error("Lỗi bình luận");
            throw new Error("Failed");
          }
        } catch (error) {}
      }
    }
  };
  const handleLikeComment = async () => {
    try {
      const value = {
        comment_id: comment.id,
        action: comment.isLikedByRequester ? "Unlike" : "Like",
      };
      const res: ListResponseReact = await managerCommnunityApi.ReactComment(value);
      if (res.responseCode === "00") {
        dispatch(fetchCommunityPostList({...params, loading: true}));
      }
    } catch (error) {
      throw new Error("Failed");
    }
  };
  const onEmojiClick = (event: any, emojiObject: any) => {
    if (replyComment) {
      setRepplyComment(replyComment + emojiObject.emoji);
    } else {
      setRepplyComment(emojiObject.emoji);
    }
  };

  const handleTimeCreatedPost = (timeStamp: number) => {
    let currentTime = new Date().getTime();
    let createTime = timeStamp;
    let diff = (currentTime - createTime) / 1000; // seconds
    if (diff < 180) {
      return `${t("community.vuadang")}`;
    } else if (diff < 3600 && diff > 180) {
      diff = diff / 60;
      diff = Math.round(diff);
      return `${diff} ${t("community.phut")}`;
    } else if (diff < 86400 && diff > 3600) {
      diff = diff / 3600;
      diff = Math.round(diff);
      return `${diff} ${t("community.gio")}`;
    } else if (diff < 2592000 && diff > 86400) {
      diff = diff / 86400;
      diff = Math.round(diff);
      return `${diff} ${t("community.ngay")}`;
    } else if (diff < 28512000 && diff > 2592000) {
      diff = diff / 2592000;
      diff = Math.round(diff);
      return `${diff} ${t("community.thang")}`;
    } else if (diff > 28512000) {
      diff = diff / 2592000;
      diff = Math.round(diff);
      return `${diff} ${t("community.nam")}`;
    }
  };
  const handleDelete = () => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.xoabinhluan")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">{t("delete.bancochacchanmuonxoabinhluankhong")}</Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}> {t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_edit} style={{marginRight: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    }).then(async () => {
      const res: ResponseHandleComment = await managerCommnunityApi.deleteComment(comment.id);
      if (res.responseCode === "00") {
        toast.success(`${t("delete.xoabinhluanthanhcong")}`);
        setShowMenuEdit(null);
        dispatch(fetchCommunityPostList({...params, loading: true}));
        setListChildComment([]);
        setOpenInputReply(false);
        setOpenShowComments(false);
      }
    });
  };
  useEffect(() => {
    setInitialValues({
      comment: comment?.content,
    });
  }, [comment]);

  const {control, handleSubmit, setValue, getValues} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  const handleOpenEmojiEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmojiEdit(event.currentTarget);
  };

  const onEmojiClickEdit = (event: any, emojiObject: any) => {
    setValue("comment", getValues().comment + emojiObject.emoji);
  };
  const handleSubmitEditComment = async (value: any) => {
    const formData = new FormData();
    formData.append("content", value.comment);
    formData.append("comment_id", comment.id);
    try {
      const res: ResponseStatus = await managerCommnunityApi.updateComment(formData);
      if (res.responseCode === "00") {
        setOpenEdit(false);
        setShowMenuEdit(null);
        toast.success(`${t("home.detaillesson.chinhsuabinhluanthanhcong")}`);
        dispatch(fetchCommunityPostList({...params, loading: true}));
      } else {
        toast.error(`${t("home.detaillesson.chinhsuabinhluanthatbai")}`);
        throw new Error("Comment is failed");
      }
    } catch (error) {}
  };

  return (
    <Box>
      <Box className={classes.comments}>
        <Box>
          <Avatar
            src={comment?.ownerComment?.avatarUrl || NO_AVATAR}
            alt="avatar"
            className={classes.comments__avatar}
          />
        </Box>
        {openEdit ? (
          <form
            onSubmit={handleSubmit(handleSubmitEditComment)}
            style={{display: "flex", alignItems: "center"}}
          >
            <Box position="relative" style={{minWidth: 500}}>
              <InputField name="comment" control={control} />
              <IconButton onClick={handleOpenEmojiEdit} className={classes.icon_emoji_edit}>
                <img src={EMOJI} alt="EMOJI" />
              </IconButton>
              {openEmojiEdit && (
                <Menu
                  id="simple-menu"
                  anchorEl={openEmojiEdit}
                  keepMounted
                  open={Boolean(openEmojiEdit)}
                  onClose={() => setOpenEmojiEdit(null)}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Picker
                    onEmojiClick={onEmojiClickEdit}
                    disableAutoFocus={true}
                    skinTone={SKIN_TONE_MEDIUM_DARK}
                    groupNames={{smileys_people: "PEOPLE"}}
                    native
                  />
                </Menu>
              )}{" "}
            </Box>
            <Box ml={2}>
              <Button className={classes.btn_edit} type="submit">
                {t("button.edit")}
              </Button>
              <Button
                className={classes.btn_delete}
                onClick={() => {
                  setShowMenuEdit(null);
                  setOpenEdit(false);
                }}
              >
                {t("button.cancel")}
              </Button>
            </Box>
          </form>
        ) : (
          <Box>
            <Box className={classes.comments__content}>
              <IconButton className={classes.comments__action__more} onClick={handleOpenMenuEdit}>
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={showMenuEdit}
                keepMounted
                open={Boolean(showMenuEdit)}
                onClose={() => setShowMenuEdit(null)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {comment?.ownerComment?.id === infoLogin.id && (
                  <MenuItem onClick={() => setOpenEdit(true)}>
                    <span>{t("btn.chinhsua")}</span>
                  </MenuItem>
                )}
                <MenuItem onClick={handleDelete}>
                  <span>{t("btn.xoabinhluan")}</span>
                </MenuItem>
              </Menu>
              <Typography variant="subtitle2" className={classes.comments__content__username}>
                {comment?.ownerComment?.name}
              </Typography>
              <Typography variant="body2">{comment.content}</Typography>
              {comment.totalLikeCount > 0 && (
                <Box className={classes.comments__content__totalLike}>
                  <Tooltip
                    title={
                      <div className={classes.comment__content__bgtooltip}>
                        <img src={ICON_LIKE} alt="LIKE" />
                        <Typography variant="body2">{comment.totalLikeCount}</Typography>
                      </div>
                    }
                  >
                    <img src={ICON_LIKE} alt="LIKE" />
                  </Tooltip>
                </Box>
              )}
            </Box>
            <Box className={classes.comments__actions}>
              <Typography
                variant="subtitle2"
                style={{color: comment.isLikedByRequester ? "#2078f4" : "initial"}}
                onClick={handleLikeComment}
              >
                {t("community.thich")}
              </Typography>

              <Typography
                variant="subtitle2"
                onClick={() => {
                  setOpenInputReply(true);
                  setOpenShowComments(true);
                  handleGetChildComment(comment.id);
                }}
                style={{marginLeft: "10px"}}
              >
                {t("community.phanhoi")}
              </Typography>
              <Typography variant="subtitle2" style={{marginLeft: 10}}>
                {handleTimeCreatedPost(comment.postedTimestampUTC)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <Typography className={classes.reply}>
          {listChildComment.length > 0 &&
            listChildComment.map((item: any, index: number) => (
              <Reply
                reply={item}
                handleGetChildComment={handleGetChildComment}
                handleTimeCreatedPost={handleTimeCreatedPost}
              />
            ))}
          {comment.totalCommentCount > 0 && !openShowComments && (
            <span
              onClick={() => {
                handleGetChildComment(comment.id);
                setOpenShowComments(true);
              }}
            >
              <i className="fas fa-long-arrow-right"></i> {comment.totalCommentCount}{" "}
              {t("community.phanhoi").toLowerCase()}
            </span>
          )}
        </Typography>
      </Box>
      {openInputReply && (
        <Box display="flex" alignItems="center" mt={2} ml={8}>
          <Box mr={1}>
            <Avatar
              src={infoLogin.profileImageUrl || NO_AVATAR}
              alt="avatar"
              className={classes.comments__avatar}
            />
          </Box>
          <Box className={classes.form__wrapper}>
            <TextField
              name="comment"
              placeholder={t("community.binhluan...")}
              fullWidth
              className={classes.input__comment}
              autoComplete="off"
              onChange={(e) => setRepplyComment(e.target.value)}
              onKeyPress={(e) => handleComment(e, comment.parentPostID, comment.id)}
              value={replyComment}
            />
            <IconButton onClick={handleOpenEmoji}>
              <img src={EMOJI} alt="EMOJI" className={classes.icon_emoji} />
            </IconButton>
            {openEmoji && (
              <Menu
                id="simple-menu"
                anchorEl={openEmoji}
                keepMounted
                open={Boolean(openEmoji)}
                onClose={() => setOpenEmoji(null)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Picker
                  onEmojiClick={onEmojiClick}
                  disableAutoFocus={true}
                  skinTone={SKIN_TONE_MEDIUM_DARK}
                  groupNames={{smileys_people: "PEOPLE"}}
                  native
                />
              </Menu>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
