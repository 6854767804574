import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import authApi from "api/authApi";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACK_GROUND from "assets/img/bg_login.jpg";
import InputField from "components/FormFields/InputField";
import {TOKEN, TOKEN_ADMIN} from "constants/config";
import FLAG_KOREA from "assets/img/flag-ko.png";
import FLAG_VIETNAM from "assets/img/flag-vi.png";
import FLAG_ENG from "assets/img/flag-en.png";
import FLAG_COMBODIA from "assets/img/flag-combodia.png";
import BACKGROUND_MOBILE from "assets/img/bg_mobile.jpg";

import {
  fetchInfoUserLogged,
  fetchIsChecked,
  handleChangeLanguage,
  selectInfoUserLogged,
  selectIsChecked,
} from "features/Auth/authSlice";
import {LoginSuccess, UserLogin} from "models/auth";
import React, {ChangeEvent, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Navigate, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";
import useStyles from "./style";

const schema = yup.object().shape({
  username: yup.string().required("*Email hoặc mã nhân viên bắt buộc nhập ."),
  password: yup.string().required("*Mật khẩu bắt buộc nhập."),
});

export default function Login() {
  const token_user = localStorage.getItem(TOKEN);
  const token_admin = localStorage.getItem(TOKEN_ADMIN);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [valueLanguage, setValueLanguage] = useState(localStorage.getItem("language") || "vi");
  const {t} = useTranslation();
  const [err, setErr] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const infoUserLogged = useAppSelector(selectInfoUserLogged);
  const isChecked = useAppSelector(selectIsChecked);
  const initialValues: UserLogin = {
    username: infoUserLogged.username,
    password: infoUserLogged.password,
  };
  const {control, handleSubmit} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const handleSubmitForm = async (infoUser: UserLogin) => {
    if (isChecked) {
      dispatch(fetchInfoUserLogged(infoUser));
    }
    try {
      const res: LoginSuccess = await authApi.login(infoUser);
      if (res.responseCode === "00") {
        localStorage.setItem(TOKEN, res.accessToken);
        if (res.role === "STUDENT") {
          localStorage.setItem("ROLE", "STUDENT");
          toast.success(`${t("auth.login.success")}`);
          await navigate("/home");
        } else {
          toast.success(`${t("auth.login.success")}`);
          localStorage.setItem("ROLE", "ADMIN");
          localStorage.setItem(TOKEN_ADMIN, res.accessToken);
          await navigate("/admin");
        }
      } else if (res.responseCode === "06") {
        toast.error(`${t("auth.login.approved")}`);
        throw new Error(`${t("auth.login.approved")}`);
      } else {
        toast.error(`${t("auth.login.failed")}`);
        throw new Error(`${t("auth.login.failed")}`);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleChangeCheckBox = () => {
    dispatch(fetchIsChecked());
  };
  const classes = useStyles({
    background: BACK_GROUND,
    bg_mobile: BACKGROUND_MOBILE,
  });
  if (token_admin) {
    return <Navigate to="/admin" />;
  }
  if (token_user) {
    return <Navigate to="/home" />;
  }

  const handleSelectLanguage = (event: React.ChangeEvent<{value: any}>) => {
    const {value} = event.target;
    localStorage.setItem("language", value);
    dispatch(handleChangeLanguage(value));
    setValueLanguage(value);
  };

  return (
    <Box>
      {isMobile ? (
        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <Box className={classes.background_mobile}>
            <FormControl className={classes.selectLanguage}>
              <Select
                value={valueLanguage}
                onChange={handleSelectLanguage}
                className={classes.selectEmpty}
                inputProps={{"aria-label": "Without label"}}
                displayEmpty
              >
                <MenuItem value="vi">
                  <img src={FLAG_VIETNAM} className={classes.icon__flag} alt="FLAG_VIETNAM" />{" "}
                  <Typography variant="subtitle2">Việt Nam</Typography>
                </MenuItem>
                <MenuItem value="en">
                  <img src={FLAG_ENG} className={classes.icon__flag} alt="FLAG_ENG" />{" "}
                  <Typography variant="subtitle2">English</Typography>
                </MenuItem>
                <MenuItem value="ko">
                  {" "}
                  <img src={FLAG_KOREA} className={classes.icon__flag} alt="FLAG_VIETNAM" />
                  <Typography variant="subtitle2">Korean</Typography>
                </MenuItem>
                <MenuItem value="khme">
                  {" "}
                  <img src={FLAG_COMBODIA} className={classes.icon__flag} alt="FLAG_VIETNAM" />
                  <Typography variant="subtitle2">Cambodia</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.form_login_mobile}>
            <Box className={classes.form__mobile__content}>
              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Typography variant="h5" className={classes.form__title}>
                  {t("auth.login.upercase")}
                </Typography>
                <Box>
                  <InputField
                    name="username"
                    label={t("staffId-or-email")}
                    control={control}
                    borderRadius="40px"
                    autocomplete={true}
                  />
                </Box>
                <InputField
                  name="password"
                  label={t("auth.password")}
                  control={control}
                  inputType="password"
                  borderRadius="40px"
                />
                {err && <Alert severity="error">{err}</Alert>}
                <Box display="flex" justifyContent="space-around" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleChangeCheckBox}
                        name="rememberMe"
                        className={classes.checkbox}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2" className={classes.checkbox__rememberMe}>
                        {t("auth.rememberMe")}
                      </Typography>
                    }
                  />
                  <Typography
                    variant="subtitle2"
                    component="i"
                    className={classes.forgotPassword}
                    onClick={() => navigate("/forgot-password")}
                  >
                    {t("auth.forgotPassword")}
                  </Typography>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.btn_submit}
                >
                  {t("auth.login")}
                </Button>

                <Typography variant="body2" className={classes.textRegister}>
                  {t("auth.dontHaveAccount")}
                  <Typography
                    variant="body2"
                    component="span"
                    onClick={() => navigate("/register")}
                  >
                    {" "}
                    {t("auth.register")}
                  </Typography>
                </Typography>
              </form>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.background}>
          <Box className={classes.content}>
            <FormControl className={classes.selectLanguage}>
              <Select
                value={valueLanguage}
                onChange={handleSelectLanguage}
                className={classes.selectEmpty}
                inputProps={{"aria-label": "Without label"}}
                displayEmpty
              >
                <MenuItem value="vi">
                  <img src={FLAG_VIETNAM} className={classes.icon__flag} alt="FLAG_VIETNAM" />{" "}
                  <Typography variant="subtitle2">Việt Nam</Typography>
                </MenuItem>
                <MenuItem value="en">
                  <img src={FLAG_ENG} className={classes.icon__flag} alt="FLAG_ENG" />{" "}
                  <Typography variant="subtitle2">English</Typography>
                </MenuItem>
                <MenuItem value="ko">
                  {" "}
                  <img src={FLAG_KOREA} className={classes.icon__flag} alt="FLAG_VIETNAM" />
                  <Typography variant="subtitle2">Korean</Typography>
                </MenuItem>
                <MenuItem value="khme">
                  {" "}
                  <img src={FLAG_COMBODIA} className={classes.icon__flag} alt="FLAG_VIETNAM" />
                  <Typography variant="subtitle2">Cambodia</Typography>
                </MenuItem>
              </Select>
            </FormControl>

            <Box className={classes.form}>
              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Typography variant="h5" className={classes.form__title}>
                  {t("auth.login.upercase")}
                </Typography>
                <Box pb={1}>
                  <InputField
                    name="username"
                    label={t("staffId-or-email")}
                    control={control}
                    borderRadius="40px"
                    autocomplete={true}
                  />
                </Box>
                <InputField
                  name="password"
                  label={t("auth.password")}
                  control={control}
                  inputType="password"
                  borderRadius="40px"
                />
                {err && <Alert severity="error">{err}</Alert>}
                <Box display="flex" justifyContent="space-around" alignItems="center" pt={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleChangeCheckBox}
                        name="rememberMe"
                        className={classes.checkbox}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2" className={classes.checkbox__rememberMe}>
                        {t("auth.rememberMe")}
                      </Typography>
                    }
                  />
                  <Typography
                    variant="subtitle2"
                    component="i"
                    className={classes.forgotPassword}
                    onClick={() => navigate("/forgot-password")}
                  >
                    {t("auth.forgotPassword")}
                  </Typography>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.btn_submit}
                >
                  {t("auth.login")}
                </Button>

                <Typography variant="body2" className={classes.textRegister}>
                  {t("auth.dontHaveAccount")}
                  <Typography
                    variant="body2"
                    component="span"
                    onClick={() => navigate("/register")}
                  >
                    {" "}
                    {t("auth.register")}
                  </Typography>
                </Typography>
              </form>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
