import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import managerTestApi from "api/managerTest";
import {useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_waiting_test.png";
import CLOCK from "assets/img/clock.png";
import {selectInfoLogin} from "features/Auth/authSlice";
import {ListExam} from "models/managerTest";
import moment from "moment";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import useStyles from "./style";

export interface TakeATestProps {}

export default function TakeATest(props: TakeATestProps) {
  const classes = useStyles();
  const {t} = useTranslation();
  const location: any = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [detailTest, setDetailTest] = useState<ListExam>();
  const navigate = useNavigate();
  const infoUser = useAppSelector(selectInfoLogin);
  const [minutes, setMinutes] = useState<any>();
  const [seconds, setSeconds] = useState<any>();

  useEffect(() => {
    (async () => {
      const res: ListExam = await managerTestApi.getDetailExam(Number(location?.state?.id));
      setDetailTest(res);
    })();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDay = new Date().getTime();
      const timeOfTest = new Date(detailTest?.exam?.startDate).getTime();
      const difference = moment.duration(moment(timeOfTest).diff(moment(currentDay)));
      if (difference.minutes() > 0) {
        setMinutes(difference.minutes());
      } else if (difference.minutes() === 0) {
        setMinutes(0);
      }
      if (difference.seconds() > 0) {
        setSeconds(difference.seconds());
      } else if (difference.seconds() === 0) {
        setSeconds(0);
      } else {
        setSeconds(0);
        setMinutes(0);
      }
    }, 1000);

    return () => clearInterval(interval);
  });
  if (location?.state === null) {
    return <Navigate to="/" />;
  }
  return (
    <Box position="relative">
      {!detailTest ? (
        <Box className={classes.loading}>
          <CircularProgress style={{width: 50, height: 50}} />
        </Box>
      ) : (
        <Box px={isMobile ? 1 : 3}>
          <img src={BACKGROUND} alt="BACKGROUND" className={classes.background} />
          <Box className={classes.container}>
            <Box className={classes.wrapper}>
              <Box flex={1}>
                <Typography variant="subtitle2" className={classes.content__title}>
                  {t("auth.fullName")} :{" "}
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={classes.content__title}
                  >
                    {infoUser?.name}
                  </Typography>
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.content__title}
                  style={{margin: isMobile ? "15px 0 " : "20px 0"}}
                >
                  {t("auth.department")} :{" "}
                  <Typography
                    variant="subtitle2"
                    className={classes.content__title}
                    component="span"
                  >
                    {detailTest?.exam?.isAll == true
                      ? `${t("tatca")}`
                      : detailTest?.exam?.department?.title}
                  </Typography>
                </Typography>
                <Typography variant="subtitle2" className={classes.content__title}>
                  {t("listexam.tieudebaithi")} :{" "}
                  <Typography
                    variant="subtitle2"
                    className={classes.content__title}
                    component="span"
                  >
                    {detailTest?.exam?.examTitle}
                  </Typography>
                </Typography>
                {detailTest?.exam?.prize?.name && (
                  <Typography variant="subtitle2" className={classes.content__title}>
                    {t("listexam.phanthuong")} :{" "}
                    <Typography
                      variant="subtitle2"
                      className={classes.content__title}
                      component="span"
                    >
                      {detailTest?.exam?.prize?.name}
                    </Typography>
                  </Typography>
                )}
              </Box>

              {
                <Box className={classes.content__right}>
                  <img src={CLOCK} alt="CLOCK" />
                  <Typography variant="subtitle2">
                    {minutes === 0 && seconds === 0 ? (
                      <Button
                        className={classes.btn_start}
                        onClick={() =>
                          navigate("/exam/take-exam", {
                            state: {
                              id: location?.state?.id,
                              startTime: new Date(),
                            },
                          })
                        }
                      >
                        {t("button.start")}
                      </Button>
                    ) : (
                      <>
                        {minutes < 10 ? "0" + minutes : minutes}:
                        {seconds < 10 ? "0" + seconds : seconds}
                      </>
                    )}
                  </Typography>
                </Box>
              }
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
