import {Box, Typography} from "@material-ui/core";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import clsx from "clsx";
import {GroupList, ListFarm, ListProvince} from "models/managerGroup";
import * as React from "react";
import {TreeNode} from "react-organizational-chart";
import useStyles from "../style";

export interface FarmProps {
  farm: ListFarm;
  area: GroupList;
  provinces: ListProvince;
}

export default function Farm({farm, area, provinces}: FarmProps) {
  const classes = useStyles();
  return (
    <TreeNode
      label={
        <Box
          className={clsx(classes.root, classes.workType, classes.provinces)}
          style={{marginTop: provinces.farms.length > 1 ? 0 : 20}}
        >
          <Typography
            variant="subtitle2"
            style={{
              color:
                area.areaName === "Miền Bắc"
                  ? "#B80F0F"
                  : area.areaName === "Miền Nam"
                  ? "#123664"
                  : "#EBA337",
            }}
          >
            {" "}
            {farm.farmName}
          </Typography>
          <PeopleAltIcon style={{color: "#c1c1c1"}} />
          <Typography variant="subtitle2" style={{color: "#137BBD", fontSize: 15}}>
            {/* 1231 */}
          </Typography>
        </Box>
      }
    />
  );
}
