import {Sunburst} from "@ant-design/plots";
import {Box, Card, CardHeader, FormControl, Grid, Select, Typography} from "@material-ui/core";
import managerChartApi from "api/managerChart";
import clsx from "clsx";
import {AttendByArea, ResponseAttendByArea} from "models/managerChart";
import React, {Fragment, useEffect, useState} from "react";
import useStyles from "./style";

import {useTranslation} from "react-i18next";

export interface ChartAttendByAreaProps {}

export default function ChartAttendByArea(props: ChartAttendByAreaProps) {
  const classes = useStyles();
  const {t} = useTranslation();
  const [currentMonth, setCurrentMonth] = useState<number | "">(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [dataStatisticAttend, setDataStatisticAttend] = useState<AttendByArea[]>([]);

  const handleChangeMonth = (event: React.ChangeEvent<{value: unknown}>) => {
    setCurrentMonth(Number(event.target.value));
  };

  const handleChangeYear = (event: React.ChangeEvent<{value: unknown}>) => {
    setCurrentYear(Number(event.target.value));
  };

  useEffect(() => {
    if (currentMonth < 1) {
      setCurrentMonth("");
    }
    (async () => {
      try {
        const res: ResponseAttendByArea<AttendByArea> =
          await managerChartApi.getStatisticAttendByArea({
            year: currentYear,
            month: currentMonth,
          });
        if (res.responseCode === "00") {
          setDataStatisticAttend(res.attendanceByMonth);
        }
      } catch (error) {}
    })();
  }, [currentYear, currentMonth]);

  const config: any = (index: number) => ({
    data:
      dataStatisticAttend.map((item, index) => ({
        key: index,
        name: "Biểu đồ điểm danh",
        children: [
          {
            name: `${t("chart.miennam")}`,
            value: item.south.totalStudents,
            path: `${t("chart.miennam")}`,
            children: [
              {
                name: `${t("chart.bohoc")}`,
                value: item.south.noWatched,
                path: "Miền Nam / Bỏ học",
              },
              {
                name: `${t("chart.hocthieu")}`,
                value: item.south.notFullyWatched,
                path: "Miền Nam / Học thiếu",
              },
              {
                name: `${t("chart.hocdu")}`,
                value: item.south.fullyWatched,
                path: "Miền Nam / Học đủ",
              },
            ],
          },
          {
            name: `${t("chart.mienbac")}`,
            value: item.north.totalStudents,
            path: `${t("chart.mienbac")}`,
            children: [
              {
                name: `${t("chart.bohoc")}`,
                value: item.north.noWatched,
                path: "Miền Bắc / Bỏ học",
              },
              {
                name: `${t("chart.hocthieu")}`,
                value: item.north.notFullyWatched,
                path: "Miền Bắc / Học thiếu",
              },
              {
                name: `${t("chart.hocdu")}`,
                value: item.north.fullyWatched,
                path: "Miền Bắc / Học đủ",
              },
            ],
          },
          {
            name: `${t("chart.mientrung")}`,
            value: item.middle.totalStudents,
            path: `${t("chart.mientrung")}`,
            children: [
              {
                name: `${t("chart.bohoc")}`,
                value: item.middle.noWatched,
                path: "Miền Trung / Bỏ học",
              },
              {
                name: `${t("chart.hocthieu")}`,
                value: item.middle.notFullyWatched,
                path: "Miền Trung / Học thiếu",
              },
              {
                name: `${t("chart.hocdu")}`,
                value: item.middle.fullyWatched,
                path: "Miền Trung / Học đủ",
              },
            ],
          },
        ],
      }))[index] || [],
    innerRadius: 0.2,
    colorField: "path",
    color: ({path}: any) => {
      if (path === `${t("chart.mienbac")}`) {
        return "#B80F0F";
      } else if (path === `${t("chart.miennam")}`) {
        return "#123664";
      } else if (path === "Miền Nam / Bỏ học") {
        return "#123664";
      } else if (path === "Miền Nam / Học thiếu") {
        return "#123664";
      } else if (path === "Miền Nam / Học đủ") {
        return "#123664";
      } else if (path === "Miền Bắc / Bỏ học") {
        return "#B80F0F";
      } else if (path === "Miền Bắc / Học thiếu") {
        return "#B80F0F";
      } else if (path === "Miền Bắc / Học đủ") {
        return "#B80F0F";
      }
      return "#EBA337";
    },

    pattern: ({name}: any) => {
      if (name === `${t("chart.bohoc")}`) {
        return {
          type: "line",
        };
      } else if (name === `${t("chart.hocthieu")}`) {
        return {
          type: "dot",
        };
      } else if (name === `${t("chart.mienbac")}`) {
        return {
          type: "",
          cfg: {
            backgroundColor: "#B80F0F",
          },
        };
      } else if (name === `${t("chart.mientrung")}`) {
        return {
          type: "",
          cfg: {
            backgroundColor: "#EBA337",
          },
        };
      } else if (name === `${t("chart.miennam")}`) {
        return {
          type: "",
          cfg: {
            backgroundColor: "#123664",
          },
        };
      }
    },
    sunburstStyle: {
      fillOpacity: 0.999999999,
    },
    label: {
      content: (originData: any) => {
        const percNoWatched = Number(
          (
            (originData.parent?.children?.filter((x: any) => {
              return x.name === `${t("chart.bohoc")}` && x.value > 0;
            })[0]?.value /
              originData.parent?.value) *
            100
          ).toFixed(1)
        );

        const percNotFullyWatched = Number(
          (
            (originData.parent?.children?.filter((x: any) => {
              return x.name === `${t("chart.hocthieu")}` && x.value > 0;
            })[0]?.value /
              originData.parent?.value) *
            100
          ).toFixed(1)
        );

        const percFullyWatched = Number(
          (
            (originData.parent?.children?.filter((x: any) => {
              return x.name === `${t("chart.hocdu")}` && x.value > 0;
            })[0]?.value /
              originData.parent?.value) *
            100
          ).toFixed(1)
        );

        if (originData.name === `${t("chart.miennam")}`) {
          return `${t("chart.nam")}`;
        } else if (originData.name === `${t("chart.mienbac")}`) {
          return `${t("chart.bac")}`;
        } else if (originData.name === `${t("chart.mientrung")}`) {
          return `${t("chart.trung")}`;
        } else if (originData.name === `${t("chart.bohoc")}` && percNoWatched > 0) {
          return `${percNoWatched}%`;
        } else if (originData.name === `${t("chart.hocdu")}` && percFullyWatched > 0) {
          return `${percFullyWatched}%`;
        } else if (originData.name === `${t("chart.hocthieu")}` && percNotFullyWatched > 0) {
          return `${percNotFullyWatched}%`;
        }
      },
    },

    interactions: [
      {
        type: "element-active",
      },
    ],
    // legend: {
    //   layout: "vertical",
    //   position: "left",
    // },
  });
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="subtitle2" className={classes.title}>
          {t("chart.bieudonhanvienthamgiahoccua3mien")}
        </Typography>
        <Box mr={2}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              native
              value={currentMonth}
              onChange={handleChangeMonth}
              defaultValue={currentMonth}
            >
              {Array.from(new Array(12)).map((id, index) => (
                <Fragment key={index.toString()}>
                  {index === 0 && <option value="">{t("tatca")}</option>}
                  <option value={index + 1}>
                    {t("chart.thang")} {index + 1}
                  </option>
                </Fragment>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              native
              value={currentYear}
              onChange={handleChangeYear}
              defaultValue={currentYear}
            >
              {Array.from(new Array(10)).map((id, index) => (
                <option value={index + 2020} key={index.toString()}>
                  {index + 2020}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {dataStatisticAttend.length > 0 && dataStatisticAttend.every((x) => x.totalStudents === 0) ? (
        <Box>{t("chart.khongcodulieu")}</Box>
      ) : (
        <Grid container spacing={3}>
          {dataStatisticAttend.map(
            (data, index) =>
              data.totalStudents > 0 && (
                <Grid
                  item
                  xs={dataStatisticAttend.length > 1 ? 4 : 12}
                  key={data.month}
                  style={{justifyContent: "center"}}
                >
                  <Card className={dataStatisticAttend.length === 1 ? classes.oneCard : ""}>
                    <CardHeader title={<Box textAlign="center">{`Tháng ${data.month}`}</Box>} />
                    <Sunburst {...config(index)} />
                    <Box className={classes.content__note}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Typography variant="subtitle2" className={classes.content__text__note}>
                            {t("chart.mausac")}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="subtitle2"
                            className={clsx(classes.content__text__note, classes.bg_note_north)}
                          >
                            {t("chart.mienbac")}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="subtitle2"
                            className={clsx(classes.content__text__note, classes.bg_note_center)}
                          >
                            {t("chart.mientrung")}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="subtitle2"
                            className={clsx(classes.content__text__note, classes.bg_note_south)}
                          >
                            {t("chart.miennam")}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="subtitle2" className={classes.content__text__note}>
                            {t("chart.hoavan")}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="subtitle2"
                            className={clsx(
                              classes.content__text__note,
                              classes.bg_note_fullyWatched
                            )}
                          >
                            {t("chart.hocdu")}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="subtitle2"
                            className={clsx(
                              classes.content__text__note,
                              classes.bg_note_notFullyWatched
                            )}
                          >
                            {t("chart.bohoc")}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="subtitle2"
                            className={clsx(classes.content__text__note, classes.bg_note_noWatched)}
                          >
                            {t("chart.hocthieu")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              )
          )}
        </Grid>
      )}
    </Box>
  );
}
