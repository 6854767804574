import {Box, Grow, makeStyles, Theme, Tooltip, Typography} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLessIcon from "@material-ui/icons/ExpandMore";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import clsx from "clsx";
import {GroupList, ListFarm, ListProvince, ListWorkType} from "models/managerGroup";
import React, {Fragment, useState} from "react";
import {TreeNode} from "react-organizational-chart";
import Farm from "./Farm";

export interface ProvincesProps {
  provinces: ListProvince;
  setValueMap: (x: any) => void;
  listDepartment: ListWorkType;
  area: GroupList;
}

export default function Provinces({provinces, setValueMap, listDepartment, area}: ProvincesProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    provinces: {
      borderRadius: 8,
      padding: 5,
      height: 100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      boxShadow: "0px 2px 4px rgb(0 0 0 / 25%), 0px 0px 6px rgb(168 168 168 / 25%)",
      minWidth: 130,
      width: "50%",
      backgroundColor: "#fff",
      margin: "0 auto",
      "&>h6": {
        display: "flex",
        height: "100%",
        justifyContent: "space-around",
        alignItems: "center",
        fontSize: 13,
        cursor: "pointer",
      },
      "&>svg": {
        color: "#137BBD",
        cursor: "pointer",
        fontSize: 25,
      },
    },

    treeNode: {
      "& > ul": {
        "&::before": {
          height: provinces.farms.length === 1 && 40,
        },
      },
    },
  }));
  const [open, setOpen] = useState(false);

  const handleOpenDropdown = () => {
    if (provinces.farms.length === 0) {
      return;
    }
    setOpen(!open);
  };
  const classes = useStyles();
  return (
    <TreeNode
      className={classes.treeNode}
      label={
        <Box
          className={classes.provinces}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{marginTop: listDepartment.provinces.length > 1 ? 0 : 20}}
        >
          <Tooltip title="Chọn xem vị trí trên bản đồ">
            <Typography
              variant="subtitle2"
              style={{
                color:
                  area.areaName === "Miền Bắc"
                    ? "#B80F0F"
                    : area.areaName === "Miền Nam"
                    ? "#123664"
                    : "#EBA337",
              }}
              onClick={() => {
                setValueMap(provinces.provinceName);
              }}
            >
              {provinces.provinceName}
            </Typography>
          </Tooltip>
          <PeopleAltIcon style={{color: "#c1c1c1"}} />
          <Typography variant="subtitle2" style={{color: "#137BBD", fontSize: 15}}>
            {provinces.totalStaffCount}
          </Typography>

          {provinces.farms.length === 0 ? (
            ""
          ) : open ? (
            <ExpandLessIcon onClick={handleOpenDropdown} />
          ) : (
            <ChevronRightIcon onClick={handleOpenDropdown} />
          )}
        </Box>
      }
    >
      {open ? (
        <Grow in={open}>
          <div style={{display: "inherit"}}>
            {provinces.farms.map((farm: ListFarm) => (
              <Fragment key={farm.farmId}>
                <Farm farm={farm} area={area} provinces={provinces} />
              </Fragment>
            ))}
          </div>
        </Grow>
      ) : null}
    </TreeNode>
  );
}
