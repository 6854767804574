import managerAdminSaga from "features/Admin/pages/ManagerAdmin/managerAdminSaga";
import managerCommunityCategorySaga from "features/Admin/pages/Community/communitySaga";
import managerCategorySaga from "features/Admin/pages/ManagerCategory/managerCategorySaga";
import managerGroupSaga from "features/Admin/pages/ManagerGroup/managerGroupSaga";
import managerLessonSaga from "features/Admin/pages/ManagerLesson/managerLessonSaga";
import managerPrizeSaga from "features/Admin/pages/ManagerPrize/managerPrizeSaga";
import managerTestListSaga from "features/Admin/pages/ManagerTest/managerExamListSaga";
import authSaga from "features/Auth/authSaga";
import optionsSaga from "features/Auth/options/optionsSaga";
import detailLessonSaga from "features/Home/pages/DetailLesson/detailLessonSaga";
import listExamStudentSaga from "features/Home/pages/ListExamStudent/listExamStudentSaga";
import ListLessonByUserSaga from "features/Home/pages/ListLesson/listLessonByUserSaga";
import managerCommunityPostSaga from "features/Admin/pages/Community/communityPostSaga";
import managerCommunityCommentSaga from "features/Admin/pages/Community/communityCommentSaga";
import {all} from "redux-saga/effects";
import managerChartSaga from "features/Admin/pages/Charts/managerChartSaga";
import managerMagazineSaga from "features/Admin/pages/ManagerMagazine/managerMagazineSaga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    optionsSaga(),
    managerAdminSaga(),
    managerCommunityCategorySaga(),
    managerCommunityPostSaga(),
    managerCommunityCommentSaga(),
    managerPrizeSaga(),
    managerGroupSaga(),
    managerTestListSaga(),
    managerCategorySaga(),
    managerLessonSaga(),
    ListLessonByUserSaga(),
    detailLessonSaga(),
    listExamStudentSaga(),
    managerChartSaga(),
    managerMagazineSaga(),
  ]);
}
