import {makeStyles, Theme} from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 4px rgba(126, 126, 126, 0.25)",
    zIndex: 99,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 8px",
    },
  },
  logo: {
    height: "auto",
    padding: 5,
    width: 120,
    borderRadius: 0,
    cursor: "pointer",
  },
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
  },
  listItems: {
    fontSize: 14,
    margin: "0 12px",
    padding: "30px 0px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.up("lg")]: {
      margin: "0 28px",
      fontSize: 16,
    },
    "&:hover": {
      "& $listItems__lesson , $listItems__profile , $listItems__test": {
        display: "block",
      },
    },
  },

  listItems__text: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      color: "#28A5F4",
      transition: "color 0.2s linear",
    },
  },

  listItems__lesson: {
    display: "none",
  },
  listItems__profile: {
    display: "none",
  },
  listItems__test: {
    display: "none",
  },

  listItems__content: {
    padding: "10px 0 10px 10px",
    position: "absolute",
    backgroundColor: " #f9f9f9",
    top: 80,
    minWidth: 200,
    boxShadow: "0px 3px 16px 0px rgba(0,0,0,0.2)",
    zIndex: 1,
    borderRadius: 10,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  listItems__content__title: {
    color: "#767676",
    cursor: "pointer",
    marginTop: 10,
    marginBottom: 15,
    paddingLeft: 10,
    "&:hover": {
      color: "#157BBC",
    },
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    marginLeft: 10,
  },
  menu: {
    "& .MuiPaper-root": {
      borderRadius: "12px",
      backgroundColor: "#fff",
      boxShadow: "0px 6px 20px rgb(0 0 0 / 20%)",
      overflowX: "hidden",
      overflowY: "auto",
      padding: 10,
      width: 150,
    },
    "& .MuiList-root ": {
      width: "100%",
    },
  },

  btn_profile: {
    padding: "5px 15px 5px 15px",
    border: "1px solid #DDDDDD",
    backgroundColor: "#ffffff",
    borderRadius: 25,
    height: 42,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "&:hover": {
        boxShadow: "0px 2px 4px rgb(0 0 0 / 18%)",
        transition: "box-shadow 0.25s ease",
      },
    },
  },
  exit: {
    display: "flex",
    alignItems: "center",
    "& > h6": {
      cursor: "pointer",
      "&:hover": {
        color: "red",
      },
    },
  },
  icon_exit: {
    color: "red",
    cursor: "pointer",
    marginRight: 5,
  },
  drawer_container: {
    zIndex: 9,
    width: "100%",
    "& .MuiDrawer-paper": {
      padding: "15px 0 0 15px",
      width: 250,
      backgroundColor: "#fff",
      color: "#000",
      [theme.breakpoints.down("xs")]: {
        width: 200,
      },
    },
  },
  avatar_mobile: {
    display: "inline-block",
    cursor: "pointer",
    fontSize: 30,
    marginRight: 10,
    color: "#e9dada",
    marginTop: 5,
  },
  username: {
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
  },
  list__item: {
    padding: "10px 5px",
    "& > a": {
      color: "#000",
      fontSize: 13,
      position: "relative",
      "&:hover": {
        color: "#28A5F4",
        transition: "color 0.2s linear",
      },
    },
  },
}));

export default useStyles;
