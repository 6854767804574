import {Column} from "@ant-design/plots";
import {each, groupBy} from "@antv/util";
import {Box, Card, FormControl, Select, Typography} from "@material-ui/core";
import managerChartApi from "api/managerChart";
import {AttendProvinceByArea, ResponseAttendProvinceByArea} from "models/managerChart";
import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useStyles from "./style";

export interface ChartAttendProvinceByAreaProps {}

export default function ChartAttendProvinceByArea(props: ChartAttendProvinceByAreaProps) {
  const classes = useStyles();
  const {t} = useTranslation();
  const [currentMonth, setCurrentMonth] = useState<number | "">(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [statisticProvinceByNorth, setStatisticProvinceByNorth] = useState<AttendProvinceByArea[]>(
    []
  );
  const [statisticProvinceBySouth, setStatisticProvinceBySouth] = useState<AttendProvinceByArea[]>(
    []
  );
  const [statisticProvinceByMiddle, setStatisticProvinceByMiddle] = useState<
    AttendProvinceByArea[]
  >([]);

  const handleChangeMonth = (event: React.ChangeEvent<{value: unknown}>) => {
    setCurrentMonth(Number(event.target.value));
  };

  const handleChangeYear = (event: React.ChangeEvent<{value: unknown}>) => {
    setCurrentYear(Number(event.target.value));
  };

  useEffect(() => {
    if (currentMonth < 1) {
      setCurrentMonth("");
    }
    (async () => {
      try {
        const res: ResponseAttendProvinceByArea<AttendProvinceByArea> =
          await managerChartApi.getStatisticAttendProvinceByArea({
            year: currentYear,
            month: currentMonth,
          });
        if (res.responseCode === "00") {
          setStatisticProvinceByNorth(res.north);
          setStatisticProvinceBySouth(res.south);

          setStatisticProvinceByMiddle(res.middle);
        }
      } catch (error) {}
    })();
  }, [currentYear, currentMonth]);
  // annotationsNorth
  const annotationsNorth: any = [];
  each(groupBy(statisticProvinceByNorth, "provinceName"), (values: any, province: any) => {
    const total = values.reduce((a: any, b: any) => a + b.attendanceCount.totalStudents, 0);
    annotationsNorth.push({
      type: "text",
      content: `${total}`,
      position: [province, total],
      style: {
        textAlign: "center",
        fontSize: 14,
        fill: "rgba(0,0,0,0.85)",
      },
      offsetY: -10,
    });
  });

  // annotationsMiddle
  const annotationsMiddle: any = [];
  each(groupBy(statisticProvinceByMiddle, "provinceName"), (values: any, province: any) => {
    const total = values.reduce((a: any, b: any) => a + b.attendanceCount.totalStudents, 0);
    annotationsMiddle.push({
      type: "text",
      content: `${total}`,
      position: [province, total],
      style: {
        textAlign: "center",
        fontSize: 18,
        fill: "rgba(0,0,0,0.85)",
      },
      offsetY: -10,
    });
  });

  // annotationsSouth
  const annotationsSouth: any = [];
  each(groupBy(statisticProvinceBySouth, "provinceName"), (values: any, province: any) => {
    const total = values.reduce((a: any, b: any) => a + b.attendanceCount.totalStudents, 0);
    annotationsSouth.push({
      type: "text",
      content: `${total}`,
      position: [province, total],
      style: {
        textAlign: "center",
        fontSize: 14,
        fill: "rgba(0,0,0,0.85)",
      },
      offsetY: -10,
    });
  });

  const config: any = (dataStatistic: AttendProvinceByArea[], annotation: any) => ({
    data:
      dataStatistic
        .map((item) => [
          {
            province: item.provinceName,
            value: item.attendanceCount.fullyWatched,
            status: `${t("chart.hocdu")}`,
          },
          {
            province: item.provinceName,
            value: item.attendanceCount.notFullyWatched,
            status: `${t("chart.hocthieu")}`,
          },
          {
            province: item.provinceName,
            value: item.attendanceCount.noWatched,
            status: `${t("chart.bohoc")}`,
          },
        ])
        .reduce((previosValue, currentValue) => {
          return previosValue.concat(currentValue);
        }, []) || [],
    isStack: true,
    xField: "province",
    yField: "value",
    seriesField: "status",

    color: ({status}: any) => {
      if (status === `${t("chart.hocdu")}`) {
        return "#123664";
      } else if (status === `${t("chart.hocthieu")}`) {
        return "#EBA337";
      }
      return "#B80F0F";
    },
    maxColumnWidth:130,
    state: {
      active: {
        animate: {duration: 100, easing: "easeLinear"},
        style: {
          lineWidth: 2,
          stroke: "#000",
        },
      },
    },
    padding: 50,
    label: {
      position: "middle",
      style: {
        fill: "#fff",
      },
      formatter: (data: any) => {
        const itemFilter = dataStatistic.filter(
          (item: AttendProvinceByArea) => item.provinceName === data.province
        )[0];
        const total = itemFilter.attendanceCount.totalStudents;
        if (data.value === 0) {
          return;
        }

        return Number.isInteger((data.value / total) * 100)
          ? `${(data.value / total) * 100}%`
          : `${((data.value / total) * 100).toFixed(1)}%`;
      },

      layout: [
        {
          type: "interval-adjust-position",
        },
      ],
    },
    interactions: [
      {
        type: "element-highlight-by-color",
      },
      {
        type: "element-link",
      },
    ],
    annotations: annotation,
    legend: {
      position: "bottom",
      offsetY: 5,
    },
  });
  return (
    <Box>
      <Box mr={2} mb={2} textAlign="right">
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            value={currentMonth}
            onChange={handleChangeMonth}
            defaultValue={currentMonth}
          >
            {Array.from(new Array(12)).map((id, index) => (
              <Fragment key={index.toString()}>
                {index === 0 && <option value="">{t("tatca")}</option>}
                <option value={index + 1}>
                  {t("chart.thang")} {index + 1}
                </option>
              </Fragment>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select native value={currentYear} onChange={handleChangeYear} defaultValue={currentYear}>
            {Array.from(new Array(10)).map((id, index) => (
              <option value={index + 2020} key={index.toString()}>
                {index + 2020}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Chart North */}

      <Box>
        <Box className={classes.title__area}>
          <Typography variant="subtitle2" style={{color: "#EB4B4B"}}>
            {t("chart.mienbac")}
          </Typography>
          <hr
            style={{
              margin: "0 20px",
              flexGrow: 1,
            }}
          />
        </Box>
        {statisticProvinceByNorth.length > 0 ? (
          <Card style={{padding: 20}}>
            <Column {...config(statisticProvinceByNorth, annotationsNorth)} />
          </Card>
        ) : (
          <Typography variant="subtitle2">{t("chart.khongcodulieu")}</Typography>
        )}
      </Box>

      {/* Chart Middle */}
      <Box my={7}>
        <Box className={classes.title__area}>
          <Typography variant="subtitle2" style={{color: "#F6AD3F"}}>
            {t("chart.mientrung")}
          </Typography>
          <hr
            style={{
              margin: "0 20px",
              flexGrow: 1,
            }}
          />
        </Box>
        {statisticProvinceByMiddle.length > 0 ? (
          <Card style={{padding: 20}}>
            <Column {...config(statisticProvinceByMiddle, annotationsMiddle)} />
          </Card>
        ) : (
          <Typography variant="subtitle2">{t("chart.khongcodulieu")}</Typography>
        )}
      </Box>

      {/* Chart South */}
      <Box>
        <Box className={classes.title__area}>
          <Typography variant="subtitle2" style={{color: "#42508F"}}>
            {t("chart.miennam")}
          </Typography>
          <hr
            style={{
              margin: "0 20px",
              flexGrow: 1,
            }}
          />
        </Box>
        {statisticProvinceBySouth.length > 0 ? (
          <Card style={{padding: 20}}>
            <Column {...config(statisticProvinceBySouth, annotationsSouth)} />
          </Card>
        ) : (
          <Typography variant="subtitle2">{t("chart.khongcodulieu")}</Typography>
        )}
      </Box>
    </Box>
  );
}
