import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import managerTestApi from "api/managerTest";
import { useAppSelector } from "app/hooks";
import clsx from "clsx";
import { selectInfoLogin } from "features/Auth/authSlice";
import { ExamQuestions, ListExam, ResponseSubmissionExam, StudentAnswers } from "models/managerTest";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { toast } from "react-toastify";
import { dateTimeToFormatYYYY } from "utils/dateTimeFormat";

export default function BeginExam() {
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      padding: "0 24px",
      [theme.breakpoints.down("xs")]: {
        padding: "0 8px",
      },
    },
    header: {
      backgroundColor: "#137BBD",
      width: "100%",
      marginTop: 20,
    },
    header__title: {
      padding: "15px 40px",
      borderBottom: "1px solid #FFFFFF",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "15px 30px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "10px 20px",
      },
      "& > h6": {
        fontSize: 20,
        fontWeight: 600,
        color: "#FFFFFF",
        [theme.breakpoints.down("md")]: {
          fontSize: 16,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 14,
        },
      },
    },
    header__timer: {
      color: "#DB2139",
      backgroundColor: "#FFFFFF",
      padding: "5px 30px",
      fontSize: 25,
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
        padding: "5px 20px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
        padding: "5px 10px",
      },
    },
    exam: {
      backgroundColor: "#FFFFFF",
      boxShadow: " 0px 1px 4px rgba(168, 168, 168, 0.4)",
      borderRadius: 6,
      padding: "10px 15px",
      marginBottom: 15,
    },
    question_number: {
      color: "#0065A6",
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
    question_content: {
      fontSize: 15,
      padding: "5px 0",
      paddingLeft: 8,
      [theme.breakpoints.down("md")]: {
        fontSize: 13,
      },
    },
    form__answers: {
      display: "flex",
      alignItems: "center",
      "& .MuiFormControlLabel-root": {
        marginRight: 0,
      },
      "& .PrivateRadioButtonIcon-root-88": {
        "& .MuiSvgIcon-root": {
          fontSize: 23,
        },
      },
    },
    icon_radio: {
      borderRadius: "50%",
      width: 14,
      height: 14,
      boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "#f5f8fa",
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: "#ebf1f5",
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#137cbd",
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 14,
        height: 14,
        backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#106ba3",
      },
    },
    answer_content: {
      fontSize: 13,
      [theme.breakpoints.down("md")]: {
        fontSize: 11,
      },
    },
    btn_submit: {
      background: "linear-gradient(0deg, #137BBD 0%, rgba(105, 197, 255, 0.8) 145.33%)",
      borderRadius: 10,
      padding: "10px 20px",
      color: "#FFFFFF",
      [theme.breakpoints.down("xs")]: {
        padding: "5px 15px",
        borderRadius: 8,
        fontSize: 11,
      },
    },
    icon_scroll: {
      position: "fixed",
      right: 30,
      bottom: 25,
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        right: 20,
        bottom: 15,
      },
      "& > svg": {
        fontSize: 30,
        color: "#0065A6",
        cursor: "pointer",
        marginTop: 10,
        [theme.breakpoints.down("xs")]: {
          fontSize: 20,
        },
      },
    },
    root_modal: {
      width: "100%",
      height: "30%",
      display: "flex",
      justifyContent: "center",
    },
    modal__container: {
      maxWidth: 560,
      borderRadius: 12,
      backgroundColor: "#fff",
      zIndex: 9,
      display: "flex",
      flexDirection: "column",
      margin: "30px 0",
      height: "100%",
    },
    modal__content: {
      padding: 15,
      "& >h6": {
        color: "red",
        fontSize: 16,
      },
    },
  }));
  const classes = useStyles();
  const { t } = useTranslation();
  const location: any = useLocation();
  const navigate = useNavigate();
  const infoUser = useAppSelector(selectInfoLogin);
  const [detailTest, setDetailTest] = useState<ListExam>();
  const [studentAnswers, setStudentAnswers] = useState<StudentAnswers[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const startTime =
    moment(location?.state?.startTime).format("YYYY/MM/DD").split("/").join("") +
    moment(location?.state?.startTime).format("HH:mm:ss").split(":").join("");

  useEffect(() => {
    (async () => {
      const res: ListExam = await managerTestApi.getDetailExam(Number(location?.state?.id));
      setDetailTest(res);
      const newStudentAnswers = res.exam.examQuestions.map((question: ExamQuestions) => ({
        questionId: question.questionId,
      }));
      setStudentAnswers(newStudentAnswers);
    })();
  }, []);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    if (hours === 0 && minutes === 0 && seconds === 1) {
      setOpenModal(true);
    }
  }, [hours, minutes, seconds]);
  useEffect(() => {
    const interval = setInterval(() => {
      const timer = detailTest?.exam?.examTime;
      const currentDay = new Date().setMinutes(new Date().getMinutes() - timer);
      const examTimer = new Date(detailTest?.exam?.startDate).getTime();
      const difference = moment.duration(moment(examTimer).diff(moment(currentDay)));
      if (difference.milliseconds() < 0) {
        return;
      }
      if (difference.hours() <= Math.floor(timer / 60) && difference.hours() >= 0) {
        setHours(difference.hours());
      }
      if (difference.minutes() < 10 && difference.minutes() >= 0) {
        setMinutes(difference.minutes());
      } else {
        setMinutes(difference.minutes());
      }
      if (difference.seconds() < 10 && difference.seconds() >= 0) {
        setSeconds(difference.seconds());
      } else {
        setSeconds(difference.seconds());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [detailTest]);

  const handleChangeRadio = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionId: number | undefined,
    index: number
  ) => {
    const newAnswer = [...studentAnswers];
    newAnswer[index].choice = event.target.value;
    newAnswer[index].questionId = questionId;
    setStudentAnswers(newAnswer);
  };
  const handleChangeOptionValue = (value: string, i: number) => {
    const newAnswer = [...studentAnswers];
    newAnswer[i].typing = value;
    setStudentAnswers(newAnswer);
  };
  const handleScrollToTop = () => {
    scroller.scrollTo("header", {
      duration: 800,
      smooth: "easeInOutQuart",
      offset: -80,
    });
  };
  const handleScrollToBottom = () => {
    scroller.scrollTo("footer", {
      duration: 800,
      smooth: "easeInOutQuart",
      offset: -80,
    });
  };

  const handleSubmitExam = async () => {
    try {
      const res: ResponseSubmissionExam = await managerTestApi.submitTest({
        examId: detailTest?.exam?.id,
        startTime,
        endTime:
          moment(new Date()).format("YYYY/MM/DD").split("/").join("") +
          moment(new Date()).format("HH:mm:ss").split(":").join(""),
        studentAnswers: studentAnswers,
      });
      if (res.responseCode === "00") {
        toast.success(`${t("test.nopbaithanhcong")}`);
        navigate(`/exam/result-exam/${detailTest?.exam?.id}`, {
          state: {
            res,
            type: detailTest?.exam?.type,
          },
        });
      } else if (res.responseDescription === "You have finished this exam.") {
        toast.error(`${t("test.bandanopbai")}`);
        throw new Error(res.responseDescription);
      } else {
        toast.error(res.responseDescription);
      }
    } catch (error) { }
  };
  if (location?.state === null) {
    return <Navigate to="/home" />;
  }
  return (
    <Box className={classes.container}>
      <Box className={classes.header} id="header">
        <Box className={classes.header__title}>
          <Typography variant="subtitle2">{infoUser.name}</Typography>
          <Typography variant="subtitle2">
            {dateTimeToFormatYYYY(detailTest?.exam?.startDate)}
          </Typography>
        </Box>
        <Box className={classes.header__title}>
          <Typography variant="subtitle2">{detailTest?.exam?.examTitle}</Typography>
          <Box className={classes.header__timer}>
            {hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:
            {seconds < 10 ? "0" + seconds : seconds}
          </Box>
        </Box>
      </Box>

      {detailTest?.exam?.examQuestions?.map((question: ExamQuestions, index: number) => (
        <Box className={classes.exam} key={question.id}>
          <Box key={question.id}>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="subtitle2" className={classes.question_number}>
                {t("test.cau")} {index + 1} :
              </Typography>
              <Typography variant="h6" className={classes.question_content}>
                {question.question.content}
              </Typography>
            </Box>
            {question.question.imageFileList.length > 0 && (
              <Box
                display="grid"
                gridTemplateColumns={
                  question?.question?.imageFileList?.length === 1 ? "1fr" : "1fr 1fr"
                }
                gridGap={10}
                my="10px"
              >
                {question.question.imageFileList.map((x: any) => {
                  return (
                    <img
                      key={x.id}
                      style={{
                        width: "100%",
                        height: "fit-content",

                        objectFit: "contain",
                        maxHeight: "450px",
                      }}
                      src={x.imageFile.url}
                      alt={x}
                    />
                  );
                })}
              </Box>
            )}

            {detailTest?.exam?.type === "TYPING" ? (
              <TextField
                autoComplete="off"
                name="typing"
                label={t("test.nhapdapan")}
                rows={2}
                variant="outlined"
                fullWidth
                multiline
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeOptionValue(event.target.value, index)
                }
              />
            ) : (
              <RadioGroup name="choice">
                {question?.question?.answers?.map((answer, index2) => (
                  <Box key={answer.id} className={classes.form__answers}>
                    <FormControlLabel
                      value={answer.id}
                      control={
                        <Radio
                          color="primary"
                          icon={<span className={classes.icon_radio} />}
                          checkedIcon={
                            <span className={clsx(classes.icon_radio, classes.checkedIcon)} />
                          }
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeRadio(event, answer.questionId, index)
                          }
                          checked={studentAnswers[index]?.choice === String(answer.id)}
                        />
                      }
                      label=""
                    />

                    <Typography variant="subtitle2" className={classes.answer_content}>
                      {answer.content}
                    </Typography>
                  </Box>
                ))}
              </RadioGroup>
            )}
          </Box>
        </Box>
      ))}
      <Box textAlign="center" id="footer">
        <Button className={classes.btn_submit} onClick={handleSubmitExam}>
          {t("test.nopbai")}
        </Button>
      </Box>
      <Box className={classes.icon_scroll}>
        <Tooltip title={t("test.arrow.dichuyenlendautrang") || ""}>
          <ExpandLessIcon onClick={handleScrollToTop} />
        </Tooltip>
        <Tooltip title={t("test.arrow.dichuyenxuongcuoitrang") || ""}>
          <ExpandMoreIcon onClick={handleScrollToBottom} />
        </Tooltip>
      </Box>
      <Modal
        open={openModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container}>
            <Box textAlign="right">
              <IconButton onClick={handleSubmitExam}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.modal__content}>
              <Typography variant="subtitle2">{t("test.dahetgiolambai")}</Typography>
              <Box textAlign="center" mt={2}>
                <Button className={classes.btn_submit} onClick={handleSubmitExam}>
                  {t("test.nopbai")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
}
