import {Box, Button, CircularProgress, makeStyles, Theme, Typography} from "@material-ui/core";
import {useAppDispatch, useAppSelector} from "app/hooks";
import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import {
  fetchCategoryList,
  selectCategoryList,
} from "features/Admin/pages/ManagerCategory/managerCategorySlice";
import React, {useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ICON_FILE from "assets/img/icon_file.png";
import moment from "moment";
import BG_FILE_PDF from "assets/img/bg_file_pdf.png";
import {convertDDMMYYYYtoSeconds, convertHHMMtoSeconds} from "utils/dateTimeFormat";
import managerMagazineApi from "api/managerMagazine";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {MagazineList, ResponseMagazine} from "models/managerMagazine";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import CheckboxField from "components/FormFields/CheckboxField";

export interface AddMagazineProps {}

export default function AddMagazine(props: AddMagazineProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      marginBottom: 5,
      width: "100%",
    },
    header: {
      color: "#004673",
      fontSize: 25,
      fontWeight: 700,
      marginBottom: 10,
    },
    title: {
      fontSize: 15,
      fontWeight: 600,
      width: "14%",
      [theme.breakpoints.up("xl")]: {
        width: "10%",
      },
    },
    form__video__wrapper: {
      backgroundColor: "#fff",
      width: "100%",
      height: 400,
      boxShadow: "0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 8,
      "&:hover": {
        backgroundColor: "#ECECEC",
        transition: "linear 0.3s all",
      },
      "& >input ": {
        display: "none",
      },
    },
    form__video__content: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
      "& > span": {
        color: "#AAAAAA",
        fontSize: 13,
      },
    },
    btn_create: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 10,
      padding: "10px 15px",
    },
    loading: {
      zIndex: 99,
      backgroundColor: "rgba(255,255,255,0.8)",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      "& >div": {
        position: "absolute",
        top: "60%",
        left: "50%",
        transform: "translate(-60%,-50%)",
        "& .MuiCircularProgress-root": {
          display: "flex",
          margin: "0 auto",
        },
      },
    },
  }));
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const listCategory = useAppSelector(selectCategoryList);
  const [isPostNow, setIsPostNow] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [errorFile, setErrorFile] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [disableDate, setDisableDate] = useState(false);
  const [disableTime, setDisableTime] = useState(false);
  const schema = yup.object().shape({
    category_id_list: yup.string().required(`${t("managerMagazine.vuilongchonchude")}`),
    title: yup.string().required(`${t("managerMagazine.vuilongnhaptieudebaidang")}`),
    publishDate: yup.string().when("postNow", {
      is: false,
      then: yup.string().required(`${t("managerMagazine.vuilongchonngaydang")}`),
    }),
    publishTime: yup.string().when("postNow", {
      is: false,
      then: yup.string().required(`${t("managerMagazine.vuilongchongiodang")}`),
    }),
  });
  const initialValues = {
    postNow: false,
    category_id_list: "",
    title: "",
    publishDate: "",
    publishTime: "",
  };
  const {control, handleSubmit, setValue} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(fetchCategoryList());
  }, []);

  useEffect(() => {
    if (fileName !== "") {
      setErrorFile("");
    }
  }, [fileName]);

  useEffect(() => {
    if (isPostNow) {
      setValue("publishDate", "");
      setValue("publishTime", "");
      setDisableTime(true);
      setDisableDate(true);
    } else {
      setDisableTime(false);
      setDisableDate(false);
    }
  }, [isPostNow]);

  const handleChangeFile = (acceptedFiles: any) => {
    if (acceptedFiles[0].type !== "application/pdf") {
      setErrorFile(`${t("managerMagazine.tepfilekhongdungdinhdang")}`);
      setFileName("");
      return;
    }
    setFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].path);
  };

  const handleSubmitForm = async (value: any) => {
    setLoading(true);
    if (file === "") {
      setErrorFile(`${t("managerMagazine.vuilongchonfile")}`);
      return;
    }
    const newValue = {
      ...value,
      published_timestamp_utc: value.postNow
        ? new Date().getTime()
        : convertDDMMYYYYtoSeconds(value.publishDate, value.publishTime),
    };

    const formData = new FormData();
    formData.append("published_timestamp_utc", newValue.published_timestamp_utc);
    formData.append("title", newValue.title);
    formData.append("attachments", file);
    formData.append("category_id_list", newValue.category_id_list);

    try {
      const res: ResponseMagazine<MagazineList> = await managerMagazineApi.createMagazine(formData);
      if (res.responseCode === "00") {
        setLoading(false);
        toast.success(`${t("managerMagazine.dangbaithanhcong")}`);
        navigate("/admin/magazine-list");
      } else {
        toast.error(`${t("managerMagazine.dangbaithatbai")}`);
        setLoading(false);
        throw new Error(`${t("managerMagazine.dangbaithatbai")}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box position="relative">
      {loading && (
        <Box className={classes.loading}>
          <Box>
            {/* <img src={LOADING} alt="LOADING" /> */}
            <CircularProgress style={{width: 50, height: 50}} />
          </Box>
        </Box>
      )}
      <Typography variant="subtitle2" className={classes.header}>
        {t("button.addnew").toUpperCase()}
      </Typography>

      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Box className={classes.root}>
          <Box className={classes.root} width="100%">
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerMagazine.lichdang")}
            </Typography>
            <Box className={classes.root}>
              <Box width="40%">
                <InputField
                  control={control}
                  type="date"
                  label=""
                  name="publishDate"
                  disabled={disableDate}
                />
              </Box>
              <Box ml={2} mr={4} width="30%">
                <InputField
                  control={control}
                  type="time"
                  label=""
                  name="publishTime"
                  disabled={disableTime}
                />
              </Box>

              <CheckboxField
                name="postNow"
                label={t("managerLesson.dangngay")}
                control={control}
                handleChange={setIsPostNow}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerMagazine.chude")}
            </Typography>
            <Box className={classes.root}>
              <SelectField
                name="category_id_list"
                label={t("managerMagazine.chonchudebaidang")}
                options={listCategory}
                control={control}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerMagazine.tieude")}
            </Typography>
            <Box className={classes.root}>
              <InputField
                name="title"
                label={t("managerMagazine.nhaptieudebaidang") + "..."}
                control={control}
                rows={2}
                multiline
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerMagazine.themfile")}
            </Typography>
            <Dropzone onDrop={handleChangeFile}>
              {({getRootProps, getInputProps}) => (
                <Box className={classes.root} position="relative">
                  <Box
                    {...getRootProps({
                      className: classes.form__video__wrapper,
                    })}
                  >
                    <input {...getInputProps()} accept=".pdf" />

                    <Box className={classes.form__video__content}>
                      {fileName.length > 0 ? (
                        <>
                          <img src={BG_FILE_PDF} alt="BG_FILE_PDF" height="90px" />
                          <Typography variant="subtitle1" component="span" style={{marginTop: 10}}>
                            {fileName}
                          </Typography>
                        </>
                      ) : (
                        <>
                          {" "}
                          <img src={ICON_FILE} alt="ICON_FILE" height="90px" />
                          <Typography variant="subtitle1">
                            {t("managerMagazine.themfile")}
                          </Typography>
                          <Typography variant="subtitle1" component="span">
                            {t("managerLesson.hoackeotha")}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Dropzone>
          </Box>
        </Box>
        {errorFile !== "" && (
          <Typography variant="subtitle2" color="secondary" style={{marginLeft: 100}}>
            {errorFile}
          </Typography>
        )}

        <Box textAlign="right" mt={2}>
          <Button type="submit" className={classes.btn_create}>
            {t("managerMagazine.dangbai")}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
