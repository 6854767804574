import managerChartApi from "api/managerChart";
import {Sunburst} from "@ant-design/plots";
import {ResponseExam3AreaByMonth, ItemList} from "models/managerChart";
import useStyles from "./style";
import React, {Fragment, useEffect, useState} from "react";
import {
  Box,
  Card,
  CardHeader,
  FormControl,
  Grid,
  Select,
  Typography,
  TypographyStyle,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
export interface ChartExamResultAreaByYearProps {}
export default function ChartExamResult3AreaByMonth(props: ChartExamResultAreaByYearProps) {
  const classes = useStyles();
  const {t} = useTranslation();
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState<number>(1);
  const [data, setData] = useState<any>();
  const handleChangeMonth = (e: any) => {
    setCurrentMonth(e.target.value);
  };
  const handleChangeYear = (e: any) => {
    setCurrentYear(e.target.value);
  };
  const config: any = (index: number) => ({
    data:
      data?.map((item: any, index: number) => ({
        key: index,
        name: `${t("chart.bieudoketquatheothang")}`,
        value: item.total,
        children: [
          {
            name: `${t("chart.mientrung")}`,
            value: item.statistic.middle,
          },
          {
            name: `${t("chart.miennam")}`,
            value: item.statistic.south,
          },
          {
            name: `${t("chart.mienbac")}`,
            value: item.statistic.north,
          },
        ],
      }))[index] || [],
      color: ["#123664", "#B80F0F", "#EBA337"],
    sunburstStyle: {
      fillOpacity:1,
      },
    interactions: [
      {
        type: "element-active",
      },
    ],
    label: {
      offset: "0%",
      content: (originData: any) => {
        const perMiddle = Number(
          (
            (originData.parent?.children?.filter((x: any) => {
              return x.name === `${t("chart.mientrung")}` && x.value > 0;
            })[0]?.value /
              originData.parent?.value) *
            100
          ).toFixed(1)
        );
        const perSouth = Number(
          (
            (originData.parent?.children?.filter((x: any) => {
              return x.name === `${t("chart.miennam")}` && x.value > 0;
            })[0]?.value /
              originData.parent?.value) *
            100
          ).toFixed(1)
        );
        const perNorth = Number(
          (
            (originData.parent?.children?.filter((x: any) => {
              return x.name === `${t("chart.mienbac")}` && x.value > 0;
            })[0]?.value /
              originData.parent?.value) *
            100
          ).toFixed(1)
        );
        if (originData.name === `${t("chart.mientrung")}` && perMiddle > 0) {
          return `${perMiddle}%`;
        }
        if (originData.name === `${t("chart.miennam")}` && perSouth > 0) {
          return `${perSouth}%`;
        }
        if (originData.name === `${t("chart.mienbac")}` && perNorth > 0) {
          return `${perNorth}%`;
        }
      },
    },
  });
  useEffect(() => {
    (async () => {
      try {
        const res: ResponseExam3AreaByMonth<ItemList> =
          await managerChartApi.getStatisticExamResult3AreaByMonth({
            year: currentYear,
            month: currentMonth,
          });
        if (res.responseCode === "00") {
          setData(res.itemList);
        }
      } catch (error) {}
    })();
  }, [currentYear, currentMonth]);
  return (
    <Box>
      <Box mr={2} mb={2} textAlign="right">
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            value={currentMonth}
            defaultValue={currentMonth}
            onChange={(e) => handleChangeMonth(e)}
          >
            {Array.from(new Array(12)).map((id, index) => (
              <Fragment key={index.toString()}>
                <option value={index + 1}>
                  {t("chart.thang")} {index + 1}
                </option>
              </Fragment>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            value={currentYear}
            onChange={(e) => handleChangeYear(e)}
            defaultValue={currentYear}
          >
            {Array.from(new Array(10)).map((id, index) => (
              <option value={index + 2020} key={index.toString()}>
                {index + 2020}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        {data && data[0].total === 0 && data[1].total === 0 && data[2].total === 0 ? (
          <Box>{t("chart.khongcodulieu")}</Box>
        ) : (
          <Grid container spacing={3} style={{marginTop: "30px"}}>
            {data?.map((item: any, index: number) => (
              <Grid item xs={4} style={{justifyContent: "center"}}>
                <Card>
                  <CardHeader
                    style={{borderBottom: "1px solid black"}}
                    title={
                      <Box textAlign="center">
                        {item.type === "passed" ? "Đậu" : item.type === "failed" ? "Rớt" : "Bỏ thi"}
                      </Box>
                    }
                  />
                  <Sunburst {...config(index)} />
                  <Box className={classes.center_layout}>
                    <Grid style={{width: "130px"}}>
                      <Grid
                        item
                        xs={12}
                        style={{display: "flex", alignItems: "center", margin: "10px 0"}}
                      >
                        <Box className={classes.box_note_north}></Box>
                        <Typography variant="subtitle2" className={classes.note_north}>
                          {t("chart.mienbac")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{display: "flex", alignItems: "center", margin: "10px 0"}}
                      >
                        <Box className={classes.box_note_middle}></Box>
                        <Typography variant="subtitle2" className={classes.note_middle}>
                          {t("chart.mientrung")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{display: "flex", alignItems: "center", margin: "10px 0 50px 0"}}
                      >
                        <Box className={classes.box_note_south}></Box>
                        <Typography variant="subtitle2" className={classes.note_south}>
                          {t("chart.miennam")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
}
