import {makeStyles, Theme} from "@material-ui/core";

type Props = {
  background: string;
};
const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  background: {
    backgroundImage: (props: Props) => `url(${props.background})`,
    backgroundSize: "cover",
    backgroundPositionX: "right",
    backgroundRepeat: "no-repeat",
    height: 150,
    width: "100%",
    position: "relative",
    "& >h6": {
      position: "absolute",
      bottom: 30,
      left: 0,
      color: "#004673",
      fontSize: 20,
      fontWeight: 700,
    },
  },
  title: {
    color: "#004673",
    fontSize: 25,
    fontWeight: 700,
    padding: "10px 0",
  },
  btn_delete_fitler: {
    background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
    color: "#fff",
    borderRadius: 10,
    padding: "5px 12px",
    alignSelf: "center",
    fontSize: 11,
  },

  btn_detail: {
    background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
    borderRadius: 10,
    fontSize: 11,
    color: "#fff",
    padding: "5px 12px",
  },
  btn_edit: {
    background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
    borderRadius: 10,
    fontSize: 11,
    color: "#fff",
    padding: "5px 12px",
    margin: "0 7px",
  },
  btn_delete: {
    background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    borderRadius: 10,
    fontSize: 11,
    color: "#fff",
    padding: "5px 12px",
    marginRight: 7,
  },

  avatar: {
    width: 25,
    height: 25,
    objectFit: "cover",
    borderRadius: "50%",
    [theme.breakpoints.up("xl")]: {
      width: 30,
      height: 30,
    },
  },

  name: {
    width: "100%",
    textAlign: "left",
    paddingLeft: 5,
    fontSize: 11,
    [theme.breakpoints.up("xl")]: {
      fontSize: 13,
    },
  },
  selectFilter: {
    borderRadius: 12,
    backgroundColor: "#fff",
  },
  inputLabel: {
    width: "90%",
    textAlign: "center",
  },
  container: {
    maxHeight: 500,
  },
  btn_download: {
    background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    borderRadius: 10,
    fontSize: 13,
    color: "#fff",
    padding: "10px 8px",
    marginRight: 10,
  },
  btn_upload: {
    background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
    borderRadius: 10,
    fontSize: 13,
    color: "#fff",
    padding: "10px 8px",
    lineHeight: 1.7,
    cursor: "pointer",
    textTransform: "uppercase",
    minWidth: 90,
    textAlign: "center",
  },
  wrapper_button: {
    display: "flex",
    position: "absolute",
    right: 0,
    bottom: 0,
    marginBottom: 10,
    alignItems: "center",
  },
}));

export default useStyles;
