import {
  Box,
  Button,
  Checkbox,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_list_test_again.png";
import {
  fetchListExamStudent,
  SelectFilterListExamStudent,
  SelectListExamStudent,
} from "features/Home/pages/ListExamStudent/listExamStudentSlice";
import moment from "moment";
import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {dateTimeToFormatDDMMYYYY} from "utils/dateTimeFormat";
import {setFilterListExam} from "../../managerExamListSlice";
import CreateExam from "./components/CreateExam";

export default function CreateExamAgain() {
  const useStyles = makeStyles(() => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        top: 50,
        left: 0,
        color: "#004673",
        fontSize: 25,
        fontWeight: 700,
      },
    },
    btn_next: {
      borderRadius: 20,
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      padding: "10px 15px",
    },
    container: {
      maxHeight: 500,
    },
  }));
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);

  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const listExam = useAppSelector(SelectListExamStudent);
  const [showCreateExam, setShowCreateExam] = useState(false);
  const [arrIdQuestion, setArrIdQuestion] = useState<any[]>([]);
  const filter = useAppSelector(SelectFilterListExamStudent);
  useEffect(() => {
    dispatch(
      fetchListExamStudent({...filter, toDate: moment(new Date()).format("yyyy-MM-DD HH:mm:ss")})
    );
  }, [dispatch, filter]);

  const handleChangePage = (page: number) => {
    dispatch(
      setFilterListExam({
        ...filter,
        page: page - 1,
      })
    );
  };
  return (
    <Box>
      {showCreateExam ? (
        <Fragment>
          <Box className={classes.background}>
            <Typography variant="subtitle2">
              {t("managerTest.nhungcauhoidethicu").toUpperCase()}
            </Typography>
          </Box>
          <CreateExam
            setShowCreateExam={setShowCreateExam}
            arrIdQuestion={arrIdQuestion.map((item) => item.id)}
            type={arrIdQuestion.every((item) => item.type === "CHOICES") ? "CHOICES" : "TYPING"}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Box className={classes.background}>
            <Typography variant="subtitle2">
              {t("managerTest.danhsachdethicu").toUpperCase()}
            </Typography>
          </Box>

          <Box>
            <TableContainer component={Paper} className={classes.container}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" width="70px">
                      {t("stt")}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="150px">
                      {t("managerTest.ngaytaode")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t("managerTest.tieudebaithi")}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="150px">
                      {t("managerTest.hinhthuc")}
                    </StyledTableCell>
                    <StyledTableCell align="center" width="150px">
                      {t("managerTest.bophan")}
                    </StyledTableCell>

                    <StyledTableCell align="center" width="100px">
                      {t("managerTest.luachon")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listExam?.basicExamResponses?.content.map((exam, index) => (
                    <TableRow key={exam.id}>
                      <StyledTableCell align="center" component="th" scope="exam">
                        {listExam.basicExamResponses?.size * listExam.basicExamResponses?.number +
                          index +
                          1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {dateTimeToFormatDDMMYYYY(exam.createdDate)}
                      </StyledTableCell>
                      <StyledTableCell align="center">{exam?.title}</StyledTableCell>

                      <StyledTableCell align="center">
                        {exam?.type === "TYPING"
                          ? `${t("managerTest.tuluan")}`
                          : `${t("managerTest.tracnghiem")}`}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {exam?.examWorkTypes.length > 1
                          ? `${t("tatca")}`
                          : exam?.examWorkTypes.map((x) => x.workType.name)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box ml={2}>
                          <Checkbox
                            color="primary"
                            onClick={() => {
                              setArrIdQuestion((prevState: any) => {
                                const foundIdx = arrIdQuestion?.findIndex((x) => x.id === exam?.id);
                                if (foundIdx > -1) {
                                  return prevState.splice(0, foundIdx);
                                }
                                if (
                                  arrIdQuestion.length > 0 &&
                                  arrIdQuestion[0].type !== exam.type
                                ) {
                                  toast.error(`${t("managerTest.vuilongchonhinhthuccauhoi")}`);
                                  return [...prevState];
                                }
                                return [...prevState, {type: exam.type, id: exam.id}];
                              });
                            }}
                            inputProps={{"aria-label": "primary checkbox"}}
                            checked={arrIdQuestion?.some((item) => item.id === exam?.id)}
                          />
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              mt={3}
              display="flex"
              justifyContent={
                listExam?.basicExamResponses?.totalPages > 1 ? "space-between" : "flex-end"
              }
            >
              {listExam?.basicExamResponses?.totalPages > 1 && (
                <Pagination
                  count={Math.ceil(
                    listExam?.basicExamResponses?.totalElements / listExam?.basicExamResponses?.size
                  )}
                  page={listExam?.basicExamResponses?.number + 1}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={(e, page) => handleChangePage(page)}
                />
              )}
              <Button className={classes.btn_next} onClick={() => setShowCreateExam(true)}>
                {t("managerTest.tieptheo")}
              </Button>
            </Box>
          </Box>
        </Fragment>
      )}
    </Box>
  );
}
