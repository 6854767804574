import managerChartApi from "api/managerChart";
import {ResponseExamAreaByYear, MonthList} from "models/managerChart";
import {useAppDispatch, useAppSelector} from "app/hooks";
import useStyles from "./style";
import {useState, useEffect} from "react";
import {Box, Card, FormControl, Select, Typography, TypographyStyle, Grid} from "@material-ui/core";
import {Line} from "@ant-design/plots";
import {fetchProvincesForStatistic, selectProvincesForStatistic} from "../../managerChartSlice";
import {ProvinceForStatistic} from "models/managerChart";
import {useTranslation} from "react-i18next";
export interface ChartExamResultAreaByYearProps {}
export default function ChartExamResultAreaByYear(props: ChartExamResultAreaByYearProps) {
  const classes = useStyles();
  const {t} = useTranslation();
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [provinceId, setProvinceId] = useState<number>();
  const [currentProvinceName, setCurrentProvinceName] = useState();
  const [tempData, setTempData] = useState<any>();
  const dispatch = useAppDispatch();
  const listProvincesForStatistic = useAppSelector(selectProvincesForStatistic);
  const handleChangeProvince = (e: any) => {
    setProvinceId(e.target.value);
  };
  const handleChangeYear = (e: any) => {
    setCurrentYear(e.target.value);
  };
  const data: any = [];
  tempData?.forEach((item: any, index: number) => {
    const objAbsent = {
      type: `${t("chart.bothi")}`,
      month: `${t("chart.thang")} ${item.month}`,
      value: item.absent,
    };
    const objPassed = {
      type: `${t("chart.dau")}`,
      month: `${t("chart.thang")} ${item.month}`,
      value: item.passed,
    };
    const objFailed = {
      type: `${t("chart.rot")}`,
      month: `${t("chart.thang")} ${item.month}`,
      value: item.failed,
    };
    data.push(objAbsent);
    data.push(objFailed);
    data.push(objPassed);
  });
  const config = {
    data,
    xField: "month",
    yField: "value",
    seriesField: "type",
    color: ["#123664", "#B80F0F", "#EBA337"],
 
    label: {
      layout: [
        {
          type: "hide-overlap",
        },
      ],
      // 隐藏重叠label
      style: {
        textAlign: "right",
      },
    },
    
    point: {
      size: 5,
      style: {
        lineWidth: 1,
        fillOpacity: 1,
      },
      shape: "diamond",
    },
    areaStyle: {
      fillOpacity: 0.7,
    },
    appendPadding: 10,
    yAxis: {
      label: {
        formatter: (value: any) => {
          return value;
        },
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const res: ResponseExamAreaByYear<MonthList> =
          await managerChartApi.getStatisticExamResultProvinceByYear({
            year: currentYear,
            province_id: provinceId,
          });
        if (res.responseCode === "00") {
          setTempData(res.monthList);
          setCurrentProvinceName(res.province.name);
        }
      } catch (error) {}
    })();
  }, [currentYear, provinceId]);
  useEffect(() => {
    dispatch(fetchProvincesForStatistic());
  }, []);
  useEffect(() => {
    if (listProvincesForStatistic.length > 0) {
      setProvinceId(listProvincesForStatistic[0].id);
    }
  }, [listProvincesForStatistic]);
  return (
    <Box>
      <Box mr={2} mb={2} textAlign="right">
        <FormControl
          variant="outlined"
          className={classes.formControl}
          // style={{minWidth: currentProvinceName.length > 20 ? 230 : 150}}
        >
          <Select
            native
            value={provinceId}
            defaultValue={provinceId}
            onChange={(e) => handleChangeProvince(e)}
          >
            {listProvincesForStatistic?.map((provinces: ProvinceForStatistic) => (
              <option value={provinces.id}>{provinces.name}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            value={currentYear}
            onChange={(e) => handleChangeYear(e)}
            defaultValue={currentYear}
          >
            {Array.from(new Array(10)).map((id, index) => (
              <option value={index + 2020} key={index.toString()}>
                {index + 2020}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Box className={classes.title__area}>
          <Typography variant="subtitle2" style={{color: "#DC2138"}}>
            {currentProvinceName}
          </Typography>
          <hr
            style={{
              margin: "0 20px",
              flexGrow: 1,
            }}
          />
        </Box>
        <Box>
          <Grid>
            <Card>
              <Line {...config} />
              <Box className={classes.center_layout}>
                <Grid style={{display: "flex", width: "300px"}}>
                  <Grid item xs={4} style={{display: "flex", alignItems: "center"}}>
                    <Box className={classes.note_passed}></Box>
                    <Typography variant="subtitle2">{t("chart.dau")} </Typography>
                  </Grid>
                  <Grid item xs={4} style={{display: "flex", alignItems: "center"}}>
                    <Box className={classes.note_failed}></Box>
                    <Typography variant="subtitle2">{t("chart.rot")}</Typography>
                  </Grid>
                  <Grid item xs={4} style={{display: "flex", alignItems: "center"}}>
                    <Box className={classes.note_absent}></Box>
                    <Typography variant="subtitle2">{t("chart.bothi")}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
