import {Datum} from "@ant-design/charts";
import {Column} from "@ant-design/plots";
import {Box, FormControl, Select, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import managerChartApi from "api/managerChart";
import {LessonByYear, ResponseLessonByYear} from "models/managerChart";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export interface ChartLessonByYearProps {}

export default function ChartLessonByYear(props: ChartLessonByYearProps) {
  const useStyles = makeStyles(() => ({
    formControl: {
      minWidth: 120,
      backgroundColor: "#FFFFFF",
      "& .MuiSelect-root": {
        padding: 10,
      },
    },
    title: {
      fontSize: 26,
      color: "#123664",
      paddingTop: 20,
    },
  }));
  const classes = useStyles();
  const {t} = useTranslation();
  const [dataLesson, setDataLesson] = useState<LessonByYear[]>([]);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const handleChangeYear = (event: React.ChangeEvent<{value: unknown}>) => {
    setYear(Number(event.target.value));
  };
  useEffect(() => {
    (async () => {
      try {
        const res: ResponseLessonByYear<LessonByYear> = await managerChartApi.getLessonByYear(year);
        setDataLesson(res.lessionOverview);
      } catch (error) {}
    })();
  }, [year]);

  const config: any = {
    data:
      dataLesson?.length > 0
        ? dataLesson.map((item) => ({...item, month: `${t("chart.thang")} ${item.month}`}))
        : [{month: 0, noTotalLession: 0}],
    xField: "month",
    yField: "noTotalLession",
    // seriesField: "month",
    color: "#123664",
    tooltip: {
      formatter: (datum: Datum) => {
        return {name: `${t("chart.sobaihoc")}`, value: datum.noTotalLession};
      },
    },
    label: {
      content: (originData: any) => {
        const val = parseFloat(originData.noTotalLession);
        if (val === 0) {
          return;
        }
        return originData.noTotalLession;
      },
      offsetY: 10,
      position: "top",
      style: {
        fontSize: 16,
        fill: "#1B1B1B",
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    // legend: {
    //   layout: "horizontal",
    //   position: "top",
    // },
  };
  return (
    <Box>
      <Box textAlign="right" mb={3}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            value={year}
            onChange={handleChangeYear}
            defaultValue={new Date().getFullYear()}
          >
            {Array.from(new Array(10)).map((id, index) => (
              <option value={index + 2020} key={id}>
                {index + 2020}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      {dataLesson?.every((item) => item.noTotalLession === 0) ? (
        <Typography variant="subtitle2" style={{fontSize: 17}}>
          {t("chart.chuacobaihoctrongnam")} {year}
        </Typography>
      ) : (
        <>
          <Column {...config} />
          <Box textAlign="center" mt={2}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("chart.bieudobaihoctrongnam")}
            </Typography>
          </Box>{" "}
        </>
      )}
    </Box>
  );
}
