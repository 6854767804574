import {Box, Button, Container, Grid, Typography} from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import authApi from "api/authApi";
import managerGroupApi from "api/managerGroupApi";
import {useAppDispatch, useAppSelector} from "app/hooks";
import NO_AVATAR from "assets/img/no_avatar.png";
import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import {fetchInfoLogin, selectInfoLogin} from "features/Auth/authSlice";
import {
  fetchDataOptions,
  fetchListFarm,
  fetchListProvince,
  fetchListWorkTypes,
  selectListArea,
  selectListDepartment,
  selectListFarm,
  selectListProvince,
  selectListWorkTypes,
} from "features/Auth/options/optionsSlice";
import {InfoUser} from "models/auth";
import {ResponseStatus} from "models/common";
import {ListOptions, ListResponseOptions} from "models/managerGroup";
import moment from "moment";
import React, {useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import ChangePassword from "./ChangePassword";
import useStyles from "./style";

export default function Profile() {
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const infoLogin = useAppSelector(selectInfoLogin);
  const [idDepartment, setIdDepartment] = useState({
    id: 0,
    name: "",
  });

  const listArea = useAppSelector(selectListArea);
  const listDepartment = useAppSelector(selectListDepartment);
  const listWorkTypes = useAppSelector(selectListWorkTypes);
  const listFarm = useAppSelector(selectListFarm);
  const listProvince = useAppSelector(selectListProvince);
  const [newProvinceId, setNewProvinceId] = useState<number>();
  const [listPosition, setListPosition] = useState<any>([]);

  useEffect(() => {
    (async () => {
      try {
        const data: any = await authApi.getPosition();
        setListPosition(data.positionList.content);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(fetchDataOptions());
  }, []);

  useEffect(() => {
    if (infoLogin.name === "") return;
    (async () => {
      try {
        const res: ListResponseOptions<ListOptions> = await managerGroupApi.getWorkTypeByArea(
          infoLogin?.area?.id
        );
        if (res.responseCode === "00") {
          dispatch(fetchListWorkTypes(res));
        }
      } catch (error) {}
    })();
    (async () => {
      try {
        const res: ListResponseOptions<ListOptions> = await managerGroupApi.getProvincesByAreaId(
          infoLogin?.area?.id
        );
        if (res.responseCode === "00") {
          dispatch(fetchListProvince(res));
        }
      } catch (error) {}
    })();
    if (infoLogin.farm) {
      (async () => {
        try {
          const res: ListResponseOptions<ListOptions> = await managerGroupApi.getFarms(
            infoLogin?.newProvince?.id,
            infoLogin?.workType.id
          );
          if (res.responseCode === "00") {
            dispatch(fetchListFarm(res));
          }
        } catch (error) {}
      })();
    }

    setIdDepartment({
      id: infoLogin.department?.id,
      name: infoLogin?.department?.title,
    });
  }, [infoLogin]);

  const initialValues = {
    staffId: infoLogin.staffId,
    name: infoLogin.name,
    email: infoLogin.email,
    phone: infoLogin.phone,
    areaId: infoLogin.area?.id,
    workTypeId: infoLogin.workType?.id,
    dateOfJoining: String(moment(infoLogin.dateOfJoining).format("yyyy-MM-DD")),
    newProvinceId: infoLogin.newProvince?.id || 0,
    positionId: infoLogin.positionObj?.id || 0,
    departmentId: infoLogin.department?.id || 0,
    farmId: infoLogin.farm?.id,
  };
  const {control, handleSubmit, reset, setValue, getValues} = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
  });
  useEffect(() => {
    reset(initialValues);
  }, [reset, infoLogin]);

  const handleSubmitForm = async (value: any) => {
    try {
      const res: InfoUser = await authApi.updateInfo(
        idDepartment.id != 15 ? {...value, workTypeId: ""} : value, // id 15 = San xuat
        infoLogin.id
      );
      if (res.responseCode === "00") {
        toast.success(`${t("auth.edit.success")}`);
        dispatch(fetchInfoLogin());
      } else {
        throw new Error(res.responseDescription);
      }
    } catch (error) {}
  };

  const handleGetListWorkTypeAndProvinces = (option: ListOptions) => {
    handleGetListWorkType(option);
    handleGetListProvince(option);
  };

  const handleGetListWorkType = async (option: ListOptions) => {
    try {
      const res: ListResponseOptions<ListOptions> = await managerGroupApi.getWorkTypeByArea(
        option?.id
      );
      if (res.responseCode === "00") {
        dispatch(fetchListWorkTypes(res));
      }
    } catch (error) {}
  };

  const handleGetListProvince = async (option: ListOptions) => {
    try {
      const res: ListResponseOptions<ListOptions> = await managerGroupApi.getProvincesByAreaId(
        option?.id
      );
      if (res.responseCode === "00") {
        dispatch(fetchListProvince(res));
      }
    } catch (error) {}
  };
  const handleEditAvatar = async (event: any) => {
    const fileAvatar = event?.target?.files[0];
    const formData = new FormData();
    formData.append("image", fileAvatar);
    try {
      const res: ResponseStatus = await authApi.updateImage(formData);
      if (res.responseCode === "00") {
        dispatch(fetchInfoLogin());
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetListFarm = async (option: ListOptions) => {
    setNewProvinceId(option.id);
    try {
      const res: ListResponseOptions<ListOptions> = await managerGroupApi.getFarms(
        option?.id,
        getValues().workTypeId
      );
      if (res.listFarm.length === 0) {
        setValue("farmId", "");
      }
      if (res.responseCode === "00") {
        dispatch(fetchListFarm(res));
      }
    } catch (error) {}
  };
  const handleClickWorkType = async (option: ListOptions) => {
    if (newProvinceId || infoLogin.newProvince.id) {
      try {
        const res: ListResponseOptions<ListOptions> = await managerGroupApi.getFarms(
          newProvinceId || infoLogin.newProvince.id,
          option.id
        );
        if (res.listFarm.length === 0) {
          setValue("farmId", "");
        }
        if (res.responseCode === "00") {
          dispatch(fetchListFarm(res));
        }
      } catch (error) {}
    }
  };

  return (
    <Box className={classes.background}>
      <Container>
        <Box className={classes.content}>
          <Box className={classes.avatar}>
            <label htmlFor="contained-button-file">
              <img src={infoLogin?.profileImageUrl || NO_AVATAR} alt="avatar" />
              <Box className={classes.upload__avatar}>
                <CameraAltIcon className={classes.upload__avatar__camera} />
              </Box>
            </label>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              name="image"
              onChange={handleEditAvatar}
              style={{display: "none"}}
            />
          </Box>
          {/*  Info */}
          <Box className={classes.content__info}>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Typography variant="h5" className={classes.content__info__title}>
                {t("profile.thongtincanhan")}
              </Typography>
              <Grid container className={classes.form}>
                {infoLogin.role !== "STUDENT" && (
                  <>
                    <Grid item xs={5} lg={4}>
                      <Typography variant="h6" className={classes.form__input}>
                        {t("auth.code").toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} lg={8}>
                      <InputField
                        name="staffId"
                        label={t("auth.code")}
                        control={control}
                        disabled
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={5} lg={4}>
                  <Typography variant="h6" className={classes.form__input}>
                    {t("auth.fullName").toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={7} lg={8}>
                  <InputField name="name" label={t("auth.fullName")} control={control} />
                </Grid>
                <Grid item xs={5} lg={4}>
                  <Typography variant="h6" className={classes.form__input}>
                    {t("auth.email").toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={7} lg={8}>
                  <InputField name="email" label={t("auth.email")} control={control} disabled />
                </Grid>

                <Grid item xs={5} lg={4}>
                  <Typography variant="h6" className={classes.form__input}>
                    {t("auth.phone").toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={7} lg={8}>
                  <InputField name="phone" label={t("auth.phone")} control={control} />
                </Grid>
              </Grid>

              {infoLogin.role === "STUDENT" && (
                <>
                  <Typography variant="h5" className={classes.content__info__title}>
                    {t("profile.thongtincongviec")}
                  </Typography>

                  <Grid container className={classes.form}>
                    <Grid item xs={5} lg={4}>
                      <Typography variant="h6" className={classes.form__input}>
                        {t("auth.code").toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} lg={8}>
                      <InputField
                        name="staffId"
                        label={t("auth.code")}
                        control={control}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={5} lg={4}>
                      <Typography variant="h6" className={classes.form__input}>
                        {t("auth.area").toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} lg={8}>
                      <SelectField
                        name="areaId"
                        label={t("auth.area")}
                        control={control}
                        borderRadius="4px"
                        options={listArea}
                        handleClick={handleGetListWorkTypeAndProvinces}
                      />
                    </Grid>
                    <Grid item xs={5} lg={4}>
                      <Typography variant="h6" className={classes.form__input}>
                        {t("auth.department").toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} lg={8}>
                      <SelectField
                        name="departmentId"
                        label={t("auth.department")}
                        control={control}
                        borderRadius="4px"
                        options={listDepartment.map(({id, title}) => ({
                          id,
                          name: title,
                        }))}
                        handleClick={(option: any) => {
                          if (option.id != 15) {
                            setValue("workTypeId", "");
                          }
                          setIdDepartment({
                            id: option.id,
                            name: option.name,
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={5} lg={4}>
                      <Typography variant="h6" className={classes.form__input}>
                        {t("auth.workType").toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} lg={8}>
                      <SelectField
                        name="workTypeId"
                        label={t("auth.workType")}
                        control={control}
                        borderRadius="4px"
                        options={listWorkTypes.map(({workType}) => ({
                          id: workType.id,
                          name: workType.name,
                        }))}
                        disabled={
                          listDepartment?.filter((department) => department.id == 15)[0]?.id === // id 15 = San xuat
                          idDepartment.id
                            ? false
                            : true
                        }
                        handleClick={handleClickWorkType}
                      />
                    </Grid>

                    <Grid item xs={5} lg={4}>
                      <Typography variant="h6" className={classes.form__input}>
                        {t("auth.province").toUpperCase()} / {t("auth.city").toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} lg={8}>
                      <SelectField
                        name="newProvinceId"
                        label={`${t("auth.province")} / ${t("auth.city")}`}
                        control={control}
                        borderRadius="4px"
                        options={listProvince}
                        handleClick={handleGetListFarm}
                      />
                    </Grid>
                    <Grid item xs={5} lg={4}>
                      <Typography variant="h6" className={classes.form__input}>
                        {t("auth.farm").toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} lg={8}>
                      <SelectField
                        name="farmId"
                        label={t("auth.farm")}
                        control={control}
                        borderRadius="4px"
                        disabled={listFarm.length === 0 ? true : false}
                        options={listFarm}
                      />
                    </Grid>
                    <Grid item xs={5} lg={4}>
                      <Typography variant="h6" className={classes.form__input}>
                        {t("auth.position").toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} lg={8}>
                      <SelectField
                        name="positionId"
                        label={t("auth.position")}
                        options={listPosition}
                        control={control}
                        borderRadius="4px"
                      />
                    </Grid>
                    <Grid item xs={5} lg={4}>
                      <Typography variant="h6" className={classes.form__input}>
                        {t("auth.date.join").toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} lg={8}>
                      <InputField
                        type="date"
                        label={t("auth.date.join")}
                        control={control}
                        name="dateOfJoining"
                        borderRadius="4px"
                        inputType="date"
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Box textAlign="center" mt={2}>
                <Button type="submit" variant="contained" className={classes.btn_submit}>
                  {t("auth.button.updateprofile")}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>

        {/* Change Password */}
        <Box className={classes.content}>
          <Box className={classes.content__info}>
            <Typography variant="h5" className={classes.content__info__title}>
              {t("profile.thaydoimatkhau")}
            </Typography>
            <ChangePassword />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
