import {Box, Button, CircularProgress, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {ExamNoQuestion} from "models/managerTest";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useStyles from "./style";

export interface TimeoutProps {
  exam: ExamNoQuestion;
  isMobile: boolean;
}

export default function Timeout({exam, isMobile}: TimeoutProps) {
  const navigate = useNavigate();
  const classes = useStyles();

  const [difference, setDifference] = useState<any>();
  const [currentTimeStamp, setCurrentTimeStamp] = useState<any>(
    Number(exam.currentServerTimestamp)
  );

  const isTimeEnd = Number(exam.endExamServerTimestamp) - Number(exam.currentServerTimestamp) > 0;

  useEffect(() => {
    const examDay = Number(exam.startExamServerTimestamp);
    const difference = moment.duration(moment(examDay).diff(moment(currentTimeStamp)));

    setDifference(difference);
  }, [currentTimeStamp]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTimeStamp((prev: any) => prev + 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const handleDateTime = () => {
    if (!difference) {
      return <CircularProgress style={{width: 20, height: 20}} />;
    }
    if (difference?.days() > 0) {
      return (
        <Typography variant="subtitle2" style={{color: "#137BBD", fontSize: isMobile ? 8 : 14}}>
          Còn {difference?.days()} ngày
        </Typography>
      );
    } else if (difference?.days() <= 0 && difference?.hours() > 0) {
      return (
        <Typography variant="subtitle2" style={{color: "#137BBD", fontSize: isMobile ? 8 : 14}}>
          Còn {difference?.hours()} giờ
        </Typography>
      );
    } else if (difference?.days() <= 0 && difference?.hours() <= 0 && difference?.minutes() > 15) {
      return (
        <Typography variant="subtitle2" style={{color: "#137BBD", fontSize: isMobile ? 8 : 14}}>
          Còn {difference?.minutes()} phút
        </Typography>
      );
    } else if (
      difference?.days() <= 0 &&
      difference?.hours() <= 0 &&
      difference?.minutes() <= 15 &&
      isTimeEnd
    ) {
      return (
        <Button
          size="small"
          className={classes.btn_detail}
          onClick={() =>
            navigate("/exam/", {
              state: {
                id: exam?.id,
              },
            })
          }
        >
          Vào thi
        </Button>
      );
    }
  };
  return <>{handleDateTime()}</>;
}
