import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Menu,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import managerGroupApi from "api/managerGroupApi";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_student_edit.png";
import EMOJI from "assets/img/emoji.png";
import axios, {AxiosResponse} from "axios";
import CheckboxField from "components/FormFields/CheckboxField";
import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import {BASE_URL, TOKEN} from "constants/config";
import Picker, {SKIN_TONE_MEDIUM_DARK} from "emoji-picker-react";
import {
  fetchCategoryList,
  selectCategoryList,
} from "features/Admin/pages/ManagerCategory/managerCategorySlice";
import {
  fetchDataOptions,
  fetchListWorkTypes,
  selectListArea,
  selectListDepartment,
} from "features/Auth/options/optionsSlice";
import {ListOptions, ListResponseOptions} from "models/managerGroup";
import {CreateLesson} from "models/managerLesson";
import moment from "moment";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useController, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";

const schemaDraft = yup.object().shape({});

export default function ReupLesson() {
  const useStyles = makeStyles((theme: Theme) => ({
    loading: {
      zIndex: 99,
      backgroundColor: "rgba(255,255,255,0.8)",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      "& >div": {
        position: "absolute",
        top: "60%",
        left: "50%",
        transform: "translate(-60%,-50%)",
        "& .MuiCircularProgress-root": {
          display: "flex",
          margin: "0 auto",
        },
      },
    },
    background: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& >div> img": {
        objectFit: "cover",
        height: "100%",
        margin: "0 auto",
        width: "100%",
      },
    },
    percentage: {
      color: "#137bbd",
      fontSize: 18,
      marginBottom: 15,
    },
    root: {
      display: "flex",
      alignItems: "center",
      marginBottom: 5,
      width: "100%",
    },
    header: {
      color: "#004673",
      fontSize: 25,
      fontWeight: 700,
      marginBottom: 10,
      minWidth: 150,
    },
    title: {
      fontSize: 15,
      fontWeight: 600,
      width: "9%",
      [theme.breakpoints.up("xl")]: {
        // fontSize: 15,
        width: "10%",
      },
    },
    selectArea: {
      padding: 6,
      backgroundColor: disableArea ? "#f8f4f4" : "#fff",
    },
    label: {
      padding: 5,
      color: "#999999",
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 9,
      },
    },

    btn_create: {
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      color: "#fff",
      borderRadius: 10,
      padding: "10px 15px",
      marginLeft: 10,
    },
    btn_draft: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 10,
      padding: "10px 15px",
    },
    btn_cancel: {
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      color: "#fff",
      borderRadius: 10,
      padding: "5px 15px",
      marginTop: 30,
    },
    icon_emoji: {
      position: "absolute",
      bottom: 10,
      right: 10,
      "& >span >img": {
        width: 25,
        height: 25,
      },
    },
    form__video__wrapper: {
      backgroundColor: "#fff",
      width: "100%",
      height: 400,
      boxShadow: "0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 8,
      "&:hover": {
        backgroundColor: "#ECECEC",
        transition: "linear 0.3s all",
      },
      "& >input ": {
        display: "none",
      },
    },
    form__video__iconClose: {
      position: "absolute",
      right: 10,
      top: 10,
      zIndex: 99,
      backgroundColor: "#FFF",
      borderRadius: "50%",
      width: 25,
      height: 25,
      cursor: "pointer",
    },
    form__video__content: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
      "& > span": {
        color: "#AAAAAA",
        fontSize: 13,
      },
    },
  }));
  const {t} = useTranslation();
  const schema = yup.object().shape({
    publishDate: yup.string().when("postNow", {
      is: false,
      then: yup.string().required(`${t("managerLesson.vuilongchonngaydang")}`),
    }),
    publishTime: yup.string().when("postNow", {
      is: false,
      then: yup.string().required(`${t("managerLesson.vuilongchongiodang")}`),
    }),
    lessionCategoryId: yup.string().required(`${t("managerLesson.vuilongchonchude")}`),
    lessionDescription: yup.string().required(`${t("managerLesson.vuilongchonmota")}`),
    lessionTitle: yup.string().required(`${t("managerLesson.vuilongnhaptenbaihoc")}`),
  });
  const listArea = useAppSelector(selectListArea);
  const listCategory = useAppSelector(selectCategoryList);
  const [listWorkTypes, setListWorkTypes] = useState<any>([]);
  const [isPostNow, setIsPostNow] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const [listProvince, setListProVince] = useState<any>([]);
  const [listFarm, setListFarm] = useState<any>([]);
  const [disableArea, setDisableArea] = useState(false);
  const [disableDate, setDisableDate] = useState(false);
  const [disableTime, setDisableTime] = useState(false);
  const [infoLesson, setInfoLesson] = useState<any>();
  const [draft, setDraft] = useState(false);
  const navigate = useNavigate();
  const [openEmoji, setOpenEmoji] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const listDepartment = useAppSelector(selectListDepartment);
  const [errorHierachy, setErrorHierachy] = useState("");
  const [disableDepartment, setDisableDepartment] = useState(false);
  const [hierachies, setHierachies] = useState<any>([
    {
      id: Math.random() * 9999,
    },
  ]);
  const handleOpenEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmoji(event.currentTarget);
  };
  const location = useLocation();
  useEffect(() => {
    setInfoLesson(location);
  }, []);

  const initialValues: CreateLesson = {
    postNow: false,
    isAll: false,
    publishDate: "",
    publishTime: "",
    hiddenDate: "",
    hiddenTime: "",
    lessionCategoryId: "",
    lessionDescription: infoLesson?.state.decription || "",
    lessionTitle: infoLesson?.state.name || "",
    areaId: "",
    workTypeId: "",
    isDraft: "N",
    departmentId: "",
    copyFilesFromLessonID: 0,
    farmId:"",
    newProvinceId:""
  };
  const {control, handleSubmit, setValue, getValues, reset} = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
    resolver: yupResolver(draft ? schemaDraft : schema),
  });
  useEffect(() => {
    reset(initialValues);
  }, [reset, infoLesson]);

  const onEmojiClick = (event: any, emojiObject: any) => {
    setValue("lessionDescription", getValues().lessionDescription + emojiObject.emoji);
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchDataOptions());
    dispatch(fetchCategoryList());
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res: any = await managerGroupApi.getWorkTypes();
        setListWorkTypes(res.workType);
      } catch (error) {}
    })();
  }, []);
  useEffect(() => {
    if (isAll) {
      setDisableArea(true);
      setDisableDepartment(true);
      setHierachies([
        {
          id: Math.random() * 9999,
        },
      ]);
      setValue("areaId", "");
      setValue("departmentId", "");
      setValue("workTypeId", "");
      setValue("newProvinceId", "");
      setValue("farmId", "");
    } else {
      setDisableArea(false);
      setDisableDepartment(false);
    }
    if (isPostNow) {
      setValue("publishDate", "");
      setValue("publishTime", "");
      setDisableTime(true);
      setDisableDate(true);
    } else {
      setDisableTime(false);
      setDisableDate(false);
    }
  }, [isAll, isPostNow]);
  const classes = useStyles();
  const token = localStorage.getItem(TOKEN);

  const handleSubmitForm = async (value: CreateLesson) => {
    if (
      hierachies.length === 1 &&
      !isAll &&
      hierachies.every((x: any) => !x.areaId && !x.departmentId && !x.workTypeId)
    ) {
      setErrorHierachy(`${t("managerLesson.vuilongchonphancap")}`);
      toast.error(`${t("managerLesson.vuilongchonphancap")}`);
      return;
    }
    const newDataHierachies = hierachies.map((x: any) => {
      return {
        areaId: x.areaId,
        workTypeId: x.workTypeId,
        departmentId: x.departmentId,
        newProvinceId: x.newProvinceId,
        farmId: x.farmId,
      };
    });
    const newValue = {
      ...value,
      isDraft: draft ? "Y" : "N",
      isAll: value.isAll ? "Y" : "N",
      publishDate: value.postNow
        ? moment(new Date()).format("YYYY/MM/DD").split("/").join("")
        : value.publishDate.split("-").join(""),
      publishTime: value.postNow
        ? moment(new Date()).format("HH/mm").split("/").join("")
        : value.publishTime.split(":").join(""),
      hiddenDate: value.hiddenDate?.split("-").join(""),
      hiddenTime: value.hiddenTime?.split(":").join(""),
      copyFilesFromLessonID: infoLesson.state.id,
    };
    const formData = new FormData();
    formData.append("publishDate", newValue.publishDate);
    formData.append("publishTime", newValue.publishTime);
    formData.append("expiryDate", newValue.hiddenDate);
    formData.append("expiryTime", newValue.hiddenTime);
    formData.append("isAll", newValue.isAll);
    formData.append("isDraft", newValue.isDraft);
    !isAll && formData.append("hiarachies", JSON.stringify(newDataHierachies));
    formData.append("lessionCategoryId", newValue.lessionCategoryId);
    formData.append("lessionDescription", newValue.lessionDescription);
    formData.append("areaId", String(newValue.areaId));
    formData.append("workTypeId", String(newValue.workTypeId));
    formData.append("departmentId", String(newValue.departmentId));
    formData.append("lessionTitle", newValue.lessionTitle);
    formData.append("copyFilesFromLessonID", newValue.copyFilesFromLessonID);
    setLoading(true);
    try {
      const res: AxiosResponse = await axios({
        method: "POST",
        url: `${BASE_URL}/lession/createLession`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
        onUploadProgress: (progressEvent) => {
          let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (percent < 100) {
            setUploadPercentage(percent);
          }
        },
        // cancelToken: new CancelToken((cancel: any) => (cancelFileUpload.current = cancel)),
      });
      if (res.data.responseCode === "00") {
        setUploadPercentage(100);
        setTimeout(() => {
          setUploadPercentage(0);
        }, 1000);
        toast.success(`${t("managerLesson.taomoibaihocthanhcong")}`);
        navigate("/admin/manager-lesson");
        setLoading(false);
      } else {
        setUploadPercentage(0);
        toast.error(`${t("managerLesson.taobaihocthatbai")}`);
        setLoading(false);
        throw new Error("Create lesson is failed");
      }
    } catch (error) {}
  };

  const handleAddMoreHierachy = () => {
    setHierachies((prevHierachies: any) => [
      ...prevHierachies,
      {
        id: Math.random() * 9999,
      },
    ]);
  };

  const handleDeleteHierachy = (id: number) => {
    const hrchy = [...hierachies];
    if (hierachies.length > 1) {
      hrchy.splice(
        hrchy.findIndex((x) => x.id === id),
        1
      );
    }
    setHierachies(hrchy);
  };

  const handleGetListFarm = async (option:ListOptions, name:string, index:number) => {
    try {
      const res: ListResponseOptions<ListOptions> = await managerGroupApi.getFarms(
         name === 'newProvinceId' ? option.id : hierachies[index].newProvinceId,
         name === 'workTypeId' ? option.id : hierachies[index].workTypeId,
      );
      if (res.responseCode === "00") {
        // setDisableFarm(false);
        const farms = [...listFarm];
        const foundIdx = farms.findIndex(x=>x.id === index);
        if(foundIdx > -1){
          const newArr = farms.map((x:any)=> {
            if(x.id === index){
              return {
                id:x.id,
                farms : res.listFarm
              }
            }else{
              return {
                ...x
              }
            }
          })
        setListFarm(newArr)
        }else{
          setListFarm((x:any)=>[...x,{
            id:index,
            farms:res.listFarm
          }])
        }
      }else{
        // setDisableFarm(true);
      }
    } catch (error) {}
  };

  const handleChangeWorkType = async (option: any, index: number) => {
    const hrchy = [...hierachies];
    hrchy[index].workTypeId = option.id;
    setHierachies(hrchy);
    await handleGetListFarm(option,'workTypeId' ,index);
  };
  const handleChangeDepartment = (option: any, index: number) => {
    const hrchy = [...hierachies];
    hrchy[index].departmentId = option.id;
    setHierachies(hrchy);
  };
  const handleChangeArea = async (option: any, index: number) => {
    const hrchy = [...hierachies];
    const farms =[...listFarm];
    hrchy[index].areaId = option.id;
    if(hrchy[index].newProvinceId && listFarm[index].farms){
      hrchy[index].newProvinceId = '';
      farms[index].farms = []
      setListFarm(farms)
    }
    setHierachies(hrchy);
    await handleGetListProvinceByAreaId(option,index);
  };

  const handleChangeFarm = (option: any, index: number) => {
    const hrchy = [...hierachies];
    hrchy[index].farmId = option.id;
    setHierachies(hrchy);
  };

  const handleChangeCity = async (option: any, index: number) => {
    const hrchy = [...hierachies];
    hrchy[index].newProvinceId = option.id;
    setHierachies(hrchy);
    await handleGetListFarm(option,'newProvinceId',index);
  };

  const handleGetListProvinceByAreaId = async (option: ListOptions,index:number) => {
    try {
      const res: ListResponseOptions<ListOptions> = await managerGroupApi.getProvincesByAreaId(
        option?.id
      );
      if (res.responseCode === "00") {
        const provinces = [...listProvince];
        const foundIdx = provinces.findIndex(x=>x.id === index);
        if(foundIdx > -1){
          const newArr = provinces.map((x:any)=> {
            if(x.id === index){
              return {
                id:x.id,
                provinces : res.provinces
              }
            }else{
              return {
                ...x
              }
            }
          })
          setListProVince(newArr)
        }else{
          setListProVince((x:any)=>[...x,{
            id:index,
            provinces:res.provinces
          }])
        }
      }
    } catch (error) {}
  };
  return (
    <Box style={{position: "relative"}}>
      <Box>
        <Tooltip title="Quay lại">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{marginLeft: 7}} />
          </IconButton>
        </Tooltip>
      </Box>
      {loading && (
        <Box className={classes.loading}>
          <Box>
            <Typography variant="subtitle2" className={classes.percentage}>
              {t("managerLesson.dangtai")}
              {uploadPercentage}%
            </Typography>
            {/* <img src={LOADING} alt="LOADING" /> */}
            <CircularProgress style={{width: 50, height: 50}} />
            {/* <Box textAlign="center">
              <Button className={classes.btn_cancel}>Hủy bỏ</Button>
            </Box> */}
          </Box>
        </Box>
      )}
      <Box className={classes.background}>
        <Typography variant="subtitle2" className={classes.header}>
          {t("button.addnew").toUpperCase()}
        </Typography>
        <div>
          <img src={BACKGROUND} alt="BACKGROUND" />
        </div>
      </Box>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Box className={classes.root}>
          <Box className={classes.root} width="100%">
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.lichdang")}
            </Typography>
            <Box className={classes.root}>
              <Box width="30%">
                <InputField
                  control={control}
                  type="date"
                  label=""
                  name="publishDate"
                  disabled={disableDate}
                />
              </Box>
              <Box ml={2} mr={4} width="30%">
                <InputField
                  control={control}
                  type="time"
                  label=""
                  name="publishTime"
                  disabled={disableTime}
                />
              </Box>

              <CheckboxField
                name="postNow"
                label={t("managerLesson.dangngay")}
                control={control}
                handleChange={setIsPostNow}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.root}>
          <Box className={classes.root} width="100%">
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.lichan")}
            </Typography>
            <Box className={classes.root}>
              <Box width="30%">
                <InputField control={control} type="date" label="" name="hiddenDate" />
              </Box>
              <Box ml={2} mr={4} width="30%">
                <InputField control={control} type="time" label="" name="hiddenTime" />
              </Box>
            </Box>
          </Box>
        </Box>
        {hierachies.map((hierachy: any, index: number) => (
          <Box className={classes.root} key={hierachy.id}>
            {index === 0 ? (
              <Typography variant="subtitle2" className={classes.title}>
                {t("managerLesson.phancap")}
              </Typography>
            ) : (
              <Typography variant="subtitle2" className={classes.title}></Typography>
            )}

            <Box className={classes.root}>
              <Box width="15%">
                <SelectField
                  name={`${hierachies.length === 1 ? "areaId" : `areaId-${index}`}`}
                  label={t("auth.area")}
                  options={listArea.map(({id, name}) => ({
                    id,
                    name: name,
                  }))}
                  control={control}
                  handleClick={handleChangeArea}
                  disabled={disableArea}
                  idxItemClick={index}
                  value={hierachy.areaId || ""}
                />
              </Box>
              <Box width="15%" ml={2}>
                <SelectField
                  name={`${hierachies.length === 1 ? "newProvinceId" : `newProvinceId-${index}`}`}
                  label={t("auth.city")}
                  options={listProvince.find((x:any)=>x.id === index)?.provinces || []}
                  control={control}
                  disabled={listProvince?.filter((province:any) => province.id === index)[0]
                    ?.id === index
                    ? false
                    : true}
                  idxItemClick={index}
                  value={hierachy.newProvinceId || ""}
                  handleClick={handleChangeCity}
                />
              </Box>
              <Box ml={2} width="15%">
                <SelectField
                  name={`${hierachies.length === 1 ? "departmentId" : `departmentId-${index}}`}`}
                  label={t("auth.department")}
                  options={listDepartment.map(({id, title}) => ({
                    id,
                    name: title,
                  }))}
                  disabled={disableDepartment}
                  control={control}
                  handleClick={handleChangeDepartment}
                  idxItemClick={index}
                  value={hierachy.departmentId || ""}
                />
              </Box>
              <Box ml={2} width="15%">
                <SelectField
                  name={`${hierachies.length === 1 ? "workTypeId" : `workTypeId-${index}`}`}
                  label={t("auth.workType")}
                  options={listWorkTypes.map((workType: any) => ({
                    id: workType?.id,
                    name: workType?.name,
                  }))}
                  disabled={
                    listDepartment?.filter((department) => department.id === 15)[0]
                      ?.id === Number(hierachy.departmentId)
                      ? false
                      : true
                  }
                  control={control}
                  handleClick={handleChangeWorkType}
                  idxItemClick={index}
                  value={hierachy.workTypeId || ""}
                />
              </Box>
              <Box  width="15%" mx={2}>
                <SelectField
                  name={`${hierachies.length === 1 ? "farmId" : `farmId-${index}`}`}
                  label={t("auth.farm")}
                  options={listFarm.find((x:any)=>x.id === index)?.farms || []}
                  disabled={listFarm?.filter((farm:any) => farm.id === index)[0]
                    ?.id === index
                    ? false
                    : true}
                  control={control}
                  idxItemClick={index}
                  value={hierachy.farmId || ""}
                  handleClick={handleChangeFarm}
                />
              </Box>
              {index === 0 && (
                <Fragment>
                  <CheckboxField
                    name="isAll"
                    label="Tất cả"
                    control={control}
                    handleChange={setIsAll}
                  />
                  {!isAll && (
                    <Tooltip title="Add more">
                      <IconButton onClick={handleAddMoreHierachy}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Fragment>
              )}
              {index > 0 && (
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDeleteHierachy(hierachy.id)}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ))}
        {errorHierachy !== "" && (
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}></Typography>
            <Typography variant="subtitle2" color="secondary" style={{flexGrow: 1}}>
              {errorHierachy}
            </Typography>
          </Box>
        )}
        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.chude")}
            </Typography>
            <Box className={classes.root}>
              <SelectField
                name="lessionCategoryId"
                label={t("managerLesson.chude")}
                options={listCategory}
                control={control}
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.tenbaihoc")}
            </Typography>
            <Box className={classes.root}>
              <InputField
                name="lessionTitle"
                label={t("managerLesson.tenbaihoc") + "..."}
                control={control}
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.mota")}
            </Typography>
            <Box className={classes.root}>
              <Box position="relative" width="100%">
                <InputField
                  name="lessionDescription"
                  label={t("managerLesson.mota")}
                  control={control}
                  rows={3}
                  multiline
                />
                <IconButton onClick={handleOpenEmoji} className={classes.icon_emoji}>
                  <img src={EMOJI} alt="EMOJI" />
                </IconButton>
                {openEmoji && (
                  <Menu
                    id="simple-menu"
                    anchorEl={openEmoji}
                    keepMounted
                    open={Boolean(openEmoji)}
                    onClose={() => setOpenEmoji(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    //  className={classes.menu}
                  >
                    <Picker
                      onEmojiClick={onEmojiClick}
                      disableAutoFocus={true}
                      skinTone={SKIN_TONE_MEDIUM_DARK}
                      groupNames={{smileys_people: "PEOPLE"}}
                      native
                    />
                  </Menu>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerLesson.video")}
            </Typography>
            {infoLesson && infoLesson.state && (
              <ReactPlayer
                url={infoLesson.state.videoUrl}
                controls={true}
                width="100%"
                height="100%"
                config={{file: {attributes: {controlsList: "nodownload"}}}}
                // Disable right click
                onContextMenu={(e: any) => e.preventDefault()}
              />
            )}
          </Box>
        </Box>
        <Box textAlign="right" mt={3}>
          <Button type="submit" className={classes.btn_draft} onClick={() => setDraft(true)}>
            {t("managerLesson.luunhap")}
          </Button>
          <Button type="submit" className={classes.btn_create} onClick={() => setDraft(false)}>
            {t("managerLesson.dangtin")}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
