import {Box, Card, Typography} from "@material-ui/core";
import {useAppSelector} from "app/hooks";
import {selectInfoLogin} from "features/Auth/authSlice";
import * as React from "react";
import useStyles from "./style";
import NO_AVATAR from "assets/img/no_avatar.png";
import ICON__ADD__IMAGE from "assets/img/icon__add__image.png";
import {useTranslation} from "react-i18next";
import VECTOR_CATEGORY_1 from "assets/img/vector_category_1.png";
import ICON__ADD__PDF from "assets/img/icon_add_pdf.png";

export interface CreatePostProps {
  openModalIMAGE: () => void;
  openModalPDF: () => void;
  setOpenModalCategory: (e: any) => void;
  setOpenModal: (e: any) => void;
}

export default function CreatePost({
  openModalIMAGE,
  openModalPDF,
  setOpenModalCategory,
  setOpenModal,
}: CreatePostProps) {
  const classes = useStyles();
  const infoLogin = useAppSelector(selectInfoLogin);
  const {t} = useTranslation();
  return (
    <Card className={classes.form}>
      <Box display="flex" alignItems="center">
        <Box>
          {infoLogin.profileImageUrl ? (
            <img src={infoLogin.profileImageUrl} alt="avatar" className={classes.avatar} />
          ) : (
            <img src={NO_AVATAR} alt="avatar" className={classes.avatar} />
          )}
        </Box>
        <Box className={classes.form__wrapper}>
          <Typography variant="subtitle2" onClick={() => setOpenModal(true)}>
            {t("community.taobaivietcongkhai")}
          </Typography>
        </Box>
      </Box>
      <Box my={2}>
        <hr />
      </Box>
      <Box display="flex" justifyContent="end" alignItems="center" py={2}>
        <Box className={classes.form__action}>
          <Box className={classes.form__field} onClick={() => openModalIMAGE()}>
            <img src={ICON__ADD__IMAGE} alt="ICON__ADD__IMAGE" />
            <Typography variant="body2" component="span">
              {t("community.anh/video")}
            </Typography>
          </Box>
          <Box className={classes.form__field} onClick={() => openModalPDF()}>
            <img src={ICON__ADD__PDF} alt="ICON__ADD__PDF" style={{width: "25px"}} />
            <Typography variant="body2" component="span">
              {t("community.file")}
            </Typography>
          </Box>
          <Box className={classes.form__field} onClick={() => setOpenModalCategory(true)}>
            <img src={VECTOR_CATEGORY_1} alt="icon_category" />
            <Typography variant="body2" component="span">
              {t("community.chudebaiviet")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
