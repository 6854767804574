import {Box, Grow, makeStyles, Theme, Typography} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLessIcon from "@material-ui/icons/ExpandMore";
import {GroupList, ListProvince, ListWorkType} from "models/managerGroup";
import React, {Fragment, useState} from "react";
import {TreeNode} from "react-organizational-chart";
// import useStyles from "../style";
import Provinces from "./Provinces";

export interface WorkTypesrops {
  listDepartment: ListWorkType;
  setValueMap: (x: any) => void;
  area: GroupList;
}

export default function WorkTypes({listDepartment, setValueMap, area}: WorkTypesrops) {
  const [open, setOpen] = useState(false);
  const useStyles = makeStyles((theme: Theme) => ({
    workType: {
      borderRadius: 8,
      padding: 5,
      height: 70,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      boxShadow: "0px 2px 4px rgb(0 0 0 / 25%), 0px 0px 6px rgb(168 168 168 / 25%)",
      minWidth: 100,
      backgroundColor: "#fff",
      width: "50%",
      margin: "0 auto",
      "&>h6": {
        display: "flex",
        height: "100%",
        justifyContent: "space-around",
        alignItems: "center",
        fontSize: 13,
      },
      "&>svg": {
        color: "#137BBD",
        cursor: "pointer",
        fontSize: 25,
      },
    },
    treeNode: {
      "& > ul": {
        "&::before": {
          height: listDepartment.provinces.length === 1 && 40,
        },
      },
    },
  }));

  const handleOpenDropdown = () => {
    if (listDepartment.provinces.length === 0) {
      return;
    }
    setOpen(!open);
  };
  const classes = useStyles();
  return (
    <TreeNode
      className={classes.treeNode}
      label={
        <Box className={classes.workType}>
          <Typography
            variant="subtitle2"
            style={{
              color:
                area.areaName === "Miền Bắc"
                  ? "#B80F0F"
                  : area.areaName === "Miền Nam"
                  ? "#123664"
                  : "#EBA337",
            }}
          >
            {listDepartment.workTypeName}
          </Typography>
          {listDepartment.provinces.length === 0 ? (
            ""
          ) : open ? (
            <ExpandLessIcon onClick={handleOpenDropdown} />
          ) : (
            <ChevronRightIcon onClick={handleOpenDropdown} />
          )}
        </Box>
      }
    >
      {open ? (
        <Grow in={open}>
          <div style={{display: "inherit"}}>
            {listDepartment.provinces.map((provinces: ListProvince) => (
              <Fragment key={provinces.provinceId}>
                <Provinces
                  provinces={provinces}
                  setValueMap={setValueMap}
                  listDepartment={listDepartment}
                  area={area}
                />
              </Fragment>
            ))}
          </div>
        </Grow>
      ) : null}
    </TreeNode>
  );
}
