import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 150,
    backgroundColor: "#FFFFFF",

    "& .MuiSelect-root": {
      padding: 10,
    },
  },
  title__area: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    margin: "15px 0",
    "& >h6": {
      fontSize: 28,
      fontWeight: 500,
    },
  },
  note_passed:{
    width:'40px',
    height:'3px',
    backgroundColor:'#42508F',
    marginRight:'10px'
  },
  note_absent:{
    width:'40px',
    height:'3px',
    backgroundColor:'#EB4B4B',
    marginRight:'10px'
  },
  note_failed:{
    width:'40px',
    height:'3px',
    backgroundColor:'#F6AD3F',
    marginRight:'10px'
  },
  center_layout:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    margin:'70px 0 40px 0'
  }
}));

export default useStyles;
