import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import managerCommnunityApi from "api/managerCommunity";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ICON_PIG_CHECKED from "assets/img/icon_pig_pink_checked.png";
import ICON_PIG from "assets/img/icon_pig_pink_nobg.png";
import {
  fetchCommunityCategoryList,
  selectCommunityCategoryList,
  setCategoryCheckList,
  setCheckList,
} from "features/Admin/pages/Community/comunitySlice";
import { ResponseStatus } from "models/common";
import { CommnunityCategoryList } from "models/managerCommunity";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsSearch } from "react-icons/bs";
import { toast } from "react-toastify";
import * as yup from "yup";

export interface ModalCategoryProps {
  handleClose: (e: any) => void;
  showBtnDelete: boolean;
}

export default function ModalCategory({ handleClose, showBtnDelete }: ModalCategoryProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    modal__container: {
      maxWidth: 560,
      borderRadius: 12,
      backgroundColor: "#fff",
      zIndex: 9,
      margin: "0 auto",
      position: "absolute",
      top: "-20%",
      left: "50%",
      transform: "translate(-50%,20%)",
      width: "100%",
      minHeight: 400,
      maxHeight: 600,
      overflowY: "auto",
    },
    modal__header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 24px",
      minHeight: 40,
      borderBottom: "1px solid rgb(235, 235, 235)",
      backgroundColor: "#E58A25",
      color: "#fff",
      "& >h6": {
        fontSize: 16,
        marginRight: 30,
      },
      "&> button": {
        color: "#fff",
      },
    },
    modal__content: {
      padding: "15px 20px",
      borderBottom: "1px solid #D4D4D4",
      [theme.breakpoints.down("xs")]: {
        padding: 15,
      },
    },
    btn_add: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 8,
      padding: "5px 10px",
      fontSize: 13,
      [theme.breakpoints.down("xs")]: {
        padding: 5,
        fontSize: 11,

        width: "25%",
      },
    },
    input: {
      height: "100%",
      "& .MuiInputBase-root": {
        height: "100%",
        borderRadius: 8,
        "& .MuiOutlinedInput-input": {
          padding: 12,
        },
      },
    },
    searchIcon: {
      fontSize: 20,
      color: "#797979",
    },
    list__category: {
      maxHeight: 300,
      overflowY: "auto",
      marginTop: 15,
    },
    list__item: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#fff",
      paddingBottom: 5,
      "&:hover": {
        backgroundColor: "#F5F5F5",
        transition: "backgroundColor 0.3s linear",
      },
    },
    icon__category: {
      width: 35,
      maxHeight: 20,
      marginRight: 8,
    },
    btn_delete: {
      color: "#FD5468",
      fontSize: 30,
    },
    icon_pig: {
      height: 30,
      width: 30,
      marginRight: 8,
      cursor: "pointer",
    },
  }));
  const classes = useStyles();
  const { t } = useTranslation();
  const [valueCategory, setValueCategory] = useState("");
  const list = useAppSelector(selectCommunityCategoryList);
  const [listCategory, setListCategory] = useState<CommnunityCategoryList[]>([]);

  useEffect(() => {
    setListCategory(list);
  }, []);

  const listCheckbox = useAppSelector(setCategoryCheckList); //get check list from store
  let [listChecked, setListChecked] = useState<number[]>(listCheckbox);
  const schema = yup.object().shape({
    name: yup.string().required(`${t("community.category.tenchudekhongduocdetrong")}`),
  });
  const dispatch = useAppDispatch();
  const handleClick = async (valueCategory: string) => {
    try {
      const res: ResponseStatus = await managerCommnunityApi.createCategory(valueCategory);
      if (res.responseCode === "00") {
        toast.success(`${t("community.category.themchudethanhcong")}`);
        dispatch(fetchCommunityCategoryList());
        setValueCategory("");
      } else {
        if (res.responseCode === "04") {
          toast.error(`${t("community.category.chudedatontai")}`);
          throw new Error("Create category is failed");
        }
      }
    } catch (error) { }
  };
  const findIndex = (array: number[], i: number) => {
    var result = -1;
    array.forEach((item, index) => {
      if (item === i) {
        return (result = index);
      }
    });
    return result;
  };
  const handleClickCheckbox = (id: number) => {
    if (listChecked.includes(id)) {
      const index = findIndex(listChecked, id);
      if (index !== -1) {
        const newListChecked = [...listChecked];
        newListChecked.splice(index, 1);
        setListChecked(newListChecked);
        dispatch(setCheckList(newListChecked));
      }
    } else {
      const newListChecked = [...listChecked];
      newListChecked.push(id);
      setListChecked(newListChecked);
      dispatch(setCheckList(newListChecked));
    }
  };
  const handleDelete = async (category_id: number) => {
    try {
      const res: ResponseStatus = await managerCommnunityApi.deleteCategory(category_id);
      if (res.responseCode === "00") {
        toast.success(`${t("community.category.xoachudethanhcong")}`);
        dispatch(fetchCommunityCategoryList());
      } else {
        if (res.responseCode === "03") {
          toast.success(`${t("community.category.xoachudethatbai")}`);
        }
      }
    } catch (error) { }
  };

  const handleSearch = (value: string) => {
    const newListSearch = list.filter((x) => x.name.toUpperCase().includes(value.toUpperCase()));
    setListCategory(newListSearch);
  };

  return (
    <Box className={classes.modal__container}>
      <Box className={classes.modal__header}>
        <IconButton onClick={handleClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="subtitle2">{t("community.chudebaiviet")}</Typography>
        <div></div>
      </Box>

      <Box className={classes.modal__content}>
        <Box display="flex" height="35px">
          <Box width="80%" mr={2}>
            <TextField
              name="name_category"
              placeholder={t("community.category.dientenchude")}
              fullWidth
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setValueCategory(event.target.value)
              }
              className={classes.input}
            />
          </Box>
          <Button className={classes.btn_add} onClick={() => handleClick(valueCategory)}>
            {t("button.addnew")}
          </Button>
        </Box>
        <Box mt={1} height="35px">
          <TextField
            name="title_post"
            placeholder={t("community.nhapnoidungtimkiem")}
            fullWidth
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleSearch(event.target.value)}
            className={classes.input}
          />
        </Box>
        {/* List Category */}
        <Box className={classes.list__category}>
          {listCategory?.length > 0 ? (
            listCategory.map((item, index) => (
              <Box key={item.id} className={classes.list__item}>
                <Box display="flex" alignItems="center">
                  <img
                    src={`/img/img-category/vector_category_${index < 7 ? index : Math.floor(Math.random() * 7)
                      }.png`}
                    alt="VECTOR_CATEGORY"
                    className={classes.icon__category}
                  />
                  <Typography variant="subtitle2">{item.name}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <form style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      style={{ display: "none" }}
                      id={item.name}
                      value={item.id}
                      onClick={() => handleClickCheckbox(item.id)}
                    />
                    <label style={{ display: "flex", alignItems: "center" }} htmlFor={item.name}>
                      {listChecked.includes(item.id) ? (
                        <img src={ICON_PIG_CHECKED} alt="ICON_PIG" className={classes.icon_pig} />
                      ) : (
                        <img src={ICON_PIG} alt="ICON_PIG" className={classes.icon_pig} />
                      )}
                    </label>
                  </form>

                  {showBtnDelete && (
                    <IconButton>
                      <Tooltip title={t("community.category.xoachude") || ""}>
                        <DeleteOutlineIcon
                          className={classes.btn_delete}
                          onClick={() => handleDelete(item.id)}
                        />
                      </Tooltip>
                    </IconButton>
                  )}
                </Box>
              </Box>
            ))
          ) : (
            <p style={{ paddingLeft: "20px" }}>{t("community.category.khongcochude")}</p>
          )}
        </Box>
      </Box>
    </Box>
  );
}
