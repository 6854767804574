import {
  Box,
  Button,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import BACKGROUND from "assets/img/bg_manager_student.png";
import {makeStyles, withStyles} from "@material-ui/styles";
import {Pagination} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import useStyles from "./style";
import {useEffect, useState} from "react";
import {AccessLogList, ListResponseAccessLog} from "models/managerUser";
import managerUserApi from "api/managerUser";
export interface AccessHistoryProps {}
export default function AccessHistory(props: AccessHistoryProps) {
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const {t} = useTranslation();
  const [logAccess, setLogAccess] = useState<any>();
  const [indexPage, setIndexPage] = useState(0);
  const [saveTime, setSaveTime] = useState([]);
  const classes = useStyles({
    background: BACKGROUND,
  });
  const getAccessLog = async (index: any) => {
    try {
      let params = {
        page: index,
        size: 10,
      };
      const res: ListResponseAccessLog<AccessLogList> = await managerUserApi.getAccessLog(params);
      if (res.responseCode === "00") {
        setLogAccess(res);
      }
    } catch (error) {}
  };
  const convertTimestamp = (logAccess: any) => {
    const timeArray: any = [];
    logAccess?.accessLogList?.forEach((item: any) => {
      if (item.accessTimestamp == null) {
        timeArray.push("");
      } else {
        var date = new Date(item.accessTimestamp);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var hour = date.getHours();
        var minutes = date.getMinutes();
        if (hour < 10 && minutes < 10) {
          timeArray.push(`0${hour}:0${minutes} - ${day}/${month}/${year}`);
        } else if (hour < 10 && minutes > 10) {
          timeArray.push(`0${hour}:${minutes} - ${day}/${month}/${year}`);
        } else if (hour > 10 && minutes < 10) {
          timeArray.push(`${hour}:0${minutes} - ${day}/${month}/${year}`);
        } else {
          timeArray.push(`${hour}:${minutes} - ${day}/${month}/${year}`);
        }
      }
    });
    setSaveTime(timeArray);
  };
  const handleChangePage = (page: any) => {
    setIndexPage(page - 1);
  };

  useEffect(() => {
    getAccessLog(indexPage);
  }, [indexPage]);
  useEffect(() => {
    convertTimestamp(logAccess);
  }, [logAccess]);
  return (
    <>
      <Box className={classes.background}>
        <Typography variant="subtitle2"> {t("admin.listitem.lichsutruycap")}</Typography>
      </Box>
      <TableContainer style={{margin: "20px 0"}} className={classes.container} component={Paper}>
        <Table stickyHeader style={{minWidth: "1600px"}}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" width="20px">
                {t("managerStudent.register.stt")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.accesshistory.hoten")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.accesshistory.manhanvien")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.accesshistory.khuvuc")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.accesshistory.bophan")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.accesshistory.phongban")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.accesshistory.trai")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.accesshistory.chucvu")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.accesshistory.truycapgannhat")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerStudent.accesshistory.thietbi")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logAccess?.accessLogList.map((item: any, index: number) => (
              <TableRow key={index}>
                <StyledTableCell align="center" component="th" scope="user">
                  {logAccess.pageInfo.pageSize * logAccess.pageInfo.pageIndex + index + 1}
                </StyledTableCell>
                <StyledTableCell align="center">{item?.user?.name}</StyledTableCell>
                <StyledTableCell align="center">{item?.user?.staffId}</StyledTableCell>
                <StyledTableCell align="center">{item?.user?.area?.name}</StyledTableCell>
                <StyledTableCell align="center">{item?.user?.department?.title}</StyledTableCell>
                <StyledTableCell align="center">{item?.user?.workType?.name}</StyledTableCell>
                <StyledTableCell align="center">{item?.user?.farm?.name}</StyledTableCell>
                <StyledTableCell align="center">{item?.user?.position}</StyledTableCell>
                <StyledTableCell align="center">{saveTime[index]}</StyledTableCell>
                <StyledTableCell align="center">{item?.device}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {logAccess?.pageInfo?.totalPages > 1 ? (
        <Box mt={3} display="flex" justifyContent="space-between">
          <Typography variant="subtitle2" style={{fontWeight: 600}}>
            {t("managerStudent.tongcong")}: {logAccess.pageInfo?.totalElements}
          </Typography>
          <Pagination
            count={Math.ceil(logAccess?.pageInfo?.totalElements / logAccess?.pageInfo?.pageSize)}
            page={logAccess?.pageInfo?.pageIndex + 1}
            variant="outlined"
            color="primary"
            shape="rounded"
            onChange={(e, page) => handleChangePage(page)}
          />
        </Box>
      ) : null}
    </>
  );
}
