import React, {Fragment, useState} from "react";
import {
  Box,
  Button,
  Container,
  createStyles,
  IconButton,
  Modal,
  Slide,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import {Pagination} from "@material-ui/lab";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {
  fetchPrizeList,
  selectFilterPrizeList,
  selectPrizeList,
  setFilterPrizeList,
} from "../../managerPrizeSlice";
import useStyles from "./style";
import {useConfirm} from "material-ui-confirm";
import {Prize} from "models/managerPrize";
import {ResponseStatus} from "models/common";
import managerPrizeApi from "api/managerPrize";
import {toast} from "react-toastify";
import EditPrize from "../EditPrize";
import {useTranslation} from "react-i18next";
export interface TablePrizeProps {}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      padding: "16px 5px",
      // backgroundColor: "#F3F3F3CC",
      color: "#1B1B1B",
      fontSize: 11,
      fontWeight: 700,
      borderRight: "1px solid rgba(224, 224, 224, 1)",
      borderBottom: "1px solid #777777",
      [theme.breakpoints.up("xl")]: {
        fontSize: 13,
      },
    },
    body: {
      padding: "16px 5px",
      fontSize: 11,
      borderRight: "1px solid rgba(224, 224, 224, 0.8)",
      [theme.breakpoints.up("xl")]: {
        fontSize: 13,
      },
    },
  })
)(TableCell);

export default function TablePrize(props: TablePrizeProps) {
  const {t} = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [idPrize, setIdPrize] = useState("");
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const filter = useAppSelector(selectFilterPrizeList);

  const prizeList = useAppSelector(selectPrizeList);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChangePage = (page: number) => {
    dispatch(
      setFilterPrizeList({
        ...filter,
        page: page - 1,
      })
    );
  };

  const handleDeletePrize = (prize: Prize) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {prize.name}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}> {t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_add} style={{marginRight: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    })
      .then(async () => {
        const res: ResponseStatus = await managerPrizeApi.deletePrize(prize.id);
        if (res.responseCode === "00") {
          toast.success(`${t("managerPrize.xoaphanthuongthanhcong")}`);
          dispatch(fetchPrizeList(filter));
        } else {
          if (res.responseCode === "30") {
            toast.error(`${t("managerPrize.xoaphanthuongthatbai")}`);
            throw new Error("Delete prize is failed");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditPrize = (id: string) => {
    setOpenModal(true);
    setIdPrize(id);
  };

  return (
    <Fragment>
      <Box>
        <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" width="70px">
                  {t("stt")}
                </StyledTableCell>
                <StyledTableCell align="center">{t("managerPrize.tenphanthuong")}</StyledTableCell>
                <StyledTableCell align="center">
                  {t("managerPrize.dieukiennhanthuong")}
                </StyledTableCell>
                <StyledTableCell align="center">{t("thaotac")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prizeList.content.map((prize, index) => (
                <TableRow key={prize.id}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {prizeList.size * prizeList.number + index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{prize.name}</StyledTableCell>
                  <StyledTableCell align="center">{prize.condition}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Button className={classes.btn_add} onClick={() => handleEditPrize(prize.id)}>
                      {t("button.edit")}
                    </Button>
                    <Button className={classes.btn_delete} onClick={() => handleDeletePrize(prize)}>
                      {t("button.delete")}
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {prizeList.totalPages > 1 && (
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Pagination
              count={Math.ceil(prizeList.totalElements / prizeList.size)}
              variant="outlined"
              page={prizeList.number}
              color="primary"
              shape="rounded"
              onChange={(e, page) => handleChangePage(page)}
            />
          </Box>
        )}
      </Box>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container}>
            <Box className={classes.modal__header}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="subtitle2">{t("managerPrize.capnhatphanthuong")}</Typography>
              <div></div>
            </Box>

            <EditPrize id={idPrize} setOpenModal={setOpenModal} />
          </Box>
        </Slide>
      </Modal>
    </Fragment>
  );
}
