import {Box, makeStyles, Theme, Typography} from "@material-ui/core";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BG_MANAGER_GROUP from "assets/img/bg_manager_group.png";
import {fetchDataOptions} from "features/Auth/options/optionsSlice";
import {GroupList} from "models/managerGroup";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import AreaGroup from "./components/Area";
import {fetchGroupList, selectGroupList} from "./managerGroupSlice";

export default function ManagerGroup() {
  const {t} = useTranslation();
  const groupList = useAppSelector(selectGroupList);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchGroupList());
    dispatch(fetchDataOptions());
  }, [dispatch]);

  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BG_MANAGER_GROUP})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        top: 50,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box>
      <Box>
        <Box className={classes.background}>
          <Typography variant="subtitle2">{t("managerGroup.danhsachdanhmuc")}</Typography>
        </Box>
        {groupList?.map((area: GroupList) => (
          <Box key={area.areaId}>
            <AreaGroup area={area} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
