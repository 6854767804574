import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, {InputHTMLAttributes, useEffect} from "react";
import {Control, useController} from "react-hook-form";

export interface CheckboxFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: any;
  label: string;
  control: Control<any>;
  handleChange?: (e: any) => void;
  disabled?: boolean;
}

export default function CheckboxField({
  name,
  label,
  control,
  handleChange,
  disabled,
  ...inputProps
}: CheckboxFieldProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    checkbox: {
      color: "#DADADA",
      borderRadius: 5,
    },
  }));
  const {
    field: {value, onChange, onBlur, ref},
    fieldState: {invalid, error},
  } = useController({
    name,
    control,
  });
  useEffect(() => {
    if (handleChange) {
      handleChange(value);
    }
  }, [value]);
  const classes = useStyles();
  return (
    <FormControl variant="outlined" margin="dense" size="small" error={invalid}>
      <FormControlLabel
        control={
          <Checkbox
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            className={classes.checkbox}
            color="primary"
            value={value}
            checked={value}
            inputRef={ref}
            disabled={disabled}
            inputProps={inputProps}
          />
        }
        label={label}
      />
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}
