import {Box, Grow, Typography} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLessIcon from "@material-ui/icons/ExpandMore";
import BORDER_BOX from "assets/img/borderBox.png";
import {GroupList, ListWorkType} from "models/managerGroup";
import React, {Fragment, useState} from "react";
import {TreeNode} from "react-organizational-chart";
import useStyles from "../style";
import WorkTypes from "./WorkTypes";

export interface AreaProps {
  area: GroupList;
  setValueMap: (x: any) => void;
}

export default function Area({area, setValueMap}: AreaProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpenDropdown = () => {
    setOpen(!open);
  };
  return (
    <TreeNode
      label={
        <Box className={classes.area}>
          <img src={BORDER_BOX} alt="BORDER_BOX" />
          <Typography
            variant="subtitle2"
            style={{
              color:
                area.areaName === "Miền Bắc"
                  ? "#B80F0F"
                  : area.areaName === "Miền Nam"
                  ? "#123664"
                  : "#EBA337",
            }}
            onClick={() => setValueMap(area.areaName)}
          >
            {area.areaName}
            {open ? (
              <ExpandLessIcon onClick={handleOpenDropdown} />
            ) : (
              <ChevronRightIcon onClick={handleOpenDropdown} />
            )}
          </Typography>
        </Box>
      }
    >
      {open ? (
        <Grow in={open}>
          <div style={{display: "inherit"}}>
            {area.listWorkType.map((listDepartment: ListWorkType) => (
              <Fragment key={listDepartment.workTypeId}>
                <WorkTypes listDepartment={listDepartment} setValueMap={setValueMap} area={area} />
              </Fragment>
            ))}
          </div>
        </Grow>
      ) : null}
    </TreeNode>
  );
}
