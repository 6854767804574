import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  form__field: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& >img": {
      marginRight: 8,
      width: 40,
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: 30,
      },
    },
    "&> span": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 11,
      },
    },
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: "50%",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: 35,
      height: 35,
    },
  },
  form: {
    padding: "10px 25px",
    borderRadius: 8,
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  form__wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F3F3F3",
    borderRadius: 30,
    marginLeft: 20,
    cursor: "pointer",
    zIndex: 99,
    width: "100%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
    },
    "& >h6": {
      width: "100%",
      color: "#AAAAAA",
      padding: "15px",
      fontSize: 14,
      [theme.breakpoints.down("xs")]: {
        padding: "8px",
        fontSize: 12,
      },
    },
    "&> button": {
      position: "absolute",
      right: 10,
    },
  },

  form__action: {
    display: "flex",
    justifyContent: "space-between",
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
