import {yupResolver} from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {makeStyles} from "@material-ui/styles";
import managerLessonApi from "api/managerLesson";
import {useAppSelector} from "app/hooks";
import EMOJI from "assets/img/emoji.png";
import LOGO from "assets/img/logo.png";
import InputField from "components/FormFields/InputField";
import Picker, {SKIN_TONE_MEDIUM_DARK} from "emoji-picker-react";
import {selectInfoLogin} from "features/Auth/authSlice";
import RepComment from "features/Home/pages/DetailLesson/components/RepComment";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {Comment, ListResponseComments} from "models/managerLesson";
import React, {ChangeEvent, Fragment, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {dateTimeToFormatYYYYHHMM} from "utils/dateTimeFormat";
import * as yup from "yup";
import NO_AVATAR from "assets/img/no_avatar.png";

export interface ListCommentProps {
  userComment: Comment;
  handleFetchListComment: () => void;
}

export default function ListComment({userComment, handleFetchListComment}: ListCommentProps) {
  const [comment, setComment] = useState("");
  const [openReply, setOpenReply] = useState(false);
  const infoLogin = useAppSelector(selectInfoLogin);
  const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
      width: 50,
      height: 50,
      borderRadius: "50%",
      objectFit: "cover",
      marginRight: 15,
      [theme.breakpoints.down("md")]: {
        width: 40,
        height: 40,
      },
      [theme.breakpoints.down("xs")]: {
        width: 30,
        height: 30,
      },
    },

    username: {
      fontWeight: 600,
      fontSize: 15,
      [theme.breakpoints.down("md")]: {
        fontSize: 13,
      },

      "&>span": {
        fontSize: 13,
        marginLeft: 10,
        color: "#A8A8A8",
        [theme.breakpoints.down("md")]: {
          fontSize: 11,
        },
      },
    },
    comments__action: {
      display: infoLogin.role === "ADMIN" || infoLogin.role === "SUPER_ADMIN" ? "block" : "none",
      marginTop: -10,
    },
    btn_delete: {
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
    },
    btn_edit: {
      background: "linear-gradient(360deg, #019ABB 0%, rgba(167, 255, 255, 0.9) 168.18%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
      marginRight: 10,
    },
    icon_emoji: {
      position: "absolute",
      bottom: 7,
      right: 10,
      "& >span >img": {
        width: 25,
        height: 25,
      },
    },
  }));

  const classes = useStyles();
  const [showMenuEdit, setShowMenuEdit] = useState<null | HTMLElement>(null);
  const confirm = useConfirm();
  const {t} = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  const [openEmoji, setOpenEmoji] = useState<null | HTMLElement>(null);
  const [listComment, setListComment] = useState<any>([]);
  const [openShowComments, setOpenShowComments] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({
    comment: userComment?.comment,
  });

  const schema = yup.object().shape({
    comment: yup.string().required(`${t("home.detaillesson.vuilongnhapbinhluan")}`),
  });

  const handleDelete = async (id: number) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.xoabinhluan")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">{t("delete.bancochacchanmuonxoabinhluankhong")}</Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}> {t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_edit} style={{marginRight: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    })
      .then(async () => {
        try {
          const res: ResponseStatus = await managerLessonApi.deleteComment(id);
          if (res.responseCode === "00") {
            toast.success(`${t("delete.xoabinhluanthanhcong")}`);
            setShowMenuEdit(null);
            handleFetchListComment();
          } else {
            toast.error(`${t("delete.xoabinhluanthatbai")}`);
            setShowMenuEdit(null);
          }
        } catch (error) {}
      })
      .catch((error) => console.log(error));
  };
  const handleEdit = () => {
    setOpenEdit(true);
  };
  useEffect(() => {
    setInitialValues({
      comment: userComment?.comment,
    });
  }, [userComment]);

  const {control, handleSubmit, setValue, getValues} = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
    resolver: yupResolver(schema),
  });
  const handleOpenMenuEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowMenuEdit(event.currentTarget);
    setValue("comment", initialValues.comment);
  };
  const handleOpenEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmoji(event.currentTarget);
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    setValue("comment", getValues().comment + emojiObject.emoji);
    setComment(emojiObject.emoji);
  };
  const handleSubmitEditComment = async (value: any) => {
    const valueComment = {
      content: value.comment,
      comment_id: userComment.id,
    };
    try {
      const res: ResponseStatus = await managerLessonApi.updateComment(valueComment);
      if (res.responseCode === "00") {
        setOpenEdit(false);
        setShowMenuEdit(null);
        handleFetchListComment();
        toast.success(`${t("home.detaillesson.chinhsuabinhluanthanhcong")}`);
      } else {
        toast.error(`${t("home.detaillesson.chinhsuabinhluanthatbai")}`);
        throw new Error("Comment is failed");
      }
    } catch (error) {}
  };
  const handleGetChildComment = async () => {
    setOpenShowComments(true);
    try {
      const res: ListResponseComments<Comment> = await managerLessonApi.getComments({
        page: 0,
        size: 999,
        lessionId: userComment.lessionId,
        commentId: userComment.id,
        depth: 1,
      });
      setListComment(res.comments.content[0].childCommentList.content);
    } catch (error) {}
  };
  return (
    <Fragment>
      <Box mt={4} display="flex" alignItems="flex-start">
        <img
          src={userComment?.user?.profileImageUrl || LOGO}
          alt="avatar"
          className={classes.avatar}
        />
        {openEdit ? (
          <form
            onSubmit={handleSubmit(handleSubmitEditComment)}
            style={{display: "flex", alignItems: "center"}}
          >
            <Box style={{minWidth: 500}}>
              <InputField
                name="comment"
                control={control}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setComment(event?.target.value)}
              />
              <IconButton onClick={handleOpenEmoji} className={classes.icon_emoji}>
                <img src={EMOJI} alt="EMOJI" />
              </IconButton>
              {openEmoji && (
                <Menu
                  id="simple-menu"
                  anchorEl={openEmoji}
                  keepMounted
                  open={Boolean(openEmoji)}
                  onClose={() => setOpenEmoji(null)}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Picker
                    onEmojiClick={onEmojiClick}
                    disableAutoFocus={true}
                    skinTone={SKIN_TONE_MEDIUM_DARK}
                    groupNames={{smileys_people: "PEOPLE"}}
                    native
                  />
                </Menu>
              )}{" "}
            </Box>
            <Box ml={2}>
              <Button className={classes.btn_edit} type="submit">
                {t("button.edit")}
              </Button>
              <Button
                className={classes.btn_delete}
                onClick={() => {
                  setShowMenuEdit(null);
                  setOpenEdit(false);
                }}
              >
                {t("button.cancel")}
              </Button>
            </Box>
          </form>
        ) : (
          <Box>
            <Typography variant="subtitle2" className={classes.username}>
              {userComment?.user?.name}
              <Typography variant="subtitle2" component="span">
                {dateTimeToFormatYYYYHHMM(userComment?.createdTime)}
              </Typography>
            </Typography>
            <Typography variant="subtitle2">{userComment?.comment}</Typography>

            <Typography
              variant="subtitle2"
              onClick={() => {
                setOpenReply(!openReply);
              }}
              style={{marginTop: "10px", cursor: "pointer", color: "#949499"}}
            >
              {t("btn.phanhoi")}
            </Typography>
            {userComment.childCommentList?.content?.length > 0 && !openShowComments && (
              <span
                onClick={() => {
                  setOpenShowComments(true);
                  handleGetChildComment();
                }}
                style={{fontSize: 12, cursor: "pointer"}}
              >
                <i className="fas fa-long-arrow-right"></i>{" "}
                {userComment.childCommentList?.totalElements} {t("community.phanhoi").toLowerCase()}
              </span>
            )}
            {openShowComments &&
              listComment.map((x: any) => (
                <Box mt={4} display="flex" alignItems="flex-start" key={x.id}>
                  <Avatar
                    src={x?.user?.profileImageUrl || NO_AVATAR}
                    alt="avatar"
                    className={classes.avatar}
                  />
                  <Box>
                    <Typography variant="subtitle2" className={classes.username}>
                      {x?.user?.name}
                      <Typography variant="subtitle2" component="span">
                        {dateTimeToFormatYYYYHHMM(x?.createdTime)}
                      </Typography>
                    </Typography>
                    <Typography variant="subtitle2">{x?.comment}</Typography>
                  </Box>
                </Box>
              ))}
            <Menu
              id="simple-menu"
              anchorEl={showMenuEdit}
              keepMounted
              open={Boolean(showMenuEdit)}
              onClose={() => setShowMenuEdit(null)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {userComment.user.id === infoLogin.id && (
                <MenuItem onClick={handleEdit}>
                  <span>{t("btn.chinhsua")}</span>
                </MenuItem>
              )}
              <MenuItem onClick={() => handleDelete(userComment.id)}>
                <span>{t("btn.xoabinhluan")}</span>
              </MenuItem>
            </Menu>
          </Box>
        )}
        <IconButton className={classes.comments__action} onClick={handleOpenMenuEdit}>
          <MoreHorizIcon />
        </IconButton>
      </Box>
      {openReply && (
        <RepComment userComment={userComment} handleGetChildComment={handleGetChildComment} />
      )}
    </Fragment>
  );
}
