import {
  LoginSuccess,
  InfoUser,
  UserLogin,
  UserRegister,
  ChangePasswordProps,
  StatisticHomePage,
  AcademicRecordProps,
  ResponseAcademicRecord,
} from "models/auth";
import {ListParams, ResponseStatus} from "models/common";
import axiosClient from "./axiosClient";

const authApi = {
  login(infoUser: UserLogin): Promise<LoginSuccess> {
    const url = "/login";
    return axiosClient.post(url, infoUser);
  },
  register(infoUser: UserRegister): Promise<ResponseStatus> {
    const url = "/signup";
    return axiosClient.post(url, infoUser);
  },
  changePassword(currentPassword: ChangePasswordProps): Promise<ResponseStatus> {
    const url = "/change-password";
    return axiosClient.post(url, currentPassword);
  },
  forgotPassword(value: any): Promise<ResponseStatus> {
    const url = "/forgot-password";
    return axiosClient.post(url, value);
  },

  getRoles() {
    const url = "/roles";
    return axiosClient.get(url);
  },

  getInfo(): Promise<InfoUser> {
    const url = "/me";
    return axiosClient.get(url);
  },
  updateInfo(newInfo: UserRegister, id: number | undefined): Promise<InfoUser> {
    const url = `/user/update/${id}`;
    return axiosClient.post(url, newInfo);
  },

  updateImage(image: FormData): Promise<ResponseStatus> {
    const url = "/user/updateProfileImage";
    return axiosClient.put(url, image);
  },

  getStatisticHomePage(): Promise<StatisticHomePage> {
    const url = "/common/getHomePageData";
    return axiosClient.get(url);
  },
  getAcademicRecord(params: ListParams): Promise<ResponseAcademicRecord<AcademicRecordProps>> {
    const url = "/exam/overview";
    return axiosClient.get(url, {params});
  },

  getPosition() {
    const url = "/position/";
    return axiosClient.get(url);
  },
  getLevel() {
    const url = "/level/";
    return axiosClient.get(url);
  },
};

export default authApi;
