import managerGroupApi from "api/managerGroupApi";
import {Department, ListOptions, ListResponseOptions} from "models/managerGroup";
import {all, call, put, takeLatest} from "redux-saga/effects";
import {
  fetchDataOptions,
  fetchListAreas,
  fetchListDepartment,
  fetchListWorkTypes,
} from "./optionsSlice";

function* handleFetchListAreas() {
  try {
    const res: ListResponseOptions<ListOptions> = yield call(managerGroupApi.getArea);
    if (res.responseCode === "00") {
      yield put(fetchListAreas(res));
    } else {
      throw new Error("Failed to fetch area list");
    }
  } catch (error) {}
}

// function* handleFetchListWorkTypes() {
//   try {
//     const res: ListResponseOptions<ListOptions> = yield call(managerGroupApi.getWorkTypes);
//     if (res.responseCode === "00") {
//       yield put(fetchListWorkTypes(res));
//     }
//   } catch (error) {}
// }

function* handleFetchListDepartment() {
  try {
    const res: ListResponseOptions<Department> = yield call(managerGroupApi.getDepartments);
    if (res.responseCode === "00") {
      yield put(fetchListDepartment(res));
    }
  } catch (error) {}
}
function* handleFetchData() {
  yield all([
    call(handleFetchListAreas),
    // call(handleFetchListWorkTypes),
    call(handleFetchListDepartment),
  ]);
}

export default function* optionsSaga() {
  yield takeLatest(fetchDataOptions, handleFetchData);
}
