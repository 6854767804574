import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slide,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import managerGroupApi from "api/managerGroupApi";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {fetchDataOptions, selectListWorkTypes} from "features/Auth/options/optionsSlice";
import {ResponseStatus} from "models/common";
import {GroupList, ListOptions, ListWorkType} from "models/managerGroup";
import React, {Fragment, useEffect, useState} from "react";
import {useController, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {fetchGroupList} from "../managerGroupSlice";
import DepartmentGroup from "./Department";
import useStyles from "./style";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";

export interface AreaGroupProps {
  area: GroupList;
}

export default function AreaGroup({area}: AreaGroupProps) {
  const {t} = useTranslation();
  const schema = yup.object().shape({
    workTypeId: yup.string().required(`${t("managerGroup.vuilongchonbophan")}`),
  });
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyles();
  const workTypeList = useAppSelector(selectListWorkTypes);
  const dispatch = useAppDispatch();

  const initialValue = {
    workTypeId: "",
  };
  const {control, handleSubmit} = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(schema),
  });
  const {
    field: {value, onChange, onBlur},
    fieldState: {invalid, error},
  } = useController({
    name: "workTypeId",
    control,
  });
  const handleOpenDropdown = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSubmitForm = async (value: any) => {
    try {
      const res: ResponseStatus = await managerGroupApi.addWorkType({
        ...value,
        areaId: area.areaId,
      });
      if (res.responseCode === "00") {
        toast.success(`${t("managerGroup.thembophanthanhcong")}`);
        setOpenModal(false);
        dispatch(fetchGroupList());
      }
    } catch (error) {}
  };

  const listWorkTypeNotAdd = workTypeList.filter((worktype) =>
    area.listWorkType.every((x) => !x?.workTypeName?.includes(worktype.name))
  );

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5" onClick={handleOpenDropdown} className={classes.title}>
          {open ? <ExpandMore /> : <NavigateNextIcon className={classes.btn_next} />}
          <Typography variant="h5" component="span">
            {" "}
            {area?.areaName}
          </Typography>
        </Typography>

        <Box className={classes.content__right}>
          <Button className={classes.btn_add} onClick={() => setOpenModal(true)}>
            {t("them")}
          </Button>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {area?.listWorkType?.map((item: ListWorkType) => (
          <Fragment key={item.workTypeId}>
            <DepartmentGroup department={item} key={item.workTypeId} area={area} />
          </Fragment>
        ))}
      </Collapse>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container}>
            <Box className={classes.modal__header}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="subtitle2">{area?.areaName}</Typography>
              <div></div>
            </Box>

            <Box className={classes.form}>
              {listWorkTypeNotAdd.length > 0 ? (
                <>
                  <Typography variant="h5">{t("button.addnew").toUpperCase()}</Typography>

                  <form onSubmit={handleSubmit(handleSubmitForm)}>
                    <FormControl fullWidth variant="outlined" size="small" error={invalid}>
                      <InputLabel id="workType">{t("managerGroup.danhsachbophan")}</InputLabel>
                      <Select
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        labelId="workType"
                        variant="outlined"
                        label={t("managerGroup.danhsachbophan")}
                        inputProps={{
                          name: "workTypeId",
                          id: "workType",
                        }}
                      >
                        {listWorkTypeNotAdd?.map((option: ListOptions) => (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>

                    <Box textAlign="center" style={{padding: "0 24px"}}>
                      <Button className={classes.form__button} type="submit">
                        {t("button.accept")}
                      </Button>
                    </Box>
                  </form>
                </>
              ) : (
                <Typography variant="subtitle2">{t("managerGroup.chuacobophanmoi")}</Typography>
              )}
            </Box>
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
}
