import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {Pagination} from "@material-ui/lab";
import managerTestApi from "api/managerTest";
import BACKGROUND from "assets/img/bg_list_test.png";
import axios, {AxiosResponse} from "axios";
import {BASE_URL, TOKEN} from "constants/config";
import {DetailUserSkip} from "models/managerLesson";
import {ListExam, ResponseListSkipExam} from "models/managerTest";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import FileDownload from "js-file-download";
import {dateTimeToFormatHHMM, dateTimeToFormatYYYY} from "utils/dateTimeFormat";

export interface ListSkipProps {}

export default function ListSkip(props: ListSkipProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        bottom: 30,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
      },
    },

    btn_goBack: {
      position: "absolute",
      top: -15,
      left: -20,
      zIndex: 99,
    },

    container: {
      maxHeight: 500,
    },
    btn_export: {
      position: "absolute",
      right: 0,
      bottom: 0,
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "10px 8px",
      marginBottom: 10,
    },
    header: {
      backgroundColor: "#fff",
      width: "100%",
      padding: "15px 20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: "6px 5px 0px 0px",
      "& > h6": {
        fontWeight: 600,
        fontSize: 15,
        [theme.breakpoints.up("xl")]: {
          fontSize: 18,
        },
      },
      "& > p": {
        color: "#DC2138",
        fontSize: 15,
        [theme.breakpoints.up("xl")]: {
          fontSize: 18,
        },
        "& >span": {
          color: "#1B1B1B",
          fontSize: 15,
          marginLeft: 15,
          [theme.breakpoints.up("xl")]: {
            fontSize: 18,
          },
        },
      },
    },
  }));
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        zIndex: 99,
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",

        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const {id} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [detailExam, setDetailExam] = useState<ListExam>();
  const [listSkip, setListSkip] = useState<ResponseListSkipExam<DetailUserSkip>>();
  const [filter, setFilter] = useState({
    page_index: 0,
    page_size: 10,
    exam_id: id,
  });

  useEffect(() => {
    (async () => {
      const res: ListExam = await managerTestApi.getDetailExam(Number(id));
      setDetailExam(res);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const res: ResponseListSkipExam<DetailUserSkip> = await managerTestApi.getListSkipExam(
          filter
        );
        setListSkip(res);
      } catch (error) {}
    })();
  }, [filter, id]);

  const handleChangePage = (page: number) => {
    setFilter({
      ...filter,
      page_index: page - 1,
    });
  };
  const handleExport = async () => {
    const token = localStorage.getItem(TOKEN);
    try {
      const res: AxiosResponse = await axios({
        method: "GET",
        url: `${BASE_URL}/statistic/statisticDropExam/export/excel?exam_id=${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "blob",
        },
        responseType: "arraybuffer",
      });
      if (res.data.responseDescription) {
        toast.error(t("file.xuatthatbai"));
        return;
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileDownload(blob, `${t("managerTest.danhsachbothi")}-${id}.xlsx`);
      toast.success(t("file.xuatthanhcong"));
    } catch (error) {}
  };
  return (
    <Box position="relative">
      <Box className={classes.btn_goBack}>
        <Tooltip title={t("button.goback") || ""}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{marginLeft: 7}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className={classes.background}>
        <Typography variant="subtitle2">{t("managerTest.danhsachbothi")}</Typography>
        <Button className={classes.btn_export} onClick={handleExport}>
          {t("button.xuattatca")}
        </Button>
      </Box>
      <Box className={classes.header}>
        <Typography variant="subtitle2">{detailExam?.exam?.examTitle}</Typography>
        <Typography variant="body2">
          {dateTimeToFormatHHMM(detailExam?.exam?.startDate)}
          <Typography variant="body2" component="span">
            {dateTimeToFormatYYYY(detailExam?.exam?.startDate)}
          </Typography>
        </Typography>

        <Typography variant="subtitle2">
          {detailExam?.exam?.type === "TYPING"
            ? `${t("managerTest.tuluan")}`
            : `${t("managerTest.tracnghiem")}`}
        </Typography>
      </Box>
      {listSkip?.dropExamList && listSkip?.dropExamList?.length > 0 ? (
        <Box>
          <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" width="70px">
                    {t("stt")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="160px">
                    {t("auth.fullName")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="100px">
                    {t("auth.code")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="120px">
                    {t("auth.email")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="100px">
                    {t("auth.workType")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="100px">
                    {t("auth.department")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="150px">
                    {t("auth.area")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="150px">
                    {t("auth.province")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listSkip?.dropExamList?.map((item: DetailUserSkip, index: number) => (
                  <TableRow key={item.id}>
                    <StyledTableCell align="center" component="th" scope="exam">
                      {listSkip.pageInfo.pageSize * listSkip.pageInfo.pageIndex + index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{item.name}</StyledTableCell>
                    <StyledTableCell align="center">{item.staffID}</StyledTableCell>
                    <StyledTableCell align="center">{item.email}</StyledTableCell>
                    <StyledTableCell align="center">{item?.workType?.name}</StyledTableCell>
                    <StyledTableCell align="center">{item?.department?.title}</StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{
                        color:
                          item?.area?.name === "Miền Bắc"
                            ? "#137BBD"
                            : item?.area?.name === "Miền Nam"
                            ? "#DC2138"
                            : "#E58A25",
                      }}
                    >
                      {item?.area?.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{item?.province?.name}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {listSkip && listSkip.pageInfo && listSkip.pageInfo.totalPages > 1 && (
            <Box mt={3} display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" style={{fontWeight: 600}}>
                {t("managerTest.tongcong")}: {listSkip.pageInfo.totalElements}
              </Typography>
              <Pagination
                count={Math.ceil(listSkip.pageInfo.totalElements / listSkip.pageInfo.pageSize)}
                page={listSkip?.pageInfo.pageIndex + 1}
                variant="outlined"
                color="primary"
                shape="rounded"
                onChange={(e, page) => handleChangePage(page)}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Typography variant="subtitle2">Không có dữ liệu</Typography>
      )}
    </Box>
  );
}
