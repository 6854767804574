import {Grid, IconButton, Theme, Typography} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FullScreen from "@material-ui/icons/Fullscreen";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Replay10Icon from "@material-ui/icons/Replay10";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMuteIcon from "@material-ui/icons/VolumeMute";
import VolumeUp from "@material-ui/icons/VolumeUp";
import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {controlFormat} from "utils/controlFormat";
import useStyles from "./style";

export interface ControlVideoProps {
  playerRef: any;
  handleToggleScreenfull: (e: any) => void;
  setControl: (e: any) => void;
  control: any;
  currentTime: number;
  duration: number;
  isWatched: boolean;
}

const ControlVideo = forwardRef(
  (
    {
      playerRef,
      handleToggleScreenfull,
      setControl,
      control,
      currentTime,
      duration,
      isWatched,
    }: ControlVideoProps,
    ref: any
  ) => {
    const classes = useStyles();
    const sliderRef = useRef<any>(null);

    const [currentTimeClick, setCurrentTimeClick] = useState(0);

    const PrettoSlider = withStyles((theme: Theme) => ({
      root: {
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
          boxShadow: "inherit",
        },
        [theme.breakpoints.down("xs")]: {
          height: 20,
          width: 20,
        },
      },
      active: {},
      valueLabel: {
        left: "calc(-50% + 4px)",
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    }))(Slider);

    useEffect(() => {
      if (playerRef.current === null) return;
      if (isWatched && currentTimeClick && playerRef.current) {
        playerRef.current?.seekTo(currentTimeClick);
      } else {
        if (currentTimeClick < playerRef.current.getCurrentTime()) {
          playerRef.current.seekTo(currentTimeClick);
        } else {
          playerRef.current.seekTo(playerRef.current.getCurrentTime());
        }
      }
    }, [currentTimeClick, playerRef.current]);

    function ValueLabelComponent(props: any) {
      const {children, open, value} = props;

      return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
          {children}
        </Tooltip>
      );
    }

    const handleRewind = () => {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    };

    const calculatePercent = React.useCallback((offset: any) => {
      if (!sliderRef.current) return 0;

      const {width, left} = sliderRef.current?.getBoundingClientRect();
      const percent = (offset - left) / width;
      const newPercent = percent <= 0 || isNaN(percent) ? 0 : percent * 100;
      return newPercent > 100 ? 100 : newPercent;
    }, []);

    const handlePercentChange = useCallback(
      (percent: any) => {
        if (!playerRef.current?.getDuration()) return;

        const newTime = (percent / 100) * playerRef.current.getDuration();
        if (playerRef.current.paused) {
          playerRef.current.play();
        }

        setCurrentTimeClick(newTime);
      },
      [playerRef.current?.duration]
    );

    const handleSeek = useCallback((offset: any) => {
      const percent = calculatePercent(offset);
      handlePercentChange(percent);
    }, []);
    const handleMouseup = useCallback(
      (e: any) => {
        if (playerRef.current.getCurrentTime() < 1) {
          return;
        }
        handleSeek(e.clientX);
      },
      [handleSeek]
    );

    return (
      <div className={classes.wrapper} ref={ref}>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{position: "relative", flexGrow: 1}}
        >
          <div
            onClick={() =>
              setControl({...control, playing: !control.playing, start: !control.start})
            }
            style={{flex: 1}}
          ></div>
          {/* <Grid container direction="row" alignItems="center" justify="center">
            <IconButton onClick={handleRewind} className={classes.controlIcons} aria-label="rewind">
              <Replay10Icon className={classes.controlIcons} fontSize="inherit" />
            </IconButton>
            <IconButton
              onClick={() => setPlaying(!playing)}
              className={classes.controlIcons}
              aria-label="play"
            >
              {playing ? (
                <PauseIcon fontSize="inherit" className={classes.icon_play} />
              ) : (
                <PlayArrowIcon className={classes.icon_play} />
              )}
            </IconButton>
          </Grid> */}
          {/* bottom controls */}
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.wrapper__control}
          >
            <Grid item xs={12} style={{padding: "0px 4px"}}>
              <PrettoSlider
                min={0}
                max={100}
                ValueLabelComponent={(props) => (
                  <ValueLabelComponent {...props} value={controlFormat(currentTime)} />
                )}
                aria-label="custom thumb label"
                value={control.played * 100}
                onChange={handleMouseup}
                ref={sliderRef}
              />
            </Grid>

            <Grid item>
              <Grid container alignItems="center">
                <IconButton
                  onClick={() =>
                    setControl({...control, playing: !control.playing, start: !control.start})
                  }
                  className={classes.iconButton}
                  disableRipple
                >
                  {control.playing ? (
                    <PauseIcon fontSize="large" />
                  ) : (
                    <PlayArrowIcon fontSize="large" />
                  )}
                </IconButton>
                <IconButton
                  disableRipple
                  onClick={handleRewind}
                  aria-label="rewind"
                  className={classes.iconButton}
                >
                  <Replay10Icon fontSize="large" />
                </IconButton>

                <IconButton
                  disableRipple
                  onClick={() => setControl({...control, muted: !control.muted})}
                  className={classes.iconButton}
                >
                  {control.muted ? (
                    <VolumeMuteIcon fontSize="large" />
                  ) : control.volume > 0.5 ? (
                    <VolumeUp fontSize="large" />
                  ) : (
                    <VolumeDown fontSize="large" />
                  )}
                </IconButton>

                <Slider
                  min={0}
                  max={100}
                  value={control.muted ? 0 : control.volume * 100}
                  onChange={(e, newValue: any) =>
                    setControl({
                      ...control,
                      volume: newValue / 100,
                      muted: newValue === 0 ? true : false,
                    })
                  }
                  aria-labelledby="input-slider"
                  style={{width: 100}}
                />

                <Typography variant="body1" style={{color: "#fff", marginLeft: 16}}>
                  {controlFormat(currentTime)}/{controlFormat(duration)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              {/* <Button
                onClick={(event: any) => setAnchorEl(event.currentTarget)}
                style={{color: "#fff",zIndex:9}}
                variant="text"
              >
                <Typography>{control.playbackRate}X</Typography>
              </Button>

              <Popover
                container={ref.current}
                open={Boolean(anchorEl)}
                id={Boolean(anchorEl) ? "simple-popover" : undefined}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Grid container direction="column-reverse">
                  {[0.5, 1, 1.5, 2].map((rate) => (
                    <Button
                      key={rate}
                      onClick={() => {
                        setControl({...control, playbackRate: rate});
                        setAnchorEl(null);
                      }}
                      variant="text"
                    >
                      <Typography color={rate === control.playbackRate ? "secondary" : "inherit"}>
                        {rate}X
                      </Typography>
                    </Button>
                  ))}
                </Grid>
              </Popover> */}
              <IconButton
                onClick={handleToggleScreenfull}
                className={classes.iconButton}
                disableRipple
              >
                <FullScreen fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
);

export default ControlVideo;
