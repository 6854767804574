import managerChartApi from "api/managerChart";
import {ResponseExamResultFarmByProvince, farmList, FarmList} from "models/managerChart";
import {useAppDispatch, useAppSelector} from "app/hooks";
import useStyles from "./style";
import {Column} from "@ant-design/plots";
import {useState, useEffect} from "react";
import {Box, Card, FormControl, Select, Typography, Grid, MenuItem} from "@material-ui/core";
import {Area} from "@ant-design/plots";
import {fetchProvincesForStatistic, selectProvincesForStatistic} from "../../managerChartSlice";
import {ProvinceForStatistic} from "models/managerChart";
import {useTranslation} from "react-i18next";
export interface ChartExamResultFarmByProvinceProps {}
export default function ChartExamResultFarmByProvince(props: ChartExamResultFarmByProvinceProps) {
  const classes = useStyles();
  const {t} = useTranslation();

  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [provinceId, setProvinceId] = useState<number>();
  const [currentProvinceName, setCurrentProvinceName] = useState<any>();
  const [dataChartFarm, setDataChartFarm] = useState<any>();
  const [farmList, setFarmList] = useState<any>();
  const [compareFarm, setCompareFarm] = useState<any>([]);
  const dispatch = useAppDispatch();
  const listProvincesForStatistic = useAppSelector(selectProvincesForStatistic);
  const handleChangeProvince = (e: any) => {
    setProvinceId(e.target.value);
  };
  const handleChangeYear = (e: any) => {
    setCurrentYear(e.target.value);
  };
  const handleChangeCompareFarm = (e: any) => {
    const target = e.target;
    const value = target.value;
    setCompareFarm(typeof value === "string" ? value.split(",") : value);
  };
  useEffect(() => {
    (async () => {
      try {
        let list = "";
        compareFarm.forEach((item: any, index: number) => {
          if (compareFarm.length === index + 1) {
            list = list + item;
          } else {
            list = list + item + ",";
          }
        });
        const res: ResponseExamResultFarmByProvince<farmList> =
          await managerChartApi.getStatisticExamResultFarmByProvince({
            year: currentYear,
            province_id: provinceId,
            farm_id_list: list,
          });
        if (res.responseCode === "00") {
          setCurrentProvinceName(res.province.name);
          setDataChartFarm(res.farmList);
        }
      } catch (error) {}
    })();
  }, [compareFarm]);

  useEffect(() => {
    (async () => {
      try {
        const res: ResponseExamResultFarmByProvince<farmList> =
          await managerChartApi.getStatisticExamResultFarmByProvince({
            year: currentYear,
            province_id: provinceId,
          });
        if (res.responseCode === "00") {
          setCurrentProvinceName(res.province.name);
          setFarmList(res.farmList);
          setDataChartFarm(res.farmList);
        }
      } catch (error) {}
    })();
  }, [currentYear, provinceId]);
  const dataChart: any = [];
  dataChartFarm?.forEach((item: farmList) => {
    const Excellent = {
      type: `${t("chart.thukhoa")}`,
      value: item.excellent,
      name: item.name,
      total: item.total,
    };
    const Good = {
      type: `${t("chart.gioi")}`,
      value: item.good,
      name: item.name,
      total: item.total,
    };
    const belowGood = {
      type: `${t("chart.kha")}`,
      value: item.below_good,
      name: item.name,
      total: item.total,
    };
    const Weak = {
      type: `${t("chart.yeu")}`,
      value: item.weak,
      name: item.name,
      total: item.total,
    };
    const Average = {
      type: `${t("chart.trungbinh")}`,
      value: item.average,
      name: item.name,
      total: item.total,
  
    };
    const below_Average = {
      type: `${t("chart.kem")}`,
      value: item.below_average,
      name: item.name,
      total: item.total,
    };
    dataChart.push(Excellent);
    dataChart.push(Good);
    dataChart.push(belowGood);
    dataChart.push(Average);
    dataChart.push(below_Average);
    dataChart.push(Weak);
  });
  const config: any = (dataChart: any[], annotation: any) => ({
    data: dataChart,
    isStack: true,
    maxColumnWidth:130,
    xField: "name",
    yField: "value",
    seriesField: "type",
    width: '400px',
    color: ["#04DC9B", "#123664", "#EBA337", "#B80F0F", "#AFAFAF", "#DDDDDD"],
    state: {
      active: {
        animate: {duration: 100, easing: "easeLinear"},
        style: {
          lineWidth: 2,
          stroke: "#000",
          
        },
      },
    },
    padding: 50,
    label: {
      formatter: (data: any) => {
        if (data.value === 0) {
          return;
        }
        var total;
        return Number.isInteger((data.value / data.total) * 100)
          ? `${(data.value / data.total) * 100}%`
          : `${((data.value / data.total) * 100).toFixed(1)}%`;
      },
      position: "middle",
      style: {
        fill: "#fff",
      },
      layout: [
        {
          type: "interval-adjust-position",
        },
      ],
    },
    interactions: [
      {
        type: "element-highlight-by-color",
      },
      {
        type: "element-link",
      },
    ],
    annotations: annotation,
    legend: {
      position: "bottom",
      offsetY: 10,
    },
  });

  useEffect(() => {
    dispatch(fetchProvincesForStatistic());
  }, []);
  useEffect(() => {
    if (listProvincesForStatistic.length > 0) {
      setProvinceId(listProvincesForStatistic[0].id);
    }
  }, [listProvincesForStatistic]);
  return (
    <Box>
      <Box mr={2} mb={2} textAlign="right">
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{minWidth: currentProvinceName?.length > 20 ? 230 : 150}}
        >
          <Select
            native
            value={provinceId}
            defaultValue={provinceId}
            onChange={(e) => handleChangeProvince(e)}
          >
            {listProvincesForStatistic?.map((provinces: ProvinceForStatistic, index: number) => (
              <option value={provinces.id} key={index}>
                {provinces.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            value={currentYear}
            onChange={(e) => handleChangeYear(e)}
            defaultValue={currentYear}
          >
            {Array.from(new Array(10)).map((id, index) => (
              <option value={index + 2020} key={index.toString()}>
                {index + 2020}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select multiple value={compareFarm} onChange={(e) => handleChangeCompareFarm(e)}>
            {farmList?.map((farm: FarmList, index: number) => (
              <MenuItem value={farm.id} key={farm.name}>
                {farm.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Box className={classes.title__area}>
          <Typography variant="subtitle2" style={{color: "#DC2138"}}>
            {currentProvinceName}
          </Typography>
          <hr
            style={{
              margin: "0 20px",
              flexGrow: 1,
            }}
          />
        </Box>
        <Box>
          {farmList?.length === 0 ? (
            <Box>{t("chart.khongcodulieu")}</Box>
          ) : (
            <Grid>
              <Card>
                <Column {...config(dataChart)} style={{marginBottom: "80px"}} />
              </Card>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
}
