import {
  Box,
  Button,
  createStyles,
  IconButton,
  Modal,
  Slide,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import managerCategoryApi from "api/managerCategory";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {Category} from "models/managerCategory";
import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {fetchCategoryList, selectCategoryList} from "../../managerCategorySlice";
import EditCategory from "../EditCategory";
import useStyles from "./style";

export default function TableCategory() {
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const categoryList = useAppSelector(selectCategoryList);
  const [valueCategory, setValueCategory] = useState<Category>({
    name: "",
    id: 0,
  });

  const classes = useStyles();
  const confirm = useConfirm();

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDeleteCategory = (category: Category) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {category.name}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}> {t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_add} style={{marginRight: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    })
      .then(async () => {
        const res: ResponseStatus = await managerCategoryApi.deleteCategory(category.id);
        if (res.responseCode === "00") {
          toast.success(`${t("managerCategory.xoachudethanhcong")}`);
          dispatch(fetchCategoryList());
        } else {
          if (res.responseCode === "30") {
            toast.error(`${t("managerCategory.xoachudethatbai")}`);
            throw new Error("Delete category is failed");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditCategory = (value: Category) => {
    setOpenModal(true);
    setValueCategory(value);
  };
  return (
    <Fragment>
      <Box>
        <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" width="130px">
                  {t("stt")}
                </StyledTableCell>
                <StyledTableCell align="center">{t("managerCategory.bophan")}</StyledTableCell>
                <StyledTableCell align="center">{t("managerCategory.chude")}</StyledTableCell>
                <StyledTableCell align="center" width="400px">
                  {t("thaotac")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categoryList.map((category, index) => (
                <TableRow key={category.id}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {category?.workType?.name || `${t("tatca")}`}
                  </StyledTableCell>
                  <StyledTableCell align="center">{category.name}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      className={classes.btn_add}
                      onClick={() => handleEditCategory(category)}
                    >
                      {t("button.edit")}
                    </Button>
                    <Button
                      className={classes.btn_delete}
                      onClick={() => handleDeleteCategory(category)}
                    >
                      {t("button.delete")}
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={3} display="flex" justifyContent="flex-end"></Box>
      </Box>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container}>
            <Box className={classes.modal__header}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="subtitle2">{t("managerCategory.capnhatchude")}</Typography>
              <div></div>
            </Box>
            <EditCategory valueCategory={valueCategory} setOpenModal={setOpenModal} />
          </Box>
        </Slide>
      </Modal>
    </Fragment>
  );
}
