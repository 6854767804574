import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { ListParams } from "models/common";
import { LessonByUser } from "models/managerLesson";
import { ListExam, ListExamResponse } from "models/managerTest";

export interface detailLessonState {
  detailLesson: LessonByUser;
  loading: Boolean;
}

const initialState: detailLessonState = {
  loading: false,
  detailLesson: {
    responseCode: "",
    responseDescription: "",
    lessionUsers: {
      id: 0,
      lessionId: 0,
      lastSeenTime: 0,
      lession: {
        attachmentID: "",
        attachment: {
          id: "",
          url: "string",
          urlThumbnail: "string",
        },
        id: 0,
        publishDate: 0,
        publishTime: 0,
        areaId: 0,
        workTypeId: 0,
        isAll: false,
        noUserDislike: 0,
        lessionCategoryId: 0,
        lessionName: "",
        lessionDescription: "",
        isDraft: false,
        videoUrl: "",
        thumbnailUrl: "",
        user: {
          createdAt: "",
          updatedAt: "",
          email: "",
          staffId: "",
          name: "",
          areaId: "",
          phone: "",
          provinceId: "",
          dateOfJoining: "",
          workTypeId: "",
          position: "",
          farmId: "",
        },
        noUserAssigned: 0,
        noUserLiked: 0,
        noUserWatched: 0,
        noComments: 0,

        lessionCategory: {
          name: "",
          id: 0,
          workType: "",
          workTypeId: 0,
        },
      },
      userId: 0,
      watched: false,
      likeStatus: "",
    },
  },
};

const detailLessonSlice = createSlice({
  initialState,
  name: "detail-lesson-slice",
  reducers: {
    fetchDetailLesson(state, action: PayloadAction<any | undefined>) {
      if (action.payload.loading) {
        state.loading = true;
      } else {
        state.loading = false;
      }
    },
    fetchDetailLessonSuccess(state, action: PayloadAction<LessonByUser>) {
      state.loading = false;
      state.detailLesson = action.payload;
    },
    fetchDetailLessonFailed(state) {
      state.loading = false;
    },
  },
});

export const { fetchDetailLesson, fetchDetailLessonSuccess, fetchDetailLessonFailed } =
  detailLessonSlice.actions;

export const selectDetailLesson = (state: RootState) => state.detailLessonReducer.detailLesson;
export const selectLoadingDetailLesson = (state: RootState) => state.detailLessonReducer.loading;
const detailLessonReducer = detailLessonSlice.reducer;
export default detailLessonReducer;
