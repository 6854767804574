import {Box, Grid} from "@material-ui/core";
import {useAppDispatch, useAppSelector} from "app/hooks";
import LOGO from "assets/img/logo.png";
import {GroupList} from "models/managerGroup";
import React, {useEffect, useState} from "react";
import {Tree} from "react-organizational-chart";
import {fetchGroupList, selectGroupList} from "../ManagerGroup/managerGroupSlice";
import Area from "./components/Area";
import useStyles from "./style";

const DashBoard = () => {
  const classes = useStyles();
  const [valueMap, setValueMap] = useState("Việt Nam");
  const groupList = useAppSelector(selectGroupList);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchGroupList());
  }, [dispatch]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={3}>
          <img src={`/img/img-map/${valueMap}.png`} alt={valueMap} className={classes.map} />
        </Grid>

        <Grid item xs={7}>
          <Box marginLeft="50px">
            <Tree
              lineWidth={"3px"}
              lineHeight={"20px"}
              lineColor={"#137BBD"}
              lineBorderRadius={"5px"}
              label={
                <Box className={classes.manager}>
                  <img src={LOGO} alt="logo" />
                </Box>
              }
            >
              {groupList.map((area: GroupList) => (
                <Area area={area} setValueMap={setValueMap} />
              ))}
            </Tree>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashBoard;
