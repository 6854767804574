import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button, CircularProgress, Grid, Typography} from "@material-ui/core";
import authApi from "api/authApi";
import {useAppSelector} from "app/hooks";
import InputField from "components/FormFields/InputField";
import {TOKEN, TOKEN_ADMIN} from "constants/config";
import {selectInfoLogin} from "features/Auth/authSlice";
import {useConfirm} from "material-ui-confirm";
import {ChangePasswordProps} from "models/auth";
import {ResponseStatus} from "models/common";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";
import useStyles from "../style";

export default function ChangePassword() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const schema = yup.object().shape({
    currentPassword: yup.string().required(`${t("profile.vuilongnhapmatkhauhientai")}`),
    newPassword: yup.string().required(`${t("profile.vuilongnhapmatkhaumoi")}`),
    confirmNewPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("newPassword"), null], `${t("profile.matkhaukhongtrungkhop")}`),
  });
  const infoLogin = useAppSelector(selectInfoLogin);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const initialValues: ChangePasswordProps = {
    currentPassword: "",
    confirmNewPassword: "",
    newPassword: "",
  };

  const {control, handleSubmit, reset} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  const handleSubmitForm = async (value: ChangePasswordProps) => {
    try {
      const res: ResponseStatus = await authApi.changePassword(value);
      if (res.responseCode === "00") {
        toast.success(`${t("profile.doimatkhauthanhcong")}`);
        reset();
      } else {
        toast.error(`${t("profile.matkhauhientaikhongdung")}`);
        throw new Error(`${t("profile.matkhauhientaikhongdung")}`);
      }
    } catch (error) {}
  };
  const handleForgotPassword = () => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("profile.quenmatkhau")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("profile.vuilongbamdongydeguimatkhaumoiveemail")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}> {t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_add} style={{marginRight: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    })
      .then(async () => {
        setLoading(true);
        const res: ResponseStatus = await authApi.forgotPassword({email: infoLogin.email});
        if (res.responseCode === "00") {
          toast.success(
            `${t("profile.yeucaulaylaimatkhau")} ${infoLogin.staffId} ${t(
              "profile.daduocguitoiadmin"
            )}`
          );
          setLoading(false);
          navigate("/login");
          localStorage.removeItem(TOKEN);
          localStorage.removeItem(TOKEN_ADMIN);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} style={{position: "relative"}}>
      {loading && (
        <Box className={classes.loading}>
          <Box>
            <CircularProgress style={{width: 50, height: 50}} />
          </Box>
        </Box>
      )}
      <Grid container className={classes.form}>
        <Grid item xs={5} lg={4}>
          <Typography variant="h6" className={classes.form__input}>
            {t("profile.matkhauhientai")}
          </Typography>
        </Grid>
        <Grid item xs={7} lg={8}>
          <InputField
            name="currentPassword"
            inputType="password"
            label={t("profile.matkhauhientai")}
            control={control}
          />
        </Grid>

        <Grid item xs={5} lg={4}>
          <Typography variant="h6" className={classes.form__input}>
            {t("profile.matkhaumoi")}
          </Typography>
        </Grid>
        <Grid item xs={7} lg={8}>
          <InputField
            name="newPassword"
            inputType="password"
            label={t("profile.matkhaumoi")}
            control={control}
          />
        </Grid>

        <Grid item xs={5} lg={4}>
          <Typography variant="h6" className={classes.form__input}>
            {t("profile.nhaplaimatkhaumoi")}
          </Typography>
        </Grid>
        <Grid item xs={7} lg={8}>
          <InputField
            name="confirmNewPassword"
            inputType="password"
            label={t("profile.nhaplaimatkhaumoi")}
            control={control}
          />
        </Grid>
      </Grid>
      <Box
        textAlign="center"
        mt={2}
        display="flex"
        justifyContent="space-around"
        alignItems="center"
      >
        <Typography
          variant="subtitle1"
          className={classes.forgotPassword}
          onClick={handleForgotPassword}
        >
          {t("auth.forgotPassword")}
        </Typography>
        <Button type="submit" variant="contained" className={classes.btn_submit}>
          {t("button.update").toUpperCase()}
        </Button>
      </Box>
    </form>
  );
}
