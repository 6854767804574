import {IconButton, InputAdornment, TextField, Theme} from "@material-ui/core";
import {LockOpen} from "@material-ui/icons";
import LockIcon from "@material-ui/icons/Lock";
import {makeStyles} from "@material-ui/styles";
import React, {InputHTMLAttributes, useState} from "react";
import {Control, useController} from "react-hook-form";

export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: any;
  label?: string;
  control: Control<any>;
  borderRadius?: string;
  inputType?: string;
  startIcon?: any;
  rows?: number;
  multiline?: any;
  valueInput?: string;
  required?: boolean;
  autocomplete?: boolean;
}

export default function InputField({
  name,
  label,
  control,
  borderRadius,
  inputType,
  multiline,
  startIcon,
  rows,
  valueInput,
  required,
  autocomplete,
  ...inputProps
}: InputFieldProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      "& >label": {
        fontSize: 16,
        padding: 8,
        paddingLeft: 6,
        color: "#999999",
        [theme.breakpoints.down("md")]: {
          fontSize: 12,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 9,
          padding: 0,
        },
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: borderRadius,
        backgroundColor: inputProps.disabled ? "#f8f4f4" : "#fff",
        padding: 8,
        [theme.breakpoints.down("xs")]: {
          padding: 0,
        },
        "& >textarea": {
          padding: 10,
        },
        "& .MuiOutlinedInput-inputMarginDense": {
          fontSize: 15,
          [theme.breakpoints.down("md")]: {
            fontSize: 12,
          },
        },
      },
      "& .MuiFormHelperText-root.Mui-error": {
        fontSize: 10,
        padding: 0,
        margin: "0",
      },
    },
    iconLock: {
      color: "#797979",
      opacity: 0.5,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
  }));
  const classes = useStyles();
  const {
    field: {value, onChange, onBlur, ref},
    fieldState: {error, invalid},
  } = useController({
    name,
    control,
  });
  const [values, setValues] = useState({
    value: value,
    showPassword: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      autoComplete={autocomplete ? "on" : "off"}
      required={required}
      name={name}
      label={label}
      value={valueInput ? valueInput : value}
      inputRef={ref}
      onChange={onChange}
      onBlur={onBlur}
      helperText={error?.message}
      inputProps={inputProps}
      variant="outlined"
      fullWidth
      margin="dense"
      multiline={multiline}
      rows={rows}
      error={invalid}
      size="small"
      className={classes.root}
      type={!values.showPassword && inputType === "password" ? "password" : "text"}
      InputLabelProps={{...(inputType === "date" && {shrink: true})}}
      InputProps={{
        style: {
          fontSize: 20,
        },

        startAdornment: startIcon,
        endAdornment: (
          <InputAdornment position="end">
            {inputType === "password" && (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? (
                  <LockOpen className={classes.iconLock} />
                ) : (
                  <LockIcon className={classes.iconLock} />
                )}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}
