import {makeStyles, Theme} from "@material-ui/core";

type Props = {
  background: string;
};
const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  background: {
    backgroundImage: (props: Props) => `url(${props.background})`,
    backgroundSize: "cover",
    backgroundPositionX: "right",
    backgroundRepeat: "no-repeat",
    height: 150,
    width: "100%",
    position: "relative",
    "& >h6": {
      position: "absolute",
      bottom: 30,
      left: 0,
      color: "#004673",
      fontSize: 20,
      fontWeight: 700,
    },
  },
  container: {
    maxHeight: 500,
  },
  title: {
    color: "#004673",
    fontSize: 20,
    fontWeight: 700,
  },
  btn_edit: {
    background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
    borderRadius: 10,
    fontSize: 13,
    color: "#fff",
    padding: "5px 12px",
    margin: "0 10px",
  },
  btn_delete: {
    background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    borderRadius: 10,
    fontSize: 13,
    color: "#fff",
    padding: "5px 12px",
  },
  btn_detail: {
    background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
    borderRadius: 10,
    fontSize: 13,
    color: "#fff",
    padding: "5px 12px",
  },
  loading: {
    zIndex: 99,
    backgroundColor: "rgba(255,255,255,0.8)",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "& >div": {
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-40%,-50%)",
      "& .MuiCircularProgress-root": {
        display: "flex",
        margin: "0 auto",
      },
    },
  },
}));

export default useStyles;
