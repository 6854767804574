import {
  Box,
  FormControlLabel,
  IconButton,
  makeStyles,
  Radio,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import managerTestApi from "api/managerTest";
import BACKGROUND from "assets/img/bg_test_detail.png";
import _ from "lodash";
import {AnswerContent, AnswerRight, ExamQuestions, ListExam} from "models/managerTest";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {dateTimeToFormatDDMMYYYY, dateTimeToFormatHHMM} from "utils/dateTimeFormat";
import {timeConvert} from "utils/timeConvert";

export default function DetailExam() {
  const params = useParams();
  const useStyles = makeStyles(() => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        bottom: 30,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
      },
    },
    header: {
      backgroundColor: "#fff",
      boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25), 0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 6,
      display: "flex",
      justifyContent: "space-between",
      padding: "20px 40px",
    },
    title: {
      fontWeight: 700,
      paddingBottom: 10,
      "& > span": {
        fontWeight: 500,
      },
    },
    question: {
      backgroundColor: "#fff",
      boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25), 0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 6,
      padding: "20px 40px",
    },
    question__content: {
      color: "#0065A6",
      fontSize: 15,
    },

    btn_goBack: {
      position: "absolute",
      top: -15,
      left: -20,
      zIndex: 99,
    },
    question_number: {
      color: "#0065A6",
      fontSize: 16,
    },
    question_content: {
      fontSize: 15,
      padding: "5px 0",
      paddingLeft: 8,
    },
  }));
  const {t} = useTranslation();
  const classes = useStyles();
  const [detailExam, setDetailExam] = useState<ListExam>();
  const [detailExamHasAnswer, setDetailExamHasAnswer] = useState<ListExam>();

  useEffect(() => {
    (async () => {
      const res: ListExam = await managerTestApi.getDetailExam(Number(params.id));
      setDetailExam(res);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res: ListExam = await managerTestApi.getDetailExamHasAnswers(Number(params.id));
      setDetailExamHasAnswer(res);
    })();
  }, []);
  const navigate = useNavigate();
  const groupTest = (
    nameArea: string | undefined,
    nameProvince: string | undefined,
    nameDepartment: string | undefined,
    nameWorkType: string | undefined
  ) => {
    const result = _.compact([nameArea, nameProvince, nameDepartment, nameWorkType]);
    return result.length > 0 ? result.join(" - ") : t("tatca");
  };
  return (
    <Box style={{position: "relative"}}>
      <Box className={classes.btn_goBack}>
        <Tooltip title={t("button.goback") || ""}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{marginLeft: 7}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className={classes.background}>
        <Typography variant="subtitle2">{t("managerTest.dethichitiet")}</Typography>
      </Box>
      <Box className={classes.header}>
        <Box>
          <Typography variant="subtitle2" className={classes.title} style={{fontSize: 18}}>
            {detailExam?.exam?.examTitle}
          </Typography>
          <Box display="flex" mb={1}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerTest.nhomthi")} :{"  "}
            </Typography>
            <Typography
              variant="subtitle2"
              component="span"
              color="secondary"
              style={{marginLeft: 3}}
            >
              {groupTest(
                detailExam?.exam?.area?.name,
                detailExam?.exam?.newProvince?.name,
                detailExam?.exam?.workType?.name,
                detailExam?.exam?.department?.title
              )}
            </Typography>
          </Box>
          <Typography variant="subtitle2" className={classes.title}>
            {t("managerTest.diemtongbaithi")} :{" "}
            <Typography variant="subtitle2" component="span" color="secondary">
              {detailExam?.exam?.totalPoint}{" "}
              <Typography variant="subtitle2" component="span" style={{color: "#000"}}>
                {t("managerTest.diem").toLowerCase()}
              </Typography>
            </Typography>
          </Typography>
        </Box>

        <Box textAlign="right">
          <Typography variant="subtitle2" className={classes.title}>
            <Typography variant="subtitle2" component="span">
              {dateTimeToFormatDDMMYYYY(detailExam?.exam?.startDate)}
            </Typography>
          </Typography>
          <Typography variant="subtitle2" className={classes.title}>
            {t("managerTest.giothi")}:{" "}
            <Typography variant="subtitle2" component="span">
              {dateTimeToFormatHHMM(detailExam?.exam?.startDate)}
            </Typography>
          </Typography>
          <Typography variant="subtitle2" className={classes.title}>
            {t("managerTest.thoigianthi")} :{" "}
            <Typography variant="subtitle2" component="span" color="secondary">
              {timeConvert(detailExam?.exam?.examTime)}
            </Typography>
          </Typography>
        </Box>
      </Box>
      {detailExam?.exam?.examQuestions?.map((question: ExamQuestions, index: number) => (
        <Box mt={2} className={classes.question} key={question.id}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" className={classes.question_number}>
                {t("managerTest.cau")} {index + 1} :
              </Typography>
              <Typography variant="h6" className={classes.question_content}>
                {question.question.content}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" className={classes.question_number}>
                {Number(question.question.point).toFixed(3)}
              </Typography>
              <Typography variant="h6" className={classes.question_content}>
                {t("managerTest.diem")}
              </Typography>
            </Box>
          </Box>
          {question.question.imageFileList.length > 0 && (
            <Box
              display="grid"
              gridTemplateColumns={
                question?.question?.imageFileList?.length === 1 ? "1fr" : "1fr 1fr"
              }
              gridGap={10}
              my="10px"
            >
              {question.question.imageFileList.map((x: any) => {
                return (
                  <img
                    key={x.id}
                    style={{
                      width: "100%",
                      height: "fit-content",

                      objectFit: "contain",
                      maxHeight: "450px",
                    }}
                    src={x.imageFile.url}
                    alt={x}
                  />
                );
              })}
            </Box>
          )}
          {detailExamHasAnswer?.questions?.map(
            (answerRight: AnswerRight) =>
              answerRight.questionId === question.questionId &&
              answerRight?.answers?.map((answer: AnswerContent) =>
                detailExam?.exam?.type === "TYPING" ? (
                  <Typography variant="subtitle2" style={{paddingTop: 5}} key={answer.id}>
                    {answer?.content}
                  </Typography>
                ) : (
                  <Box key={answer.id}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={answer.isRightAnswer}
                          disabled={!answer.isRightAnswer}
                          style={{
                            color: answer.isRightAnswer ? "#1FDA53" : "rgba(0, 0, 0, 0.24)",
                          }}
                        />
                      }
                      label={
                        <Box>
                          <Typography
                            variant="subtitle2"
                            style={{fontWeight: answer?.isRightAnswer ? 700 : 500}}
                          >
                            {answer?.content}
                          </Typography>
                          {answer?.imageFileList?.length > 0 && (
                            <Box
                              display="grid"
                              gridTemplateColumns={
                                answer?.imageFileList?.length === 1 ? "1fr" : "1fr 1fr"
                              }
                              gridGap={10}
                            >
                              {answer?.imageFileList?.map((x: any) => {
                                return (
                                  <Box
                                    key={x.id}
                                    position="relative"
                                    maxWidth={400}
                                    height={250}
                                    maxHeight={300}
                                  >
                                    <img
                                      style={{
                                        objectFit: "contain",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                      src={x.imageFile.url}
                                      alt={x}
                                    />
                                  </Box>
                                );
                              })}
                            </Box>
                          )}
                        </Box>
                      }
                    />
                  </Box>
                )
              )
          )}
        </Box>
      ))}
    </Box>
  );
}
