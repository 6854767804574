import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {InfoUser, UserLogin} from "models/auth";
export interface AuthState {
  infoUserLogged: UserLogin;
  isChecked: boolean;
  loading: boolean;
  infoLogin: InfoUser;
  language: string;
}

const initialState: AuthState = {
  infoUserLogged: {
    username: "",
    password: "",
  },
  loading: false,
  isChecked: false,
  language: "",
  infoLogin: {
    email: "",
    staffId: "",
    name: "",
    area: {
      id: "",
      name: "",
    },
    phone: "",
    province: {
      id: "",
      name: "",
    },
    dateOfJoining: "",
    workType: {
      id: "",
      name: "",
    },
    farm: {
      id: "",
      name: "",
    },
    position: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    fetchInfoUserLogged(state, action: PayloadAction<UserLogin>) {
      state.infoUserLogged = action.payload;
    },
    fetchIsChecked(state) {
      state.isChecked = !state.isChecked;
    },
    fetchInfoLogin(state) {
      state.loading = true;
    },
    fetchInfoLoginSuccess(state, action: PayloadAction<InfoUser>) {
      state.infoLogin = action.payload;
      state.loading = false;
    },

    // Change Language
    handleChangeLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
  },
});

// action
export const {
  fetchInfoUserLogged,
  fetchIsChecked,
  fetchInfoLogin,
  fetchInfoLoginSuccess,
  handleChangeLanguage,
} = authSlice.actions;

//Selector
export const selectInfoUserLogged = (state: RootState) => state.authReducer.infoUserLogged;
export const selectIsChecked = (state: RootState) => state.authReducer.isChecked;
export const selectInfoLogin = (state: RootState) => state.authReducer.infoLogin;
export const selectInfoLoginLoading = (state: RootState) => state.authReducer.loading;
export const selectLanguage = (state: RootState) => state.authReducer.language;

const authReducer = authSlice.reducer;

export default authReducer;
