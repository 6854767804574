import {Box, makeStyles, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {Visibility} from "@material-ui/icons";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {
  fetchListTrendingMagazine,
  SelectListTrendingMagazine,
} from "features/Admin/pages/ManagerMagazine/managerMagazineSlice";
import React, {useEffect} from "react";
import Slider from "react-slick";
import ICON_PDF from "assets/img/icon_pdf.png";
import {dateTimeToFormatHHMM, dateTimeToFormatYYYY} from "utils/dateTimeFormat";
import {useTranslation} from "react-i18next";
import LOGO from "assets/img/logo.png";
import {useLocation} from "react-router-dom";

export interface ListTrendingProps {
  handleClickShowModal: (e: any) => void;
  handleClickShowViewer: (e: any) => void;
}

export default function ListTrending({
  handleClickShowModal,
  handleClickShowViewer,
}: ListTrendingProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&>span": {
        fontSize: 12,
        color: "#DC2138",
        marginRight: 3,
      },
      "&>h6": {
        fontSize: 14,
        color: "#898989",
        marginLeft: 3,
      },
    },
    icon__visibility: {
      color: "#898989",
      fontSize: 15,
      marginRight: 5,
    },
    thumbnail: {
      width: "100%",
      height: 370,
      objectFit: "cover",
      marginTop: 15,
    },
    category: {
      position: "absolute",
      bottom: 0,
      left: 0,
      fontSize: 15,
      width: "100%",
      backgroundColor: "rgba(44, 44, 44, 0.5)",
      color: "#fff",
      padding: 15,
      display: "flex",
      alignItems: "center",
      "& >img": {
        width: 25,
        height: 25,
        marginRight: 15,
      },
    },
  }));
  const {t} = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useAppDispatch();
  const listTrending = useAppSelector(SelectListTrendingMagazine);
  const filter = {
    page_index: 0,
    page_size: 4,
    sort_type: 0,
    until_published_timestamp_utc: 0,
    is_hidden: false,
  };
  useEffect(() => {
    dispatch(fetchListTrendingMagazine(filter));
  }, []);

  const settings = {
    slidesToShow: 1,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Box width={isNotMobile ? "60%" : "100%"} mr={isNotMobile ? 3 : 0}>
      <Slider {...settings}>
        {listTrending.magazineList.map((magazine) => (
          <Box key={magazine.id}>
            <Box className={classes.root} mt={2}>
              <Box className={classes.header} width="150px">
                <Typography variant="subtitle2" component="span">
                  {dateTimeToFormatHHMM(String(new Date(magazine.publishedTimestampUTC)))}
                </Typography>
                &#9679;
                <Typography variant="subtitle2">
                  {dateTimeToFormatYYYY(String(new Date(magazine.publishedTimestampUTC)))}
                </Typography>
              </Box>

              <Box
                className={classes.root}
                style={{
                  cursor: location.pathname.includes("/admin") ? "pointer" : "initial",
                }}
                onClick={() => handleClickShowViewer(magazine.id)}
              >
                <Visibility className={classes.icon__visibility} />
                {magazine.total_view_count} {t("managerMagazine.luotxem").toLowerCase()}
              </Box>
            </Box>
            <Box
              position="relative"
              onClick={() => handleClickShowModal(magazine)}
              style={{cursor: "pointer"}}
            >
              <Typography variant="subtitle2" className={classes.category}>
                <img src={ICON_PDF} alt="ICON_PDF" />
                {magazine.categoryList[0].name}
              </Typography>
              <img
                src={magazine.attachmentList[0].urlThumbnail || LOGO}
                alt="background"
                className={classes.thumbnail}
              />
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
