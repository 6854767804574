import {FormHelperText, makeStyles, MenuItem, Select, Theme} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import * as React from "react";
import {Control, useController} from "react-hook-form";

export interface SelectOption {
  id: number | string;
  title?: string;
  name?: string;
  [key: string]: any;
}

export interface SelectFieldProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: any;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  options: SelectOption[];
  borderRadius?: string;
  startIcon?: any;
  handleClick?: any;
  workType?: string;
  department?: string;
  required?: boolean;
  idxItemClick?: number;
}

export function SelectField({
  name,
  control,
  label,
  disabled,
  options,
  startIcon,
  borderRadius,
  handleClick,
  department,
  required,
  idxItemClick,
}: SelectFieldProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: 6,
      backgroundColor: disabled ? "#f8f4f4" : "#fff",
      borderRadius: borderRadius,
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    label: {
      padding: 5,
      color: "#999999",
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 9,
      },
    },
    error: {
      fontSize: 10,
      padding: 0,
    },
    options: {
      fontSize: 14,
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
        margin: 0,
      },
    },
  }));
  const classes = useStyles();
  const {
    field: {value, onChange, onBlur},
    fieldState: {invalid, error},
  } = useController({
    name,
    control,
  });

  return (
    <FormControl
      fullWidth
      variant="outlined"
      margin="dense"
      size="small"
      disabled={disabled}
      error={invalid}
      required={required}
    >
      <InputLabel id={`${name}_label`} className={classes.label}>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}_label`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        className={classes.root}
        startAdornment={startIcon}
        inputProps={{
          name: `${name}`,
          id: `${name}_label`,
        }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.id}
            value={option?.id}
            onClick={() => {
              handleClick && handleClick(option, idxItemClick);
            }}
          >
            {department === "department" ? (
              <span className={classes.options}>{option?.title}</span>
            ) : (
              <span className={classes.options}>{option?.name}</span>
            )}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText className={classes.error}>{error?.message}</FormHelperText>
    </FormControl>
  );
}
