import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: "#004673",
  },
  formControl: {
    minWidth: 150,
    backgroundColor: "#FFFFFF",

    "& .MuiSelect-root": {
      padding: 10,
    },
  },
  oneCard: {
    width: "50%",
    margin: "0 auto",
  },
  content__note: {
    border: "1px solid #818181",
    backgroundColor: "#FFF",
    margin: "0px 20px 30px 20px",
  },
  content__text__note: {
    padding: "5px 10px",
    border: "1px solid  #818181",
    fontSize: 12,
    height: 35,
    textAlign: "center",
  },
  bg_note_south: {
    backgroundColor: "#123664",
    color: "#fff",
  },
  bg_note_north: {
    backgroundColor: "#B80F0F",
    color: "#fff",
  },
  bg_note_center: {
    backgroundColor: "#EBA337",
    color: "#fff",
  },
  bg_note_notFullyWatched: {
    fontWeight: 700,
    color: "#000",
    background:
      "repeating-linear-gradient(45deg, rgb(255 255 255), rgb(189 174 175) 10px, rgb(66 51 51) 10px, rgb(255 246 246) 10px)",
  },
  bg_note_noWatched: {
    color: "#000",
    fontWeight: 700,
    backgroundColor: "rgb(243 223 223)",
    backgroundImage:
      "radial-gradient(rgb(142 140 140) 20%, #19181800 20%), radial-gradient(rgb(142 140 140) 20%, #18131300 20%)",
    backgroundSize: "20px 20px",
    backgroundPosition: " 0 0, 30px 30px",
  },
  bg_note_fullyWatched: {
    border: "1px solid #6B6B6B",
    color: "#000",
    backgroundColor: "#fff",
    fontWeight: 700,
  },
}));

export default useStyles;
