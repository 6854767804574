import { ResponseStatus } from "models/common";
import axiosClient from "./axiosClient";

const managerFilesApi = {
    uploadFiles(file: FormData): Promise<ResponseStatus> {
        const url = "/files/uploadFiles";
        return axiosClient.post(url, file);
    },
};

export default managerFilesApi;
