import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 8,
    display: "inline-block",
    padding: 5,
    minWidth: 80,
    height: 70,
    "&>h6": {
      display: "flex",
      height: "100%",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  manager: {
    borderRadius: 8,
    display: "inline-block",
    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 0px 6px rgba(168, 168, 168, 0.25)",
    minWidth: 140,
    "& > img": {
      height: "auto",
      objectFit: "fill",
      padding: 10,
      width: 150,
    },
    "& >h6": {
      color: "#137BBD",
      fontWeight: 600,
    },
  },

  area: {
    position: "relative",
    marginTop: 5,

    "&>img": {
      width: 120,
      height: 120,
    },
    "& >h6": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      width: "100%",
      fontWeight: 600,
      color: "#137BBD",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",

      "& >svg": {
        cursor: "pointer",
      },
    },
    "&>div": {
      display: "inherit",
    },
  },
  workType: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: "0px 2px 4px rgb(0 0 0 / 25%), 0px 0px 6px rgb(168 168 168 / 25%)",
    minWidth: 100,
    backgroundColor: "#fff",
    width: "50%",
    margin: "0 auto",
    "&>svg": {
      color: "#137BBD",
      cursor: "pointer",
      fontSize: 25,
    },
  },
  provinces: {
    minWidth: 130,
    height: 100,
    backgroundColor: "#fff",
    "& >h6": {
      fontSize: 13,
      cursor: "pointer",
    },
  },
  map: {
    width: "100%",
    objectFit: "cover",
  },
  test: {
    "& > ul": {
      "&::before": {
        height: 40,
      },
    },
  },
}));

export default useStyles;
