import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button, IconButton, Modal, Slide, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import managerGroupApi from "api/managerGroupApi";
import {useAppDispatch} from "app/hooks";
import clsx from "clsx";
import InputField from "components/FormFields/InputField";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {ListFarm, ListProvince} from "models/managerGroup";
import React, {useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import * as yup from "yup";
import {fetchGroupList} from "../managerGroupSlice";
import useStyles from "./style";

export interface FarmGroupProps {
  farm: ListFarm;
}

export default function FarmGroup({farm}: FarmGroupProps) {
  const [openModal, setOpenModal] = useState(false);
  const confirm = useConfirm();
  const {t} = useTranslation();
  const schema = yup.object().shape({
    farmName: yup.string().required(`${t("managerGroup.vuilongnhaptentrai")}`),
  });
  const initialValues = {
    farmName: farm.farmName,
  };
  const classes = useStyles();
  const {control, handleSubmit, resetField} = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSubmitForm = async (value: any) => {
    try {
      const res: ResponseStatus = await managerGroupApi.updateFarm(value, farm.farmId);
      if (res.responseCode === "00") {
        toast.success(`${t("managerGroup.capnhattentraithanhcong")}`);
        dispatch(fetchGroupList());
        setOpenModal(false);
        resetField("farmName");
      } else {
        toast.error(`${t("managerGroup.capnhatthatbai")}`);
      }
    } catch (error) {}
  };

  const handleDeleteFarm = async (id: number) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {farm.farmName}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}>{t("delete.dongy")}</Button>,
      cancellationText: <Button className={classes.btn_add}> {t("delete.huybo")}</Button>,
    })
      .then(async () => {
        const res: ResponseStatus = await managerGroupApi.deleteFarm(id);
        if (res.responseCode === "00") {
          toast.success(`${t("managerGroup.xoatraithanhcong")}`);
          setOpenModal(false);
          dispatch(fetchGroupList());
        } else {
          toast.error(`${t("managerGroup.xoatraithatbai")}`);
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h5" className={clsx(classes.title_farm, classes.title)}>
        <Typography variant="h5" component="span">
          {" "}
          {farm.farmName}
        </Typography>
      </Typography>

      <Box className={classes.content__right}>
        <Button className={classes.btn_delete} onClick={() => handleDeleteFarm(farm.farmId)}>
          {t("button.delete")}
        </Button>
        <Button className={classes.btn_edit} onClick={() => setOpenModal(true)}>
          {t("button.edit")}
        </Button>
      </Box>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container}>
            <Box className={classes.modal__header}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="subtitle2">
                {t("managerGroup.capnhattrai")} {farm.farmName}
              </Typography>
              <div></div>
            </Box>

            <Box className={classes.form}>
              <Typography variant="h5"></Typography>
              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <InputField
                  name="farmName"
                  label={t("managerGroup.nhaptentraimoi")}
                  control={control}
                  borderRadius="10px"
                />

                <Box textAlign="center">
                  <Button type="submit" className={classes.form__button}>
                    {t("button.update")}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
}
