import {
  Box,
  Button,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import {makeStyles, withStyles} from "@material-ui/styles";
import managerLessonApi from "api/managerLesson";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_manager_lesson.png";
import LOADING from "assets/img/loading.gif";
import axios, {AxiosResponse} from "axios";
import clsx from "clsx";
import {TOKEN, BASE_URL} from "constants/config";
import {id} from "date-fns/locale";
import {useConfirm} from "material-ui-confirm";
import {ResponseStatus} from "models/common";
import {Lesson} from "models/managerLesson";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import FileDownload from "js-file-download";
import {
  fetchLessonList,
  selectFilterLessonListPosted,
  selectLessonList,
  setFilterLessonListPosted,
} from "../../managerLessonSlice";

export interface ListLessonPostedProps {}

export default function ListLessonPosted(props: ListLessonPostedProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: 100,
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        width: "90%",
        height: 150,
      },
    },

    thumbnail_video: {
      width: 40,
      height: 40,
      objectFit: "cover",
    },

    percentHide: {
      width: "100%",
      position: "relative",
      backgroundColor: "#EFEFEF",
      height: 8,
      borderRadius: 8,
      marginTop: 5,
    },
    percent: {
      height: 8,
      borderRadius: 8,
      position: "absolute",
      top: 0,
      left: 0,
    },
    percentView: {
      backgroundColor: "#F1A34D",
    },
    percentLike: {
      backgroundColor: "  #0499C8",
    },

    btn_delete: {
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    },
    btn_detail: {
      background: "linear-gradient(360deg, #019ABB 0%, rgba(167, 255, 255, 0.9) 168.18%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
      margin: "2px 10px",
    },
    btn_add: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
    },
    btn_list_missing: {
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
    },
    root_modal: {
      width: "100%",
      height: "60%",
      display: "flex",
      justifyContent: "center",
    },
    modal__container: {
      width: "100%",
      maxWidth: 560,
      borderRadius: 12,
      backgroundColor: "#fff",
      zIndex: 9,
      display: "flex",
      flexDirection: "column",
      margin: "30px 0",
    },
    modal__header: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "0 24px",
      minHeight: 20,
      borderBottom: "1px solid rgb(235, 235, 235)",
      "& >h6": {
        fontWeight: 700,
        fontSize: 18,
        marginRight: 50,
      },
    },
    icon_play: {
      padding: 5,
      backgroundColor: "#fff",
      width: 50,
      height: 50,
      borderRadius: "50%",
      color: "#f9ab00",
      transition: "ease-in 0.5s",
      border: "8px solid #999",
      "&:hover": {
        backgroundColor: "#f9ab00",
        color: "#fff",
      },
    },
    container: {
      maxHeight: 500,
    },
  }));
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);

  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const listLesson = useAppSelector(selectLessonList);
  const filter = useAppSelector(selectFilterLessonListPosted);

  const handleChangePage = (page: number) => {
    dispatch(
      setFilterLessonListPosted({
        ...filter,
        page: page - 1,
      })
    );
  };
  const handleDeleteLesson = (lesson: Lesson) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {lesson.lessionName}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: (
        <Button className={classes.btn_delete} style={{marginRight: 0}}>
          {t("delete.dongy")}
        </Button>
      ),
      cancellationText: <Button className={classes.btn_add}> {t("delete.huybo")}</Button>,
    })
      .then(async () => {
        const res: ResponseStatus = await managerLessonApi.deleteLesson(lesson.id);
        if (res.responseCode === "00") {
          toast.success(`${t("managerLesson.xoabaihocthanhcong")}`);
          dispatch(fetchLessonList({...filter, type: "POSTED"}));
        } else {
          toast.error(`${t("managerLesson.xoabaihocthatbai")}`);
          throw new Error(res.responseDescription);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleExport = async (lesson: Lesson) => {
    const token = localStorage.getItem(TOKEN);
    try {
      const res: AxiosResponse = await axios({
        method: "GET",
        url: `${BASE_URL}/statistic/statisticLession/export/excel?lesson_id=${lesson.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "blob",
        },
        responseType: "arraybuffer",
      });
      if (res.data.responseDescription) {
        toast.error(t("file.xuatthatbai"));
        return;
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileDownload(blob, `report-lesson-${lesson.id}.xlsx`);
      toast.success(t("file.xuatthanhcong"));
    } catch (error) {}
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader style={{minWidth: "1850px"}}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" width="50px">
                {t("stt")}
              </StyledTableCell>
              <StyledTableCell align="center" width="70px">
                {t("managerLesson.video")}
              </StyledTableCell>
              <StyledTableCell align="center" width="250px">
                {t("managerLesson.tenbaihoc")}
              </StyledTableCell>
              <StyledTableCell align="center" width="100px">
                {t("managerLesson.ngaydang")}
              </StyledTableCell>
              <StyledTableCell align="center" width="100px">
                {t("managerLesson.ngayan")}
              </StyledTableCell>
              <StyledTableCell align="center" width="100px">
                {t("managerLesson.gioan")}
              </StyledTableCell>
              <StyledTableCell align="center" width="120px">
                {t("managerLesson.sohocvienxem")}
              </StyledTableCell>
              <StyledTableCell align="center" width="120px">
                {t("managerLesson.thich")}
              </StyledTableCell>
              <StyledTableCell align="center" width="100px">
                {t("managerLesson.binhluan")}
              </StyledTableCell>
              <StyledTableCell align="center" width="120px">
                {t("managerLesson.nguoidang")}
              </StyledTableCell>
              <StyledTableCell align="center" width="100px">
                {t("managerLesson.khuvuc")}
              </StyledTableCell>
              <StyledTableCell align="center" width="120px">
                {t("managerLesson.tinh")}
              </StyledTableCell>
              <StyledTableCell align="center" width="100px">
                {t("managerLesson.bophan")}
              </StyledTableCell>
              <StyledTableCell align="center" width="100px">
                {t("managerLesson.phongban")}
              </StyledTableCell>
              <StyledTableCell align="center" width="100px">
                {t("managerLesson.trai")}
              </StyledTableCell>
              <StyledTableCell align="center" width="350px">
                {t("thaotac")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listLesson?.content.map((lesson, index) => (
              <TableRow key={lesson.id}>
                <StyledTableCell align="center" component="th" scope="user">
                  {listLesson.size * listLesson.number + index + 1}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {lesson.attachment.urlThumbnail ? (
                    <img
                      src={lesson.attachment.urlThumbnail}
                      alt="thumbnal_video"
                      className={classes.thumbnail_video}
                    />
                  ) : (
                    <img src={LOADING} alt="thumbnal_video" className={classes.thumbnail_video} />
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">{lesson.lessionName}</StyledTableCell>
                <StyledTableCell align="center">
                  {String(lesson?.publishDate).slice(-2)}/{String(lesson?.publishDate).slice(4, 6)}/
                  {String(lesson?.publishDate).slice(0, 4)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {String(lesson?.expiryDateTimeAsString).slice(8)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {String(lesson?.expiryDateTimeAsString).slice(0, 5)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle2" style={{color: "#DC2138"}} component="span">
                    {" "}
                    {lesson?.noUserWatched}
                  </Typography>{" "}
                  <i> {t("managerLesson.nguoixem")}</i>
                  <Box className={classes.percentHide}>
                    <Box
                      className={clsx(classes.percent, classes.percentView)}
                      width={
                        lesson?.noUserLiked === 0
                          ? "0"
                          : Math.ceil((lesson?.noUserLiked / lesson?.noUserAssigned) * 100) + "%"
                      }
                    ></Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle2" style={{color: "#DC2138"}} component="span">
                    {lesson?.noUserLiked}
                  </Typography>{" "}
                  <i> {t("managerLesson.thich").toLowerCase()}</i>
                  <Box className={classes.percentHide}>
                    <Box
                      className={clsx(classes.percent, classes.percentLike)}
                      width={
                        lesson?.noUserLiked === 0
                          ? "0"
                          : Math.floor((lesson?.noUserLiked / lesson?.noUserAssigned) * 100) + "%"
                      }
                    ></Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle2" style={{color: "#DC2138"}} component="span">
                    {lesson?.noComments}
                  </Typography>{" "}
                  <i> {t("managerLesson.binhluan").toLowerCase()}</i>
                </StyledTableCell>
                <StyledTableCell align="center">{lesson?.user?.name}</StyledTableCell>
                <StyledTableCell align="center">{lesson?.area?.name}</StyledTableCell>
                <StyledTableCell align="center">{lesson?.newProvince?.name}</StyledTableCell>
                <StyledTableCell align="center">{lesson?.department?.title}</StyledTableCell>
                <StyledTableCell align="center">{lesson?.workType?.name}</StyledTableCell>
                <StyledTableCell align="center">{lesson?.farm?.name}</StyledTableCell>
                <StyledTableCell align="center">
                  <Button
                    onClick={() => handleDeleteLesson(lesson)}
                    className={classes.btn_delete}
                    size="small"
                  >
                    {t("managerLesson.xoabai")}
                  </Button>
                  <Button
                    className={classes.btn_detail}
                    size="small"
                    onClick={() =>
                      navigate(`/admin/manager-lesson/edit/posted/${lesson?.id}`, {
                        state: {
                          detailLesson: lesson,
                          type: "POSTED",
                        },
                      })
                    }
                  >
                    {t("button.edit")}
                  </Button>

                  <Button
                    onClick={() =>
                      navigate(`/admin/manager-lesson/listPosted/list-skip/${lesson?.id}`)
                    }
                    className={classes.btn_list_missing}
                    size="small"
                  >
                    {t("managerLesson.dsbohoc")}
                  </Button>

                  <Button
                    onClick={() => handleExport(lesson)}
                    className={classes.btn_list_missing}
                    size="small"
                  >
                    {t("button.xuattatca")}
                  </Button>

                  <Button
                    className={classes.btn_detail}
                    size="small"
                    onClick={() =>
                      navigate(`/admin/manager-lesson/listPosted/detail/${lesson?.id}`, {
                        state: {
                          listLesson,
                        },
                      })
                    }
                  >
                    {t("button.detail")}
                  </Button>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {listLesson?.totalPages > 1 && (
        <Box mt={3} display="flex" justifyContent="space-between">
          <Typography variant="subtitle2" style={{fontWeight: 600}}>
            {t("managerLesson.tongbaihoc")}: {listLesson.totalElements}
          </Typography>
          <Pagination
            count={Math.ceil(listLesson?.totalElements / listLesson?.size)}
            page={listLesson?.number + 1}
            variant="outlined"
            color="primary"
            shape="rounded"
            onChange={(e, page) => handleChangePage(page)}
          />
        </Box>
      )}
    </>
  );
}
