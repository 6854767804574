import "moment/locale/vi";

const moment = require("moment");

export const dateTimeToFormatDDMMYYYY = (date: string | undefined) => {
  return date && moment(date).format("DD-MM-YYYY");
};
export const dateTimeToFormatHHMM = (date: any) => {
  return date && moment(date).format("HH:mm");
};

export const dateTimeToFormatYYYY = (date: string) => {
  return date && moment(date).format("DD/MM/YYYY");
};

export const dateTimeToFormatYYYYHHMM = (date: string) => {
  return date && moment(date).format("DD/MM/YYYY HH:MM");
};

export const convertHHMMtoSeconds = (time: string) => {
  const [hours, minutes] = time.split(":");
  return Number(hours) * 60 * 60 + Number(minutes) * 60;
};

export const convertDDMMYYYYtoSeconds = (date: string, time: string) => {
  return moment(`${date} - ${time}`, "YYYY-MM-DD - HH:mm").toDate().getTime();
};
