import {
  Box,
  createStyles,
  FormControl,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import authApi from "api/authApi";
import BANNER from "assets/img/academic_record.png";
import {AcademicRecordProps, ResponseAcademicRecord} from "models/auth";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {dateTimeToFormatYYYY} from "utils/dateTimeFormat";
import useStyles from "./style";
import {ListParams} from "models/common";

interface IListAcademicRecord {
  content: AcademicRecordProps[];
  [key: string]: any;
}
interface IAcademicRecordProps {
  id?: string | undefined;
}
export default function AcademicRecord({id}: IAcademicRecordProps) {
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "5px ",
        backgroundColor: "#E9A051",
        color: "#FFF",
        fontSize: 8,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        [theme.breakpoints.up("sm")]: {
          fontSize: 13,
          padding: "16px 5px",
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: 15,
        },
      },
      body: {
        padding: "8px 5px",
        fontSize: 8,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",
        [theme.breakpoints.up("sm")]: {
          fontSize: 13,
          padding: "16px 5px",
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: 15,
        },
      },
      footer: {
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        fontSize: 7,
        padding: "8px 5px",
        [theme.breakpoints.up("sm")]: {
          fontSize: 13,
          padding: "16px 5px",
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: 15,
        },
      },
    })
  )(TableCell);
  const {t} = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [filter, setFilter] = useState<ListParams>({
    page: 0,
    size: 10,
    userId: Number(id) || "",
  });

  const handleChangeYear = (event: React.ChangeEvent<{value: unknown}>) => {
    setYear(Number(event.target.value));
  };

  const [listAcademicRecord, setListAcademicRecord] = useState<IListAcademicRecord>({
    content: [],
  });

  const [averageYear, setAverageYear] = useState(0);
  useEffect(() => {
    (async () => {
      try {
        const res: ResponseAcademicRecord<AcademicRecordProps> = await authApi.getAcademicRecord({
          ...filter,
          year,
        });
        setAverageYear(res.averageMarkInYear);
        setListAcademicRecord(res.list);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [year, filter, id]);
  const handleChangePage = (page: number) => {
    setFilter({
      ...filter,
      page: page - 1,
    });
  };
  return (
    <Box px={isMobile ? 1 : id ? 0 : 3}>
      <Box
        my={isMobile ? 1 : 3}
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        flexDirection="row"
        position="relative"
      >
        <Typography variant="subtitle2" className={classes.title}>
          {t("academic.hocbacanhan")}
        </Typography>
        <Box className={classes.banner}>
          <img src={BANNER} alt="BANNER" />
        </Box>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            value={year}
            onChange={handleChangeYear}
            defaultValue={new Date().getFullYear()}
          >
            {Array.from(new Array(10)).map((id, index) => (
              <option value={index + 2020} key={id}>
                {index + 2020}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      {listAcademicRecord?.content?.length > 0 ? (
        <Box>
          <TableContainer component={Paper} className={classes.container_table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">{t("academic.ngaythi")}</StyledTableCell>
                  <StyledTableCell align="center">{t("academic.tenbaithi")}</StyledTableCell>
                  <StyledTableCell align="center">{t("academic.trangthai")}</StyledTableCell>
                  <StyledTableCell align="center">{t("academic.diemthi")}</StyledTableCell>
                  <StyledTableCell align="center">{t("academic.xeploai")}</StyledTableCell>
                  <StyledTableCell align="center">{t("academic.ghichu")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listAcademicRecord?.content?.map((item: AcademicRecordProps) => (
                  <TableRow key={item.id}>
                    <StyledTableCell align="center" width={isMobile ? "50px" : "130px"}>
                      {dateTimeToFormatYYYY(item.startDate)}
                    </StyledTableCell>
                    <StyledTableCell align="center" width={isMobile ? "120px" : "600px"}>
                      {item.title}
                    </StyledTableCell>
                    <StyledTableCell align="center" width={isMobile ? "70px" : "130px"}>
                      {item.point === null || ""
                        ? ""
                        : item.point === 0
                        ? `${t("academic.rot")}`
                        : `${t("academic.dau")}`}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      width={isMobile ? "50px" : "130px"}
                      style={{color: "#DB2139"}}
                    >
                      {Math.floor(item.point)}
                    </StyledTableCell>
                    <StyledTableCell align="center" width={isMobile ? "50px" : "130px"}>
                      {(item.point / item.maxPoint) * 100 < 50
                        ? `${t("point.yeu")}`
                        : (item.point / item.maxPoint) * 100 < 65
                        ? `${t("point.trungbinh")}`
                        : (item.point / item.maxPoint) * 100 < 80
                        ? `${t("point.kha")}`
                        : (item.point / item.maxPoint) * 100 < 100
                        ? `${t("point.gioi")}`
                        : (item.point / item.maxPoint) * 100 === 100
                        ? `${t("point.thukhoa")}`
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell align="center" width={isMobile ? "50px" : "130px"}>
                      {moment
                        .duration(
                          moment(new Date(item.endDate).getTime()).diff(
                            moment(new Date().getTime())
                          )
                        )
                        .milliseconds() < 0 && !item.isSubmitted
                        ? `${t("academic.bothi")}`
                        : ""}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>

              <TableFooter>
                <StyledTableCell
                  colSpan={3}
                  style={{
                    color: "#004673",
                    textAlign: "center",
                    fontSize: isMobile ? 10 : 17,
                    fontWeight: 700,
                  }}
                  align="center"
                >
                  {t("academic.diemtrungbinhnam")}
                </StyledTableCell>
                <StyledTableCell align="center" style={{color: "#DB2139", fontWeight: 700}}>
                  {(averageYear * 100).toFixed(1)}
                </StyledTableCell>
                <StyledTableCell align="center" style={{color: "#004673", fontWeight: 700}}>
                  {averageYear &&
                    (averageYear * 100 < 50
                      ? `${t("point.yeu")}`
                      : averageYear * 100 < 65
                      ? `${t("point.trungbinh")}`
                      : averageYear * 100 < 80
                      ? `${t("point.kha")}`
                      : averageYear * 100 < 100
                      ? `${t("point.gioi")}`
                      : averageYear * 100 === 100
                      ? `${t("point.thukhoa")}`
                      : "")}
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableFooter>
            </Table>
          </TableContainer>

          {listAcademicRecord?.totalPages > 0 && (
            <Box mt={isMobile ? 2 : 3} display="flex" justifyContent="flex-end" mb={3}>
              <Pagination
                count={Math.ceil(listAcademicRecord?.totalElements / listAcademicRecord?.size)}
                page={listAcademicRecord?.number + 1}
                variant="outlined"
                color="primary"
                shape="rounded"
                onChange={(e, page) => handleChangePage(page)}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box>{t("academic.khongcodulieu")}</Box>
      )}
    </Box>
  );
}
