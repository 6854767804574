import {Avatar, Box, Button, IconButton, makeStyles, Menu, Theme} from "@material-ui/core";
import {selectInfoLogin} from "features/Auth/authSlice";
import React, {ChangeEvent, useState} from "react";
import NO_AVATAR from "assets/img/no_avatar.png";
import Picker, {SKIN_TONE_MEDIUM_DARK} from "emoji-picker-react";
import {useAppSelector} from "app/hooks";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {Comment} from "models/managerLesson";
import InputField from "components/FormFields/InputField";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import EMOJI from "assets/img/emoji.png";
import {toast} from "react-toastify";
import managerLessonApi from "api/managerLesson";
import {ResponseStatus} from "models/common";

export interface RepCommentProps {
  userComment: Comment;
  handleGetChildComment: () => void;
}

export default function RepComment({userComment, handleGetChildComment}: RepCommentProps) {
  const [comment, setComment] = useState("");
  const {t} = useTranslation();
  const infoLogin = useAppSelector(selectInfoLogin);
  const [openEmoji, setOpenEmoji] = useState<null | HTMLElement>(null);
  const [openButton, setOpenButton] = useState(false);

  const schema = yup.object().shape({
    rep_comment: yup.string().required(`${t("home.detaillesson.vuilongnhapbinhluan")}`),
  });
  const {control, handleSubmit, setValue, getValues, reset} = useForm({
    defaultValues: {
      rep_comment: "",
    },
    resolver: yupResolver(schema),
  });
  const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
      width: 50,
      height: 50,
      marginRight: 15,
      [theme.breakpoints.down("md")]: {
        width: 40,
        height: 40,
      },
      [theme.breakpoints.down("xs")]: {
        width: 30,
        height: 30,
      },
    },

    comments__action: {
      display: userComment.user.id === infoLogin.id ? "block" : "none",
      position: "absolute",
      right: -60,
      bottom: 10,
    },
    btn_delete: {
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
    },
    btn_edit: {
      background: "linear-gradient(360deg, #019ABB 0%, rgba(167, 255, 255, 0.9) 168.18%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
      marginRight: 10,
    },
    icon_emoji: {
      position: "absolute",
      bottom: 7,
      right: 10,
      [theme.breakpoints.down("sm")]: {
        bottom: 0,
        right: -3,
      },
      "& >span >img": {
        width: 25,
        height: 25,
        [theme.breakpoints.down("sm")]: {
          width: 18,
          height: 18,
        },
      },
    },
    btn__comment: {
      background:
        comment.length > 0 ? "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)" : "transparent",
      color: "#fff",
    },
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }));

  const classes = useStyles();
  const onEmojiClick = (event: any, emojiObject: any) => {
    setValue("rep_comment", getValues().rep_comment + emojiObject.emoji);
    setComment(emojiObject.emoji);
  };
  const handleOpenEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmoji(event.currentTarget);
  };
  const handleSubmitComment = async (value: any) => {
    const valueComment = {
      comment: value.rep_comment,
      lessionId: Number(userComment?.lessionId),
      parentCommentId: Number(userComment?.id),
    };
    try {
      const res: ResponseStatus = await managerLessonApi.postComment(valueComment);
      if (res.responseCode === "00") {
        handleGetChildComment();
        setOpenButton(false);
        toast.success(`${t("home.detaillesson.binhluanthanhcong")}`);
        reset();
      } else {
        toast.error(`${t("home.detaillesson.binhluanthatbai")}`);
        throw new Error("Comment is failed");
      }
    } catch (error) {}
  };
  return (
    <form onSubmit={handleSubmit(handleSubmitComment)}>
      <Box className={classes.root} ml={5} mt={2}>
        <Box>
          <Avatar
            src={infoLogin?.profileImageUrl || NO_AVATAR}
            alt="avatar"
            className={classes.avatar}
          />
        </Box>
        <Box position="relative" width="100%">
          <InputField
            name="rep_comment"
            label={t("home.detaillesson.vietbinhluan")}
            onClick={() => setOpenButton(true)}
            control={control}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setComment(event?.target.value)}
          />
          <IconButton onClick={handleOpenEmoji} className={classes.icon_emoji}>
            <img src={EMOJI} alt="EMOJI" />
          </IconButton>
          {openEmoji && (
            <Menu
              id="simple-menu"
              anchorEl={openEmoji}
              keepMounted
              open={Boolean(openEmoji)}
              onClose={() => setOpenEmoji(null)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Picker
                onEmojiClick={onEmojiClick}
                disableAutoFocus={true}
                skinTone={SKIN_TONE_MEDIUM_DARK}
                groupNames={{smileys_people: "PEOPLE"}}
                native
              />
            </Menu>
          )}
        </Box>
      </Box>
      {openButton && (
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            onClick={() => {
              setOpenButton(false);
              reset();
            }}
          >
            {t("button.cancel")}
          </Button>
          <Button className={classes.btn__comment} type="submit" disabled={comment === ""}>
            {t("home.detaillesson.binhluan")}
          </Button>
        </Box>
      )}
    </form>
  );
}
