import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  title: {
    borderLeft: "5px solid #157BBC",
    backgroundColor: "#F6F6F6",
    padding: "15px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    border: "1px solid #DEDEDE",
    marginBottom: 15,
    "& > span": {
      fontSize: 17,
      fontWeight: 600,
      marginLeft: 5,
    },
  },
  title_department: {
    paddingLeft: 50,
    borderLeft: "1px solid #DEDEDE",
  },
  title_province: {
    paddingLeft: 100,
    borderLeft: "1px solid #DEDEDE",
  },
  title_farm: {
    paddingLeft: 150,
    borderLeft: "1px solid #DEDEDE",
  },
  content__right: {
    backgroundColor: "#F6F6F6",
    border: "1px solid #DEDEDE",
    marginBottom: 15,
    minWidth: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 15,
  },

  btn_next: {
    color: "#157BBC",
  },
  btn_add: {
    background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
    boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25), 0px 0px 4px rgba(168, 168, 168, 0.25)",
    borderRadius: 6,
    color: "#fff",
    fontSize: 12,
  },
  btn_edit: {
    background: "linear-gradient(360deg, #019ABB 0%, rgba(167, 255, 255, 0.9) 168.18%)",
    boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25), 0px 0px 4px rgba(168, 168, 168, 0.25)",
    borderRadius: 6,
    color: "#fff",
    fontSize: 12,
    "&:hover": {
      backgroundColor: "#E38825",
    },
  },
  btn_delete: {
    background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    fontSize: 12,
    color: "#fff",
    borderRadius: 6,
    border: "1px solid #E38825",
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#DB2139",
      color: "#fff",
    },
  },

  root_modal: {
    width: "100%",
    height: "60%",
    display: "flex",
    justifyContent: "center",
  },
  modal__container: {
    width: "100%",
    maxWidth: 560,
    borderRadius: 12,
    backgroundColor: "#fff",
    zIndex: 9,
    display: "flex",
    flexDirection: "column",
    margin: "30px 0",
  },
  modal__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px",
    minHeight: 65,
    borderBottom: "1px solid rgb(235, 235, 235)",
    "& >h6": {
      fontWeight: 700,
      fontSize: 18,
      marginRight: 50,
    },
  },
  modal__content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 30px",
    "& >h6": {
      fontWeight: 600,
      fontSize: 13,
    },
  },
  form: {
    padding: "15px 30px",
    "& >h5": {
      color: "#004673",
      fontWeight: 600,
      fontSize: 16,
      marginBottom: 15,
    },
  },
  form__button: {
    background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
    color: "#fff",
    marginTop: 15,
    borderRadius: 10,
  },
}));

export default useStyles;
