import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Slide,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {Visibility} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import {Pagination} from "@material-ui/lab";
import LOGO from "assets/img/logo.png";
import managerMagazineApi from "api/managerMagazine";
import {useAppDispatch, useAppSelector} from "app/hooks";
import GetAppIcon from "@material-ui/icons/GetApp";
import ICON_PDF from "assets/img/icon_pdf.png";
import {BASE_URL} from "constants/config";
import {
  fetchCategoryList,
  selectCategoryList,
} from "features/Admin/pages/ManagerCategory/managerCategorySlice";
import {MagazineList, ResponseMagazine} from "models/managerMagazine";
import moment from "moment";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Document, Page, pdfjs} from "react-pdf";
import {useLocation} from "react-router-dom";
import {dateTimeToFormatYYYY} from "utils/dateTimeFormat";
import {
  fetchListMagazine,
  selectFilterListMagazineNews,
  SelectListMagazine,
  setFilterMagazineNews,
  setFilterSearchDebounce,
} from "../../managerMagazineSlice";
import ContentRight from "./components/contentRight";
import InputSearch from "./components/inputSearch";
import ListTrending from "./components/ListTrending";
import ListViewr from "./components/ListViewer";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export interface MagazineNewsProps {}

export default function MagazineNews(props: MagazineNewsProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > h6 ": {
        fontSize: 13,
        color: "#898989",
        [theme.breakpoints.down("sm")]: {
          fontSize: 11,
        },
      },
    },
    inputSearch: {
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
        borderRadius: 12,
      },
    },
    btn_menu_category: {
      backgroundColor: "#fff",
      color: "#000",
      borderRadius: 8,
      textTransform: "initial",
      width: "100%",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },

    menu__category__list: {
      paddingBottom: 20,
      "& .MuiMenu-paper": {
        width: "30%",
        marginTop: "150px",
      },
    },
    menu__category__item: {
      padding: "5px 30px",

      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&>img": {
        width: 30,
        height: 30,
        cursor: "pointer",
      },
    },

    category__name: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      fontSize: 15,
      backgroundColor: "rgba(44, 44, 44, 0.5)",
      color: "#fff",
      padding: "10px 0px",
      paddingLeft: 10,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& >img": {
        width: 25,
        height: 25,
        marginRight: 15,
      },
    },
    thumbnail: {
      width: "100%",
      height: 200,
      objectFit: "cover",
      marginTop: 15,
    },
    icon__visibility: {
      color: "#898989",
      fontSize: 15,
      marginRight: 5,
    },
    magazine__title: {
      fontWeight: 600,
      fontSize: 15,
    },
    magazine__search__content: {
      position: "relative",
      width: "250px",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "150px",
      },
    },
    magazine__search__thumbnail: {
      width: "100%",
      height: 170,
      objectFit: "cover",
      marginRight: 20,
      [theme.breakpoints.down("md")]: {
        height: 140,
      },
    },
    magazine__search__info: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      padding: "20px 30px",
      [theme.breakpoints.down("md")]: {
        padding: "15px 20px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },
      "& >h6": {
        fontSize: 23,
        fontWeight: 600,
        [theme.breakpoints.down("md")]: {
          fontSize: 20,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 15,
        },
      },
    },
    root_modal: {
      width: "1200px",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
    },
    modal__container: {
      width: "100%",
      borderRadius: 12,
      backgroundColor: "#fff",
      zIndex: 9,
      display: "flex",
      flexDirection: "column",
      margin: "30px 0",
    },
    modal__header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 24px",
      minHeight: 65,
      borderBottom: "1px solid rgb(235, 235, 235)",
      "& >h6": {
        fontWeight: 700,
        fontSize: 18,
        marginRight: 50,
      },
    },
    modal__content: {
      height: 600,
      overflowY: "auto",
      width: "100%",
      margin: "0 auto",
    },
    content__right: {
      position: "fixed",
      right: 40,
      top: 180,
      width: "30%",
      [theme.breakpoints.down("lg")]: {
        right: 20,
      },
    },
    content__pdf: {
      overflowY: "auto",
      overflowX: "hidden",
      width: "100%",
      height: "100%",
      "&  .react-pdf__Page": {
        "& .react-pdf__Page__canvas": {
          width: "100% !important",
          height: "100% !important",
        },
      },
    },
    loading: {
      zIndex: 99,
      backgroundColor: "rgba(255,255,255,0.8)",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      "& >div": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        "& .MuiCircularProgress-root": {
          display: "flex",
          margin: "0 auto",
        },
      },
    },
  }));
  const {t} = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [openModal, setOpenModal] = useState(false);
  const filter = useAppSelector(selectFilterListMagazineNews);
  const listMagazine = useAppSelector(SelectListMagazine);
  const [listChecked, setListChecked] = useState<number[]>([]);
  const [numPages, setNumPages] = useState(null);
  const location = useLocation();
  const [listViewer, setListViewer] = useState<any>();

  const [itemMagazine, setItemMagazine] = useState({
    name: "",
    url: "",
    category: "",
    id: "",
  });

  const [checked, setChecked] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const dispatch = useAppDispatch();
  const checkOpenMagazineFromCommunity = (location: any) => {
    if (location.state) {
      if (location.state.openModal) {
        handleClickShowModal(location.state.magazine);
      }
    }
  };
  useEffect(() => {
    checkOpenMagazineFromCommunity(location);
  }, []);
  useEffect(() => {
    if (listChecked.length > 0) {
      dispatch(
        fetchListMagazine({
          ...filter,
          category_id_list: listChecked.join(","),
        })
      );
    } else {
      dispatch(fetchListMagazine(filter));
    }
  }, [filter, listChecked]);
  useEffect(() => {
    dispatch(fetchCategoryList());
  }, []);
  const listCategory = useAppSelector(selectCategoryList);
  const handleClickChecked = (id: number) => {
    const listCheck: number[] = [...listChecked];
    const foundItem = listCheck.findIndex((x) => x === id);
    if (foundItem === -1) {
      listCheck.push(id);
    } else {
      listCheck.splice(foundItem, 1);
    }
    if (filter.page_index > 0) {
      dispatch(
        setFilterMagazineNews({
          ...filter,
          page_index: 0,
        })
      );
    }
    setListChecked(listCheck);
    setChecked(!checked);
  };

  const handleChangePage = (page: number) => {
    dispatch(
      setFilterMagazineNews({
        ...filter,
        page_index: page - 1,
        page_size: 12,
        is_hidden: false,
      })
    );
  };
  const convertTimeStamp = (time: number) => {
    if (!time) return;
    const timeStampCurrentDate = new Date().getTime();
    const diff = (timeStampCurrentDate - time) / 1000;
    if (diff > 86400) {
      return (
        <Typography variant="subtitle2">{dateTimeToFormatYYYY(String(new Date(time)))}</Typography>
      );
    }
    if (diff < 120) {
      return <Typography variant="subtitle2">{t("managerMagazine.vuadang")}</Typography>;
    }
    if (diff < 3600 && diff > 120) {
      return (
        <Typography variant="subtitle2">
          {Math.round(diff / 60)} {t("managerMagazine.phuttruoc")}
        </Typography>
      );
    } else if (diff < 86400) {
      return (
        <Typography variant="subtitle2">
          {Math.round(diff / 3600)} {t("managerMagazine.giotruoc")}
        </Typography>
      );
    }
  };

  const listCurrentDate = listMagazine.magazineList.map((magazine: MagazineList) =>
    moment(new Date(magazine.publishedTimestampUTC)).format("DD/MM/YYYY")
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFilter = {...filter, title: e.target.value, page_index: 0};
    dispatch(setFilterSearchDebounce(newFilter));
  };

  const handleClickShowModal = async (magazine: MagazineList) => {
    await setItemMagazine({
      name: magazine.title,
      url: magazine.attachmentList[0].url,
      category: magazine.categoryList[0].name,
      id: magazine.attachmentList[0].id,
    });

    setOpenModal(true);
    const formData = new FormData();
    formData.append("is_increase_view", "true");
    formData.append("magazine_id", magazine.id);
    await managerMagazineApi.updateMagazine(formData);
  };
  function onDocumentLoadSuccess({numPages}: any) {
    setNumPages(numPages);
  }

  const handleCloseModalView = () => {
    setOpenModalView(false);
  };
  const handleShowViewer = async (id: string) => {
    if (!location.pathname.includes("/admin")) return;
    setOpenModalView(true);
    try {
      const data = await managerMagazineApi.getMagazineViewer({magazine_id: id, page: 0});
      setListViewer(data.viewers);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangePageViewer = async (page: number) => {
    try {
      const data = await managerMagazineApi.getMagazineViewer({
        magazine_id: listViewer.content[0].magazine.id,
        page: page - 1,
      });
      setListViewer(data.viewers);
    } catch (error) {
      console.log(error);
    }
  };
  const [scale, setScale] = useState(1);

  const onPageLoad = (page: any) => {
    const parentDiv = document.querySelector("#pdfDocument");
    let pageScale = parentDiv && parentDiv.clientWidth / page.originalWidth;
    if (scale !== pageScale) {
      setScale(pageScale || 1);
    }
  };
  return (
    <Container maxWidth="xl">
      {listChecked.length > 0 || (filter && filter.title && filter.title.length > 0) ? (
        <Box>
          <InputSearch handleSearchChange={handleSearchChange} filter={filter} />
          <Box mt={2}>
            <Grid container>
              <Grid item xs={12} sm={7}>
                {listMagazine.magazineList.map((magazine: MagazineList) => (
                  <Box key={magazine.id} display="flex" mb={3}>
                    <Box
                      className={classes.magazine__search__content}
                      onClick={() => handleClickShowModal(magazine)}
                    >
                      <Typography variant="subtitle2" className={classes.category__name}>
                        <img src={ICON_PDF} alt="ICON_PDF" />
                        {magazine.categoryList[0].name}
                      </Typography>
                      <img
                        src={magazine.attachmentList[0].urlThumbnail || LOGO}
                        alt="background"
                        className={classes.magazine__search__thumbnail}
                      />
                    </Box>

                    <Box className={classes.magazine__search__info}>
                      <Typography variant="subtitle2">{magazine.title}</Typography>
                      <Box className={classes.root}>
                        {convertTimeStamp(magazine.publishedTimestampUTC)}
                        <Box className={classes.root}>
                          <Visibility className={classes.icon__visibility} />
                          <Typography variant="subtitle2">
                            {magazine.total_view_count} {t("managerMagazine.luotxem").toLowerCase()}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}

                {listMagazine.pageInfo?.totalPages > 1 && (
                  <Box my={3} display="flex" justifyContent="flex-start">
                    <Pagination
                      count={Math.ceil(
                        listMagazine.pageInfo?.totalElements / listMagazine.pageInfo?.pageSize
                      )}
                      page={listMagazine.pageInfo?.pageIndex + 1}
                      variant="outlined"
                      color="primary"
                      shape="rounded"
                      onChange={(e, page) => handleChangePage(page)}
                    />
                  </Box>
                )}
              </Grid>
              {isNotMobile && (
                <Grid item sm={5} className={classes.content__right}>
                  <ContentRight
                    listCurrentDate={listCurrentDate}
                    handleClickChecked={handleClickChecked}
                    listCategory={listCategory}
                    listChecked={listChecked}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      ) : (
        <>
          <InputSearch handleSearchChange={handleSearchChange} filter={filter} />

          <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <ListTrending
              handleClickShowModal={handleClickShowModal}
              handleClickShowViewer={handleShowViewer}
            />

            {isNotMobile && (
              <ContentRight
                listCurrentDate={listCurrentDate}
                handleClickChecked={handleClickChecked}
                listCategory={listCategory}
                listChecked={listChecked}
              />
            )}
          </Box>

          <Box mt={5}>
            <Grid container spacing={3}>
              {listMagazine.magazineList.map((magazine: MagazineList) => (
                <Grid item xs={6} sm={4} key={magazine.id}>
                  <Box
                    position="relative"
                    style={{cursor: "pointer"}}
                    onClick={() => handleClickShowModal(magazine)}
                  >
                    <Typography variant="subtitle2" className={classes.category__name}>
                      <img src={ICON_PDF} alt="ICON_PDF" />
                      {magazine.categoryList[0].name}
                    </Typography>
                    <img
                      src={magazine.attachmentList[0].urlThumbnail || LOGO}
                      alt="background"
                      className={classes.thumbnail}
                    />
                  </Box>

                  <Box mt={1}>
                    <Typography variant="subtitle2" className={classes.magazine__title}>
                      {magazine.title}
                    </Typography>
                    <Box className={classes.root}>
                      {convertTimeStamp(magazine.publishedTimestampUTC)}
                      <Box
                        className={classes.root}
                        style={{
                          cursor: location.pathname.includes("/admin") ? "pointer" : "initial",
                        }}
                        onClick={() => handleShowViewer(magazine.id)}
                      >
                        <Visibility className={classes.icon__visibility} />
                        <Typography variant="subtitle2">
                          {magazine.total_view_count} {t("managerMagazine.luotxem").toLowerCase()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            {listMagazine.pageInfo?.totalPages > 1 && (
              <Box my={3} display="flex" justifyContent="flex-end">
                <Pagination
                  count={Math.ceil(
                    listMagazine.pageInfo?.totalElements / listMagazine.pageInfo?.pageSize
                  )}
                  page={listMagazine.pageInfo?.pageIndex + 1}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={(e, page) => handleChangePage(page)}
                />
              </Box>
            )}
          </Box>
        </>
      )}

      {/* Modal list viewer */}
      <Modal
        open={openModalView}
        onClose={handleCloseModalView}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModalView}>
          <Box className={classes.modal__container}>
            {listViewer?.content?.length > 0 ? (
              <>
                <Box className={classes.modal__header}>
                  <div></div>
                  <Typography variant="subtitle2">
                    {listViewer?.content[0]?.magazine?.title}
                  </Typography>
                  <IconButton onClick={handleCloseModalView}>
                    <CloseIcon />
                  </IconButton>
                </Box>

                <ListViewr listViewer={listViewer} />

                <Box mt={3} display="flex" justifyContent="flex-end" marginRight="15px">
                  <Pagination
                    count={Math.ceil(listViewer?.totalElements / listViewer?.size)}
                    page={listViewer?.number + 1}
                    variant="outlined"
                    color="primary"
                    shape="rounded"
                    onChange={(e, page) => handleChangePageViewer(page)}
                  />
                </Box>
              </>
            ) : (
              <Box className={classes.loading}>
                <Box>
                  <CircularProgress style={{width: 50, height: 50}} />
                </Box>
              </Box>
            )}
          </Box>
        </Slide>
      </Modal>
      {/* Modal watch pdf */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container} id="pdfDocument">
            <Box className={classes.modal__header}>
              {location.pathname.includes("/admin") ? (
                <Tooltip title="Download">
                  <IconButton>
                    <GetAppIcon onClick={() => window.open(itemMagazine.url)} />
                  </IconButton>
                </Tooltip>
              ) : (
                <div></div>
              )}
              <Typography variant="subtitle2">
                {itemMagazine.name} - {itemMagazine.category}
              </Typography>
              <IconButton onClick={() => setOpenModal(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Document
              file={`${BASE_URL}/common/pdf/${itemMagazine.id}`}
              onLoadSuccess={onDocumentLoadSuccess}
              className={classes.content__pdf}
              error={<Box p={4}>{t("khongcodulieu")}</Box>}
              loading={
                <Box className={classes.loading}>
                  <Box>
                    <CircularProgress style={{width: 50, height: 50}} />
                  </Box>
                </Box>
              }
            >
              {Array.from(new Array(numPages), (item, index) => (
                <Page onLoadSuccess={onPageLoad} scale={scale} pageNumber={index + 1} key={index} />
              ))}
            </Document>
          </Box>
        </Slide>
      </Modal>
    </Container>
  );
}
