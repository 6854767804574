import {yupResolver} from "@hookform/resolvers/yup";
import {Avatar, Box, Button, IconButton, Menu, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import managerLessonApi from "api/managerLesson";
import {useAppDispatch, useAppSelector} from "app/hooks";
import EMOJI from "assets/img/emoji.png";
import NO_AVATAR from "assets/img/no_avatar.png";
import InputField from "components/FormFields/InputField";
import Picker, {SKIN_TONE_MEDIUM_DARK} from "emoji-picker-react";
import {selectInfoLogin} from "features/Auth/authSlice";
import {ResponseStatus} from "models/common";
import React, {ChangeEvent, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import * as yup from "yup";
import {selectDetailLesson} from "../detailLessonSlice";

type CreateCommentProps = {
  handleFetchListComment: () => void;
};

export default function CreateComment({handleFetchListComment}: CreateCommentProps) {
  const [comment, setComment] = useState("");
  const detailLesson = useAppSelector(selectDetailLesson);
  const infoLogin = useAppSelector(selectInfoLogin);
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    icon_emoji: {
      position: "absolute",
      bottom: 10,
      right: 10,
      [theme.breakpoints.down("md")]: {
        bottom: 6,
      },
      [theme.breakpoints.down("xs")]: {
        bottom: 0,
        right: 0,
      },
      "& >span >img": {
        width: 25,
        height: 25,
        [theme.breakpoints.down("xs")]: {
          width: 20,
          height: 20,
        },
      },
    },
    avatar: {
      width: 50,
      height: 50,
      marginRight: 15,
      [theme.breakpoints.down("md")]: {
        width: 40,
        height: 40,
      },
      [theme.breakpoints.down("xs")]: {
        width: 30,
        height: 30,
      },
    },
    btn__comment: {
      background:
        comment.length > 0 ? "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)" : "transparent",
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const {t} = useTranslation();

  const schema = yup.object().shape({
    comment: yup.string().required(`${t("home.detaillesson.vuilongnhapbinhluan")}`),
  });
  const [openEmoji, setOpenEmoji] = useState<null | HTMLElement>(null);
  const [openButton, setOpenButton] = useState(false);
  const dispatch = useAppDispatch();
  const initialValues = {
    comment: "",
  };
  const {control, handleSubmit, setValue, getValues, reset} = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  const handleOpenEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmoji(event.currentTarget);
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    setValue("comment", getValues().comment + emojiObject.emoji);
    setComment(emojiObject.emoji);
  };
  const handleSubmitComment = async (value: any) => {
    const valueComment = {
      comment: value.comment,
      lessionId: Number(detailLesson?.lessionUsers?.lessionId),
    };
    try {
      const res: ResponseStatus = await managerLessonApi.postComment(valueComment);
      if (res.responseCode === "00") {
        handleFetchListComment();
        setOpenButton(false);
        toast.success(`${t("home.detaillesson.binhluanthanhcong")}`);
        reset();
      } else {
        toast.error(`${t("home.detaillesson.binhluanthatbai")}`);
        throw new Error("Comment is failed");
      }
    } catch (error) {}
  };
  return (
    <form onSubmit={handleSubmit(handleSubmitComment)}>
      <Box>
        <Box className={classes.root}>
          <Box>
            <Avatar
              src={infoLogin?.profileImageUrl || NO_AVATAR}
              alt="avatar"
              className={classes.avatar}
            />
          </Box>
          <Box position="relative" width="100%">
            <InputField
              name="comment"
              label={t("home.detaillesson.vietbinhluan")}
              onClick={() => setOpenButton(true)}
              control={control}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setComment(event?.target.value)}
            />
            <IconButton onClick={handleOpenEmoji} className={classes.icon_emoji}>
              <img src={EMOJI} alt="EMOJI" />
            </IconButton>
            {openEmoji && (
              <Menu
                id="simple-menu"
                anchorEl={openEmoji}
                keepMounted
                open={Boolean(openEmoji)}
                onClose={() => setOpenEmoji(null)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Picker
                  onEmojiClick={onEmojiClick}
                  disableAutoFocus={true}
                  skinTone={SKIN_TONE_MEDIUM_DARK}
                  groupNames={{smileys_people: "PEOPLE"}}
                  native
                />
              </Menu>
            )}
          </Box>
        </Box>
        {openButton && (
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              onClick={() => {
                setOpenButton(false);
                reset();
              }}
            >
              {t("button.cancel")}
            </Button>
            <Button className={classes.btn__comment} type="submit" disabled={comment === ""}>
              {t("home.detaillesson.binhluan")}
            </Button>
          </Box>
        )}
      </Box>
    </form>
  );
}
