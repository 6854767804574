import {
  Box,
  Button,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {Pagination} from "@material-ui/lab";
import {makeStyles, withStyles} from "@material-ui/styles";
import managerMagazineApi from "api/managerMagazine";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_manager_magazine.png";
import {MagazineList, ResponseMagazine} from "models/managerMagazine";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useConfirm} from "material-ui-confirm";

import {toast} from "react-toastify";
import {dateTimeToFormatHHMM, dateTimeToFormatYYYY} from "utils/dateTimeFormat";
import {
  fetchListMagazine,
  SelectFilterListMagazine,
  SelectListMagazine,
  setFilterListTableMagazine,
} from "./managerMagazineSlice";

export default function ManagerMagazine() {
  const useStyles = makeStyles(() => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        bottom: 30,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
      },
    },
    icon__visibility: {
      color: "#797979",
      fontSize: 25,
      cursor: "pointer",
      "&:hover": {
        color: "#004673",
      },
    },
    btn_edit: {
      background: "linear-gradient(360deg, #019ABB 0%, rgba(167, 255, 255, 0.9) 168.18%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",

      marginRight: 15,
      marginLeft: 25,
    },
    btn_delete: {
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      borderRadius: 10,
      fontSize: 13,
      color: "#fff",
      padding: "5px 12px",
    },

    container: {
      maxHeight: 500,
    },
    content_time: {
      fontSize: 11,

      marginRight: 55,
    },
  }));
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        zIndex: 99,
        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",

        [theme.breakpoints.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);

  const {t} = useTranslation();
  const classes = useStyles();
  const confirm = useConfirm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const filter = useAppSelector(SelectFilterListMagazine);
  const listMagazine = useAppSelector(SelectListMagazine);
  useEffect(() => {
    dispatch(fetchListMagazine(filter));
  }, [dispatch, filter]);
  const handleChangePage = (page: number) => {
    dispatch(
      setFilterListTableMagazine({
        ...filter,
        page_index: page - 1,
      })
    );
  };

  const handleVisibilityMagazine = async (item: MagazineList) => {
    const formData = new FormData();
    formData.append("magazine_id", item.id);
    formData.append("is_hidden", String(!item.isHidden));
    try {
      const res: ResponseMagazine<MagazineList> = await managerMagazineApi.updateMagazine(formData);
      if (res.responseCode === "00") {
        dispatch(fetchListMagazine(filter));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (item: MagazineList) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {item.title}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}> {t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_edit} style={{marginRight: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    })
      .then(async () => {
        const res: ResponseMagazine<MagazineList> = await managerMagazineApi.deleteMagazine({
          magazine_id: item.id,
        });
        if (res.responseCode === "00") {
          dispatch(fetchListMagazine(filter));
          toast.success(`${t("managerMagazine.xoabaidangthanhcong")}`);
        } else {
          toast.error(`${t("managerMagazine.xoabaidangthatbai")}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Box>
      <Box className={classes.background}>
        <Typography variant="subtitle2">{t("managerMagazine.danhsachbaivietdadang")}</Typography>
      </Box>

      <Box>
        <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" width="50px">
                  {t("stt")}
                </StyledTableCell>
                <StyledTableCell align="center" width="200px">
                  {t("managerMagazine.tieude")}
                </StyledTableCell>
                <StyledTableCell align="center" width="120px">
                  {t("managerMagazine.chude")}
                </StyledTableCell>
                <StyledTableCell align="center" width="100px">
                  {t("managerMagazine.thoigian")}
                </StyledTableCell>
                <StyledTableCell align="center" width="120px">
                  {t("managerMagazine.luotxem")}
                </StyledTableCell>
                <StyledTableCell align="center" width="160px">
                  {t("thaotac")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listMagazine?.magazineList.map((magazine: MagazineList, index: number) => (
                <TableRow key={magazine.id}>
                  <StyledTableCell
                    align="center"
                    component="th"
                    scope="magazine"
                    style={{color: magazine.isHidden ? "#AAAAAA" : "rgba(0, 0, 0, 0.87)"}}
                  >
                    {listMagazine.pageInfo?.pageSize * listMagazine.pageInfo?.pageIndex + index + 1}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{color: magazine.isHidden ? "#AAAAAA" : "rgba(0, 0, 0, 0.87)"}}
                  >
                    {magazine.title}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{color: magazine.isHidden ? "#AAAAAA" : "rgba(0, 0, 0, 0.87)"}}
                  >
                    {magazine.categoryList[0].name}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{color: magazine.isHidden ? "#AAAAAA" : "rgba(0, 0, 0, 0.87)"}}
                  >
                    <Typography
                      variant="subtitle2"
                      component="p"
                      className={classes.content_time}
                      style={{color: magazine.isHidden ? "#AAAAAA" : "#DC2138"}}
                    >
                      {" "}
                      {dateTimeToFormatHHMM(String(new Date(magazine.publishedTimestampUTC)))}
                    </Typography>
                    <Typography variant="subtitle2">
                      {dateTimeToFormatYYYY(String(new Date(magazine.publishedTimestampUTC)))}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{color: magazine.isHidden ? "#AAAAAA" : "rgba(0, 0, 0, 0.87)"}}
                  >
                    {magazine.total_view_count} {t("managerMagazine.luotxem").toLowerCase()}
                  </StyledTableCell>

                  <StyledTableCell
                    style={{color: magazine.isHidden ? "#AAAAAA" : "rgba(0, 0, 0, 0.87)"}}
                  >
                    <Box display="flex" alignItems="center" justifyContent="center">
                      {magazine.isHidden ? (
                        <VisibilityOffIcon
                          className={classes.icon__visibility}
                          onClick={() => handleVisibilityMagazine(magazine)}
                        />
                      ) : (
                        <VisibilityIcon
                          className={classes.icon__visibility}
                          onClick={() => handleVisibilityMagazine(magazine)}
                        />
                      )}
                      <Button
                        className={classes.btn_edit}
                        onClick={() =>
                          navigate(`/admin/magazine-edit/${magazine.id}`, {
                            state: {
                              detailMagazine: magazine,
                            },
                          })
                        }
                      >
                        {t("button.edit")}
                      </Button>

                      <Button className={classes.btn_delete} onClick={() => handleDelete(magazine)}>
                        {t("button.delete")}
                      </Button>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {listMagazine.pageInfo?.totalPages > 1 && (
          <Box mt={3} display="flex" justifyContent="space-between">
            <Typography variant="subtitle2" style={{fontWeight: 600}}>
              {t("managerMagazine.tongbaiviet")}: {listMagazine.pageInfo.totalElements}
            </Typography>
            <Pagination
              count={Math.ceil(
                listMagazine.pageInfo?.totalElements / listMagazine.pageInfo?.pageSize
              )}
              page={listMagazine.pageInfo?.pageIndex + 1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={(e, page) => handleChangePage(page)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
