import { ResponseStatus } from "models/common";
import {
  Department,
  GroupList,
  ListOptions,
  ListResponseHiarachy,
  ListResponseOptions,
} from "models/managerGroup";
import axiosClient from "./axiosClient";

const managerGroupApi = {
  getAll(): Promise<ListResponseHiarachy<GroupList>> {
    const url = "/common/getHiarachy";
    return axiosClient.get(url);
  },
  getArea(): Promise<ListResponseOptions<ListOptions>> {
    const url = "/public/getAreas";
    return axiosClient.get(url);
  },

  // Department

  getDepartments(): Promise<ListResponseOptions<Department>> {
    const url = "/department/getDepartments";
    return axiosClient.get(url);
  },

  // WorkType
  getWorkTypes() {
    const url = "/public/getWorkTypes";
    return axiosClient.get(url);
  },

  getWorkTypeByArea(id: number | string | undefined): Promise<ListResponseOptions<ListOptions>> {
    const url = `/getWorkTypesByArea?areaId=${id}`;
    return axiosClient.get(url);
  },

  addWorkType(value: ListOptions): Promise<ResponseStatus> {
    const url = "/addWorkTypeToArea";
    return axiosClient.post(url, value);
  },
  updateWorkType(value: any) {
    const url = "/updateWorkType";
    return axiosClient.put(url, value);
  },
  deleteWorkTypeFromArea(value: ListOptions): Promise<ResponseStatus> {
    const url = "/removeWorkTypeFromArea";
    return axiosClient.delete(url, {
      data: value,
    });
  },
  //  Province
  getProvinces(
    areaId: number | string | undefined,
    workTypeId: number | string | undefined
  ): Promise<ListResponseOptions<ListOptions>> {
    const url = `/public/getProvinces?areaId=${areaId}&workTypeId=${workTypeId}`;
    return axiosClient.get(url);
  },

  getProvincesByAreaId(
    areaId: number | string | undefined
  ): Promise<ListResponseOptions<ListOptions>> {
    const url = `/public/getProvincesByAreaId?areaId=${areaId}`;
    return axiosClient.get(url);
  },
  addProvince(value: ListOptions): Promise<ResponseStatus> {
    const url = "/addProvince";
    return axiosClient.post(url, value);
  },
  updateProvince(value: ListOptions): Promise<ListOptions> {
    const url = "/updateProvince";
    return axiosClient.put(url, value);
  },
  deleteProvince(value: ListOptions): Promise<ResponseStatus> {
    const url = "/removeProvince";
    return axiosClient.delete(url, {
      data: value,
    });
  },

  //  Farm
  getFarms(
    newProvinceId: number | string | undefined,
    workTypeId: number | string | undefined
  ): Promise<ListResponseOptions<ListOptions>> {
    const url = `/getFarms?newProvinceId=${newProvinceId}&workTypeId=${workTypeId}`;
    return axiosClient.get(url);
  },

  addFarm(value: ListOptions): Promise<ResponseStatus> {
    const url = "/addFarm";
    return axiosClient.post(url, value);
  },
  updateFarm(value: string, id: number): Promise<ResponseStatus> {
    const url = `/updateFarm?farmId=${id}`;
    return axiosClient.put(url, value);
  },
  deleteFarm(id: number): Promise<ResponseStatus> {
    const url = `/deleteFarm?farmId=${id}`;
    return axiosClient.delete(url);
  },
};

export default managerGroupApi;
