import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";
import {ListParams} from "models/common";
import {ListExam, ListExamResponse} from "models/managerTest";

export interface ManagerListExamState {
  listExam: {
    content: ListExam[];
    [key: string]: any;
  };
  loading: Boolean;
  filter: ListParams;
}

const initialState: ManagerListExamState = {
  listExam: {
    content: [],
  },
  loading: false,
  filter: {
    page: 0,
    size: 10,
  },
};

const managerListExamSlice = createSlice({
  initialState,
  name: "manager-list-exam-slice",
  reducers: {
    fetchListExam(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchListExamSuccess(state, action: PayloadAction<ListExamResponse<ListExam>>) {
      state.loading = false;
      state.listExam = action.payload.exams;
    },
    setFilterListExam(state, action: PayloadAction<ListParams>) {
      state.filter = action.payload;
    },
  },
});

export const {fetchListExam, fetchListExamSuccess, setFilterListExam} =
  managerListExamSlice.actions;

export const SelectListExam = (state: RootState) => state.managerListExamReducer.listExam;
export const SelectFilterListExam = (state: RootState) => state.managerListExamReducer.filter;
export const selectLoadingListExam = (state: RootState) => state.managerListExamReducer.loading;
const managerListExamReducer = managerListExamSlice.reducer;
export default managerListExamReducer;
