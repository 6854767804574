import {makeStyles, Theme} from "@material-ui/core";

type Props = {
  background: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  background: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > img": {
      width: "80%",
      objectFit: "cover",
      height: "100%",
      margin: "0 auto",
    },
  },
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    width: "100%",
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
    "& > div > div >div": {
      padding: "4px !important",
    },
  },
  title: {
    fontSize: 14,
    width: "17%",
    color: "#1B1B1B",
    fontWeight: 550,
    [theme.breakpoints.up("xl")]: {
      fontSize: 16,
      width: "14%",
    },
  },
  title_edit: {
    color: "#004673",
    fontSize: 20,
    fontWeight: 700,
  },
  btn_submit: {
    background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
    borderRadius: 10,
    fontSize: 13,
    color: "#fff",
    padding: "5px 12px",
  },
}));

export default useStyles;
