import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import managerMagazineApi from "api/managerMagazine";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BG_FILE_PDF from "assets/img/bg_file_pdf.png";
import InputField from "components/FormFields/InputField";
import {SelectField} from "components/FormFields/SelectFiled";
import {
  fetchCategoryList,
  selectCategoryList,
} from "features/Admin/pages/ManagerCategory/managerCategorySlice";
import {MagazineList, ResponseMagazine} from "models/managerMagazine";
import moment from "moment";
import React, {useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {convertDDMMYYYYtoSeconds} from "utils/dateTimeFormat";

export interface EditMagazineProps {}

export default function EditMagazine(props: EditMagazineProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      marginBottom: 5,
      width: "100%",
    },
    header: {
      color: "#004673",
      fontSize: 25,
      fontWeight: 700,
      marginBottom: 10,
      marginTop: 30,
    },
    title: {
      fontSize: 15,
      fontWeight: 600,
      width: "14%",
      [theme.breakpoints.up("xl")]: {
        width: "10%",
      },
    },
    form__video__wrapper: {
      backgroundColor: "#fff",
      width: "100%",
      height: 400,
      boxShadow: "0px 0px 4px rgba(168, 168, 168, 0.25)",
      borderRadius: 8,
      "&:hover": {
        backgroundColor: "#ECECEC",
        transition: "linear 0.3s all",
      },
      "& >input ": {
        display: "none",
      },
    },
    form__video__content: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
      "& > span": {
        color: "#AAAAAA",
        fontSize: 13,
      },
    },
    btn_update: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 10,
      padding: "10px 15px",
    },
    btn_goBack: {
      position: "absolute",
      top: -45,
      left: -20,
    },
    loading: {
      zIndex: 99,
      backgroundColor: "rgba(255,255,255,0.8)",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      "& >div": {
        position: "absolute",
        top: "60%",
        left: "50%",
        transform: "translate(-60%,-50%)",
        "& .MuiCircularProgress-root": {
          display: "flex",
          margin: "0 auto",
        },
      },
    },
  }));

  const location: any = useLocation();
  const {id} = useParams();

  const detailMagazine: MagazineList = location.state.detailMagazine;
  const initialValues = {
    category_id_list: detailMagazine.categoryList[0].id,
    title: detailMagazine.title,
    publishDate: moment(new Date(detailMagazine.publishedTimestampUTC)).format("YYYY-MM-DD"),
    publishTime: moment(new Date(detailMagazine.publishedTimestampUTC)).format("HH:mm"),
  };
  const {control, handleSubmit} = useForm({
    defaultValues: initialValues,
  });
  const dispatch = useAppDispatch();
  const listCategory = useAppSelector(selectCategoryList);

  useEffect(() => {
    dispatch(fetchCategoryList());
  }, []);
  const classes = useStyles();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [errorFile, setErrorFile] = useState("");
  const [file, setFile] = useState(detailMagazine.attachmentList[0].url);
  const [loading, setLoading] = useState(false);

  const handleChangeFile = (acceptedFiles: any) => {
    if (acceptedFiles[0].type !== "application/pdf") {
      setErrorFile(`${t("managerMagazine.tepfilekhongdungdinhdang")}`);
      return;
    }
    setFile(acceptedFiles[0]);
  };

  const handleSubmitForm = async (value: any) => {
    setLoading(true);
    const newValue = {
      ...value,
      published_timestamp_utc: convertDDMMYYYYtoSeconds(value.publishDate, value.publishTime),
    };

    const formData = new FormData();
    formData.append("published_timestamp_utc", newValue.published_timestamp_utc);
    formData.append("title", newValue.title);
    formData.append("attachments", file);
    formData.append("category_id_list", newValue.category_id_list);
    formData.append("magazine_id", String(id));

    try {
      const res: ResponseMagazine<MagazineList> = await managerMagazineApi.updateMagazine(formData);
      if (res.responseCode === "00") {
        setLoading(false);
        toast.success(`${t("managerMagazine.capnhatthanhcong")}`);
        navigate("/admin/magazine-list");
      } else {
        toast.error(`${t("managerMagazine.capnhatthatbai")}`);
        setLoading(false);
        throw new Error(`${t("managerMagazine.capnhatthatbai")}`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box position="relative">
      {loading && (
        <Box className={classes.loading}>
          <Box>
            <CircularProgress style={{width: 50, height: 50}} />
          </Box>
        </Box>
      )}
      <Box className={classes.btn_goBack}>
        <Tooltip title={t("button.goback") || ""}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{marginLeft: 7}} />
          </IconButton>
        </Tooltip>
      </Box>

      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Typography variant="subtitle2" className={classes.header}>
          {t("managerMagazine.chinhsua").toUpperCase()}
        </Typography>
        <Box className={classes.root}>
          <Box className={classes.root} width="100%">
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerMagazine.lichdang")}
            </Typography>
            <Box className={classes.root}>
              <Box width="40%">
                <InputField
                  control={control}
                  type="date"
                  label=""
                  name="publishDate"
                  //   disabled={disableDate}
                />
              </Box>
              <Box ml={2} mr={4} width="30%">
                <InputField
                  control={control}
                  type="time"
                  label=""
                  name="publishTime"
                  //   disabled={disableTime}
                />
              </Box>

              {/* <CheckboxField
            name="postNow"
            label={t("managerLesson.dangngay")}
            control={control}
            handleChange={setIsPostNow}
          /> */}
            </Box>
          </Box>
        </Box>
        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerMagazine.chude")}
            </Typography>
            <Box className={classes.root}>
              <SelectField
                name="category_id_list"
                label={t("managerMagazine.chonchudebaidang")}
                options={listCategory}
                control={control}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerMagazine.tieude")}
            </Typography>
            <Box className={classes.root}>
              <InputField
                name="title"
                label={t("managerMagazine.nhaptieudebaidang") + "..."}
                control={control}
                rows={2}
                multiline
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.root}>
          <Box className={classes.root}>
            <Typography variant="subtitle2" className={classes.title}>
              {t("managerMagazine.themfile")}
            </Typography>
            <Dropzone onDrop={handleChangeFile}>
              {({getRootProps, getInputProps}) => (
                <Box className={classes.root} position="relative">
                  <Box
                    {...getRootProps({
                      className: classes.form__video__wrapper,
                    })}
                  >
                    <input {...getInputProps()} accept=".pdf" />

                    <Box className={classes.form__video__content}>
                      <img src={BG_FILE_PDF} alt="BG_FILE_PDF" height="90px" />
                      <Typography variant="subtitle1" component="span" style={{marginTop: 10}}>
                        {detailMagazine.attachmentList[0].name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Dropzone>
          </Box>
        </Box>
        {errorFile !== "" && (
          <Typography variant="subtitle2" color="secondary" style={{marginLeft: 100}}>
            {errorFile}
          </Typography>
        )}

        <Box textAlign="right" mt={2}>
          <Button type="submit" className={classes.btn_update}>
            {t("button.update")}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
