import {Box, Collapse, List, Typography} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {makeStyles} from "@material-ui/styles";
import ICON_PIG from "assets/img/icon_pig.png";
import ICON_PIG_ACTIVE from "assets/img/icon_pig_active.png";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {BsBook} from "react-icons/bs";
// import RiUserSettingsFill from "react-icons/ri";
import {FaGraduationCap, FaUsers, FaUsersCog} from "react-icons/fa";
import {RiNewspaperLine} from "react-icons/ri";
import {GiTrophyCup} from "react-icons/gi";
import {MdDashboard, MdOutlineChat, MdOutlinePlayLesson} from "react-icons/md";

import {RiUserSettingsFill} from "react-icons/ri";
import {NavLink} from "react-router-dom";
interface ListItemsProps {
  openDrawer: Boolean;
}

const ListItems = ({openDrawer}: ListItemsProps) => {
  const MyNavLink = React.forwardRef<any, any>((props, ref) => (
    <NavLink
      ref={ref}
      end
      to={props.to}
      className={({isActive}) =>
        `${props.className} ${isActive ? props.activeClassName : props.className}`
      }
    >
      {props.children}
    </NavLink>
  ));

  const useStyles = makeStyles(() => ({
    link: {
      width: "95%",
      margin: "0 auto",
      "& .MuiListItemIcon-root": {
        fontSize: 23,
      },
      color: "#000",
    },
    activeListItems: {
      "& .MuiListItemText-root": {
        "&> span": {
          fontWeight: 500,
          color: "#DC2138",
          "& >div>img": {
            background: `url(${ICON_PIG_ACTIVE})`,
            width: 17,
            height: 17,
            backgroundSize: "cover",
          },
        },
      },
    },
    active: {
      backgroundColor: "#EBF7FF",
      color: "#DC2138",
      width: "95%",
      margin: "0 auto",
      "& .MuiListItemText-root": {
        "&> span": {
          fontWeight: 600,
          fontSize: 16,
        },
      },
      "& .MuiListItemIcon-root": {
        fontSize: 23,
        color: "#DC2138",
      },
    },
    activeLink: {
      color: "#DC2138",
      fontWeight: 600,
      fontSize: 16,
    },
    icon: {
      width: 20,
      height: 20,
    },
    listItems: {
      width: "95%",
      margin: "0 auto",
      "& .MuiListItemText-root": {
        "& >span": {
          fontSize: 14,
        },
      },
    },
    icon_expandmore: {
      color: "#157BBC",
    },
    icon_pig: {
      width: 17,
      height: 17,
      marginRight: 25,
    },
  }));

  const classes = useStyles();
  const {t} = useTranslation();

  const [openListDashboard, setOpenListDashboard] = useState(false);
  const [openListManagerAdmin, setOpenListManagerAdmin] = useState(false);
  const [openListManagerTest, setOpenListManagerTest] = useState(false);
  const [openListManagerStudent, setOpenListManagerStudent] = useState(false);
  const [openListManagerLesson, setOpenListManagerLesson] = useState(false);
  const [openCommunity, setOpenCommunity] = useState(false);
  const [openMagazine, setOpenMagazine] = useState(false);
  return (
    <div>
      {/* Manager Chart */}
      <div onClick={() => setOpenListDashboard(!openListDashboard)}>
        <ListItem
          button
          // component={MyNavLink}
          // to="/admin"
          // activeClassName={classes.active}
          className={openListDashboard ? classes.active : classes.link}
        >
          <ListItemIcon>
            <MdDashboard />
          </ListItemIcon>
          <ListItemText primary={t("admin.listitem.bieudo")} />
          {openListDashboard ? <ExpandLess /> : <ExpandMore className={classes.icon_expandmore} />}
        </ListItem>
      </div>
      <Collapse in={openListDashboard} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            to="/admin"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.bieudophancap")}</Typography>
                </Box>
              }
            />
          </ListItem>

          <ListItem
            button
            to="/admin/chart-lesson-by-year"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.baihoctrongnam")}</Typography>
                </Box>
              }
            />
          </ListItem>

          <ListItem
            button
            to="/admin/chart-attend-by-area"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.diemdanh3mien")}</Typography>
                </Box>
              }
            />
          </ListItem>
          <ListItem
            button
            to="/admin/chart-attend-provinces-by-area"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">
                    {t("admin.listitem.diemdanhtinhtheomien")}
                  </Typography>
                </Box>
              }
            />
          </ListItem>

          <ListItem
            button
            to="/admin/chart-attend-area-by-year"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">
                    {t("admin.listitem.diemdanhmientrongnam")}
                  </Typography>
                </Box>
              }
            />
          </ListItem>

          <ListItem
            button
            to="/admin/chart-attend-province-by-year"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">
                    {t("admin.listitem.diemdanhtinhtrongnam")}
                  </Typography>
                </Box>
              }
            />
          </ListItem>

          <ListItem
            button
            to="/admin/chart-attend-farm-by-province"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">
                    {t("admin.listitem.diemdanhtraitheotinh")}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
          <ListItem
            button
            to="/admin/chart-exam-result-area-by-year"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">
                    {t("admin.listitem.kiemtramientrongnam")}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
          <ListItem
            button
            to="/admin/chart-exam-result-3-area-by-month"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">
                    {t("admin.listitem.kiemtra3mientrongthang")}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
          <ListItem
            button
            to="/admin/chart-exam-result-province-by-year"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">
                    {t("admin.listitem.kiemtratinhtrongnam")}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
          <ListItem
            button
            to="/admin/chart-exam-result-farm-by-province"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">
                    {t("admin.listitem.hocluccuatraitrongtinh")}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        </List>
      </Collapse>

      {/* Manager Group */}

      <ListItem
        button
        to="/admin/manager-group"
        component={MyNavLink}
        activeClassName={classes.active}
        className={classes.link}
      >
        <ListItemIcon>
          <FaUsers />
        </ListItemIcon>
        <ListItemText primary={t("admin.listitem.quanlyphancap")} />
      </ListItem>

      {/* Manager Admin */}
      <div onClick={() => setOpenListManagerAdmin(!openListManagerAdmin)}>
        <ListItem
          button
          // to="/admin/manager-admin"
          // component={MyNavLink}
          // activeClassName={classes.active}
          className={openListManagerAdmin ? classes.active : classes.link}
        >
          <ListItemIcon>
            <RiUserSettingsFill />
          </ListItemIcon>
          <ListItemText primary={t("admin.listitem.quanlyadmin")} />
          {openListManagerAdmin ? (
            <ExpandLess />
          ) : (
            <ExpandMore className={classes.icon_expandmore} />
          )}
        </ListItem>
      </div>

      {/* </NavLink> */}
      <Collapse in={openListManagerAdmin} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            to="/admin/manager-admin"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">
                    {t("admin.listitem.danhsachtaikhoan")}
                  </Typography>
                </Box>
              }
            />
          </ListItem>

          <ListItem
            button
            to="/admin/manager-admin-history"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.nhatkyhoatdong")}</Typography>
                </Box>
              }
            />
          </ListItem>
        </List>
      </Collapse>

      {/* Manager Student */}
      <div onClick={() => setOpenListManagerStudent(!openListManagerStudent)}>
        <ListItem
          button
          // to="/admin/manager-student"
          // component={MyNavLink}
          // activeClassName={classes.active}
          className={openListManagerStudent ? classes.active : classes.link}
        >
          <ListItemIcon>
            <FaGraduationCap />
          </ListItemIcon>
          <ListItemText primary={t("admin.listitem.quanlyhocvien")} />
          {openListManagerStudent ? (
            <ExpandLess />
          ) : (
            <ExpandMore className={classes.icon_expandmore} />
          )}
        </ListItem>
      </div>

      <Collapse in={openListManagerStudent} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            to="/admin/manager-student"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.danhsachhocvien")}</Typography>
                </Box>
              }
            />
          </ListItem>

          <ListItem
            button
            to="/admin/manager-student-register"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.danhsachdangky")}</Typography>
                </Box>
              }
            />
          </ListItem>
          <ListItem
            button
            to="/admin/manager-student-access-history"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.lichsutruycap")}</Typography>
                </Box>
              }
            />
          </ListItem>
        </List>
      </Collapse>

      <div onClick={() => setOpenListManagerLesson(!openListManagerLesson)}>
        <ListItem
          button
          // to="/admin/manager-lesson"
          // component={MyNavLink}
          // activeClassName={classes.active}
          className={openListManagerLesson ? classes.active : classes.link}
        >
          <ListItemIcon>
            <MdOutlinePlayLesson />
          </ListItemIcon>
          <ListItemText primary={t("admin.listitem.quanlybaihoc")} />
          {openListManagerLesson ? (
            <ExpandLess />
          ) : (
            <ExpandMore className={classes.icon_expandmore} />
          )}
        </ListItem>
      </div>

      <Collapse in={openListManagerLesson} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            to="/admin/manager-lesson"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.danhsachbaihoc")}</Typography>
                </Box>
              }
            />
          </ListItem>

          <ListItem
            button
            to="/admin/manager-lesson/create-new"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.themmoi")}</Typography>
                </Box>
              }
            />
          </ListItem>
        </List>
      </Collapse>

      {/* Manager Prize */}

      <ListItem
        button
        to="/admin/manager-prize"
        component={MyNavLink}
        activeClassName={classes.active}
        className={classes.link}
      >
        <ListItemIcon>
          <GiTrophyCup />
        </ListItemIcon>
        <ListItemText primary={t("admin.listitem.quanlyphanthuong")} />
      </ListItem>

      {/* Manager Test */}
      <div onClick={() => setOpenListManagerTest(!openListManagerTest)}>
        <ListItem
          button
          // to="/admin/manager-test/list"
          // component={MyNavLink}
          // activeClassName={classes.active}
          className={openListManagerTest ? classes.active : classes.link}
        >
          <ListItemIcon>
            <BsBook />
          </ListItemIcon>
          <ListItemText primary={t("admin.listitem.quanlykiemtra")} />
          {openListManagerTest ? (
            <ExpandLess />
          ) : (
            <ExpandMore className={classes.icon_expandmore} />
          )}
        </ListItem>
      </div>

      {/* </NavLink> */}
      <Collapse in={openListManagerTest} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            to="/admin/manager-test/list"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.danhsachdethi")}</Typography>
                </Box>
              }
            />
          </ListItem>

          <ListItem
            button
            to="/admin/manager-test/create-new"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.themmoi")}</Typography>
                </Box>
              }
            />
          </ListItem>

          {/* <ListItem
            button
            to="/admin/manager-test/create-test-again"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.taodethilai")}</Typography>
                </Box>
              }
            />
          </ListItem> */}
        </List>
      </Collapse>

      {/* Manager category */}

      <ListItem
        button
        to="/admin/manager-category"
        component={MyNavLink}
        activeClassName={classes.active}
        className={classes.link}
      >
        <ListItemIcon>
          <MdOutlineChat />
        </ListItemIcon>
        <ListItemText primary={t("admin.listitem.quanlychude")} />
      </ListItem>

      {/* Community */}
      <div onClick={() => setOpenCommunity(!openCommunity)}>
        <ListItem
          button
          // to="/admin/community-news"
          // component={MyNavLink}
          // activeClassName={classes.active}
          className={openCommunity ? classes.active : classes.link}
        >
          <ListItemIcon>
            <FaUsersCog />
          </ListItemIcon>
          <ListItemText primary={t("admin.listitem.congdong")} />
          {openCommunity ? <ExpandLess /> : <ExpandMore className={classes.icon_expandmore} />}
        </ListItem>
      </div>

      <Collapse in={openCommunity} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            to="/admin/community-pending"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.duyetbai")}</Typography>
                </Box>
              }
            />
          </ListItem>

          <ListItem
            button
            to="/admin/community-news"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("admin.listitem.bangtin")}</Typography>
                </Box>
              }
            />
          </ListItem>
        </List>
      </Collapse>

      {/* Magazine */}
      <div onClick={() => setOpenMagazine(!openMagazine)}>
        <ListItem
          button
          // to="/admin/magazine-list"
          // component={MyNavLink}
          // activeClassName={classes.active}
          className={openMagazine ? classes.active : classes.link}
        >
          <ListItemIcon>
            <RiNewspaperLine />
          </ListItemIcon>
          <ListItemText primary={t("managerMagazine.tapchi")} />
          {openMagazine ? <ExpandLess /> : <ExpandMore className={classes.icon_expandmore} />}
        </ListItem>
      </div>

      <Collapse in={openMagazine} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            to="/admin/magazine-list"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">
                    {t("managerMagazine.danhsachbaiviet")}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
          <ListItem
            button
            to="/admin/magazine-create"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("managerMagazine.dangbai")}</Typography>
                </Box>
              }
            />
          </ListItem>

          <ListItem
            button
            to="/admin/magazine-news"
            component={MyNavLink}
            activeClassName={classes.activeListItems}
            className={classes.listItems}
          >
            <ListItemText
              primary={
                <Box display="flex">
                  <img src={ICON_PIG} alt="icon" className={classes.icon_pig} />
                  <Typography variant="subtitle2">{t("managerMagazine.tapchi")}</Typography>
                </Box>
              }
            />
          </ListItem>
        </List>
      </Collapse>
    </div>
  );
};

export default ListItems;
