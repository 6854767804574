import { Box, Button, IconButton, Tooltip, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import authApi from "api/authApi";
import managerGroupApi from "api/managerGroupApi";
import managerUserApi from "api/managerUser";
import { useAppDispatch, useAppSelector } from "app/hooks";
import BACKGROUND from "assets/img/bg_student_edit.png";
import InputField from "components/FormFields/InputField";
import { SelectField } from "components/FormFields/SelectFiled";
import {
  fetchDataOptions,
  fetchListProvince,
  fetchListWorkTypes,
  selectListArea,
  selectListDepartment,
  selectListProvince,
  selectListWorkTypes,
} from "features/Auth/options/optionsSlice";
import { InfoUser } from "models/auth";
import { ListOptions, ListResponseOptions } from "models/managerGroup";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useStyles from "./style";

export interface EditAccountProps { }

export default function EditAccount(props: EditAccountProps) {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const listArea = useAppSelector(selectListArea);
  const listWorkTypes = useAppSelector(selectListWorkTypes);
  const listDepartment = useAppSelector(selectListDepartment);
  const location = useLocation();
  const studentPage = location.pathname.includes("/manager-student");
  const listProvince = useAppSelector(selectListProvince);
  const navigate = useNavigate();
  const [detailUser, setDetailUser] = useState<InfoUser>();
  const [sourceBack, setSuourceBack] = useState<any>();
  const [idDepartment, setIdDepartment] = useState({
    id: 0,
    name: "",
  });
  const classes = useStyles({
    background: BACKGROUND,
  });
  useEffect(() => {
    setSuourceBack(location)
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const res: ListResponseOptions<ListOptions> = await managerGroupApi.getWorkTypeByArea(
          detailUser?.area?.id
        );
        if (res.responseCode === "00") {
          dispatch(fetchListWorkTypes(res));
        }
      } catch (error) { }
    })();
    (async () => {
      try {
        const res: ListResponseOptions<ListOptions> = await managerGroupApi.getProvincesByAreaId(
          detailUser?.area?.id
        );
        if (res.responseCode === "00") {
          dispatch(fetchListProvince(res));
        }
      } catch (error) { }
    })();
    setIdDepartment({
      id: detailUser?.department?.id,
      name: detailUser?.department?.title,
    });
  }, [detailUser]);
  useEffect(() => {
    dispatch(fetchDataOptions());
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const res: InfoUser = await managerUserApi.detailUser(id);
        setDetailUser(res);
      } catch (error) { }
    })();
  }, [id]);

  const initialValues = {
    name: detailUser?.name,
    email: detailUser?.email,
    areaId: detailUser?.area?.id || "",
    workTypeId: detailUser?.workType?.id || "",
    dateOfJoining: String(moment(detailUser?.dateOfJoining).format("yyyy-MM-DD")),
    newProvinceId: detailUser?.newProvince?.id || "",
    position: detailUser?.position || "",
    departmentId: detailUser?.department?.id || "",
    phone: detailUser?.phone,
  };
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
  });

  useEffect(() => {
    reset(initialValues);
  }, [reset, detailUser]);

  const handleGetListWorkTypeAndProvinces = (option: ListOptions) => {
    handleGetListWorkType(option);
    handleGetListProvince(option);
  };

  const handleGetListWorkType = async (option: ListOptions) => {
    try {
      const res: ListResponseOptions<ListOptions> = await managerGroupApi.getWorkTypeByArea(
        option?.id
      );
      if (res.responseCode === "00") {
        dispatch(fetchListWorkTypes(res));
      }
    } catch (error) { }
  };

  const handleGetListProvince = async (option: ListOptions) => {
    try {
      const res: ListResponseOptions<ListOptions> = await managerGroupApi.getProvincesByAreaId(
        option?.id
      );
      if (res.responseCode === "00") {
        dispatch(fetchListProvince(res));
      }
    } catch (error) { }
  };
  const handleSubmitForm = async (value: any) => {
    try {
      const res: InfoUser = await authApi.updateInfo(
        idDepartment.name !== "Sản Xuất" ? { ...value, workTypeId: "" } : value,
        detailUser?.id
      );
      if (res.responseCode === "00") {
        toast.success(`${t("auth.edit.success")}`);
        if (sourceBack) {
          if (sourceBack.state === null) {
            studentPage ? navigate("/admin/manager-student") : navigate("/admin/manager-admin");
          }
          if (sourceBack.state !== null) {
            navigate("/admin/manager-student-register")
          }
        }
      } else {
        throw new Error(res.responseDescription);
      }
    } catch (error) { }
  };
  return (
    <Box position="relative">
      <Box className={classes.btn_goBack}>
        <Tooltip title="Quay lại">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{ marginLeft: 7 }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className={classes.background}>
        <Typography variant="subtitle2" className={classes.title_edit}>
          {t("admin.chitiet")}
        </Typography>
        <img src={BACKGROUND} alt="BACKGROUND" />
      </Box>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Box className={classes.root}>
          <Typography variant="subtitle2" className={classes.title}>
            {t("admin.tenhocvien")}
          </Typography>

          <Box width="100%">
            <InputField name="name" control={control} disabled />
          </Box>
        </Box>

        <Box className={classes.root}>
          <Typography variant="subtitle2" className={classes.title}>
            {t("auth.email")}
          </Typography>

          <Box width="100%">
            <InputField name="email" control={control} disabled />
          </Box>
        </Box>
        {studentPage ? (
          <>
            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("auth.area")}
              </Typography>

              <Box width="100%">
                <SelectField
                  name="areaId"
                  control={control}
                  borderRadius="4px"
                  options={listArea}
                  handleClick={handleGetListWorkTypeAndProvinces}
                  disabled
                />
              </Box>
            </Box>
            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("auth.department")}
              </Typography>

              <Box width="100%">
                <SelectField
                  disabled
                  name="departmentId"
                  control={control}
                  borderRadius="4px"
                  options={listDepartment.map(({ id, title }) => ({
                    id,
                    name: title,
                  }))}
                  handleClick={(option: any) => {
                    if (option.name !== "Sản Xuất") {
                      setValue("workTypeId", "");
                    }
                    setIdDepartment({
                      id: option.id,
                      name: option.name,
                    });
                  }}
                />
              </Box>
            </Box>

            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("auth.workType")}
              </Typography>

              <Box width="100%">
                <SelectField

                  name="workTypeId"
                  control={control}
                  borderRadius="4px"
                  options={listWorkTypes.map(({ workType }) => ({
                    id: workType.id,
                    name: workType.name,
                  }))}
                  disabled
                // disabled={
                //   listDepartment?.filter((department) => department.title === "Sản Xuất")[0]
                //     ?.id === idDepartment.id
                //     ? false
                //     : true
                // }
                />
              </Box>
            </Box>
            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("admin.tinh/thanhpho")}
              </Typography>

              <Box width="100%">
                <SelectField
                  disabled
                  name="newProvinceId"
                  control={control}
                  borderRadius="4px"
                  options={listProvince}
                />
              </Box>
            </Box>
            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("auth.position")}
              </Typography>

              <Box width="100%">
                <SelectField
                  disabled
                  name="position"
                  options={[
                    {
                      id: "Tổng quản lý - General Manager",
                      name: "Tổng quản lý - General Manager",
                    },
                    {
                      id: "Quản lý - Manager",
                      name: "Quản lý - Manager",
                    },
                    {
                      id: "Giám sát - Supervisor",
                      name: "Giám sát - Supervisor",
                    },
                    {
                      id: "Trưởng trại - Farm leader",
                      name: "Trưởng trại - Farm leader",
                    },
                    {
                      id: "Kỹ thuật trại - Farm technique",
                      name: "Kỹ thuật trại - Farm technique",
                    },
                    {
                      id: "Công nhân - Worker",
                      name: "Công nhân - Worker",
                    },
                    {
                      id: "Nhân viên - Staff",
                      name: "Nhân viên - Staff",
                    },
                    {
                      id: "Khác - Other",
                      name: "Khác - Other",
                    },
                  ]}
                  control={control}
                  borderRadius="5px"
                />
              </Box>
            </Box>

            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("admin.ngaydangky")}
              </Typography>

              <Box width="100%">
                <InputField
                  disabled
                  type="date"
                  control={control}
                  name="dateOfJoining"
                  borderRadius="5px"
                  inputType="date"
                />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box className={classes.root}>
              <Typography variant="subtitle2" className={classes.title}>
                {t("admin.sodienthoai")}
              </Typography>

              <Box width="100%">
                <InputField control={control} name="phone" disabled />
              </Box>
            </Box>
          </>
        )}
        {/* <Box textAlign="right" mt={2}>
          <Button className={classes.btn_submit} type="submit">
            {t("button.accept")}
          </Button>
        </Box> */}
      </form>
    </Box>
  );
}
