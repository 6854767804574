import {Box, Container, makeStyles, Theme, Typography} from "@material-ui/core";
import {useAppDispatch, useAppSelector} from "app/hooks";
import BACKGROUND from "assets/img/bg_manager_prize.png";
import {ListParams} from "models/common";
import React, {useEffect} from "react";
import AddPrize from "./components/AddPrize";
import InputSearch from "../../../../components/InputSearch";
import TablePrize from "./components/TablePrize";
import {fetchPrizeList, selectFilterPrizeList, setFilterSearchDebounce} from "./managerPrizeSlice";
import {useTranslation} from "react-i18next";

export default function ManagerPrize() {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        top: 50,
        left: 0,
        color: "#004673",
        fontSize: 25,
        fontWeight: 700,
      },
    },
  }));
  const classes = useStyles();
  const {t} = useTranslation();
  const filter = useAppSelector(selectFilterPrizeList);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchPrizeList(filter));
  }, [dispatch, filter]);

  const handleSearchChange = (newFilter: ListParams) => {
    dispatch(setFilterSearchDebounce(newFilter));
  };
  return (
    <Box>
      {/* Add new */}
      <AddPrize />

      <Box className={classes.background}>
        <Typography variant="subtitle2">{t("managerPrize.danhsach")}</Typography>
      </Box>
      {/*  Search by name */}
      <Box>
        <InputSearch
          filter={filter}
          onSearchChange={handleSearchChange}
          label={t("managerPrize.timkiemtheotenphanthuong")}
        />
      </Box>

      {/* Table */}
      <TablePrize />
    </Box>
  );
}
