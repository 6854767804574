import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 150,
    backgroundColor: "#FFFFFF",

    "& .MuiSelect-root": {
      padding: 10,
    },
  },
  title__area: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    margin: "15px 0",
    "& >h6": {
      fontSize: 28,
      fontWeight: 500,
    },
  },
  note_south:{
    color:"#123664"
  },
  note_north:{
    color:"#B80F0F"
  },
  note_middle:{
    color:"#EBA337"
  },
  center_layout:{
    display:"flex",
    justifyContent:'center',
    alignItems:"center"
  },
  box_note_south:{
    backgroundColor:'#123664',
    width:'40px',
    height:'20px',
    marginRight:'10px'
  },
  box_note_north:{
    backgroundColor:'#B80F0F',
    width:'40px',
    height:'20px',
    marginRight:'10px'
  },
  box_note_middle:{
    backgroundColor:'#EBA337',
    width:'40px',
    marginRight:'10px',
    height:'20px'
  }
}));

export default useStyles;
