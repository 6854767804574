import {
  Box,
  Button,
  Container,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useAppSelector} from "app/hooks";
import BACKGROUND_TYPING from "assets/img/bg_result_typing.png";
import BACKGROUND_GIOI from "assets/img/giỏi.png";
import BACKGROUND_KHA from "assets/img/khá.png";
import BACKGROUND_TB from "assets/img/tb.png";
import BACKGROUND_THUKHOA from "assets/img/thủ khoa.png";
import BACKGROUND_YEU from "assets/img/yeu.png";
import {selectInfoLogin} from "features/Auth/authSlice";
import React from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {secondsToHms} from "utils/timeConvert";

export default function ResultExam() {
  const useStyles = makeStyles((theme: Theme) => ({
    background: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    wrapper: {
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 4px 4px rgba(168, 168, 168, 0.25), 0px 1px 4px rgba(168, 168, 168, 0.4)",
      borderRadius: 6,
    },
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 15,
      borderBottom: "1px solid #C7C7C7",
      padding: "30px 25px",
      [theme.breakpoints.down("md")]: {
        padding: "18px 15px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "14px",
        marginBottom: 0,
      },
      "& > h6": {
        fontWeight: 700,
        fontSize: 23,
        [theme.breakpoints.down("md")]: {
          fontSize: 18,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 15,
        },
      },
    },
    btn_detail: {
      borderRadius: 10,
      fontSize: 15,
      padding: "5px 30px",
      background: "linear-gradient(0deg, #137BBD 0%, rgba(105, 197, 255, 0.8) 145.33%)",
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        padding: "5px 15px",
        fontSize: 11,
        borderRadius: 8,
      },
    },
    point: {
      color: "#DB2139",
      fontSize: 23,
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
      },
      "& >span": {
        color: "#0065A6",
      },
    },
    btn_goHome: {
      borderRadius: 10,
      fontSize: 15,
      padding: "5px 30px",
      background: "linear-gradient(0deg, #137BBD 0%, rgba(105, 197, 255, 0.8) 145.33%)",
      color: "#fff",
      marginTop: 15,
      [theme.breakpoints.down("xs")]: {
        padding: "5px 15px",
        fontSize: 11,
        borderRadius: 8,
      },
    },
  }));
  const {t} = useTranslation();
  const classes = useStyles();
  const location: any = useLocation();
  const infoUser = useAppSelector(selectInfoLogin);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box px={isMobile ? 1 : 3}>
      {location?.state?.type === "TYPING" ? (
        <>
          <Box width={isMobile ? "100%" : "50%"} margin="0 auto">
            <img src={BACKGROUND_TYPING} alt="BACKGROUND" className={classes.background} />
          </Box>
          <Box textAlign="center" style={{marginTop: -20}} pb={2}>
            <Typography variant="subtitle2" component="i" style={{color: "#909090"}}>
              {t("test.adminsechamdiem")}
            </Typography>
          </Box>
          <Box className={classes.wrapper} padding="0px 30px">
            <Box className={classes.root}>
              <Typography variant="subtitle2">{location?.state?.res?.examTitle}</Typography>
              <Typography variant="subtitle2" style={{color: "#DB2139"}}>
                {secondsToHms(location?.state?.res?.duration)}
              </Typography>
            </Box>
          </Box>
          <Box textAlign="center">
            <Button className={classes.btn_goHome} onClick={() => navigate("/home")}>
              {t("test.vetrangchu")}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box width={isMobile ? "100%" : "50%"} margin="0 auto">
            <img
              src={
                (location?.state?.res?.point / location?.state?.res?.maxPoint) * 100 < 50
                  ? BACKGROUND_YEU
                  : (location?.state?.res?.point / location?.state?.res?.maxPoint) * 100 < 65
                  ? BACKGROUND_TB
                  : (location?.state?.res?.point / location?.state?.res?.maxPoint) * 100 < 80
                  ? BACKGROUND_KHA
                  : (location?.state?.res?.point / location?.state?.res?.maxPoint) * 100 < 100
                  ? BACKGROUND_GIOI
                  : BACKGROUND_THUKHOA
              }
              alt="BACKGROUND"
              className={classes.background}
            />
          </Box>

          <Box className={classes.wrapper}>
            <Box className={classes.root}>
              <Typography variant="subtitle2">{location?.state?.res?.examTitle}</Typography>
              <Typography variant="subtitle2" style={{color: "#DB2139"}}>
                {secondsToHms(location?.state?.res?.duration)}
              </Typography>
            </Box>
            <Box className={classes.root} flex={1}>
              <Typography variant="subtitle2">{infoUser.name}</Typography>
              <Typography variant="subtitle2" className={classes.point}>
                {location?.state?.res?.point}{" "}
                <Typography variant="subtitle2" className={classes.point} component="span">
                  {t("test.diem")}
                </Typography>
              </Typography>
              <Button
                className={classes.btn_detail}
                onClick={() =>
                  navigate(`/test/detail/${location?.state?.res?.submissionId}`, {
                    state: {
                      examId: location?.state?.res?.examId,
                      duration: location?.state?.res?.duration,
                    },
                  })
                }
              >
                {t("button.detail")}
              </Button>
            </Box>{" "}
          </Box>
        </>
      )}
    </Box>
  );
}
