import {
  Box,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MenuIcon from "@material-ui/icons/Menu";
import {useAppSelector} from "app/hooks";
import VECTOR_CATEGORY from "assets/img/vector_category.png";
import clsx from "clsx";
import {selectLessonList} from "features/Admin/pages/ManagerLesson/managerLessonSlice";
import {Lesson} from "models/managerLesson";
import queryString from "query-string";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

export interface ListVideoRecommendProps {
  openDrawer: boolean;
  setOpenDrawer: (e: any) => void;
}

export default function ListVideoRecommend({openDrawer, setOpenDrawer}: ListVideoRecommendProps) {
  const navigate = useNavigate();
  const listLesson = useAppSelector(selectLessonList);
  const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
      overflowY: "scroll",
      height: 700,
      backgroundColor: "#fff",
      marginLeft: 15,
      boxShadow: "-2px 2px 4px rgba(168, 168, 168, 0.4)",
      borderRadius: " 10px 0px 0px 10px",
      padding: "10px 0px 10px 15px",
    },
    container: {
      maxHeight: 140,
    },
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    drawer: {
      width: "100%",
      flexShrink: 0,
    },
    drawerOpen: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      position: "initial",
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      position: "initial",
      width: "100%",
    },
    drawer__title: {
      fontWeight: 600,
      fontSize: 15,
      [theme.breakpoints.down("lg")]: {
        fontSize: 13,
      },
    },
    icon_vector_category: {
      width: 30,
      height: 20,
      [theme.breakpoints.down("lg")]: {
        width: 25,
        height: 15,
      },
    },
    menu_icon: {
      color: "#28A5F4",
    },
    image: {
      width: "100%",
      maxHeight: 140,
      cursor: "pointer",
      objectFit: "cover",
      height: "100%",
    },
    content: {
      width: "100%",
      height: "100%",
      paddingBottom: 0,
      backgroundColor: "#F3F3F3",
    },
    content__date: {
      color: "#4F4F4F",
      [theme.breakpoints.down("lg")]: {
        fontSize: 13,
      },
    },
    content__category: {
      fontWeight: 700,
      fontSize: 15,
      marginRight: 5,
      [theme.breakpoints.down("lg")]: {
        fontSize: 12,
      },
    },
    content__lesson__name: {
      fontSize: 15,
      fontWeight: 700,
      margin: "8px 0",
      cursor: "pointer",
      maxHeight: "1.4rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("lg")]: {
        fontSize: 13,
        margin: "5px 0",
      },
      "&:hover": {
        color: "#28A5F4",
        transition: "color 0.3s linear",
      },
    },
    content__lesson__description: {
      maxHeight: "2.5rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("lg")]: {
        fontSize: 12,
      },
    },
    content__lesson__status__icon: {
      marginLeft: 20,
      "& >img": {
        width: 15,
        height: 15,
        marginRight: 5,
      },
    },
  }));
  const {t} = useTranslation();
  const classes = useStyles();
  const location: any = useLocation();
  const querySearchDate = queryString.parse(location.search);
  return (
    <div className={classes.wrapper}>
      <Box className={classes.root}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpenDrawer(!openDrawer)}
          edge="start"
        >
          {!openDrawer ? (
            <ArrowBackIosIcon className={classes.menu_icon} />
          ) : (
            <MenuIcon style={{fontSize: 20}} />
          )}
        </IconButton>
        {openDrawer && (
          <>
            <Typography variant="subtitle2" className={classes.drawer__title}>
              {t("home.detaillesson.danhsachbaihockhac")}
            </Typography>
            <div></div>
          </>
        )}
      </Box>
      <Drawer
        variant="permanent"
        anchor="right"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer,
          }),
        }}
      >
        {listLesson?.content?.map((lesson: Lesson) => (
          <Box mb={2} key={lesson.id}>
            <Grid container className={classes.container}>
              <Grid item xs={openDrawer ? 5 : 12}>
                {!openDrawer ? (
                  <Tooltip title={lesson?.lessionName}>
                    <img
                      src={lesson?.attachment.urlThumbnail}
                      alt="LESSON"
                      className={classes.image}
                      onClick={() =>
                        navigate({
                          pathname: `/admin/manager-lesson/listPosted/detail/${lesson?.id}`,
                          search: `year=${querySearchDate.year}&month=${querySearchDate.month}`,
                        })
                      }
                    />
                  </Tooltip>
                ) : (
                  <img
                    src={lesson?.attachment.urlThumbnail}
                    alt="LESSON"
                    className={classes.image}
                    onClick={() =>
                      navigate({
                        pathname: `/admin/manager-lesson/listPosted/detail/${lesson?.id}`,
                        search: `year=${querySearchDate.year}&month=${querySearchDate.month}`,
                      })
                    }
                  />
                )}
              </Grid>

              {openDrawer && (
                <Grid item xs={7}>
                  <Box className={classes.content}>
                    <Box pl={2} p={1}>
                      <Box textAlign="right">
                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                          <Typography variant="subtitle2" className={classes.content__category}>
                            {lesson?.lessionCategory?.name}
                          </Typography>

                          <img
                            src={VECTOR_CATEGORY}
                            alt="background"
                            className={classes.icon_vector_category}
                          />
                        </Box>
                      </Box>

                      <Tooltip
                        disableHoverListener={lesson?.lessionName.length < 30}
                        title={<Typography variant="subtitle2">{lesson?.lessionName}</Typography>}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.content__lesson__name}
                          onClick={() =>
                            navigate({
                              pathname: `/admin/manager-lesson/listPosted/detail/${lesson?.id}`,
                              search: `year=${querySearchDate.year}&month=${querySearchDate.month}`,
                            })
                          }
                        >
                          {lesson?.lessionName}
                        </Typography>
                      </Tooltip>

                      <Tooltip
                        disableHoverListener={lesson?.lessionDescription.length < 30}
                        title={
                          <Typography variant="subtitle2">{lesson?.lessionDescription}</Typography>
                        }
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.content__lesson__description}
                        >
                          {t("home.detaillesson.mota")} : {lesson?.lessionDescription}
                        </Typography>
                      </Tooltip>
                      {/* )} */}
                      <Box pt={1} display="flex" alignItems="center">
                        <Typography variant="body1" className={classes.content__date}>
                          {String(lesson?.publishDate).slice(-2)}/
                          {String(lesson?.publishDate).slice(4, 6)}/
                          {String(lesson?.publishDate).slice(0, 4)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        ))}
      </Drawer>
    </div>
  );
}
