import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    width: "100%",
    objectFit: "cover",
  },
  container: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(168, 168, 168, 0.25), 0px 2px 4px rgba(168, 168, 168, 0.3)",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 8px",
      display: "block",
    },
  },
  content__right: {
    marginLeft: 30,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      textAlign: "center",
      marginTop: 15,
    },
    "& >img": {
      width: 200,
      height: 200,
      [theme.breakpoints.down("md")]: {
        width: 150,
        height: 150,
      },
      [theme.breakpoints.down("xs")]: {
        width: 100,
        height: 100,
      },
    },
    "& > h6": {
      textAlign: "center",
      fontSize: 30,
      color: "#DB2139",
    },
  },
  content__title: {
    backgroundColor: "#F2F2F2",
    fontSize: 20,
    fontWeight: 600,
    borderRadius: 8,
    padding: "10px 50px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 30px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
      fontSize: 14,
    },
    "&>span": {
      color: "#157BBC",
      padding: "0",
    },
  },
  btn_start: {
    background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    borderRadius: 20,
    color: "#fff",
    padding: "8px 20px",
    [theme.breakpoints.down("md")]: {
      padding: "5px 10px",
      borderRadius: 10,
    },
  },
  loading: {
    zIndex: 99,
    backgroundColor: "rgba(255,255,255,0.8)",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "& .MuiCircularProgress-root": {
      display: "flex",
      margin: "0 auto",
      height: "100%",
      marginTop: 100,
    },
  },
}));

export default useStyles;
